<script setup>
import { onMounted } from 'vue'
import { useUserStore } from '@/stores/user'

//vars
const userStore = useUserStore()

onMounted(() => {
  userStore.fetchNotifications()
})
</script>

<template>
  <v-alert
    v-if="userStore.getModuleMessages?.length > 0"
    color="black"
    class="pa-1 text-white"
  >
    <span>
      {{ userStore.getModuleMessages }}
    </span>
  </v-alert>
</template>
