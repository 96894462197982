import { google } from 'google-maps'
import { Loader, LoaderOptions } from '@googlemaps/js-api-loader'
import { MarkerClusterer } from '@googlemaps/markerclusterer'

const options = {
  apiKey: process.env.VUE_APP_MAPS_API_KEY as string,
  libraries: ['drawing', 'geometry', 'visualization', 'marker'] as (
    | 'drawing'
    | 'geometry'
    | 'visualization'
    | 'marker'
    | 'localContext'
    | 'places'
  )[],
}
const loader = new Loader(options)

// let markers: null | google.maps.MarkerLibrary = null
// let g: null | google.maps.MapsLibrary = null

async function getAdvancedMarkerLibrary(): Promise<google.maps.MarkerLibrary> {
  const g: google = await loader.load()
  return (await g.maps.importLibrary('markers')) as google.maps.MarkerLibrary
}

export { loader, MarkerClusterer, google, getAdvancedMarkerLibrary }
