import { defineStore } from 'pinia'
import {
  doc,
  updateDoc,
  collection,
  query,
  onSnapshot,
  where,
} from 'firebase/firestore'
import { firestore, firestoreQuery } from '../services/firebase'
/* async function clientQuery(db, id, callback) {
  const q = query(collection(db, 'client'), where('clientId', '==', id))

  const querySnapshot = await getDocs(q)
  querySnapshot.forEach(doc => {
    // doc.data() is never undefined for query doc snapshots
    callback(doc.data())
  })
} */
let unsubscribeSystem = () => {
  // initializes the variable
}

export const useNavigationStore = defineStore('navigation', {
  state: () => ({
    version: '',
    newVersion: '',
    menuItems: [],
    clientMenu: [],
    moduleMenu: [],
    roleMenu: [],
    redirectPath: '',
    modalTitle: '',
    toggleLeftDrawer: false,
    showMobileAdd: false,
    showMobileFilter: false,
    showModal: false, // TODO: move to below
    hasModal: false,
    hasRightDrawer: false,
    modalProperties: {
      show: false,
      header: false,
      close: false,
      footer: false,
      icon: '',
      title: '',
      confirmCloseOptions: null,
    },
    showSnackbar: false,
    isReloading: false,
    isSigningIn: false,
  }),
  getters: {
    getMainMenuItems: state => state.menuItems,
    getClientMenuItems: state => state.clientMenu,
    getRoleMenuItems: state => state.roleMenu,
    getModuleMenuItems: state => state.moduleMenu,
  },
  actions: {
    getPageId(route) {
      let returnVal
      // Willing to re-evaluate this algorithm. This could be made more efficient if we have the possibility of large menus.
      // To return the mapped page id with the provided route we loop through the menuUtems array
      // and subarrays

      this.menuItems.forEach(mi => {
        route.find(rt => {
          if (mi.route[0] === rt) returnVal = mi.pageId
          if (mi.items && typeof mi.items === 'object')
            mi.items.forEach(mii => {
              if (mii.pageId && mii.route[1] === rt) {
                returnVal = mii.pageId
              }
              if (mii.page_ID && mii.route[1] === rt) {
                returnVal = mii.page_ID
              }
            })
        })
      })
      // if this still hasn't been set, get a default route
      /* if (!returnVal) {
        this.menuItems.forEach(mi => {
          if (mi.title.toLowerCase() === 'home') {
            returnVal = mi.pageId
          }
          // if (mi.route[0].toLowerCase() === 'home') returnVal = mi.page_ID
        })
        // route[0].toLowerCase() === mi.title.toLowerCase()
      } */
      return returnVal
    },
    getPageSlug(id) {
      let returnVal
      this.menuItems.forEach(mi => {
        if (mi.pageId === id) returnVal = mi.route.join('/')
        /*route.find(rt => {
          if (mi.route[0] === rt) returnVal = mi.pageId
          if (mi.items && typeof mi.items === 'object')
            mi.items.forEach(mii => {
              if (mii.route[1] === rt) returnVal = mii.pageId
            })
        })*/
      })
      return returnVal
    },
    setMenu(menuData) {
      this.menuItems = []
      if (menuData) {
        const tempMenu = [...menuData]

        tempMenu.forEach(el => {
          // add a custom route name for the router
          // this is to avoid using PageIDs in the URL
          // we need this array to map unique routes back to PageIDs
          el['route'] =
            el.title.toLowerCase() !== 'home'
              ? [el.title.toLowerCase().replaceAll(' ', '-')]
              : []
          // scan if contains items subarray
          if (el.items && typeof el.items === 'object')
            el.items.forEach(elb => {
              elb['route'] = [
                `${el.title.toLowerCase().replaceAll(' ', '-')}`,
                `${elb.title.toLowerCase().replaceAll(' ', '-')}`,
              ]
            })
        })

        this.menuItems = [...tempMenu]
      }
    },
    setModuleMenu(src) {
      this.moduleMenu = []
      src.forEach(el => {
        // some of them don't have names
        if (el.name)
          if (!this.moduleMenu.some(x => x.moduleId === el.moduleId)) {
            this.moduleMenu.push({
              title: el.name,
              module: el.name.toLowerCase().replaceAll(' ', '-'),
              moduleId: el.moduleId,
            })
          }
      })
    },
    setClientMenu(src) {
      this.clientMenu = []
      if (src)
        src.forEach(el => {
          // check that it doesn't already exist in the menu
          if (!this.clientMenu.some(x => x.clientId === el.clientId)) {
            this.clientMenu.push({
              title: el.name,
              client: el.name.toLowerCase().replaceAll(' ', '-'),
              clientId: el.clientId,
            })
          }
        })
    },
    setRoleMenu(src) {
      this.roleMenu = []
      if (src)
        src.forEach(el => {
          if (!this.roleMenu.some(x => x.roleId === el.roleId)) {
            this.roleMenu.push({
              title: el.name,
              module: el.name.toLowerCase().replaceAll(' ', '-'),
              roleId: el.roleId,
            })
          }
        })
    },
    subscribeSystem() {
      // the previous action is purely for retrieving specific data, this one is to keep IndexedDB up-to-date
      const q = query(
        collection(firestore, 'system')
        // where('userId', '==', this.userId)
      )
      unsubscribeSystem = onSnapshot(q, querySnapshot => {
        querySnapshot.docChanges().forEach(async change => {
          // idb.addToDatabase('pages', change.doc.data())
          console.log('version updated ', change.doc.data())
          // change.doc.data()
          this.newVersion = change.doc.data()?.version
          // this.pages = await idb.getDataStore('pages')
        })
      })
    },
    /* async getClient(id) {
      let returnVal
      await firestoreQuery(db, id, {
        coll: 'client',
        field: 'clientId',
      }).then(res => {
        this.currentClient = { ...res }
        returnVal = res.name
      })
      return returnVal
    },
    async getModule(id) {
      console.log('id ', id)
      firestoreQuery(db, id, {
        coll: 'module',
        field: 'moduleId',
      }).then(res => {
        this.currentModule = { ...res }
      })
    }, */
    updateVersion() {
      this.version = this.newVersion
    },
    clearNavigation() {
      // resets everything except version
      // this.newVersion = ''
      this.menuItems = []
      this.clientMenu = []
      this.moduleMenu = []
      this.roleMenu = []
      this.redirectPath = ''
      this.modalTitle = ''
      this.toggleLeftDrawer = false
      this.showMobileAdd = false
      this.showMobileFilter = false
      this.showModal = false
      this.modalProperties = {
        show: false,
        header: false,
        close: false,
        footer: false,
        icon: '',
        title: '',
      }
      this.showSnackbar = false
      this.isSigningIn = false
      // this.isReloading = false
    },
  },
  persist: true,
})
