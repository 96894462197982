export function isPhone(): boolean {
  const userAgent = navigator.userAgent.toLowerCase()

  const isPhone = /(mobile)/.test(userAgent)
  // const isPhone = /(iphone|android)/.test(userAgent)
  // navigator.userAgent.match(/iPhone/i)
  if (isPhone) return true
  else return false
}

export function isAndroid(): boolean {
  const userAgent = navigator.userAgent.toLowerCase()
  const isAndroid = /(android)/.test(userAgent)
  // navigator.userAgent.match(/iPhone/i)
  if (isAndroid) return true
  else return false
}

export function isIPhone(): boolean {
  const userAgent = navigator.userAgent.toLowerCase()
  const isIPhone = /(iphone)/.test(userAgent)
  // navigator.userAgent.match(/iPhone/i)
  if (isIPhone) return true
  else return false
  // const platform = navigator?.platform.toLowerCase()

  // return platform === 'iphone'
}

export function isIPhoneSE(): boolean {
  return (
    (window.innerHeight === 667 && window.innerWidth === 375) ||
    (window.innerHeight === 548 && window.innerWidth === 375)
  )
}

export function isIPhoneSElandscape(): boolean {
  return window.innerHeight === 375 && window.innerWidth === 667
}

export function isIPhoneSEsm(): boolean {
  return (
    (window.innerHeight === 647 && window.innerWidth === 375) ||
    (window.innerHeight === 325 && window.innerWidth === 667)
  )
}

export function isTablet(): boolean {
  const userAgent = navigator.userAgent.toLowerCase()

  const isIpad =
    navigator.maxTouchPoints &&
    navigator.userAgent.includes('Safari') &&
    !navigator.userAgent.includes('Chrome')

  const isTablet =
    /(ipad|tablet|(android(?!.*mobile))|(windows(?!.*phone)(.*touch))|kindle|playbook|silk|(puffin(?!.*(IP|AP|WP))))/.test(
      userAgent
    )

  // stupid Google is forcing high-end tablets to select "desktop mode" by default.

  const isForcedDesktopMode =
    !isPhone() && // it's not a phone
    !isTablet && // it's not a tablet--possibly because Chrome is pretending to be desktop
    !isIpad && // it's not an iPad
    window.innerWidth < window.innerHeight && // this isn't perfect, but if the window is skinnier than it's tall, there's a good chance it's a portrait tablet
    navigator.maxTouchPoints > 0 // this should rule out a lot of desktop computers

  // navigator.userAgent.toLowerCase().match(/iPad/i)
  if (isIpad || isTablet || isForcedDesktopMode) return true
  else return false
}

export function isPWA(): boolean {
  if (
    isIPhone() &&
    'standalone' in window.navigator &&
    // eslint-disable-next-line
    // @ts-ignore
    window.navigator.standalone
  )
    return true

  if (isAndroid() && window.matchMedia('(display-mode: standalone)').matches)
    return true

  return false
}

export function isPortrait(): boolean {
  const portrait = window.matchMedia('(orientation: portrait)')
  let returnVal = portrait.matches
  portrait.addEventListener('change', function (e) {
    if (e.matches) {
      // Portrait mode
      returnVal = true
    } else {
      // Landscape
      returnVal = false
    }

    // tablets need to refresh each time the orientation changes to rework the selected layout
    if (isTablet()) window.location.reload()
  })
  return returnVal
}
