import { getId } from '@/services/lookup'
import {
  MapToolDiff,
  MapWidgetToolManager,
  MapFilteredRecordsetDef,
  MapZoomObj,
  IMapSubtool,
} from './WidgetMap.model'
import { google } from '@/services/maps'
import { makeid } from '@/services/utils'
import { MarkerManager } from '@googlemaps/markermanager'
import { MarkerWithLabel } from '@googlemaps/markerwithlabel'

/**
 * A class that manages a collection of `MapDatapoint`s for a singular
 * recordset. This class keeps track of filters for that recordset. Each filter
 * must be registered with `registerFilter()`, after which it can be updated
 * with `refreshFilter()`, which uses the current tool state to calculate which
 * records in the recordset belong in the given filter.
 */
export class MapDataset {
  recordsetName: string
  rawRecordset: IMapDataRecord[]
  datapointDict: { [recordId: string]: MapDatapoint }
  markerManagerDict: { [filterName: string]: MarkerManager }

  filterRefDict: { [filterName: string]: string[] } // The definition of each record
  filterDefDict: { [filterName: string]: MapFilteredRecordsetDef } // The records that each filter points to
  filterUpdateDict: { [propName: string]: { [filterName: string]: true } } // Props that update filters

  /**
   * Creates a new MapDataset from the given recordset
   * @param recordset A recordset passed in from the datahandler
   * @param recordsetName The name of the recordset, for later reference
   */
  constructor(
    recordset: IMapDataRecord[],
    recordsetName: string,
    map: google.maps.Map
  ) {
    this.recordsetName = recordsetName
    this.rawRecordset = recordset
    this.datapointDict = {}
    this.filterDefDict = {}
    this.filterRefDict = {}
    this.markerManagerDict = {}
    this.filterUpdateDict = {}
    for (const record of recordset) {
      const datapoint = new MapDatapoint(record, recordsetName, map)
      this.datapointDict[datapoint.id] = datapoint
    }
  }

  /**
   * Consumes a filtered recordset definition and registers it to this map
   * dataset. This allows the filter to be shown, hidden, and updated. This
   * method also register's the filter's zoom states using the tool manager.
   * @param filteredRecordsetDef The definition object for the filtered
   * recordset that is being registered
   * @param toolManager The tool manager object that contains the current state
   * of the map's tools. This is used to calculate which records currently
   * belong in the filter and to initialize the filter's datapoints as either
   * visible or invisible.
   * @param google The google object, for adding datapoints
   * @param map The map object, for adding datapoints' markers and polylines
   */
  registerFilter(
    filteredRecordsetDef: MapFilteredRecordsetDef,
    toolManager: MapWidgetToolManager,
    google: google,
    map: google.maps.Map
  ) {
    // Save this filter definition
    this.filterDefDict[filteredRecordsetDef.name] = filteredRecordsetDef
    this.markerManagerDict[filteredRecordsetDef.name] = new MarkerManager(
      map,
      {}
    )
    // Register this filter's update properties
    for (const filter of filteredRecordsetDef.filters) {
      for (const value of filter.values) {
        if (
          value.length > 2 &&
          value[0] === '[' &&
          value[value.length - 1] === ']'
        ) {
          const subs = value?.substring(1, value.length - 1) // Remove brackets
          if (!this.filterUpdateDict[subs]) {
            this.filterUpdateDict[subs] = {}
          }
          this.filterUpdateDict[subs][filteredRecordsetDef.name] = true
        }
      }
    }
    this.refreshFilter(filteredRecordsetDef.name, toolManager, true)

    // Initialize all datapoints in this filter to be shown as needed
    const toolState =
      toolManager.activeState.stateDict[filteredRecordsetDef.name]
    for (const recordId of this.filterRefDict[filteredRecordsetDef.name]) {
      this.datapointDict[recordId].init(google, map, toolState.value)
    }
  }

  /**
   * Refreshes the named filter by using the given tool manager to recalculate
   * the records to which the given filter currently applies.
   * @param filterName The name of the filter to refresh
   * @param toolManager The tool manager containing the current state of the
   * tools, from which the new definition of a filter can be extracted
   */
  private refreshFilter(
    filterName: string,
    toolManager: MapWidgetToolManager,
    isInitializingFilter = false
  ) {
    const filterDef = this.filterDefDict[filterName]
    if (!filterDef) return
    let filteredRecordset = this.rawRecordset
    for (const filter of filterDef.filters) {
      // Generate the list of expanded filter values
      const expandedFilterValues: string[] = []
      for (const value of filter.values) {
        if (
          value.length > 2 &&
          value[0] === '[' &&
          value[value.length - 1] === ']'
        ) {
          for (const activeChildTool of toolManager.menuState.getActiveChildren(
            value.substring(1, value.length - 1)
          )) {
            expandedFilterValues.push(activeChildTool)
          }
        } else {
          expandedFilterValues.push(value)
        }
      }

      filteredRecordset = filteredRecordset.filter(record => {
        switch (filter.compare) {
          case 'isAnyOf': // deliberate fall-through
          case 'isEqualTo':
            return expandedFilterValues.includes(
              (record as any)?.[filter.property]
            )
        }
      })
    }
    const filteredIdList = filteredRecordset.map(
      record => getId(record, this.recordsetName) ?? ''
    )
    this.filterRefDict[filterName] = filteredIdList
    // Update this filter's marker manager
    if (!isInitializingFilter) this.markerManagerDict[filterName].clearMarkers()
    for (const id of filteredIdList) {
      this.datapointDict[id].addMarkersToManager(
        this.markerManagerDict[filterName],
        toolManager.toolDefDict[filterName]
      )
    }
  }

  /**
   * Applies any needed updates to this dataset's filters based on the changes
   * in tools' states.
   * @param toolNames A list of the names of tools whose state changed or whose
   * children's state changed. This is the list of any tool whose change could
   * trigger a filter update.
   * @param toolManager The tool manager, which holds the state of the map and
   * menu.
   */
  updateFiltersUsingToolList(
    toolNames: string[],
    toolManager: MapWidgetToolManager
  ) {
    const toolsThatTriggerUpdatesDict: { [toolName: string]: true } = {}
    for (const toolName of toolNames) {
      // If this tool triggers any updates, save that fact
      if (this.filterUpdateDict[toolName]) {
        toolsThatTriggerUpdatesDict[toolName] = true
      }
    }

    // Convert the tools that trigger updates into filters to update
    const filtersToUpdateDict: { [filterName: string]: true } = {}
    for (const toolName of Object.keys(toolsThatTriggerUpdatesDict)) {
      for (const filterName of Object.keys(
        this.filterUpdateDict[toolName] ?? {}
      )) {
        filtersToUpdateDict[filterName] = true
      }
    }

    for (const filterName of Object.keys(filtersToUpdateDict)) {
      // Hide each record the filter originally referred to
      if (
        toolManager.activeState.stateDict[filterName]?.value ||
        !Object.prototype.hasOwnProperty.call(
          toolManager.menuState.stateDict,
          filterName
        )
      ) {
        this.applyStateChange(
          filterName,
          {
            showMarkers: false,
            showPolylines: false,
          },
          toolManager
        )
      }

      // Update which records are members of the filter
      this.refreshFilter(filterName, toolManager)

      // Show all records the filter now shows if the tool is selected or if
      // this filter does not correspond to a selectable tool
      if (
        toolManager.menuState.stateDict[filterName]?.value ||
        !Object.prototype.hasOwnProperty.call(
          toolManager.menuState.stateDict,
          filterName
        )
      ) {
        this.applyStateChange(
          filterName,
          {
            showMarkers:
              toolManager.menuState.stateDict[filterName]?.showMarkers,
            showPolylines:
              toolManager.menuState.stateDict[filterName]?.showPolylines,
          },
          toolManager
        )
      }
      /* Re-hide markers to fix a bug where adding them to a manager the first 
      time shows them regardless of state. This must be done here because this
      method only executes on user action after the map has loaded */
      if (
        !toolManager.menuState.stateDict[filterName]?.value ||
        !toolManager.menuState.stateDict[filterName]?.showMarkers
      ) {
        this.markerManagerDict[filterName]?.hide()
      }
    }
  }

  /**
   * Changes the visibility of datapoints in the given filter using the provided
   * changes. A marker manager handles marker visibility, but the polylines are
   * shown and hidden manually.
   * @param filterName The name of the filter whose visibility is being
   * updated
   * @param state The visibility changes to apply to this filter
   * @param categories An optional parameter that can be used to filter down
   * markers and polylines within a single datapoint
   */
  setVisibility(
    filterName: string,
    state: {
      showMarkers?: boolean
      showPolylines?: boolean
    },
    markerManager: MarkerManager,
    toolManager: MapWidgetToolManager
  ) {
    const targetRecords: string[] = this.filterRefDict[filterName] ?? []
    for (const recordId of targetRecords) {
      if (Object.prototype.hasOwnProperty.call(state, 'showMarkers')) {
        if (state.showMarkers) {
          markerManager?.show()
          // The nuclear option - rebuild and clear the dataset on show/hide.
          // TODO: Revisit this if performance becomes an issue
          for (const datapointId of this.filterRefDict[filterName]) {
            this.datapointDict[datapointId].addMarkersToManager(
              this.markerManagerDict[filterName],
              toolManager.toolDefDict[filterName]
            )
          }
        } else {
          markerManager?.hide()
          markerManager?.clearMarkers()
        }
      }
      if (Object.prototype.hasOwnProperty.call(state, 'showPolylines'))
        this.datapointDict[recordId].setPolylineVisibility(
          state.showPolylines ?? false
        )
    }
  }

  /**
   * Applies the given state change to the given filter.
   * @param filterName The name of the filter whose state is being changed
   * @param state The prescribed state change for the given filter
   */
  applyStateChange(
    filterName: string,
    state: { showMarkers?: boolean; showPolylines?: boolean },
    toolManager: MapWidgetToolManager
  ) {
    this.setVisibility(
      filterName,
      state,
      this.markerManagerDict[filterName],
      toolManager
    )
  }

  /**
   * Initializes all datapoints in this dataset that were not previously
   * initialized when registering a filter. This method is necessary because it
   * allows datapoints to be shown when filter definitions change. Deferring
   * this initialization selectively helps calculate visibility state only when
   * filters are registered and updated.
   * @param google The google object, necessary for initializing google-based
   * objects
   * @param map The map that datapoints should be initialized to
   */
  initializeNonVisibleDatapoints(google: google, map: google.maps.Map) {
    for (const datapoint of Object.values(this.datapointDict)) {
      if (!datapoint.isInitialized()) {
        datapoint.init(google, map, false)
      }
    }
  }
}

/**
 * A class that manages a collection of polylines and markers for a single
 * record within a recordset. This class couples a record with its map elements.
 */
export class MapDatapoint {
  id: string
  markerDict: { [id: string]: google.maps.Marker }
  polylineDict: { [id: string]: google.maps.Polyline }
  record: IMapDataRecord
  private _isInitialized: boolean
  persistLabelDict: { [markerId: string]: boolean }
  map: google.maps.Map

  /**
   * Creates a datapoint for the given record. To initialize this datapoint's
   * markers and polylines, call `init()`.
   * @param record The record around which this datapoint should be built.
   * @param recordsetName The name of the record set that this record is from,
   * which is used to look up the key name and key of this record
   */
  constructor(
    record: IMapDataRecord,
    recordsetName: string,
    map: google.maps.Map
  ) {
    this.record = record
    this.markerDict = {}
    this.polylineDict = {}
    this.persistLabelDict = {}
    this.id = getId(record, recordsetName) ?? `fake-id-${makeid(6)}`
    this._isInitialized = false
    this.map = map
  }

  /**
   * Initializes the map elements for this datapoint. This is deferred from
   * creation as a computational optimization.
   * @param google The google object, necessary for creating google objects.
   * @param map The map on which this datapoint's elements should be
   * initialized.
   * @param isVisible Whether or not to initialize this point as visible.
   * @returns This datapoint, for constructor chaining
   */
  init(google: google, map: google.maps.Map, isVisible: boolean) {
    const elements = MapDataRecord.getElements(this.record)

    // Initialize Marker Map Objects
    if (elements?.length && google) {
      for (const elementWithMarker of elements.filter(elt => elt.marker) ??
        []) {
        let pixelOffset = -40 // Default

        if (this.record.type === 'ChainMarker') {
          let isMarkerOffset = false
          let labelString = elementWithMarker.label.match(
            />[0-9]+-[0-9+]+[0-9+]</g
          )?.[0]
          if (labelString) {
            const parsedLabelString = labelString.substring(
              1,
              labelString.length - 1
            ) // 0000-00+0
            labelString = parsedLabelString.split('+')[1]
            if (labelString === '0') {
              labelString = parsedLabelString.split('-')[0]
              labelString = '' + parseInt(labelString) // Trim leading zeros
            } else {
              isMarkerOffset = true
            }
          } else {
            labelString = elementWithMarker.id.split('-')[1]
          }

          pixelOffset = isMarkerOffset ? -30 : -40 // Set dynamic pixel offset for labels
          this.markerDict[elementWithMarker.id] = new MarkerWithLabel({
            position: {
              lat: parseFloat('' + elementWithMarker.marker!.lat), // TODO: Remove parse when config allows
              lng: parseFloat('' + elementWithMarker.marker!.lng),
            },
            icon: {
              url: elementWithMarker.marker!.icon,
              scaledSize: isMarkerOffset
                ? new google.maps.Size(30, 30)
                : new google.maps.Size(40, 40),
            },
            labelContent: labelString,
            labelAnchor: isMarkerOffset
              ? new google.maps.Point(-22, -27)
              : new google.maps.Point(-22, -34),
            labelClass: isMarkerOffset
              ? 'markerLabelOffset'
              : 'markerLabelStation',
          })
        } else {
          this.markerDict[elementWithMarker.id] = new google.maps.Marker({
            position: {
              lat: parseFloat('' + elementWithMarker.marker!.lat), // TODO: Remove parse when config allows
              lng: parseFloat('' + elementWithMarker.marker!.lng),
            },
            icon: {
              url: elementWithMarker.marker!.icon,
              // url: `/img/icons/widgets/map/${elementWithMarker.marker!.icon}`,
              scaledSize: new google.maps.Size(40, 40),
              labelOrigin: new google.maps.Point(20, 12),
            },
            // map: undefined, // Allow the marker manager to handle visibility
            // visible: true,
          })
        }

        // Set up marker label events if needed
        if (elementWithMarker.label) {
          this.persistLabelDict[elementWithMarker.id] = false

          const infoWindow = new google.maps.InfoWindow({
            content: elementWithMarker.label,
            ariaLabel: `${this.id} - ${elementWithMarker.id}`,
          } as google.maps.InfoWindowOptions)

          this.markerDict[elementWithMarker.id].addListener('click', () => {
            this.persistLabelDict[elementWithMarker.id] = true
            infoWindow.open({
              anchor: this.markerDict[elementWithMarker.id],
              map,
            } as any)
          })
          this.markerDict[elementWithMarker.id].addListener('mouseover', () => {
            infoWindow.open({
              anchor: this.markerDict[elementWithMarker.id],
              map,
            } as any)
          })
          this.markerDict[elementWithMarker.id].addListener('mouseout', () => {
            if (!this.persistLabelDict[elementWithMarker.id]) infoWindow.close()
          })

          infoWindow.addListener('closeclick', () => {
            this.persistLabelDict[elementWithMarker.id] = false
          })

          if (this.record.type === 'ChainMarker') {
            // If this is an advanced marker
            const markerPosition =
              this.markerDict[elementWithMarker.id].getPosition()!
            infoWindow.setPosition(
              new google.maps.LatLng(
                markerPosition.lat(), // + 0.0001,
                markerPosition.lng()
              )
            )
            infoWindow.setOptions({
              pixelOffset: new google.maps.Size(0, pixelOffset),
            })
          }
        }
      }
    }

    // Initialize Polyline Map Objects
    if (elements?.length && google) {
      for (const elementWithPolyline of elements.filter(elt => elt.polyline)) {
        const id = elementWithPolyline.id ?? makeid(6)
        this.polylineDict[id] = new google.maps.Polyline({
          path: elementWithPolyline.polyline!.coordinates,
          geodesic: true,
          strokeColor: elementWithPolyline?.polyline!.color ?? '#000000',
          strokeOpacity: 1,
          strokeWeight: 6,
          visible: isVisible,
        })
        if (isVisible) {
          this.polylineDict[id].setMap(map)
        }
      }
    }
    this._isInitialized = true
    return this
  }

  /**
   * Sets the visibility of this datapoint's polylines
   * @param isVisible The visibility state to set this datapoint's polylines to
   * @param categories The optional category on which to filter the polylines that
   * should be shown or hidden.
   */
  setPolylineVisibility(
    isVisible: boolean,
    categories: string[] | undefined = undefined
  ) {
    for (const polyline of Object.values(this.polylineDict)) {
      polyline.setMap(isVisible ? this.map : null)
      polyline.setVisible(isVisible)
    }
  }

  /**
   * @returns True if this datapoint has already been initialized, false
   * otherwise
   */
  isInitialized(): boolean {
    return this._isInitialized
  }

  /**
   * Adds all of this datapoint's markers to the given manager, and calculates
   * the appropriate zoom values for each marker
   * @param markerManager The marker manager to which this datapoint's markers should
   * be added
   * @param toolDef The map tool corresponding to the filter being updated. This
   * is needed to calculate zoom behavior.
   */
  addMarkersToManager(markerManager: MarkerManager, toolDef: IMapSubtool) {
    for (const elementWithMarker of MapDataRecord.getElements(
      this.record
    )?.filter(elt => elt.marker) ?? []) {
      let minZoom = 1
      let maxZoom = 22

      const elementZoomObj = MapElement.extractZoom(elementWithMarker)
      if (elementZoomObj) {
        minZoom = elementZoomObj.min
        maxZoom = elementZoomObj.max
      } else if (toolDef?.zoom) {
        const toolZoomObj = MapZoomObj.getZoomForCategory(
          toolDef.zoom,
          this.record?.category
        )
        if (toolZoomObj) {
          minZoom = toolZoomObj.min
          maxZoom = toolZoomObj.max
        }
      }
      const marker = this.markerDict[elementWithMarker.id]
      if (marker) {
        markerManager.addMarker(marker, minZoom, maxZoom)
      } else {
        console.error(
          `No initialized marker found for element ${elementWithMarker.id}`
        )
      }
    }
  }
}

/**
 * A lightweight class corresponding to the structure of a firestore record that
 * contains map data
 */
export class MapDataRecord implements IMapDataRecord {
  properties?: {
    elements: MapElement[]
  }
  track: string
  line: string
  category: string
  type: string
  railId: string | number

  constructor(record: {
    properties?: {
      elements: MapElement[]
    }
    track: string
    line: string
    category: string
    type: string
    railId: string | number
  }) {
    this.properties = record.properties
    this.track = record.track
    this.line = record.line
    this.category = record.category
    this.type = record.type
    this.railId = record.railId
  }

  static getData(r: IMapDataRecord): { elements: MapElement[] } | undefined {
    if (r?.properties) return r?.properties
    if (r?.mapData) return r?.mapData
    return undefined
  }

  static getElements(r: IMapDataRecord): MapElement[] | undefined {
    return MapDataRecord.getData(r)?.elements
  }
}

/**
 * A lightweight interface corresponding to the structure of a firestore record
 * that contains map data
 */
export interface IMapDataRecord {
  mapData?: null | {
    elements: MapElement[]
  }
  properties?: null | {
    elements: MapElement[]
  }
  track: string
  line: string
  category: string
  type: string
  railId: string | number
}

export class MapElement {
  marker?: MapMarker
  polyline?: MapPolyline
  linear?: any
  label: string
  id: string
  category?: string

  constructor(data: {
    marker?: MapMarker
    polyline?: MapPolyline
    label: string
    id: string
    category?: string
  }) {
    this.label = data.label
    if (data.marker) this.marker = data.marker
    if (data.polyline) this.polyline = data.polyline
    this.id = data.id
    if (data.category) this.category = data.category
  }

  /**
   * Produces a MapZoomObj describing a MapElement's zoom properties if such
   * properties are present
   * @param elt The map element from which a zoom element is being extracted.
   * @returns A MapZoomObj that describes the zoom state of this element, or
   * undefined if this is not calculable
   */
  static extractZoom(elt: MapElement): MapZoomObj | undefined {
    if (!elt.marker?.zoom?.length) return undefined
    let min = elt.marker.zoom[0]
    let max = elt.marker.zoom[0]

    for (const zoomEntry of elt.marker.zoom) {
      if (zoomEntry < min) min = zoomEntry
      if (zoomEntry > max) max = zoomEntry
    }

    return { min, max, category: '' } as MapZoomObj
  }
}

/**
 * A lightweight class corresponding to the data needed for a google.maps.Marker
 * object. One member of a MapDataRecord's `mapData.markers` list
 */
export class MapMarker {
  // label: string
  // category: string
  // id: string
  icon: string
  lat: number
  lng: number
  zoom: number[]

  constructor(marker: {
    // label: string
    // category: string
    // id: string
    icon: string
    lat: number
    lng: number
    zoom: number[]
  }) {
    // this.label = marker.label
    // this.category = marker.category
    // this.id = marker.id
    this.icon = marker.icon
    this.lat = marker.lat
    this.lng = marker.lng
    this.zoom = marker.zoom
  }
}

/**
 * A lightweight class corresponding to the data needed for a
 * google.maps.Polyline object. One member of a MapDataRecord's
 * `mapData.polylines` list
 */
export class MapPolyline {
  path: string
  color: string
  coordinates: google.maps.LatLng[]

  constructor(polyline: {
    path: string
    color: string
    coordinates: google.maps.LatLng[]
  }) {
    this.path = polyline.path
    this.color = polyline.color
    this.coordinates = polyline.coordinates
  }
}

/**
 * A class for managing multiple map datasets corresponding to multiple
 * recordsets. Stores lookup references and the map's current zoom state. This
 * class must be initialized after the map object is created.
 */
export class MapDatasetManager {
  google: google
  map: google.maps.Map
  currentZoom: number
  datasetDict: { [recordsetName: string]: MapDataset }
  filterRefDict: { [filterName: string]: string }

  /**
   * Creates a dataset manager object to manage all datasets for the given map
   * @param google The google object, which this map dataset manager saves
   * @param map A reference to the map on which this map dataset manager's
   * datapoints should be placed.
   */
  constructor(google: google, map: google.maps.Map) {
    this.google = google
    this.map = map
    this.currentZoom = map.getZoom()
    this.datasetDict = {}
    this.filterRefDict = {}
  }

  /**
   * Registers the given recordset by creating a MapDataset that corresponds to
   * it.
   * @param recordset A list of records retrieved from the database that
   * contain map data
   * @param recordsetName The name of the recordset, used for key lookup
   */
  registerRecordset(recordset: IMapDataRecord[], recordsetName: string) {
    this.datasetDict[recordsetName] = new MapDataset(
      recordset,
      recordsetName,
      this.map
    )
  }

  /**
   * Returns true if the given recordset has already been registered, false
   * otherwise
   * @param recordsetName The name of the recordset to search for within this
   * MapDatasetManager
   * @returns True if this dataset manager contains the given recordset, false
   * otherwise
   */
  hasRecordset(recordsetName: string) {
    return Object.prototype.hasOwnProperty.call(this.datasetDict, recordsetName)
  }

  /**
   * Registers a given filter to its appropriate dataset. This has the side
   * effect of initializing all datapoints that are actively included in the
   * filter to their appropriate visibility state.
   * @param filteredRecordsetDef The filtered recordset definition to register
   * on the appropriate `MapDataset`
   * @param toolManager The tool state from which the current state of the given
   * filter is extracted. This helps initialize the list of records to which the
   * filter refers.
   */
  registerFilter(
    filteredRecordsetDef: MapFilteredRecordsetDef,
    toolManager: MapWidgetToolManager
  ) {
    this.datasetDict?.[filteredRecordsetDef.recordset]?.registerFilter(
      filteredRecordsetDef,
      toolManager,
      this.google,
      this.map
    )
    this.filterRefDict[filteredRecordsetDef.name] =
      filteredRecordsetDef.recordset
  }

  /**
   * Applies any needed updates to the filters in all relevant datasets based on
   * the given tool states.
   * @param toolNames A list of the names of tools whose state changed or whose
   * children's state changed. This is the list of any tool whose change could
   * trigger a filter update.
   * @param toolManager The tool manager, which holds the state of the map and
   * menu.
   */
  updateFiltersUsingToolList(
    toolNames: string[],
    toolManager: MapWidgetToolManager
  ) {
    for (const dataset of Object.values(this.datasetDict)) {
      dataset.updateFiltersUsingToolList(toolNames, toolManager)
    }
  }

  /**
   * Applies visibility changes in datapoints as prescribed by the given list of
   * diffs.
   * @param diffArray A list of diff objects that describe the changes in the
   * map's state.
   */
  applyDiff(diffArray: MapToolDiff[], toolManager: MapWidgetToolManager) {
    for (const diff of diffArray) {
      const datasetName = this.filterRefDict[diff.toolName]
      if (datasetName) {
        this.datasetDict[datasetName].applyStateChange(
          diff.toolName,
          diff.getVisibilityChange(),
          toolManager
        )
      } else {
        // This occurs if the tool is part of a dynamic filter
        console.log(`Dataset for ${diff.toolName} tool not found`)
      }
    }
  }

  /**
   * Initializes all datapoints that were not previously initialized when
   * registering a filter. This method is necessary because it allows datapoints
   * to be shown when filter definitions change. Deferring this initialization
   * selectively helps calculate visibility state only when filters are
   * registered and updated.
   */
  initailizeNonVisibleDatapoints() {
    for (const dataset of Object.values(this.datasetDict)) {
      dataset.initializeNonVisibleDatapoints(this.google, this.map)
    }
  }
}
