import { defineStore } from 'pinia'
import {
  doc,
  updateDoc,
  collection,
  query,
  onSnapshot,
  where,
} from 'firebase/firestore'
import { firestore, firestoreQuery } from '../services/firebase'

let unsubscribeSettings = () => {
  // initializes the variable
}

export const useSettingsStore = defineStore('settings', {
  state: () => ({
    toggleSettings: false,
    settings: [],
    showUserMenuSettings: false,
  }),
  getters: {},
  actions: {
    /* Future settings work
    subscribeSettings(userId) {
      console.log('userid ', userId)
      // the previous action is purely for retrieving specific data, this one is to keep IndexedDB up-to-date
      const q = query(
        collection(firestore, 'settings'),
        where('userId', '==', userId)
        // where('userId', '==', this.userId)
      )
      unsubscribeSettings = onSnapshot(q, querySnapshot => {
        querySnapshot.docChanges().forEach(async change => {
          // idb.addToDatabase('pages', change.doc.data())
          console.log('settings updated ', change.doc.data())
          // change.doc.data()
          this.settings = change.doc.data()
          // this.pages = await idb.getDataStore('pages')
        })
      })
    },
    unsubscribeSettings() {
      unsubscribeSettings()
    }, */
  },
})
