import { Timestamp } from '@/services/firebase'
import { dateFStoJS, makeFirestoreId } from '@/services/utils'
import {
  lookupRecordsetKey,
  lookupFirestoreProps,
  lookupFSCollectionName,
} from '@/services/lookup'
import { useUserStore } from '@/stores/user'
import {
  WidgetFormConfig,
  WidgetFormConfigHandler,
  WidgetFormFieldConfig,
} from './WidgetForm.model'
import { FirestoreImageHandler } from '@/model/Image.model'

/**
 * An object to aggregate and manage all of the data needed to store a record to
 * firestore. This also acts as the core data storage object for a form, and is
 * designed to be passed around to other widgets as needed.
 *
 * In addition to simple properties, this object stores all files and notes that
 * will need to be processed and attached to the record.
 *
 * `content` represents properties that map 1:1 with firestore fields, and
 * `otherProps` represents all other properties. Form Fields will be saved by
 * name and transferred over to `content` later via a lookup dict. This is so
 * that form logic that operates on the name of a form field may take effect.
 */
export class FirestoreUploadData {
  id: string
  idKey: string | null
  recordset: string
  fsCollection: string | null
  content: { [key: string]: any } // Firestore props
  otherProps: { [key: string]: any }
  files: { [key: string]: (File | string)[] | undefined }
  images: { [key: string]: FirestoreImageHandler }
  notes: { [key: string]: FormNoteData }
  delaySensitiveFields: { [key: string]: Date }
  time: number

  constructor(
    recordset: string,
    content: any = {},
    otherProps: any = {},
    files: { [key: string]: (File | string)[] | undefined } = {}
  ) {
    this.recordset = recordset
    this.content = content ?? {}
    this.otherProps = otherProps ?? {}
    this.files = files
    this.images = {} as { [key: string]: FirestoreImageHandler }
    this.notes = {}
    this.idKey = this.getIdKey()
    this.id = '' // overwtitten in generateId()
    this.time = 0 // overwtitten in generateId()
    this.delaySensitiveFields = {}
    this.generateId()
    this.initializeAllProps()
    this.fsCollection = lookupFSCollectionName(this.recordset)
  }

  private generateId() {
    this.time = new Date().getTime()
    let newId = makeFirestoreId(this.time)
    const idKey = this.getIdKey()
    if (idKey) {
      newId = this.content[idKey] ?? newId
      this.content[idKey] = newId
    }
    this.id = newId
    return this.id
  }

  /**
   * Produces the name of the property that the firestore record that this
   * object represents uses as its id
   * @returns The name of the property used as a key for this
   * FirestoreUploadData's records
   */
  getIdKey() {
    return lookupRecordsetKey(this.recordset)
  }

  /**
   * Looks up which props belong to a firestore object of this recordset type
   * and initializes them all to null. This helps standardize the data format
   * so that all records have at least the prescribed fields.
   */
  initializeAllProps() {
    for (const prop of lookupFirestoreProps(this.recordset)?.props ?? []) {
      if (!Object.prototype.hasOwnProperty.call(this.content, prop)) {
        this.content[prop] = null
      }
    }
  }

  /**
   * Initializes this FirestoreUploadData with the value of each field in a
   * WidgetFormConfig object. Each prop is initialized under the form field name
   * rather than the firestore prop name.
   * @param formConfig A form config to pull data from to initialize this
   * FirestoreUploadData
   * @param mode The mode (add, edit, view, etc) in which this record is being
   * initialized with data
   * @returns This FirestoreUploadData for constructor chaining
   */
  initializeWith(
    formConfig: WidgetFormConfig | null,
    mode: string
  ): FirestoreUploadData {
    if (formConfig) {
      const data = WidgetFormConfigHandler.extractInitValues(formConfig, mode)
      // eslint-disable-next-line
      for (let fieldName of Object.keys(data)) {
        this.updateValue(
          new FormFieldEmittedValue(data[fieldName], fieldName, 'other', {
            source: 'init',
            firestoreProp: null, // Firestore props won't be needed here
          })
        )
      }
    }
    return this
  }

  /**
   * Updates a FirestoreUploadData with the value emitted from a form field or
   * packaged together with a ffev.
   * @param ffev The FormFieldEmittedValue that describes which property to
   * update
   */
  updateValue(ffev: FormFieldEmittedValue) {
    if (ffev.type === 'note') {
      const fnd = ffev.value as FormNoteData
      this.notes[ffev.name] = fnd
    } else {
      // Set the firestore content or non-firestore property as appropriate
      if (lookupFirestoreProps(this.recordset)?.props?.includes(ffev.name)) {
        this.content[ffev.name] = ffev.getFirestoreValue()
      } else {
        this.otherProps[ffev.name] = ffev.getFirestoreValue()
        // this.otherProps[ffev.name] = ffev.value // Firestore values are now stored first as other props
      }

      // Handle additional data as needed
      if (ffev.type === 'file') {
        this.files[ffev.firestoreProp ?? ffev.name] = ffev.files
      } else if (ffev.type === 'signature') {
        if (ffev.requiresImageProcessing()) {
          this.images[ffev.firestoreProp ?? ffev.name] = ffev.image!
          // Additional prop to help the grid
          this.content[(ffev.firestoreProp ?? ffev.name) + 'DateTime'] =
            ffev.image!.image.updateDateTime!
        } else {
          // If we're not uploading a new image, get rid of any new image uploads
          delete this.images[ffev.firestoreProp ?? ffev.name]
        }
      } else if (ffev.type === 'image') {
        if (ffev.requiresImageProcessing()) {
          this.images[ffev.firestoreProp ?? ffev.name] = ffev.image!
        } else {
          delete this.images[ffev.firestoreProp ?? ffev.name]
        }
      }
    }
  }

  /**
   * Updates this form data with a new FormSubmitDelayData that will either
   * add or remove a delay field
   * @param fsdd The FormSubmitDelayData object with which to update this
   * FormData's state
   */
  updateDelays(fsdd: FormSubmitDelayData) {
    if (fsdd.getAction() === 'registerDelay') {
      this.delaySensitiveFields[fsdd.getName()] = new Date()
    } else if (fsdd.getAction() === 'resolveDelay') {
      if (
        Object.prototype.hasOwnProperty.call(
          this.delaySensitiveFields,
          fsdd.getName()
        )
      ) {
        delete this.delaySensitiveFields[fsdd.getName()]
      }
    }
  }

  /**
   * Returns true if the form is ready to submit, false otherwise. One condition
   * that may prevent the form from being ready to submit is if there are any
   * outstanding delay fields.
   * @returns False if there are any outstanding delay fields, true otherwise
   */
  isReadyToSubmit() {
    return Object.keys(this.delaySensitiveFields).length === 0
  }

  /**
   * Returns a value by the given key from wherever it's stored in the
   * FirestoreUploadData object
   * @param key The key under which the value is stored
   * @returns The given value, or undefined if the value is not initialized
   */
  getValue(key: string) {
    return Object.prototype.hasOwnProperty.call(this.content, key)
      ? this.content[key]
      : this.otherProps[key]
  }

  /**
   * Applies the note changes that have been saved to this FirestoreUploadData's
   * note dictionary
   * @param parentType The parent type string that should be used for notes
   * whose parent type is not another note
   * @param deletions An array of the ids of notes to delete (not implemented
   * here, see `deleteNoteById()`)
   */
  applyNoteChanges(parentType: string, deletions: string[] = []) {
    const noteHandler = new FormNoteHandler(this.content)

    // eslint-disable-next-line
    for (let noteData of Object.values(this.notes)) {
      const noteToChange = noteHandler.getNoteById(
        (noteData.isNewNote ? noteData.parentId : noteData.id) ?? ''
      )
      if (noteToChange) {
        if (!noteData.isNewNote) {
          // Case: Editing an existing note
          noteToChange.note = noteData.note
          RecordDateHandler.setUpdateUserAndTime(noteToChange)
        } else {
          // Case: Adding a child to a nested note
          new FormNoteHandler(noteToChange).addNote(
            noteData.note,
            noteData.parentId ?? this.id, // Fallback of making it a top-level note
            'Note' // This block is for a note whose parent is another note
          )
        }
        // Case: New top-level note
      } else if (noteData.isNewNote && !noteData.parentId) {
        /* Use the id of this firestore record as the parentId since this
        record is the parent */
        noteHandler.addNote(noteData.note, this.id, parentType)
      }
    }
  }

  setStatus(status: 'In Progress' | 'Complete') {
    this.content.status = status
  }

  /**
   * Updates the create user and create date time properties of this record
   * @param andCreateUserAndTime if true, also sets the values for the create
   * user and create time of this record
   */
  setUpdateUserAndTime(andCreateUserAndTime = false) {
    RecordDateHandler.setUpdateUserAndTime(this.content, andCreateUserAndTime)
  }

  clone(): FirestoreUploadData {
    return new FirestoreUploadData(
      this.recordset + '',
      JSON.parse(JSON.stringify(this.content)),
      JSON.parse(JSON.stringify(this.otherProps)),
      this.files
    )
  }

  applyFirestorePropDict(handler: WidgetFormConfigHandler) {
    for (const fieldName of Object.keys(handler.firestorePropDict)) {
      if (!['file', 'note'].includes(handler.fieldTypeDict[fieldName])) {
        // Initialize props to default values
        let value = this.otherProps[fieldName]
        if (value === undefined || value?.length === 0) value = null
        this.content[handler.firestorePropDict[fieldName]] = value
      }
    }
  }
}

/**
 * An object to bundle all the data that a form field would need to emit up to
 * the form component. Used to abstract away complex fields such as file uploads
 * and to handle special fields like notes.
 *
 * `Type` can be set to `'other'` to send data from elsewhere to somewhere that
 * needs a ffev.
 */
export class FormFieldEmittedValue {
  value: any
  name: string
  type:
    | 'button'
    | 'checkbox'
    | 'date'
    | 'file'
    | 'input'
    | 'image'
    | 'map'
    | 'note'
    | 'radio'
    | 'select'
    | 'textarea'
    | 'signature'
    | 'other'
  isValid?: boolean
  hasValidation?: boolean
  firestoreProp?: string
  source?: 'init' | 'user'

  files?: (File | string)[]
  image?: FirestoreImageHandler
  delayUpdate?: FormSubmitDelayData
  imageStatus?: 'new' | 'existing' | 'remove' | 'edited' | null

  constructor(
    value: any,
    name: string,
    type:
      | 'button'
      | 'checkbox'
      | 'date'
      | 'file'
      | 'input'
      | 'image'
      | 'map'
      | 'note'
      | 'radio'
      | 'select'
      | 'textarea'
      | 'signature'
      | 'other',
    options: {
      firestoreProp: string | null
      isValid?: boolean
      imageStatus?: 'new' | 'existing' | 'remove' | 'edited' | null
      source?: 'init' | 'user'
      files?: (File | string)[]
      image?: FirestoreImageHandler | null
    }
  ) {
    this.value = value
    this.type = type
    this.name = name
    if (options?.isValid) this.isValid = options?.isValid
    if (options?.imageStatus) this.imageStatus = options?.imageStatus
    if (options?.firestoreProp) this.firestoreProp = options?.firestoreProp
    if (options?.source) this.source = options?.source
    if (options?.files) this.files = options?.files
    if (options?.image) this.image = options?.image
  }

  /**
   * Produces the value of this ffev as it should be stored in firebase (e.g.
   * dates as Timestamps)
   * @returns The value of this ffev in a firestore-friendly format
   */
  getFirestoreValue() {
    if (
      (this.type.includes('date') || this.type.includes('time')) &&
      this.value &&
      !!this.value.getTime
    ) {
      // If this is a date or time field with a value that has the JS
      // Date.getTime method, convert it to a firestore date
      return Timestamp.fromDate(this.value as Date)
    } else if (this.type === 'image') {
      // For an image, the value should be a FirestoreImageDataHandler object
      return this?.value?.getLocalValue() ?? null
    }
    if (this.value === undefined || this.value === '') {
      return null
    } else {
      return this.value
    }
  }

  /**
   * Initializes this FFEV's delay update field
   * @param fsdd The form submit delay data object with which to initialize this
   * ffev
   * @returns This ffev for constructor chaining
   */
  initializeDelayUpdate(fsdd: FormSubmitDelayData) {
    this.delayUpdate = fsdd
    return this
  }

  /**
   * @returns A delay update if this ffev has one, undefined otherwise
   */
  getDelayUpdate(): FormSubmitDelayData | undefined {
    return this.delayUpdate
  }

  /**
   * @returns True if this ffev has an image that will need to be processed,
   * false otherwise
   */
  requiresImageProcessing(): boolean {
    return (
      !!this.image &&
      (this.imageStatus === 'new' || this.imageStatus === 'edited')
    )
  }
}

/**
 * A class for packaging and managing data that a form should wait on before
 * submitting
 */
export class FormSubmitDelayData {
  fieldName: string
  action: 'registerDelay' | 'resolveDelay'

  constructor(fieldName: string, action: 'registerDelay' | 'resolveDelay') {
    this.fieldName = fieldName
    this.action = action
  }

  /**
   * @returns The name of the form field from which this FormSubmitDelayData
   * object originated
   */
  getName() {
    return this.fieldName
  }

  /**
   * @returns The action type that this FormSubmitDelayData represents as a
   * string
   */
  getAction() {
    return this.action
  }
}

/**
 * A class for packaging and managing data for controlling the lock state of
 * different fields in a form
 */
export class FormLockStateData {
  fieldName: string
  action: 'requestUnlock'
  config: WidgetFormFieldConfig | undefined
  lockOtherFields: boolean
  lockOtherFieldsExcept: string[]

  constructor(
    fieldName: string,
    action: 'requestUnlock',
    config: WidgetFormFieldConfig | undefined,
    options: {
      lockOtherFields?: boolean
      lockOtherFieldsExcept?: string[]
    } = {}
  ) {
    this.fieldName = fieldName
    this.action = 'requestUnlock'
    this.config = config
    this.lockOtherFields = options?.lockOtherFields ?? true
    this.lockOtherFieldsExcept = options?.lockOtherFieldsExcept ?? []
  }

  /**
   * @returns The name of the form field from which this FormLockStateData
   * object originated
   */
  getName() {
    return this.fieldName
  }

  /**
   * @returns The action type that this FormLockStateData represents as a
   * string
   */
  getAction() {
    return this.action
  }

  /**
   * @returns whether or not the form field that requested a lock state change
   * is a signature field
   */
  isSignature() {
    return this.config?.type === 'signature'
  }

  /**
   * Sets the formFieldConfig as unlockable for the form field that requested
   * this change
   */
  setUnlockable() {
    if (this?.config) this.config.isUnlockable = true
  }

  /**
   * @returns A list of names corresponding to the fields that should not be
   * locked by this lock state update
   */
  getExceptions() {
    return this.lockOtherFieldsExcept.concat([this.getName()])
  }
}

/**
 * A PWA object to represent note data within a form. ID should be the id of
 * __this note__ if it __is not new__ or the ID of the parent if the note __is
 * new__. If the note is new and the parent is a top-level firestore record that
 * is not a note, the id should be null.
 *
 */
export class FormNoteData {
  id: string
  parentId?: string | null
  note: string
  isNewNote: boolean

  constructor(id: string | null, note: string | null, isNewNote = false) {
    this.id = isNewNote ? makeFirestoreId() : id ?? makeFirestoreId()
    if (isNewNote) {
      this.parentId = id
    }
    this.note = note ?? ''
    this.isNewNote = isNewNote
  }
}

/**
 * A handler object to manage an object that holds child notes. Can add, view,
 * and delete nested child notes.
 */
export class FormNoteHandler {
  noteParent: INoteParent

  constructor(noteParent: INoteParent) {
    this.noteParent = noteParent
  }

  /**
   * Performs a depth-first search on the child notes of the given object to
   * find the note whose id is given. Returns a reference to that note or null
   * if that note is not found in `obj`'s list of child notes.
   * @param obj The note parent whose notes to iterate through
   * @param id The id of the note to search for
   * @returns A reference to the note whose id is requested, or null if no such
   * note is a child of the parent note on whom this method is called
   */
  private _getChildNoteById(obj: INoteParent, id: string): INote | null {
    if (!obj?.childNotes?.length) {
      return null
    }
    for (const note of obj.childNotes) {
      if (note?.noteId === id) {
        return note
      }
      const possibleChildNote = this._getChildNoteById(note, id)
      if (possibleChildNote) return possibleChildNote
    }
    return null
  }

  /**
   * Retrieves a reference to the note object of the given id, or null if no
   * such note is found
   * @param id The id of the note to retrieve
   */
  getNoteById(id: string): INote | null {
    return this._getChildNoteById(this.noteParent, id)
  }

  private _deleteChildNoteById(noteParent: INoteParent, id: string): boolean {
    if (!noteParent?.childNotes?.length) {
      return false
    }
    for (let i = 0; i < noteParent?.childNotes?.length ?? []; i++) {
      if (noteParent.childNotes[i].noteId === id) {
        ;(noteParent.childNotes as any[]).splice(i, 1)
        return true
      }
      if (this._deleteChildNoteById(noteParent.childNotes[i], id)) {
        return true
      }
    }
    return false
  }

  /**
   * Deletes a child note or nested child note of the given id if such a note
   * exists
   * @param id The id of the note to delete
   * @returns True if the deletion is successful, false otherwise
   */
  deleteNoteById(id: string): boolean {
    return this._deleteChildNoteById(this.noteParent, id)
  }

  /**
   * Tests whether or not this object has child notes
   * @returns True if this object has at least one child note, false otherwise
   */
  hasNotes() {
    return (
      !!this.noteParent?.childNotes?.length || !!this.noteParent?.notes?.length
    )
  }

  /**
   * Creates a firestore note record from the given data with all fields
   * initialized to null except the content, parentId, parentType, and
   * create/update user/datetime
   * @param content The new note's content
   * @param parentId The id of the note's parent.
   * * Can be a noteId or the id of another type of firestore record. Should not
   * be null
   * @param parentType The type of the note's parent, as established by the SQL
   * database's convention
   * @returns A note record with its firestore fields initialized for
   * standardization
   */
  static createNote(
    content: string,
    parentId: string | null,
    parentType: string
  ) {
    const noteKeys = lookupFirestoreProps('notes')?.props
    const newNote = {} as any
    if (noteKeys) {
      // eslint-disable-next-line
      for (let key of noteKeys) {
        newNote[key] = null
      }
      newNote.parentId = parentId
      newNote.parentType = parentType
      newNote.note = content
      newNote.noteId = makeFirestoreId()
      RecordDateHandler.setUpdateUserAndTime(newNote, true)
      return newNote
    }
  }

  /**
   * Adds a note with the given content, id, and parentType to the childNote
   * array of this handler's noteParent object
   * @param note The content of the note to be added to the noteParent's child
   * note array
   * @param parentId The id of the parent note or parent firestore record
   * @param parentType The parent type string for this note
   */
  addNote(note: string, parentId: string, parentType: string) {
    if (!Object.prototype.hasOwnProperty.call(this.noteParent, 'childNotes')) {
      this.noteParent.childNotes = []
    }
    // TODO: Add logic for dynamic parent type
    this.noteParent.childNotes!.push(
      FormNoteHandler.createNote(
        note,
        parentId ?? null, // Eliminates undefined
        parentType
      )
    )
  }
}

/**
 * An interface for objects that may hold a collection of notes. Every note is
 * itself a note parent. This interface an apply to firestore records or to PWA
 * objects.
 */
export interface INoteParent {
  childNotes?: INote[]
  notes?: INote[]
}

/**
 * An interface for a PWA note object or firestore note object. Every note is
 * also a note parent because it may have childNotes
 */
export interface INote extends INoteParent {
  note: string
  noteId: string
}

/**
 * A convenience class for setting the create/update user and time on a
 * firestore record. Uses firestore timestamps
 */
export class RecordDateHandler {
  targetRecord: any

  constructor(targetRecord: any) {
    this.targetRecord = targetRecord
  }

  /**
   * Updates the create user and create date time properties of this record
   * @param andCreateUserAndTime if true, also sets the values for the create
   * user and create time of this record. These values will be set with
   * firestore timestamps rather than javascript Date objects.
   */
  static setUpdateUserAndTime(targetRecord: any, andCreateUserAndTime = false) {
    const time = Timestamp.fromDate(new Date())
    const userStore = useUserStore()
    targetRecord.updateUserId = userStore.userId ?? null
    targetRecord.updateDateTime = time ?? null
    if (andCreateUserAndTime) {
      targetRecord.createUserId = userStore.userId ?? null
      targetRecord.createDateTime = time ?? null
    }
  }

  static setCreateUserName(targetRecord: any) {
    const userStore = useUserStore()
    targetRecord.createUserName = (userStore.user as any)?.displayName ?? null
  }
}
