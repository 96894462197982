<script setup>
const clickHandler = el => {
  window.scrollTo({
    top: document.getElementsByName(el)[0].offsetTop,
    behavior: 'smooth',
  })
  //
}
</script>

<template>
  <v-container fluid class="px-5">
    <v-card class="mx-auto text-left">
      <template v-slot:title>
        <v-icon icon="mdi-file-lock-outline" size="x-small" /> Privacy Policy
      </template>
      <template v-slot:subtitle>
        <em>The Privacy Policy was last modified on October 5th, 2018.</em>
      </template>
      <template v-slot:text>
        <p>
          <span>
            MaxTrax LLC, Inc. (&ldquo;MaxTrax LLC,&rdquo; &ldquo;we,&rdquo; or
            &ldquo;us&rdquo;) is committed to protecting your privacy and wants
            you to feel secure when visiting our website and using our Service.
            Our Privacy Policy explains how we collect, use, disclose, and
            protect information that applies to our Service, and your choices
            about the collection and use of your information.
          </span>
        </p>

        <p>
          <span>Click on the links below to jump to each section:</span>
        </p>

        <div class="mt-5 mb-2" style="color: #00549f">
          <span>1.</span>
          <span>
            <a href="#sec1" @click.prevent="clickHandler('sec1')"
              >How We Collect and Use Information</a
            >
          </span>
        </div>

        <div class="mb-2" style="color: #00549f">
          <span>2.</span>
          <span>
            <a href="#sec2" @click.prevent="clickHandler('sec2')"
              >Sharing of Your Information</a
            >
          </span>
        </div>

        <div class="mb-2" style="color: #00549f">
          <span>3.</span>
          <span>
            <a href="#sec3" @click.prevent="clickHandler('sec3')"
              >How We Store and Protect Your Information</a
            >
          </span>
        </div>

        <div class="mb-2" style="color: #00549f">
          <span>4.</span>
          <span>
            <a href="#sec4" @click.prevent="clickHandler('sec4')"
              >Your Choices about Your Information</a
            >
          </span>
        </div>

        <div class="mb-2" style="color: #00549f">
          <span>5.</span>
          <span>
            <a href="#sec5" @click.prevent="clickHandler('sec5')"
              >Children&rsquo;s Privacy</a
            >
          </span>
        </div>

        <div class="mb-2" style="color: #00549f">
          <span>6.</span>
          <span>
            <a href="#sec6" @click.prevent="clickHandler('sec6')"
              >Links to Other Websites and Services</a
            >
          </span>
        </div>

        <div class="mb-2" style="color: #00549f">
          <span>7.</span>
          <span>
            <a href="#sec7" @click.prevent="clickHandler('sec7')"
              >How to Contact Us</a
            >
          </span>
        </div>

        <div class="mb-5" style="color: #00549f">
          <span>8.</span>
          <span>
            <a href="#sec8" @click.prevent="clickHandler('sec8')"
              >Changes to Our Privacy Policy</a
            >
          </span>
        </div>

        <p class="mt-30">
          <a name="sec1"></a>
          <strong>1. </strong>
          <strong>HOW WE COLLECT AND USE INFORMATION</strong>
        </p>

        <p>
          <span class="fs-i"
            >Capitalized terms that are not defined in this Privacy Policy have
            the meaning given them in our
            <a href="/terms-of-use">Terms of Use</a></span
          >
        </p>

        <p>
          <span>We collect the following types of information about you:</span>
        </p>

        <p>
          <strong>Information you provide us directly:</strong>
          <span>
            We ask for certain information such as your username, first and last
            name, nickname, affiliated organization, size of your affiliated
            organization, address, e-mail address and billing information when
            you register for a MaxTrax LLC account, make a purchase through the
            Service, or if you correspond with us. We may also retain any
            messages you send through the Service, and may collect information
            you provide in Content you post to the Service. We use this
            information to operate, maintain, and provide to you the features
            and functionality of the Service.
          </span>
        </p>

        <p>
          <strong>Information we may receive from third parties</strong>
          <span>
            : We may receive information about you from third parties. For
            example, if you access our websites or Service through a third-party
            connection or log-in, for example, through a third-party plug-in,
            that third party may pass certain information about your use of its
            service to MaxTrax LLC. You should always review, and if necessary,
            adjust your privacy settings on third-party websites and services
            before linking or connecting them to the Service. You may also
            unlink your third party account from the Service by adjusting your
            settings on the third party service.
          </span>
        </p>

        <p>
          <span></span>
        </p>

        <p>
          <strong>Analytics information:</strong>
          <span>
            To assist us in improving the Service, we may directly, or through a
            third party (including use of third-party analytics tools), collect
            analytics data, measure traffic and usage trends for the Service,
            including the pages you visit, and collect other relevant
            information.
          </span>
        </p>

        <p>
          <strong>Cookies information</strong>
          <span>
            : When you visit the Service, we may send one or more cookies
            &mdash; a small text file containing a string of alphanumeric
            characters &mdash; to your computer that uniquely identifies your
            browser and lets MaxTrax LLC help you log in faster and enhance your
            navigation through the site. A cookie may also convey information to
            us about how you use the Service (e.g., the pages you view, the
            links you click and other actions you take on the Service), and
            allow us or our business partners to track your usage of the Service
            over time. A persistent cookie remains on your hard drive after you
            close your browser. Persistent cookies may be used by your browser
            on subsequent visits to the site. Persistent cookies can be removed
            by following your web browser&rsquo;s directions. A session cookie
            is temporary and disappears after you close your browser. You can
            reset your web browser to refuse all cookies or to indicate when a
            cookie is being sent. However, some features of the Service may not
            function properly if the ability to accept cookies is disabled.
          </span>
        </p>

        <p>
          <strong>Log file information</strong>
          <span>
            : Log file information is automatically reported by your browser or
            mobile device each time you access the Service. When you use our
            Service, our servers automatically record certain log file
            information. These server logs may include anonymous information
            such as your web request, Internet Protocol (&ldquo;IP&rdquo;)
            address, browser type, referring / exit pages and URLs, number of
            clicks and how you interact with links on the Service, domain names,
            landing pages, pages viewed, and other such information.
          </span>
        </p>

        <p>
          <strong>Clear gifs/web beacons information</strong>
          <span>
            : When you use the Service, we may employ clear gifs (also known as
            web beacons) which are used to anonymously track the online usage
            patterns of our users. In addition, we may also use clear gifs in
            HTML-based emails sent to our users to track which emails are opened
            and which links are clicked by recipients. The information is allows
            for more accurate reporting and improvement of the Service.
          </span>
        </p>

        <p>
          <strong>Device identifiers</strong>
          <span>
            : When you access the Service by or through a mobile device
            (including but not limited to smart-phones or tablets), we may
            access, collect, monitor and/or remotely store one or more
            &ldquo;device identifiers,&rdquo; such as a universally unique
            identifier (&ldquo;UUID&rdquo;). However, we will not collect UUIDs
            on user-created applications without your express consent. Device
            identifiers are small data files or similar data structures stored
            on or associated with your mobile device, which uniquely identify
            your mobile device. A device identifier may be data stored in
            connection with the device hardware, data stored in connection with
            the device&rsquo;s operating system or other software, or data sent
            to the device by MaxTrax LLC. A device identifier may convey
            information to us about how you browse and use the Service. A device
            identifier may remain persistently on your device, to help you log
            in faster and enhance your navigation through the Service. Some
            features of the Service may not function properly if use or
            availability of device identifiers is impaired or disabled.
          </span>
        </p>

        <p>
          <strong>Location data</strong>
          <span>
            : When you access the Service, we may access, collect, monitor
            and/or remotely store &ldquo;location data,&rdquo; which may include
            GPS coordinates (e.g. latitude and/or longitude) or similar
            information regarding the location of your device. Location data may
            convey to us information about how you browse and use the Service.
            Some features of the Service, particularly location-based services,
            may not function properly if use or availability of location data is
            impaired or disabled.
          </span>
        </p>

        <p>
          <strong>Commercial and marketing communications: </strong>
          <span>
            We use the information we collect or receive to communicate directly
            with you. We may send you emails containing newsletters, promotions
            and special offers. If you do not want to receive such email
            messages, you will be given the option to opt out or change your
            preferences. We also use your information to send you
            Service-related emails (e.g., account verification, purchase and
            billing confirmations and reminders, changes/updates to features of
            the Service, technical and security notices). You may not opt out of
            Service-related e-mails.
          </span>
        </p>

        <p>
          <strong
            >Use of certain service type information we collect about you:
          </strong>
          <span>
            We use or may use cookies, log file, device identifiers, location
            data and clear gifs information to: (a) remember information so that
            you will not have to re-enter it during your visit or the next time
            you visit the site; (b) provide custom, personalized content and
            information; (c) provide and monitor the effectiveness of our
            Service; (d) monitor aggregate metrics such as total number of
            visitors, traffic, and demographic patterns; (e) diagnose or fix
            technology problems; (f) help you efficiently access your
            information after you sign in; (g) provide advertising to your
            browser or device; and (h) automatically update applications
            published by MaxTrax LLC. However, we will not automatically update
            user-created applications without your express consent.
          </span>
        </p>

        <p class="mt-3 mb-2">
          <a name="sec2"></a>
          <strong>2. </strong>
          <strong>SHARING OF YOUR INFORMATION</strong>
        </p>

        <p>
          <a name="h.1fob9te"></a>
          <span
            >We will not rent or sell your information to third parties outside
            MaxTrax LLC and its group companies (including any parent,
            subsidiaries and affiliates) without your consent, except as noted
            below:</span
          >
        </p>

        <p>
          <strong>Who we may share your information with</strong>
          <span>
            : We may share your information with third-party business partners
            for the purpose of providing the Service to you. Those business
            partners will be given limited access to your information as is
            reasonably necessary to deliver the Service, and we will require
            that such third parties comply with this Privacy Policy.
          </span>
        </p>

        <p>
          <strong>Who you may choose to share your Content with</strong>
          <span>
            : Any information or content that you voluntarily disclose for
            posting to the Service, such as Content posted by you to our forums
            or bug tracking system, may become available to the public, as
            controlled by any applicable privacy settings. If you remove
            information that you posted to the Service, copies may remain
            viewable in cached and archived pages of the Service, or if other
            users have copied or saved that information.
          </span>
        </p>

        <p>
          <strong>What happens in the event of a change of control</strong>
          <span>
            : We may buy or sell/divest/transfer the company (including any
            shares in the company), or any combination of its products,
            services, assets and/or businesses. Your information such as
            customer names and email addresses, Content and other user
            information related to the Service may be among the items sold or
            otherwise transferred in these types of transactions. We may also
            sell, assign or otherwise transfer such information in the course of
            corporate divestitures, mergers, acquisitions, bankruptcies,
            dissolutions, reorganizations, liquidations, similar transactions or
            proceedings involving all or a portion of the company.
          </span>
        </p>

        <p>
          <strong
            >Instances where we are required to share your information</strong
          >
          <span
            >: MaxTrax LLC will disclose your information where required to do
            so by law or subpoena or if we reasonably believe that such action
            is necessary to (a) comply with the law and the reasonable requests
            of law enforcement; (b) to enforce our
            <a href="/terms-of-use">Terms of Use</a> or to protect the security
            or integrity of our Service; and/or (c) to exercise or protect the
            rights, property, or personal safety of MaxTrax LLC, our users or
            others.</span
          >
        </p>

        <p>
          <strong
            >Sharing certain service type information we collect about
            you</strong
          >
          <span>
            : We may share certain service type information, including
            information obtained through tools such as cookies, log files,
            device identifiers, location data and clear gifs (such as anonymous
            usage data, referring/exit pages and URLs, platform types, number of
            clicks, etc.): (i) with our third-party business partners for the
            purposes described in the section above on &ldquo;
          </span>
          <span>How We Collect and Use Information</span>
          <span
            >.&rdquo; We may also aggregate or otherwise strip data of all
            personally identifying characteristics and may share that
            aggregated, anonymized data with third parties.</span
          >
          <span></span>
        </p>

        <p class="mt-3 mb-2">
          <a name="sec3"></a>
          <strong>3. </strong>
          <strong>HOW WE STORE AND PROTECT YOUR INFORMATION</strong>
        </p>

        <p>
          <strong>Storage and Processing</strong>
          <span>
            : Your information collected through the Service may be stored and
            processed in the United States or any other country in which MaxTrax
            LLC or its subsidiaries, affiliates or service providers maintain
            facilities. MaxTrax LLC may transfer information that we collect
            about you, including personal information, to affiliated entities,
            or to other third parties across borders and from your country or
            jurisdiction to other countries or jurisdictions around the world.
            If you are located in the European Union or other regions with laws
            governing data collection and use that may differ from U.S. law,
            please note that we may transfer information, including personal
            information, to a country and jurisdiction that does not have the
            same data protection laws as your jurisdiction, and you consent to
            the transfer of information to the U.S. or any other country in
            which MaxTrax LLC or its parent, subsidiaries, affiliates or service
            providers maintain facilities and the use and disclosure of
            information about you as described in this Privacy Policy.
          </span>
        </p>

        <p>
          <strong>Keeping your information safe</strong>
          <span>
            : MaxTrax LLC cares about the security of your information, and uses
            commercially reasonable safeguards to preserve the integrity and
            security of all information collected through the Service. To
            protect your privacy and security, we take reasonable steps (such as
            requesting a unique password) to verify your identity before
            granting you access to your account. You are responsible for
            maintaining the secrecy of your unique password and account
            information, and for controlling access to your email communications
            from MaxTrax LLC, at all times. However, MaxTrax LLC cannot ensure
            or warrant the security of any information you transmit to MaxTrax
            LLC or guarantee that information on the Service may not be
            accessed, disclosed, altered, or destroyed. Your privacy settings
            may also be affected by changes to the functionality of third party
            sites and services that you add to the Service. MaxTrax LLC is not
            responsible for the functionality or security measures of any third
            party.
          </span>
        </p>

        <p>
          <strong>Compromise of information</strong>
          <span>
            : In the event that any information under our control is compromised
            as a result of a breach of security, MaxTrax LLC will take
            reasonable steps to investigate the situation and where appropriate,
            notify those individuals whose information may have been compromised
            and take other steps, in accordance with any applicable laws and
            regulations.
          </span>
        </p>

        <p class="mt-3 mb-2">
          <a name="sec4"></a>
          <strong>4. </strong>
          <strong>YOUR CHOICES ABOUT YOUR INFORMATION</strong>
        </p>

        <p>
          <strong>You control your account information and settings</strong>
          <span>
            : You may update your account information at any time by logging
            into your account and changing your profile settings. You can also
            stop receiving promotional email communications from us by clicking
            on the &ldquo;unsubscribe link&rdquo; provided in such
            communications. We make every effort to promptly process all
            unsubscribe requests. As noted above, you may not opt out of
            Service-related communications (e.g., account verification, purchase
            and billing confirmations and reminders, changes/updates to features
            of the Service, technical and security notices). If you have any
            questions about reviewing or modifying your account information, you
            can contact us directly at
            <a href="mailto:support@netconsultingroup.com"
              >support@netconsultingroup.com</a
            >.
          </span>
        </p>

        <p>
          <strong
            >Opting out of collection of your information for Tracking:</strong
          >
          <span>
            Please refer to your mobile device or browser&rsquo;s technical
            information for instructions on how to delete and disable cookies,
            and other tracking/recording tools. Depending on your type of
            device, it may not be possible to delete or disable tracking
            mechanisms on your mobile device. Note that disabling cookies and/or
            other tracking tools prevents MaxTrax LLC or its business partners
            from tracking your browser&rsquo;s activities in relation to the
            Service. However, doing so may disable many of the features
            available through the Service. If you have any questions about
            opting out of the collection of cookies and other tracking/recording
            tools, you can contact us directly at
            <a href="mailto:support@netconsultingroup.com"
              >support@netconsultingroup.com</a
            >.
          </span>
        </p>

        <p>
          <strong>How long we keep your Content</strong>
          <span
            >: Following termination or deactivation of your user account,
            MaxTrax LLC may retain your profile information and Content for a
            commercially reasonable time for backup, archival, or audit
            purposes.
          </span>
        </p>

        <p class="mt-3 mb-2">
          <a name="sec5"></a>
          <strong>5. </strong>
          <strong>CHILDREN&rsquo;S PRIVACY</strong>
        </p>

        <p>
          <span>
            MaxTrax LLC does not knowingly collect or solicit any information
            from anyone under the age of 13 or knowingly allow such persons to
            register as users. The Service and its content are not directed at
            children under the age of 13. In the event that we learn that we
            have collected personal information from a child under age 13
            without verification of parental consent, we will delete that
            information as quickly as possible. If you believe that we might
            have any information from or about a child under 13, please contact
            us at
            <a href="mailto:support@netconsultingroup.com"
              >support@netconsultingroup.com</a
            >.
          </span>
        </p>

        <p class="mt-3 mb-2">
          <a name="sec6"></a>
          <strong>6. </strong>
          <strong>LINKS TO OTHER WEB SITES AND SERVICES</strong>
        </p>

        <p>
          <span>
            We are not responsible for the practices employed by websites or
            services linked to or from the Service, including the information or
            content contained therein. Please remember that when you use a link
            to go from the Service to another website, our Privacy Policy does
            not apply to third-party websites or services. Your browsing and
            interaction on any third-party website or service, including those
            that have a link or advertisement on our website, are subject to
            that third party&rsquo;s own rules and policies. In addition, you
            agree that we are not responsible for and we do not have any control
            over any third-parties that you authorize to access your Content. If
            you are using a third-party website or service and you allow such a
            third-party access to your Content you do so at your own risk. This
            Privacy Policy does not apply to information we collect by other
            means (including offline) or from other sources other than through
            the Service.
          </span>
        </p>

        <p class="mt-3 mb-2">
          <a name="sec7"></a>
          <strong>7. </strong>
          <strong>HOW TO CONTACT US</strong>
        </p>

        <p>
          <span
            >If you have any questions about this Privacy Policy or the Service,
            please contact us at
            <a href="mailto:support@netconsultingroup.com"
              >support@netconsultingroup.com</a
            >.</span
          >
        </p>

        <p class="mt-3 mb-2">
          <a name="sec8"></a>
          <strong>8. </strong>
          <strong>CHANGES TO OUR PRIVACY POLICY</strong>
        </p>

        <p>
          <span>
            MaxTrax LLC may modify or update this Privacy Policy from time to
            time to reflect the changes in our business and practices, and so
            you should review this page periodically. When we change the policy
            in a material manner we will let you know and update the &lsquo;last
            modified&rsquo; date at the bottom of this page.
          </span>
        </p>
      </template>
    </v-card>
  </v-container>
</template>
