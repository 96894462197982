<script setup>
import { defineProps } from 'vue'
// components
import ToolDispatcher from '@/components/ToolDispatcher.vue'
import useCommonConfig from '@/composables/useCommonConfig'
const {
  isMobile,
  router,
  isNotMobile,
  display,
  dataStore,
  navigationStore,
  pagesStore,
  widgetsStore,
  userStore,
} = useCommonConfig()

const props = defineProps({
  page: Number,
})

const icons = [
  { icon: 'mdi-home', text: 'Home' },
  { icon: 'mdi-magnify', text: 'search' },
  { icon: 'mdi-hammer', text: 'tools' },
  { icon: 'mdi-filter', text: 'filter' },
  { icon: 'mdi-dots-vertical', text: 'more' },
]
</script>

<template>
  <v-bottom-navigation
    height="72"
    class="align-center nav-bottom px-3 bg-primary"
    style="min-height: 72px; position: sticky; bottom: 0; z-index: 1000"
  >
    <div id="bottom-navigation" :class="['d-flex', { 'is-mobile': isMobile }]">
      <v-btn
        v-if="router.currentRoute.value.params.slug?.length > 0"
        stacked
        variant="text"
        prepend-icon="mdi-home"
        class="pa-0 rounded-0 nav-bottom__first-button"
        exact
        :active="false"
        :to="{
          name: 'dynamic',
          params: {
            client: router.currentRoute.value.params.client,
            module: userStore.getCurrentModuleName,
          },
        }"
      >
        Home
      </v-btn>
      <tool-dispatcher
        v-for="(tool, tIdx) in pagesStore.currentPage.properties.tools"
        :tool="tool.name"
        :key="tIdx"
      />
      <!--<span
      id="footer-filter-trigger"
      :style="navigationStore.showMobileFilter ? 'min-width: 80px' : null"
    ></span>

    <span
      id="add-trigger"
      :style="navigationStore.showMobileFilter ? 'min-width: 80px' : null"
    ></span>-->
    </div>
  </v-bottom-navigation>
</template>

<style lang="scss">
.nav-bottom {
  // padding-bottom: env(safe-area-inset-bottom);

  .is-mobile {
    .v-btn {
      min-width: 56px !important;
      max-height: 48px;
      margin: 4px 0px;
      padding: 0px 8px !important;
      // padding: 0px 8px env(safe-area-inset-bottom) 8px !important;
      box-sizing: border-box;

      &::before {
        content: '';
        border-left: thin solid currentColor;
        height: 80%;
        position: absolute;
        left: 0;
      }
    }

    .v-btn.nav-bottom__first-button {
      &::before {
        border-left: none;
      }
    }
  }

  .v-btn {
    /*@media (max-width: 960px) {
      min-width: 56px !important;
      max-height: 48px;
      margin: 4px 0px;
      padding: 0px 8px !important;
      // padding: 0px 8px env(safe-area-inset-bottom) 8px !important;
      box-sizing: border-box;

      &::before {
        content: '';
        border-left: thin solid currentColor;
        height: 80%;
        position: absolute;
        left: 0;
      }
    }*/

    &--stacked .v-btn__prepend {
      margin-bottom: 0;
    }
  }

  /*.v-btn--stacked .v-btn__prepend {
    margin-bottom: 0;
  }*/

  /* .v-btn:hover,
  .v-btn:active,
  .v-btn--selected {
    border: thin solid currentColor;
  }*/

  #footer-filter-trigger,
  #add-trigger {
    margin-top: -4px;
    font-size: 12px;
  }
}
</style>
