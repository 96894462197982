<script setup>
import { ref, onMounted, watch } from 'vue'
import { useRoute } from 'vue-router'
import { useNavigationStore } from '@/stores/navigation'
import { useUserStore } from '@/stores/user'
import StatusIndicator from './StatusIndicator.vue'

// vars
const route = useRoute()
const navigationStore = useNavigationStore()
const userStore = useUserStore()
const bcPath = ref([])

onMounted(() => {
  setBreadcrumbs()
})
// watch
watch(
  () => route.path,
  () => {
    setBreadcrumbs()
  }
)

// methods

const formattedTitle = el => {
  // look up proper formatting in menu
  return (
    navigationStore.menuItems.find(
      x => x?.title.toLowerCase().replaceAll(' ', '-') === el
    )?.title || el.replaceAll('-', ' ')
  )
}

const disableCurrentBreadCrumb = el => {
  if (el === 'home') {
    if (
      route.path.replace('/', '').split('/').includes('privacy-policy') ||
      route.path.replace('/', '').split('/').includes('terms-of-use') ||
      route.params?.slug?.length > 0
    ) {
      return false
    }

    if (!route.params.slug) {
      return true
    }

    return true
  }
  if (el === 'privacy-policy' || el === 'terms-of-use') return true
  return route.params.slug?.includes(el)
}

const hrefGenerator = (rootPath, el) => {
  const slashEl = `/${el}`
  if (!route.path.replace('/', '').split('/').includes('privacy-policy')) {
    return `/${rootPath}${el.toLowerCase() === 'home' ? '' : slashEl}`
  } else return slashEl
}
const setBreadcrumbs = () => {
  // breadcrumbs run on an array //.replace('/', 'home')
  let tempArr = [...route.path.replace('/', '').split('/')]
  let rootPath = `${userStore.getCurrentClientName}`
  // remove empty elements
  // tempArr.splice(tempArr.indexOf(''), 1)
  if (tempArr.indexOf(userStore.getCurrentClientName) > -1) {
    tempArr.splice(tempArr.indexOf(userStore.getCurrentClientName), 1) // remove the client name
  }

  if (tempArr.indexOf(userStore.getCurrentModuleName) > -1) {
    rootPath = `${userStore.getCurrentClientName}/${userStore.getCurrentModuleName}` // tempArr[0] // if we're on a module page, we need that to remain in the path
    tempArr.splice(tempArr.indexOf(userStore.getCurrentModuleName), 1) // remove the module name
  }
  tempArr.unshift('home') // home should always be present

  bcPath.value = []

  if (route.path !== '/')
    tempArr.forEach(el => {
      bcPath.value.push({
        title: formattedTitle(el),
        disabled: disableCurrentBreadCrumb(el),
        href: hrefGenerator(rootPath, el),
      })
    })
}
</script>

<template>
  <v-row
    no-gutters
    class="bg-white align-center border-bottom breadcrumbs-bar__root"
  >
    <v-breadcrumbs
      :items="bcPath"
      density="compact"
      bg-color="white"
      class="pl-5 w-50 text-capitalize"
    >
      <template v-slot:title="{ item }">
        <v-icon
          v-if="item.title.toLowerCase() === 'home'"
          size="small"
          icon="mdi-home"
          style="font-size: 1.1rem !important"
        ></v-icon>
        <span class="breadcrumbs-bar__title"> {{ item.title }}</span>
      </template>
    </v-breadcrumbs>
    <status-indicator
      v-if="userStore.isAuthenticated && userStore.user.isValidated"
      class="mr-5"
    />
  </v-row>
</template>

<style lang="scss">
.breadcrumbs-bar {
  &__root {
    .v-breadcrumbs-item--link:hover {
      text-decoration: none;
    }
    .v-breadcrumbs-item--disabled {
      opacity: 1;
    }
  }

  &__title {
    &:hover {
      text-decoration: underline;
    }
  }
}
</style>
