<script setup>
import { LayoutConfig } from '@/model/LayoutConfig.model'
import { WidgetMenuCellConfig } from '@/model/WidgetMenuCellConfig.model'
import vuetify from '@/plugins/vuetify'
import { ref, defineProps, onMounted, onUnmounted, computed, watch } from 'vue'
import { useDisplay } from 'vuetify'
import router from '@/router'
// stores
import { useNavigationStore } from '@/stores/navigation'
import { useUserStore } from '@/stores/user'
import { isPhone as ddIsPhone, isTablet } from '@/services/deviceDetection.ts'

const display = useDisplay()
const props = defineProps({
  depth: Number,
  device: String,
  config: Object, // WidgetMenuCellConfig,
})

const navigationStore = useNavigationStore()
const userStore = useUserStore()
const show = ref(false)
const titleBgColor = ref('grey')
const titleTextColor = ref('grey')
const isImageLoaded = ref(false)
const isPhone = computed(() => {
  return props.device === 'phone'
})
const isNotPhone = computed(() => {
  return props.device !== 'phone'
})
const getMinWidth = computed(() => {
  let tileWidth = 200
  let depthCorrection = 5
  if (props.device === 'phone') {
    return '100%'
  }
  if (props.config.type === 'tile') {
    return tileWidth - 2 * depthCorrection + 'px'
  }
  if (props.config.items.length) {
    return (
      Math.min(
        display.width,
        props.config.items.length * tileWidth - 2 * depthCorrection
      ) + 'px'
    )
  }
  console.error(`Menu tile ${props.config.title} improperly sized`)
  return 200 + 'px' // Execution should never reach this condition
})

const imgSrc = computed(() => {
  return `${process.env.VUE_APP_BASE_PATH}/img/menu/${props.config.image}`
})

onMounted(() => {
  show.value = props.config.isOpen ?? false
  if (props.config.type === 'tile') {
    titleBgColor.value = 'rgb(88,127,49)'
    titleTextColor.value = 'white'
  }
  if (props.config.type === 'group') {
    titleBgColor.value = 'grey'
    titleTextColor.value = 'red'
  }
})

function toggleShow() {
  show.value = !show.value
}

const clickHandler = id => {
  toggleShow()
  router.push({
    name: 'dynamic',
    params: {
      client: userStore.getCurrentClientName,
      module: userStore.getCurrentModuleName,
      slug: [navigationStore.getPageSlug(id)],
    },
  })
}

const imgLoaded = () => {
  isImageLoaded.value = true
}
</script>

<template>
  <div
    :style="{
      'width': device === 'phone' ? '100%' : config.width,
      'min-width': getMinWidth,
    }"
  >
    <v-sheet
      :color="props.config.type === 'tile' ? 'white' : 'transparent'"
      height="100%"
      elevation="1"
    >
      <!-- Mobile Banner -->
      <v-hover v-if="isPhone" v-slot="{ isHovering, props }">
        <v-sheet
          :color="titleBgColor"
          :style="{ color: titleTextColor }"
          @click="clickHandler(config.pageId)"
          min-height="80px"
          :class="`cursor-pointer elevation-${isHovering ? 8 : 0}`"
          v-bind="props"
        >
          <div class="d-flex flex-no-wrap mx-2">
            <div class="d-inline" max-height="40px">
              <v-img
                v-if="config.image && device === 'phone'"
                :src="imgSrc"
                :aspect-ratio="1"
                width="80px"
                class="ma-0"
                contain
              />
            </div>

            <div class="d-flex flex-column pa-2 text-left">
              <span
                style="font-size: x-large"
                :class="[
                  { 'lh-15': $vuetify.display.mdAndUp },
                  { 'lh-10': ddIsPhone() || $vuetify.display.smAndDown },
                ]"
              >
                <v-icon v-if="config.type === 'group'">{{
                  show ? 'mdi-minus' : 'mdi-plus'
                }}</v-icon>
                {{ config.title }}
              </span>
              <span class="font-weight-medium" style="font-size: small">
                {{ config.subTitle }}
              </span>
            </div>
          </div>
        </v-sheet>
      </v-hover>

      <!-- Desktop/Tablet Banner -->
      <v-hover v-else-if="isNotPhone" v-slot="{ isHovering, props }">
        <div
          v-bind="props"
          :class="[
            {
              'cursor-pointer elevation-8':
                isHovering && config.type === 'tile',
            },
          ]"
          style="height: 100%"
          @click="clickHandler(config.pageId)"
        >
          <v-sheet
            :color="titleBgColor"
            :style="`color: ${titleTextColor}`"
            :class="{
              'py-2': true,
              'pl-1': true,
              'elevation-8': isHovering && config.type === 'group',
            }"
          >
            <div
              :class="{
                'd-flex': true,
                'lh-15': true,
                'justify-center': config.type === 'tile',
                'align-center': true,
              }"
            >
              <v-btn
                v-if="config.type === 'group'"
                :icon="show ? 'mdi-minus' : 'mdi-plus'"
                variant="text"
                height="25px"
                width="25px"
              ></v-btn>
              <v-icon
                v-if="config.icon && device !== 'phone'"
                :class="['mr-0', { 'mr-1 fs-16': config.icon.includes('fa:') }]"
              >
                {{ config.icon }}
              </v-icon>
              <span :class="[{ 'ml-1': !config.icon.includes('fa-thin') }]">{{
                config.title
              }}</span>
            </div>
          </v-sheet>

          <!-- Tile Content -->
          <v-sheet v-if="config.type === 'tile' && device !== 'phone'">
            <div
              :class="{
                'd-flex flex-column ma-2': isImageLoaded,
              }"
            >
              <div class="text-grey-darken-1" style="line-height: 1.1">
                {{ config.subTitle }}
              </div>
              <v-spacer vertical />
              <v-img
                v-if="config.image"
                :src="imgSrc"
                contain
                @load="imgLoaded"
              ></v-img>
            </div>
          </v-sheet>
        </div>
      </v-hover>

      <!-- Group content -->

      <v-expand-transition>
        <v-sheet
          class="d-flex flex-wrap"
          v-if="config?.items?.length && show"
          color="#ddd"
        >
          <WidgetMenuCell
            v-for="item of config.items"
            :key="item.title"
            :config="item"
            :device="device"
            :depth="depth + 1"
          />
        </v-sheet>
      </v-expand-transition>
    </v-sheet>
  </div>
</template>
