<script setup>
import * as d3 from 'd3'
import { ref, defineProps, computed, watch, onMounted, defineEmits } from 'vue'
import { storeToRefs } from 'pinia'
import useCommonConfig from '@/composables/useCommonConfig'
import { formatDate } from '@/services/utils'

const { isMobile, isNotMobile, display, linearStore } = useCommonConfig()

const {
  backData,
  scrollWidth,
  xScrollPos,
  yScrollPos,
  getIsNewData,
  toolbarIdx,
  isCrossHairModeEnabled,
  chartZoomReset,
  isXZoomEnabled,
  isYZoomEnabled,
  axTop,
  xTicks,
  xAxis,
  yAxis,
  yGrid,
  // yAxisLeft,
  //yVal,
  path,
  line,
  lineValues,
  xTop,
  // y,
  //y2,
  isLoading,
  trackProperties,
} = storeToRefs(linearStore)

const props = defineProps({
  configs: Object,
  data: Array,
  iteration: Number,
})

const emits = defineEmits(['emitPagination', 'emitRefresh'])

const margin = { top: 10, right: 20, bottom: 10, left: 20 }
const initialWidth = window.innerWidth // - margin.left - margin.right
const width = ref(scrollWidth.value ? scrollWidth.value : initialWidth)
const height = 240 - margin.top - margin.bottom

const svg = ref()
const svgg = ref()
// const line = ref()
const lineValueY = ref()
const x = ref()
const x2 = ref()
const y = ref()
const y2 = ref()
const xToolTip = ref()
const xAxisG = ref()
// const xAxis = ref()
// const path = ref()
// const path = ref()
// const trackTicks = ref([])
//const xTicks = ref(10)
const zoomState = ref()
const propYValues = ref(props.configs?.yValues) //.toUpperCase()
const propXValues = ref(props.configs?.xValues) //.toUpperCase()
// zoom vars
const newX = ref()
const newY = ref()
const xScale = ref()
const yScale = ref()
//const axTop = ref()
const yAxisLeft = ref()
const axLeft = ref()
const axTrack = ref()
const dataset = ref()
const currentXvalue = ref()
const currentYvalue = ref()
const currentChainMarker = ref(props.configs.currentChainMarker)
const currentScrollLeft = ref(0)

const dataVar = ref(backData.value) // ref(props.data)
let abcData = [] // TODO: come up with a better name
let colorArr = []
// determine if the dataset is ascending or descending
const isAscending =
  dataVar.value[0].startPosition < dataVar.value[0].endPosition

let startVal
let endVal
// find whichever path is starting first
const startArr = []
const endArr = []
dataVar.value.forEach(x => {
  startArr.push(x.data[0][propXValues.value])
  endArr.push(x.data[x.data.length - 1][propXValues.value])
})

if (isAscending) {
  startVal = Math.min(...startArr)
  endVal = Math.min(...endArr)
  //queryEndVal = startVal + end
  // console.log('queryEndVal ', queryEndVal)
} else {
  startVal = Math.max(...startArr)
  endVal = Math.max(...endArr)

  //.log('queryEndVal ', queryEndVal)
}

dataVar.value.forEach((propData, pdIdx) => {
  if (
    (isAscending && propData.startPosition < endVal) ||
    (!isAscending && propData.startPosition > endVal)
  ) {
    colorArr.push(propData.color)
    abcData = [...abcData, ...propData.data]
  }
  // propData.data.forEach(plotData => {
  //   //
  //   abcData = [...abcData, ...plotData]
  // })
})
// console.log('backdata ', backData.value)
// console.log('abcData ', abcData)
const dataNest = Array.from(
  d3.group(abcData, d => {
    return d.Survey_ID // this should probably become a variable
  }),
  ([key, value]) => ({ key, value })
)

const keys = Array.from(dataNest.keys()) // list of group names
const color = d3.scaleOrdinal().domain(keys).range(colorArr)

// path mover
const pathsToManipulate = ref([])
dataVar.value.forEach(() => {
  pathsToManipulate.value.push({
    xDisplacement: 0,
    yDisplacement: 0,
    isDisplayed: true,
  })
})

const displacementAmount = 1
const movePath = (id, direction, idx) => {
  const path = document.getElementById(id)

  if (direction === 'down')
    pathsToManipulate.value[idx].yDisplacement += displacementAmount

  if (direction === 'up')
    pathsToManipulate.value[idx].yDisplacement -= displacementAmount

  if (direction === 'left')
    pathsToManipulate.value[idx].xDisplacement -= displacementAmount

  if (direction === 'right')
    pathsToManipulate.value[idx].xDisplacement += displacementAmount

  path.style.transform = `translate(${pathsToManipulate.value[idx].xDisplacement}px,${pathsToManipulate.value[idx].yDisplacement}px)`
}
// end path mover

// path toggler
const togglePath = (id, isDisplayed, idx) => {
  const path = document.getElementById(id)
  let displayVal = 'block'
  pathsToManipulate.value[idx].isDisplayed = true
  if (isDisplayed) {
    pathsToManipulate.value[idx].isDisplayed = false
    displayVal = 'none'
  }

  path.style.display = displayVal
}

// end path toggler

// scroll to position

const setScrollPosition = () => {
  //console.log('>>>> ', document.getElementById(`chart-top-axis-${propYValues}`))
  const chartArr = document.getElementsByClassName('chart-scroller')
  for (let i = 0; i < chartArr.length; i++) {
    chartArr[i].scrollLeft = xScrollPos.value
    window.scrollTo(0, yScrollPos.value)
  }
  // document.getElementById(`chart-top-axis-${propYValues}`).scrollLeft =
  //   xScrollPos.value
  // document.getElementById(`chart-container-${propYValues}`).scrollLeft =
  //   xScrollPos.value
  // e.target.scrollLeft
}

// end scroll to position

const yAxisPresets = ref(props.configs.yAxis)

const yAxisDomain = computed(() => {
  if (props.configs.yAxis.length > 1) {
    return yAxisPresets.value
  } else {
    let min, midMin, median, midMax, max
    min = d3.min(abcData, d => d[propYValues.value])
    max = d3.max(abcData, d => d[propYValues.value])
    median = d3.median(abcData, d => d[propYValues.value])
    midMin = Math.floor(median + (Math.abs(min) - median) / 2)
    midMax = Math.floor(median + (Math.abs(max) - median) / 2)

    return [max, midMax, median, midMin, min]
  }
  // return [100, 50, 0, -50, -100]
})

const yAxisDomainMinMax = computed(() => {
  return [yAxisDomain.value[yAxisDomain.value.length - 1], yAxisDomain.value[0]]
})
let splitMarker, topMarker, topOffset, startPosition, startMod
// if (props.iteration === toolbarIdx.value) {
splitMarker = backData.value[0].startMaker.split('+')
topMarker = splitMarker[0]
topOffset = parseFloat(splitMarker[1])
startPosition = backData.value[0].data[0].Position
startMod = backData.value[0].data[0].positionMod // delete?
//}
const getMarkerOffset = (d, i) => {
  const tempObj = abcData.find(x => x[propXValues.value] === d)
  // console.log(' tempObj.Position ', tempObj?.Position)
  // if (i === 0) return backData.value[0].startMaker
  // else {
  let returnMarker, returnOffset
  let newNum = parseFloat(tempObj?.Position) / props.configs.chainMarkerDistance // parseInt(tempObj?.Position)

  // if the number has no decimal places, then it represents a whole marker
  if (newNum % 1 === 0) {
    returnMarker = parseFloat(topMarker) + parseFloat(newNum)
    returnOffset = 0
  } else {
    let splitNewNum = newNum.toString().split('.')

    returnMarker = parseFloat(topMarker) + parseFloat(splitNewNum[0])
    // parseFloat(splitNewNum[0]) === 0
    //   ? parseFloat(topMarker) + parseFloat(splitNewNum[0])
    //   : parseFloat(topMarker)

    // topOffset = topOffset + parseInt(d) //splitNewNum[1] /// to be converted
    //if (props.configs.chainMarkerDistance > tempObj?.Position) {
    if (props.configs.chainMarkerDistance > parseFloat(tempObj?.Position)) {
      // const addOrSub = isAscending
      //   ? tempObj?.Position - startPosition
      //   : startPosition - tempObj?.Position
      const addOrSub = isAscending
        ? parseFloat(tempObj?.Position) - startPosition
        : startPosition - parseFloat(tempObj?.Position)

      returnOffset = parseFloat(topOffset) + parseFloat(addOrSub) //tempObj?.Position - d // (tempObj?.Position - (tempObj?.Position - 1)) // tempObj?.Position // figure out how to add this -> + topOffset
      //console.log('addOrSub ', addOrSub)
    } else {
      //props.configs.chainMarkerDistance < tempObj?.Position
      // console.log(
      //   tempObj?.Position,
      //   '- ',
      //   returnMarker,
      //   ' * ',
      //   props.configs.chainMarkerDistance
      // )

      returnOffset = Math.abs(
        parseFloat(splitNewNum[0]) * props.configs.chainMarkerDistance -
          parseFloat(tempObj?.Position)
      )

      // if (isNaN(returnOffset)) {
      //   // check the previous value
      //   console.log(d, ' <- NOOOOOOOOOOOOOOOOOOOOOOO! -> ', returnOffset)

      //   returnOffset = d
      // }

      // if (returnOffset > props.configs.chainMarkerDistance)
      //   returnOffset = returnOffset - props.configs.chainMarkerDistance
      // parseFloat(tempObj?.Position) -
      // parseFloat(returnMarker) * props.configs.chainMarkerDistance
    }
    // returnOffset =
    //   props.configs.chainMarkerDistance * parseFloat(`0.${splitNewNum[1]}`) +
    //   topOffset // topOffset + parseInt(d)
  }
  // if d / chainMarkerDistance = 1 (whole number, with no decimal points), set topMarker to this number and the topOffset to 0

  return `${returnMarker}+${Math.ceil(returnOffset)}`
  //}
  // console.log('tempObj?.Offset ', tempObj?.Offset)
  // if (tempObj?.Marker !== undefined)
  //   return tempObj.Marker + '+' + tempObj?.Offset // TODO: should not be hard-coded
  // return '0+0'
}

// const trackPropertyFinder = val => {
//   let returnVal = ''

//   trackProperties.value[0].Properties.elements.forEach(x => {
//     if (parseInt(val) === x.linear.start) {
//       console.log('pop! ', x)

//       // fire a new method

//       returnVal = x.label
//     }
//   })
//   return returnVal
// }

//const zoom = d3.zoom()
// Set dimensions and margins for the chart

// const setzoom = () => {
//   zoom.scaleExtent([1, 20]).on('zoom', function zoomHandler() {
//     //alert(svglist.length);
//     console.log('zoom zoom!')
//   })
// }
// const getTrackProperties = val => {
//   // trackTicks
//   // axTrack.value
//   //   .append('rect')
//   //   .attr('class', 'track-rect')
//   //   .attr('x', 67 * xTicks.value)
//   //   .attr('y', -40)
//   //   .attr('width', x.linear.end - x.linear.start)
//   //   .attr('height', 40)
//   //   .style('fill', '#e0e0e0')
//   trackTicks.value.forEach(v => {
//     const gV = 'g-' + v.toString().replaceAll('.', '')
//     d3.select(v)._groups[0][0].parentNode.classList.add(gV)

//     console.log('v ', v)
//     console.log('d3.select(v) ', d3.select(v))
//     console.log(
//       'd3.select(v).groups ',
//       d3.select(v)._groups[0][0].parentNode.children[3]
//     )
//     // d3.select(v).node().parentNode.attr('width', '800')
//     // d3.select(v).attr('class', '12345')
//     // d3.selectAll(d3.select('.' + v).node().parentNode + ' text')
//     d3.selectAll(`.${gV}`)
//       .append('rect')
//       .attr('class', 'track-rect')
//       .attr('y', -40)
//       .attr('width', 1000)
//       .attr('height', 40)
//       .style('fill', '#e0e0e0')
//     //.attr('width', 1000)
//     // d3.select(this.parentElement).attr('class', '12345')
//   })
//   // trackProperties.value.forEach(x => {
//   //   console.log('x > ', x)
//   // })
// }

const paginationHandler = () => {
  emits('emitPagination')
}

const syncScrolling = e => {
  const graphs = document.querySelectorAll('.chart-scroller')
  for (let i = 0; i < graphs.length; i++) {
    graphs[i].scrollLeft = parseInt(e.target.scrollLeft)
    // console.log('e.target.scrollLeft ', parseInt(e.target.scrollLeft))
    // console.log('graphs[i].scrollLeft ', graphs[i].scrollLeft)
  }
}

const generateTrackDisplay = newX => {
  // adds rects to axTrack
  trackProperties.value[0]?.Properties.elements.forEach(tp => {
    // we need a prior check
    abcData.find(d => {
      if (tp.linear.start === d[propXValues.value]) {
        console.log('x[propXValues.value] ', d[propXValues.value])
        const linearStart = newX(d[propXValues.value])
        console.log('x.value(tp.linear.end) ', newX(tp.linear.end))
        const linearEnd = newX(tp.linear.end) - linearStart
        console.log('linearEnd ', linearEnd)

        axTrack.value.selectAll('.track-rect').remove()
        axTrack.value.selectAll('.track-rect-text').remove()

        axTrack.value
          .append('rect')
          .attr('class', 'track-rect')
          .attr('x', linearStart)
          .attr('y', -40)
          .attr('width', linearEnd)
          .attr('height', 40)
          .attr('fill', 'url(#stationPattern)')
        axTrack.value
          .append('text')
          .attr('class', 'track-rect-text')
          .text(tp.label)
          .attr('fill', '#000')
          .attr('x', linearStart + linearEnd / 2)
          .attr('y', -20)
          .attr('text-anchor', 'middle')
          .attr('font-weight', 'bold')
      }
    })
  })
}

const loadChart = () => {
  svg.value = d3
    .select(`#chart-container-${propYValues.value}`)
    .append('svg')
    .attr('width', width.value + margin.left + margin.right)
    .attr('height', height + margin.top + margin.bottom)
    .append('g')
    .attr('transform', `translate(15,10)`)

  const defs = svg.value.append('defs')

  x.value = d3
    .scaleLinear()
    .domain([
      d3.min(abcData, d => d[propXValues.value]),
      d3.max(abcData, d => d[propXValues.value]),
    ])
    .range([0, width.value - 30])
  // abcData.map(d => d[propXValues])
  // svg.value
  //   .append('g')
  //   .attr('transform', `translate(0, ${height})`)
  //   .call(d3.axisTop(x.value).ticks(5))
  x2.value = x.value.copy()
  xToolTip.value = x.value.copy()

  xAxis.value = d3.axisTop(x2)
  // Y-axis stuff
  yAxisLeft.value = d3
    .select(`#chart-yaxis-${propYValues.value}`)
    .append('svg')
    .attr('width', 60)
    .attr('height', height + margin.top + margin.bottom)
    .append('g')
    .attr('transform', `translate(60,10)`)

  const clip = defs
    .append('SVG:clipPath')
    .attr('id', 'clip')
    .append('SVG:rect')
    .attr('width', width.value) // TODO: increase margin to push clipped region to chart axis lines
    .attr('height', height + margin.top + margin.bottom)
    .attr('x', 0)
    .attr('y', 0)

  const clipPath = svg.value.append('g').attr('clip-path', 'url(#clip)')
  // y.value = d3
  //   .scaleLinear()
  //   .domain([
  //     d3.min(abcData, d => d[propYValues]),
  //     d3.max(abcData, d => d[propYValues]),
  //   ])
  //   .range([height - 30, 0])

  y.value = d3
    .scaleLinear()
    .domain(
      props.configs.yAxis.length > 1
        ? yAxisDomainMinMax.value
        : [
            d3.min(abcData, d => d[propYValues.value]),
            d3.max(abcData, d => d[propYValues.value]),
          ]
    )
    .range([height, 0])

  y2.value = x2.value.copy() // ?

  axLeft.value = yAxisLeft.value
    .append('g')
    .style('font-size', '14px')
    .call(
      props.configs.yAxis.length > 1
        ? d3
            .axisLeft(y.value)
            .ticks(5)
            .tickFormat(d3.format('.2f'))
            .tickPadding(10)
            .tickValues(
              props.configs.yAxis.length > 1 ? yAxisDomain.value : null
            )
        : d3.axisLeft(y.value).ticks(5)
    )
    .selectAll('.tick text')
    .style('fill', '#777') // Make the font color grayer
  //.call(g => g.select('.domain').remove())

  // end Y-axis stuff

  // top / X-axis stuff

  // detached top axis
  // Add the x-axis
  // only call this on the first iteration
  if (props.iteration === toolbarIdx.value) {
    xAxisG.value = axTop.value = d3
      .select(`#chart-top-axis`)
      .append('svg')
      .attr('width', width.value + margin.left + margin.right)
      .attr('height', 40)
      .append('g')
      .attr('transform', `translate(20,40)`)
      .style('font-size', '14px')
      .attr('class', 'top-tick')

    xAxisG.value // may be redundant
      .call(
        d3
          .axisTop(x.value)
          .ticks(xTicks.value) //.tickValues(x.value.ticks(xTicks.value).concat(x.value.domain())) //.ticks(xTicks.value)
          .tickFormat((d, i) => {
            return getMarkerOffset(d, i)
          })
        // d3.axisTop(x.value)
        //.tickValues(x.value.ticks(d3.timeMonth.every(2))) // Display ticks every 6 months
        //.tickFormat(d3.timeFormat('%b %Y'))
      )
      .call(g => g.select('.domain').remove()) // Remove the x-axis line
      .selectAll('.tick line') // Select all tick lines
      .style('stroke-opacity', 1)

    // top axis
    d3.selectAll('.tick').each(function (d, i) {
      let tick = d3.select(this),
        text = tick.select('text'),
        bBox = text.node().getBBox()
      tick
        .insert('rect', ':first-child')
        .attr('x', bBox.x - 3)
        .attr('y', bBox.y - 6)
        .attr('height', bBox.height + 6)
        .attr('width', bBox.width + 6)
        .style('fill', '#e0e0e0')
        .attr('stroke-width', 1)
        .attr('stroke', '#777')
    })

    axTrack.value = d3
      .select(`#chart-track-properties`)
      .append('svg')
      .attr('width', width.value + margin.left + margin.right)
      .attr('height', 30)
      .append('g')
      .attr('transform', `translate(10,45)`)
      .style('font-size', '14px')
      .attr('class', 'track-tick')

    const stationPattern = svg.value
      .append('pattern')
      .attr('id', 'stationPattern')
      .attr('width', '4')
      .attr('height', '4')
      .attr('patternTransform', 'rotate(45 0 0)')
      .attr('patternUnits', 'userSpaceOnUse')
    stationPattern
      .append('line')
      .attr('x1', 0)
      .attr('y1', 0)
      .attr('x2', 0)
      .attr('y2', 10)
      .style('stroke', '#376191')
      .style('stroke-width', 3)
      .style('stroke-opacity', 0.3)
    generateTrackDisplay(x.value)
    // axTrack.value
    //   .call(
    //     d3
    //       .axisTop(x.value)
    //       .ticks(100)
    //       .tickSize(-height)
    //       .tickFormat((d, i) => trackPropertyFinder(d))
    //     // d3.axisTop(x.value)
    //     //.tickValues(x.value.ticks(d3.timeMonth.every(2))) // Display ticks every 6 months
    //     //.tickFormat(d3.timeFormat('%b %Y'))
    //   )
    //   .call(g => g.select('.domain').remove())
  }

  // end top / X-axis stuff

  // Grid stuff
  // yAxis.value = svg.value
  //   .append('g')
  //   .attr('class', 'grid')
  //   .data(y.value.ticks(5))
  //   .call(d3.axisLeft(y.value).tickSize(-width.value))
  // .attr('stroke', (d, i, nodes) => {
  //   console.log('ddd>>> ', d)
  //   if (d === 0) return '#777'
  //   if (
  //     d === props.configs.yAxis[1] ||
  //     d === props.configs.yAxis[props.configs.yAxis.length - 2]
  //   )
  //     return '#FFC300'
  //   if (
  //     d === props.configs.yAxis[0] ||
  //     d === props.configs.yAxis[props.configs.yAxis.length - 1]
  //   )
  //     return '#ff0000'
  //   else return '#e0e0e0'
  // })
  // .attr('stroke-width', 0.5)
  // .style('stroke-dasharray', (d, i, nodes) => {
  //   if (d === 0) return '5 5'
  // })
  // .style('visibility', (d, i, nodes) => {
  //   if (props.configs.yAxis.some(v => v === d)) {
  //     return 'visible' // Hide the first and last tick labels
  //   }
  //   if (props.configs.yAxis.some(v => v !== d)) {
  //     return 'hidden' // Show the remaining tick labels
  //   }
  // })

  gy.value = svg.value.append('g').call(yGridGenerator, y.value)

  // yAxis.value = svg.value
  //   .selectAll('yGrid')
  //   .data(y.value.ticks(40))
  //   .join('line')
  //   .attr('x1', 0)
  //   .attr('x2', width.value)
  //   .attr('y1', d => y.value(d))
  //   .attr('y2', d => y.value(d))
  //   .attr('stroke', (d, i, nodes) => {
  //     if (d === 0) return '#777'
  //     if (
  //       d === props.configs.yAxis[1] ||
  //       d === props.configs.yAxis[props.configs.yAxis.length - 2]
  //     )
  //       return '#FFC300'
  //     if (
  //       d === props.configs.yAxis[0] ||
  //       d === props.configs.yAxis[props.configs.yAxis.length - 1]
  //     )
  //       return '#ff0000'
  //     else return '#e0e0e0'
  //   })
  //   .attr('stroke-width', 0.5)
  //   .style('stroke-dasharray', (d, i, nodes) => {
  //     if (d === 0) return '5 5'
  //   })
  //   .style('visibility', (d, i, nodes) => {
  //     if (props.configs.yAxis.some(v => v === d)) {
  //       return 'visible' // Hide the first and last tick labels
  //     }
  //     if (props.configs.yAxis.some(v => v !== d)) {
  //       return 'hidden' // Show the remaining tick labels
  //     }
  //   })

  // END Grid stuff

  line.value[props.iteration] = (linedata, x, y) => {
    return d3
      .line()
      .x(d => {
        return x(d[propXValues.value])
      })
      .y(d => {
        // console.log('> ddddd >', d)
        let yval = d[propYValues.value] ? d[propYValues.value] : 0
        return y(yval)
      })(linedata)
  }

  path.value[props.iteration] = svg.value
    .selectAll('path')
    .data(dataNest)
    .join('path')
    .attr('id', d => `pointline-${propYValues.value}-${d.key}`)
    .attr('class', 'pointline')
    .attr('fill', 'none')
    .style('stroke', d => {
      return color(d.key)
    })
    .attr('d', d => {
      lineValues.value[props.iteration] = [d.value, x.value, y.value]
      return line.value[props.iteration](
        lineValues.value[props.iteration][0],
        lineValues.value[props.iteration][1],
        lineValues.value[props.iteration][2] //lineValues[props.iteration][2]
      )
    })

  // This is for tracking the mouse movements across our chart
  const listeningRect = svg.value
    .append('rect')
    .attr('class', 'listening-rect')
    .attr('width', width.value)
    .attr('height', height)

  const mouseG = svg.value
    .append('g')
    .attr('class', `mouse-over-effects-${propYValues.value}`)

  const mouseLine = mouseG
    .append('path') // this is the black vertical line to follow mouse
    .attr('class', `mouse-line-${propYValues.value}`)
    .style('stroke', 'black')
    .style('stroke-width', '1px')
    .style('opacity', '0')

  const lines = document.getElementsByClassName('pointline') // likely delete

  const tooltip = d3
    .select(`#chart-container-${propYValues.value}`)
    .append('div')
    .attr('id', `tooltip-${propYValues.value}`)
    .attr('class', 'tooltip')

  listeningRect
    .on('mouseout', function () {
      if (isCrossHairModeEnabled.value) {
        // on mouse out hide line, circles and text
        mouseLine.style('opacity', '0') /// d3.select(`.mouse-line-${propYValues}`).style('opacity', '0')

        tooltip.style('display', 'none')
      }
    })
    .on('mouseover', function () {
      if (isCrossHairModeEnabled.value) {
        // on mouse in show line, circles and text
        mouseLine.style('opacity', '1') //d3.select(`.mouse-line-${propYValues}`).style('opacity', '1')
      }
    })
    .on('mousemove', function (e) {
      if (isCrossHairModeEnabled.value) {
        // mouse moving over canvas
        // const mouse = e.pageX //+ 100 // newLeft - width.value / 2 //e.pageX
        // const xPos = e.pageX - 130 + currentScrollLeft.value // x.value(d[propXValues])
        // const [yCoord] = yCoord[1] // d3.pointer(e, this)
        const yPos = d3.pointer(e, this)[1] // e.clientY - 300 //y.value(d[propYValues]) // yCoord - 300
        const [xCoord] = d3.pointer(e, this) //- currentScrollLeft.value
        const toolTipPos = xCoord + 150 - currentScrollLeft.value //+ 150
        const linePos = xCoord - 5
        const bisectData = d3.bisector(d => {
          return d[propXValues.value]
        }).left

        let toolTipText = ''
        // TODO: marker and offset need to be variables
        // dataNest.foreach() // get all arrays
        dataNest.forEach((dN, dNiDx) => {
          const x0 = xToolTip.value.invert(xCoord)
          const i = bisectData(dataNest[dNiDx].value, x0, 1)
          const d0 = dataNest[dNiDx].value[i - 1]
          const d1 = dataNest[dNiDx].value[i]
          if (d0 !== undefined && d1 !== undefined) {
            const d =
              x0 - d0[propXValues.value] > d1[propXValues.value] - x0 ? d1 : d0

            //<br><strong>Marker:</strong> ${d?.Marker}+${d?.Offset}

            toolTipText += `<p><strong>Survey:</strong> ${
              backData.value[dNiDx].surveyName
            } <span class=' d-inline-block' style='width: 20px; height: 20px; background-color:${
              backData.value[dNiDx].color
            }'>&nbsp;</span> <br><strong>${
              backData.value[dNiDx].positionUnits
            }:</strong> ${
              d.Position // [propXValues.value]
            }<br><strong>Measurement:</strong> ${
              d[propYValues.value] !== undefined ? d[propYValues.value] : 'N/A'
            }<br>
        <strong>Lat:</strong> ${d[props.configs.lat]}, <strong>Lng:</strong> ${
              d[props.configs.long]
            }</p><br>`
          }
        })
        mouseLine.attr('d', function () {
          let d = 'M' + linePos + ',' + height
          d += ' ' + linePos + ',' + 0
          return d
        })
        tooltip
          .style('display', 'block')
          .style('left', `${toolTipPos}px`) // hack
          .style('top', `${yPos}px`)
          .html(toolTipText)
      }
    })

  setScrollPosition()
} // end loadChart()

watch(
  () => backData.value,
  newVal => {
    // update chart when data updates
    // loadChart()
    // let oldSum, newSum
    // newVal.forEach(x => {
    //   x.data.length
    // })
    // console.log('backData newval ', newVal)
  },
  { deep: true }
)

const scrollHandler = e => {
  syncScrolling(e)
  // console.log(' e.e.target.scrollWidth ', e.target.scrollWidth)
  // console.log('e.target.clientWidth ', e.target.clientWidth)
  const endWidth = e.target.scrollWidth - e.target.clientWidth // - 40
  // TODO: when the component reloads, scroll the element to the previous position
  currentScrollLeft.value = e.target.scrollLeft
  // console.log('e.target.scrollLeft ', e.target.scrollLeft + 40)
  // console.log('endWidth ', endWidth)
  // console.log('% ', endWidth - e.target.scrollLeft)
  // trouble executing on smaller screens
  if (endWidth <= e.target.scrollLeft + 40) {
    // console.log('end is nigh')
    xScrollPos.value = e.target.scrollLeft
    yScrollPos.value = window.scrollY

    // expands the width
    if (getIsNewData.value) {
      // a new trigger will be needed. Maybe from Pinai
      scrollWidth.value = width.value + initialWidth
    }
    if (props.iteration === toolbarIdx.value) {
      // if necessary?

      if (getIsNewData.value) {
        // xTicks.value = xTicks.value + 5
        paginationHandler()
      }
      //removeCharts()
      // loadChart()
    }
    // paginationHandler()
  }
}
const gy = ref()
const yGridGenerator = (g, y) => {
  g.selectAll('.grid-line').remove()

  // yGrid.value = g
  //   .selectAll('yGrid')
  //   .data(y.ticks(40))
  //   .join('line')
  //   .attr('x1', 0)
  //   .attr('x2', width.value)

  g.selectAll('yGrid')
    .data(y.ticks(40))
    .join('line')
    .attr('class', 'grid-line')
    .attr('x1', 0)
    .attr('x2', width.value)
    .attr('y1', d => y(d))
    .attr('y2', d => y(d))
    .attr('stroke-width', 0.5)
    .attr('stroke', (d, i, nodes) => {
      if (d === yAxisDomain.value[3]) return '#777'
      if (
        d === yAxisDomain.value[1] ||
        d === yAxisDomain.value[yAxisDomain.value.length - 2]
      )
        return '#FFC300'
      if (
        d === yAxisDomain.value[0] ||
        d === yAxisDomain.value[yAxisDomain.value.length - 1]
      )
        return '#ff0000'
      else return '#e0e0e0'
    })
    .style('stroke-dasharray', (d, i, nodes) => {
      if (d === 0) return '5 5'
    })
    .style('visibility', (d, i, nodes) => {
      if (yAxisDomain.value.some(v => v === d)) {
        return 'visible' // Hide the first and last tick labels
      }
      if (yAxisDomain.value.some(v => v !== d)) {
        return 'hidden' // Show the remaining tick labels
      }
    })
}

const remoteCall = xVal => {
  axTop.value.call(
    d3
      .axisTop(xVal)
      .ticks(xTicks.value)
      .tickFormat((d, i) => getMarkerOffset(d, i)) // add marker here
  )
}

const handleZoom = e => {
  x2.value = e.transform.rescaleX(x.value)
  y2.value = e.transform.rescaleY(y.value)

  const xVal = isXZoomEnabled.value ? x2.value : x.value
  const yVal = isYZoomEnabled.value ? y2.value : y.value

  xToolTip.value = xVal

  for (let i = 0; i < line.value.length; i++) {
    path.value[i].attr('d', d => {
      if (i === props.iteration) lineValues.value[i] = [d.value, xVal, yVal]
      else {
        lineValues.value[i][0] = d.value
        lineValues.value[i][1] = xVal
        // we don't want to pass the y.value to all the other charts
      }

      return line.value[i](
        lineValues.value[i][0],
        lineValues.value[i][1],
        lineValues.value[i][2]
      )
    })
  }
  if (isXZoomEnabled.value) {
    remoteCall(xVal)
    generateTrackDisplay(xVal)
  }

  if (isYZoomEnabled.value) {
    gy.value.call(yGridGenerator, yVal)
    // detached left ticks
    yAxisLeft.value
      .call(
        props.configs.yAxis.length > 1
          ? d3
              .axisLeft(y.value)
              .scale(e.transform.rescaleY(y.value))
              .ticks(5)
              .tickValues(yAxisDomain.value)
              .tickFormat(d3.format('.2f'))
              .tickPadding(10)
          : d3.axisLeft(y.value).ticks(5).scale(e.transform.rescaleY(y.value))
      )
      .style('font-size', '14px')
  }

  // if (props.iteration === toolbarIdx.value) {
  // apply tick backgrounds
  d3.selectAll('.tick').each(function (d, i) {
    var tick = d3.select(this),
      text = tick.select('text'),
      bBox = text.node().getBBox()

    tick
      .insert('rect', ':first-child')
      .attr('x', bBox.x - 3)
      .attr('y', bBox.y - 6)
      .attr('height', bBox.height + 6)
      .attr('width', bBox.width + 6)
      .style('fill', '#e0e0e0')
      .attr('stroke-width', 1)
      .attr('stroke', '#777')
  })
  //}
}

const zoom = d3.zoom().scaleExtent([0.25, 100]).on('zoom', handleZoom)

// zoom controls

const zoomReset = () => {
  emits('emitRefresh')
  zoom.transform(svg.value, d3.zoomIdentity) // delete?
}

watch(
  () => chartZoomReset.value,
  () => {
    zoomReset()
  },
  {
    deep: true,
  }
)
/*
// This will be necessary if/when we can get the buttons to zoom in and out on the axes
watch(
  () => chartZoomInX.value,
  () => {
    zoomInX()
  },
  {
    deep: true,
  }
)

watch(
  () => chartZoomOutX.value,
  () => {
    zoomOutX()
  },
  {
    deep: true,
  }
)


watch(
  () => chartZoomInY.value,
  () => {
    zoomOutY()
  },
  {
    deep: true,
  }
)

watch(
  () => chartZoomOutY.value,
  () => {
    zoomOutX()
  },
  {
    deep: true,
  }
)*/

const showToolbars = computed(() => {
  return props.iteration === toolbarIdx.value
})

onMounted(() => {
  console.log('chart mounted!')
  loadChart()
  svg.value.call(zoom)
})
</script>

<template>
  <div v-if="showToolbars" class="pos-fix z-100" style="margin-top: 25px">
    <div class="d-flex bg-grey-lighten-4 border-bottom" style="height: 50px">
      <div class="d-flex align-center" style="width: 130px; max-width: 130px">
        <div class="text-left font-weight-bold">Markers</div>
      </div>
      <!-- 154px = 130px left column + 1px border + 12px padding + 11px  -->
      <div class="d-inline-flex border-left" style="width: calc(100vw - 200px)">
        <div
          :id="`chart-top-axis`"
          v-scroll.self="scrollHandler"
          class="chart-selector chart-scroller text-left overflow-x-hidden"
        ></div>
      </div>
    </div>
    <!-- Track -->
    <div
      class="d-flex overflow-y-hidden w-100 bg-grey-lighten-4 border-bottom"
      style="max-height: 60px"
    >
      <div
        class="align-center d-inline-flex text-left font-weight-bold"
        style="width: 130px; max-width: 130px"
      >
        Track
      </div>
      <div
        class="d-inline-flex align-center border-left"
        style="width: calc(100vw - 200px)"
      >
        <div
          :id="`chart-track-properties`"
          v-scroll.self="scrollHandler"
          class="chart-selector chart-scroller text-left overflow-x-hidden"
        ></div>
      </div>
    </div>
  </div>
  <div
    class="d-flex border-bottom pos-rel"
    :style="showToolbars ? 'margin-top: 110px;' : ''"
  >
    <!--width:100vw-->
    <!--<div :id="`chart-container`" class="overflow-x-auto"></div>-->
    <div
      class="d-inline-flex align-start overflow-hidden pos-rel widget-linear-chart__left"
      style="min-width: 130px; max-width: 130px"
    >
      <div
        class="d-inline text-no-wrap font-weight-bold pos-abs"
        style="rotate: -270deg; max-width: 50px; top: 50%; margin-top: -50%"
      >
        {{ configs.label }}
      </div>
      <div
        :id="`chart-yaxis-${propYValues}`"
        class="ml-12 w-100 chart-selector"
      ></div>
    </div>
    <div class="d-flex border-left px-0" style="width: calc(100vw - 200px)">
      <div
        class="pl-1 py-1 d-inline-flex pos-abs bg-white border"
        style="left: 10; top: 5"
      >
        <!-- Controller -->
        <div
          v-for="(controller, cIdx) in dataVar"
          class="d-flex align-center rounded mr-1 pa-1 bg-grey-lighten-2 border z-10"
          :key="cIdx"
        >
          <div
            class="d-inline-block mr-1"
            :style="`height: 15px; width: 15px; background-color: ${controller.color};`"
          >
            &nbsp;
          </div>
          <small>{{ formatDate(new Date(controller.date), false) }}</small>

          <v-tooltip :text="controller.surveyName" location="top">
            <template v-slot:activator="{ props }">
              <v-btn
                v-bind="props"
                size="x-small"
                variant="text"
                @click.stop="
                  togglePath(
                    `pointline-${propYValues}-${
                      controller.data[0][configs.surveyId]
                    }`,
                    pathsToManipulate[cIdx].isDisplayed,
                    cIdx
                  )
                "
                ><v-icon
                  :icon="
                    pathsToManipulate[cIdx].isDisplayed
                      ? 'mdi-check'
                      : 'mdi-close'
                  "
              /></v-btn> </template
          ></v-tooltip>
          <v-btn
            size="x-small"
            variant="text"
            @click.stop="
              movePath(
                `pointline-${propYValues}-${
                  controller.data[0][configs.surveyId]
                }`,
                'down',
                cIdx
              )
            "
            ><v-icon icon="mdi-arrow-down"
          /></v-btn>
          <v-btn
            size="x-small"
            variant="text"
            @click.stop="
              movePath(
                `pointline-${propYValues}-${
                  controller.data[0][configs.surveyId]
                }`,
                'up',
                cIdx
              )
            "
            ><v-icon icon="mdi-arrow-up" /></v-btn
          ><v-btn
            size="x-small"
            variant="text"
            @click.stop="
              movePath(
                `pointline-${propYValues}-${
                  controller.data[0][configs.surveyId]
                }`,
                'left',
                cIdx
              )
            "
            ><v-icon icon="mdi-arrow-left" /></v-btn
          ><v-btn
            size="x-small"
            variant="text"
            @click.stop="
              movePath(
                `pointline-${propYValues}-${
                  controller.data[0][configs.surveyId]
                }`,
                'right',
                cIdx
              )
            "
            ><v-icon icon="mdi-arrow-right"
          /></v-btn>
        </div>
      </div>
      <div
        :id="`chart-container-${propYValues}`"
        v-scroll.self="scrollHandler"
        class="text-left overflow-x-auto chart-selector chart-scroller"
      ></div>

      <v-progress-linear
        v-if="isLoading"
        color="primary"
        indeterminate
        class="pos-abs w-100"
        style="bottom: 0"
      ></v-progress-linear>
    </div>
  </div>
</template>

<style lang="scss">
.widget-linear-chart__root {
  .line-dashed {
    stroke-dasharray: 5, 5;
  }
  rect {
    pointer-events: all;
    fill-opacity: 0;
    stroke-opacity: 0;
    z-index: 1;
  }

  #chart-top-axis rect,
  #chart-track-properties .track-rect {
    fill-opacity: 1;
    stroke-opacity: 1;
  }

  .tooltip {
    position: absolute;
    padding: 10px;
    background-color: steelblue;
    color: white;
    border: 1px solid white;
    border-radius: 10px;
    display: none;
    opacity: 0.75;
  }

  .z-100 {
    z-index: 100 !important;
  }

  .z-10 {
    z-index: 10 !important;
  }
}
</style>
