import { FirestoreUploadData } from '@/model/FormData.model'
import { useUserStore } from '@/stores/user'
import { makeid } from './utils'
import { usePagesStore } from '@/stores/pages'
import { useDataStore } from '@/stores/data'
import { useNavigationStore } from '@/stores/navigation'

let isInitialized = false
let userStore: null | ReturnType<typeof useUserStore> = null
let pageStore: null | ReturnType<typeof usePagesStore> = null
let dataStore: null | ReturnType<typeof useDataStore> = null
let navigationStore: null | ReturnType<typeof useNavigationStore> = null

function initStores() {
  if (!isInitialized) {
    isInitialized = true
    userStore = useUserStore()
    pageStore = usePagesStore()
    dataStore = useDataStore()
    navigationStore = useNavigationStore()
  }
}

/**
 * Creates and returns a `FirestoreUploadData` object that corresponds to a new
 * log entry with the given information
 * @param action The action being logged
 * @param result The result of the action
 * @param details Details about the result, if needed
 * @returns A `FirestoreUploadData` object containing a new log record
 */
export function generateLogRecord(
  action: string,
  result: string,
  details: string | undefined
): FirestoreUploadData {
  initStores()
  const fsud = new FirestoreUploadData('logs', {
    action: action,
    result: result,
    details: details ?? null, // Firestore doesn't like undefined
    deletedFlag: false,
    // pageId: (pageStore?.currentPage as any)?.pageId ?? null,
    clientId: (userStore?.currentClient as any)?.clientId ?? null,
    moduleId: (userStore?.currentModule as any)?.moduleId ?? null,
    roleId: (userStore?.currentRole as any)?.roleId ?? null,
    recordStamp: makeid(20),
    version: navigationStore?.version,
  })
  fsud.setUpdateUserAndTime(true)
  return fsud
}

/**
 * Creates a log record with the given information and submits it to firestore.
 * This function is the best entry point for recording new logs, since the data
 * store is imported dynamically at runtime rather than at compile time, which
 * may cause issues.
 * @param action The action being logged
 * @param result The result of the action
 * @param details Details about the result, if needed
 */
export async function recordLog(
  action: string,
  result: string,
  details: string | undefined = undefined
) {
  initStores()
  try {
    await (dataStore as any).recordLog(action, result, details)
  } catch (error: any) {
    console.error(`Failed to add [${action}] log: ${error?.message ?? error}`)
  }
}
