import { defineStore } from 'pinia'
import {
  collection,
  query,
  onSnapshot,
  getCountFromServer,
  orderBy,
  startAfter,
  limit,
  where,
  getDocs,
} from 'firebase/firestore'
import { firestore, firestoreQuery } from '../services/firebase'
// import { db } from '../../firebase'
import idb from '@/api/idb'

let unsubWidget = () => {
  // initializes the variable
}

export const useWidgetsStore = defineStore('widgets', {
  state: () => ({
    widgets: [{}],
    currentForm: null,
    showAdd: false,
  }),
  getters: {
    getFetchedWidgets: state => state.widgets,
  },
  actions: {
    async getWidgetById(widgetId) {
      // if widgets array is empty pull from indexeddb
      if (this.widgets.length === 0)
        this.widgets = await idb.getDataStore('widgets')
      // check that the widget isn't already stored in Pinia
      if (!this.widgets.find(w => w.widgetId === widgetId)) {
        await firestoreQuery(firestore, widgetId, {
          coll: 'widgets',
          field: 'widgetId',
        }).then(async res => {
          if (res[0]) {
            // push to IndexedDB
            idb.addToDatabase('widgets', res[0])
            // pull indexedDB to Pinia widgets
            this.widgets = await idb.getDataStore('widgets')
            // this.widgets.push(res[0])
          } else {
            console.error(`Cannot access widget ${widgetId}`)
          }
        })
      }
    },
    subscribeWidgets() {
      // the previous action is purely for retrieving specific data, this one is to keep IndexedDB up-to-date
      const q = query(collection(firestore, 'widgets'))
      unsubWidget = onSnapshot(q, querySnapshot => {
        querySnapshot.docChanges().forEach(async change => {
          // this.defects = [] // empty first
          // this.defects = [...doc.data()]
          idb.addToDatabase('widgets', change.doc.data())
          this.widgets = await idb.getDataStore('widgets')
        })
      })
    },
    unsubscribeWidgets() {
      unsubWidget()
    },
  },
  persist: true,
})
