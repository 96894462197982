// this is temporary while Renee sets up proper auth

import { defineStore } from 'pinia'
import {
  doc,
  updateDoc,
  collection,
  query,
  onSnapshot,
  where,
} from 'firebase/firestore'
import {
  app,
  getAuth,
  linkWithPopup,
  OAuthProvider,
  onAuthStateChanged,
  signInWithEmailAndPassword,
  signInWithPopup,
  signOut,
} from '../../firebase'
import {
  firestore,
  firestoreQuery,
  sendPasswordResetEmail,
  mtcfDev,
} from '../services/firebase'
const auth = getAuth(app)
const microsoftProvider = new OAuthProvider('microsoft.com')
import idb from '@/api/idb'

let unsubscribeUser = () => {
  // initializes the variable
}
import { recordLog } from '@/services/logging'

const firestoreChangeDefault = async (coll, id, val) => {
  const docRef = doc(firestore, coll, `${id}`)
  await updateDoc(docRef, {
    clients: val,
  }).catch(error => {
    console.log(error)
  })
}

const firestoreResetSystemUpdate = async (coll, docId, val) => {
  const docRef = doc(firestore, coll, `${docId}`)
  await updateDoc(docRef, {
    systemUpdate: val,
  }).catch(error => {
    console.log(error)
  })
}

const resetDefault = (obj, level, mode, ids = {}, localArr = []) => {
  // level = client, module, role
  // mode = true / false
  obj.clients.forEach(x => {
    if (x.clientId === ids.clientId) {
      if (level === 'client') {
        x.isDefault = mode
        firestoreChangeDefault('users', obj.userId, obj.clients)
      }
      if (level === 'module' || level === 'role') {
        x.modules.forEach(y => {
          if (y.moduleId === ids.moduleId) {
            if (level === 'module') {
              y.isDefault = mode
              firestoreChangeDefault('users', obj.userId, obj.clients)
            }

            if (level === 'role')
              y.roles.forEach(z => {
                if (z.roleId === ids.roleId) {
                  z.isDefault = mode
                  firestoreChangeDefault('users', obj.userId, obj.clients)
                }
              })
          }
        })
      }
    }
  })

  localArr.forEach(x => {
    if (x.clientId === ids.clientId) {
      if (level === 'client') {
        x.isDefault = mode
      }
      if (level === 'module' || level === 'role')
        x.modules.forEach(y => {
          if (y.moduleId === ids.moduleId) {
            if (level === 'module') {
              y.isDefault = mode
            }

            if (level === 'role')
              y.roles.forEach(z => {
                if (z.roleId === ids.roleId) {
                  z.isDefault = mode
                }
              })
          }
        })
    }
  })
}

export const useUserStore = defineStore('user', {
  state: () => ({
    userId: null,
    isAuthenticated: false,
    user: { displayName: '', isValidated: false },
    clients: [],
    modules: [], // needed?
    roles: [], // needed?
    currentClient: null,
    currentModule: { roles: [] },
    currentRole: { menu: {} },
    notifications: [],
  }),
  getters: {
    getCurrentClientName: state => {
      return state.currentClient?.name
        ? state.currentClient?.name.toLowerCase().replaceAll(' ', '-')
        : null
    },
    getCurrentModuleName: state => {
      return state.currentModule?.name
        ? state.currentModule?.name.toLowerCase().replaceAll(' ', '-')
        : null
    },
    getClients: state => state.clients,
    getModules: state => state.currentClient.modules,
    getRoles: state => state.currentModule.roles,
    getMenuFromRole: async state => {
      /*await new Promise((resolve, reject) => {
        return resolve(state.currentRole.menu?.properties?.items)
      })*/
      return state.currentRole?.menu
        ? state.currentRole.menu?.properties?.items
        : null
    },
    getDefaultModule: state => {
      let hasDefault
      let returnVal = {}

      state.currentClient?.modules.forEach(el => {
        if (el.isDefault) {
          hasDefault = true
          returnVal['moduleId'] = el.moduleId
          returnVal['name'] = el.name.toLowerCase().replaceAll(' ', '-')
        }
      })

      // if there is no default, then we select the first one ## this may not be needed
      if (!hasDefault) {
        returnVal['moduleId'] = state.currentClient.modules[0]?.moduleId
        returnVal['name'] = state.currentClient.modules[0]?.name
          .toLowerCase()
          .replaceAll(' ', '-')
      }

      return returnVal
    },
    getCurrentRole: state => state.currentRole,
    getNotifications: state => state.notifications,
    getModuleMessages: state => state.currentModule?.message,
    getIsAuthenticated: state => state.isAuthenticated,
    getUser: state => state.user,
  },
  actions: {
    subscribeUser() {
      // the previous action is purely for retrieving specific data, this one is to keep IndexedDB up-to-date
      const q = query(
        collection(firestore, 'users'),
        where('userId', '==', this.userId)
      )
      unsubscribeUser = onSnapshot(q, querySnapshot => {
        querySnapshot.docChanges().forEach(async change => {
          this.user = change.doc.data()
          // this.pages = await idb.getDataStore('pages')
        })
      })
    },

    signIn(email, password) {
      return signInWithEmailAndPassword(auth, email, password).then(
        async userCredential => {
          const user = userCredential.user
          await firestoreQuery(firestore, user.uid, {
            coll: 'users',
            field: 'uuid',
          }).then(async res => {
            this.userId = res[0].userId // this is redundant
            this.user = res[0]
            idb.getDatabase() // initialize indexeddb

            // Signed in
            this.isAuthenticated = true

            // check if we need to refresh page
            if (this.getUser.systemUpdate) {
              // set the systemUpdate value to false to prevent infinite reloads!
              await firestoreResetSystemUpdate('users', this.userId, false)
              console.log('reload on load')
              // location.reload() // don't reload here... it will only reload the sign-in page
            }
          })
          return userCredential // Allows flexible promise chaining
        }
      )
    },
    signInWithMicrosoft() {
      return signInWithPopup(auth, microsoftProvider).then(result => {
        // User is signed in.
        // IdP data available in result.additionalUserInfo.profile.
        const credential = OAuthProvider.credentialFromResult(result)
        const accessToken = credential.accessToken
        const idToken = credential.idToken
      })
    },
    async logOut() {
      signOut(auth)
        .then(async () => {
          await recordLog('Sign out', 'Success')
          // Sign-out successful.
          this.isAuthenticated = false
          //localStorage.removeItem('navigation')
          this.user = { displayName: '', isValidated: false }
          this.clients = []
          // localStorage.clear()
          localStorage.removeItem('data')
          localStorage.removeItem('user')
          localStorage.removeItem('pages')
          localStorage.removeItem('widgets')
          // window.location.reload()
        })
        .catch(error => {
          console.error(error)
        })
    },
    async linkProvider(provider) {
      linkWithPopup(auth.currentUser, new OAuthProvider(provider)).then(
        result => {
          // Get the OAuth access token and ID Token
          const credential = OAuthProvider.credentialFromResult(result)
          const accessToken = credential.accessToken
          const idToken = credential.idToken
        }
      )
    },
    checkUserStatus() {
      onAuthStateChanged(auth, user => {
        // this.isAuthenticated = user ? true : false
      })
    },

    async getUserData() {
      let defaults = {}

      // set clients
      // get all clients
      this.user.clients.forEach(async (cl, clIdx) => {
        await firestoreQuery(firestore, cl.clientId, {
          coll: 'clients',
          field: 'clientId',
        }).then(clRes => {
          if (!this.clients.find(x => x.client === clRes[0].clientId))
            this.clients.push({
              ...clRes[0],
              modules: [],
              isDefault: cl.isDefault,
            })

          const modulesArr = [] // temporarily store modules

          // now add modules
          cl.modules.forEach(async (md, mdIdx) => {
            await firestoreQuery(firestore, md.moduleId, {
              coll: 'modules',
              field: 'moduleId',
            }).then(mdRes => {
              // this.clients[clIdx].modules
              modulesArr.push({
                ...mdRes[0],
                roles: [],
                isDefault: md.isDefault,
              })

              const rolesArr = [] // temporarily store roles

              // get roles
              cl.modules[mdIdx].roles.forEach(async (rl, rlIdx) => {
                await firestoreQuery(firestore, rl.roleId, {
                  coll: 'roles',
                  field: 'roleId',
                }).then(rlRes => {
                  // this.clients[clIdx].modules[mdIdx].roles
                  rolesArr.push({
                    ...rlRes[0],
                    isDefault: rl.isDefault,
                  })
                })

                this.clients[clIdx].modules[mdIdx].roles = [...rolesArr] // array.push causes duplicates in the array
                // do it one more time for menu
                this.clients[clIdx].modules[mdIdx].roles.forEach(
                  async (mn, mnIdx) => {
                    // mn['menu'] = []
                    await firestoreQuery(firestore, mn.menuId, {
                      coll: 'menus',
                      field: 'menuId',
                    })
                      .then(mnRes => {
                        mn.menu = { ...mnRes[0] }
                      })
                      .then(() => {
                        this.setCurrentClient()
                        this.setCurrentModule()
                        this.setCurrentRole()
                      })
                  }
                )
              })
            })

            this.clients[clIdx].modules = [...modulesArr]
          })
        })
      })

      return defaults // may not be necessary anymore
    },
    // change stores
    async setCurrentClient(clientId = null) {
      // reset the old isDefault
      if (clientId)
        resetDefault(this.user, 'client', false, {
          clientId: this.currentClient?.clientId,
        })

      this.currentClient = {}
      // await Promise.resolve(this.clients).then(() => {
      Promise.resolve(
        (this.currentClient = clientId
          ? this.clients.find(el => el.clientId === clientId)
          : this.clients.find(x => x.isDefault))
      ).then(v => {
        // this.setCurrentModule(this.getDefaultModule.moduleId)
        v.modules.forEach(m => {
          if (m.isDefault) {
            this.setCurrentModule(m.moduleId)
          }
        })
      })

      // reset the new isDefault
      if (clientId)
        resetDefault(this.user, 'client', true, {
          clientId: this.currentClient?.clientId,
        })
      // })
    },
    async setCurrentModule(moduleId = null) {
      // reset the old isDefault
      if (moduleId)
        resetDefault(this.user, 'module', false, {
          clientId: this.currentClient?.clientId,
          moduleId: this.currentModule?.moduleId,
        })
      //
      this.currentModule = {}
      this.currentModule = moduleId
        ? this.currentClient.modules.find(x => x.moduleId === moduleId)
        : this.currentClient.modules.find(x => x.isDefault)

      // set new isDefault
      if (moduleId) {
        resetDefault(this.user, 'module', true, {
          clientId: this.currentClient.clientId,
          moduleId: moduleId, //  this.currentModule.moduleId,
        })
        // resetDefault will update this.user, but we want the local copy of this.clients and this.currentClient to change as well
        this.clients.forEach(c => {
          if (c.clientId === this.currentClient.clientId) {
            c.modules.forEach(m => {
              if (m.moduleId === moduleId) {
                m.isDefault = true
              } else m.isDefault = false
            })
          }
        })
      }

      // changeDefault(modules, id, tue)
    },
    async setCurrentRole(roleId = null) {
      // reset the old isDefault
      if (roleId) {
        // change in firestore
        resetDefault(
          this.user,
          'role',
          false,
          {
            clientId: this.currentClient?.clientId,
            moduleId: this.currentModule?.moduleId,
            roleId: this.currentRole?.roleId,
          },
          this.clients
        )
      }

      this.currentRole = roleId
        ? this.currentModule?.roles.find(x => x.roleId === roleId)
        : this.currentModule?.roles.find(x => x.isDefault)
      // reset the new isDefault
      if (roleId) {
        resetDefault(
          this.user,
          'role',
          true,
          {
            clientId: this.currentClient.clientId,
            moduleId: this.currentModule.moduleId,
            roleId: this.currentRole.roleId,
          },
          this.clients
        )
      }
    },
    fetchNotifications() {
      // this should probably be setup as a snapshot to get realtime updates
      this.notifications = []
      firestoreQuery(firestore, this.userId, {
        coll: 'notification',
        field: 'userId',
      }).then(res => {
        this.notifications = [...res]
      })
    },
    async acceptLicenseAgreement() {
      const docRef = doc(firestore, 'users', `${this.user.userId}`)
      await updateDoc(docRef, {
        isValidated: true,
      }).catch(error => {
        console.log(error)
      })
    },
    async getModuleId(name) {
      let returnVal = null
      this.clients.forEach(x => {
        x.modules.forEach(y => {
          if (y.name.replaceAll(' ', '-').toLowerCase() === name)
            returnVal = y.moduleId
        })
      })

      return returnVal
      // look through clients for the module name? and get the module ID
    },
    async getModuleName(id) {
      let returnVal = null
      this.clients.forEach(x => {
        x.modules.forEach(y => {
          if (y.moduleId === id)
            returnVal = y.name.replaceAll(' ', '-').toLowerCase()
        })
      })

      return returnVal
      // look through clients for the module name? and get the module ID
    },
    requestPasswordReset(email) {
      console.log(`requesting password reset for ${email}`)
      return sendPasswordResetEmail(auth, email)
        .then(() => {
          console.log('Email has been sent.')
        })
        .catch(error => {
          const errorCode = error.code
          const errorMessage = error.message
          console.error(`${errorCode} - ${errorMessage}`)
        })

      // return axios.post(
      //   `${process.env.VUE_APP_CLOUD_FUNCTION_BASE_PATH}/requestPasswordReset`,
      //   {
      //     email: email,
      //   }
      // )
      // return mtcfDev('requestPasswordReset', { email: email })
    },
    executePasswordReset(email, code, newPassword) {
      console.log(`executing password reset for ${email}`)
      sendPasswordResetEmail(auth, email)
        .then(() => {
          console.log('Email has been sent.')
        })
        .catch(error => {
          const errorCode = error.code
          const errorMessage = error.message
          console.error(`${errorCode} - ${errorMessage}`)
        })
      // return axios.post(
      //   `${process.env.VUE_APP_CLOUD_FUNCTION_BASE_PATH}/executePasswordReset`,
      //   {
      //     email: email,
      //     code: code,
      //     password: newPassword,
      //   }
      // )
      // return mtcfDev('executePasswordReset', {
      //   email: email,
      //   code: code,
      //   password: newPassword,
      // })
    },
  },
  persist: true,
})
