import { useDataStore } from '@/stores/data'
import idb from '@/api/idb'
import useCommonConfig from '@/composables/useCommonConfig'

let isInitialized = false
let isOfflineFlag = false
let dataStore: null | ReturnType<typeof useDataStore>

function initStores() {
  if (!isInitialized) {
    isInitialized = true
    dataStore = useDataStore()
  }
}

/**
 * Deprecated in favor of `retryRecordQueue()` within the data store
 */
/*export*/ async function retryRecordQueue() {
  initStores()
  const recordsAttempted: string[] = []
  if (await (dataStore as any)?.queuedRecordCount())
    while (await (dataStore as any)?.queuedRecordCount()) {
      try {
        const id = await (dataStore as any)?.retryRecordFromQueue()
        await idb.removeFromQueue(id)
        if (recordsAttempted.includes(id)) {
          break
        } else {
          recordsAttempted.push(id)
        }
      } catch (e: any) {
        console.log(`Retrying record from queue failed: ${e?.message ?? e}`)
        continue
      }
    }
}

/**
 * @returns True if the app is offline, false otherwise
 */
export function isOffline() {
  return isOfflineFlag
}

/**
 * Sets the app's offline status to the given boolean value
 */
export function setOfflineState(state: boolean) {
  isOfflineFlag = state
}
