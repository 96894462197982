<script setup>
import { defineProps, defineEmits, computed, ref, watch, onMounted } from 'vue'
import { useNavigationStore } from '@/stores/navigation'
const props = defineProps({
  itemConfig: Object,
  excludeList: Object,
})

const navigationStore = useNavigationStore()
const show = ref(false)

watch(
  () => navigationStore.toggleLeftDrawer,
  newVal => {
    // when the parent drawer closes, this needs to close, too so that it doesn't remain open when the drawer opens again
    if (!newVal) show.value = false
  }
)
</script>

<template>
  <v-btn
    v-bind="props"
    variant="text"
    append-icon="mdi-chevron-right"
    class="w-100 d-flex justify-space-between"
    @click.prevent="show = !show"
  >
    {{ props.itemConfig.label }}
  </v-btn>
  <v-slide-x-transition>
    <v-card
      v-if="show"
      class="text-left d-flex flex-column w-100 h-100"
      rounded="0"
      style="position: absolute; top: 0; right: 0; z-index: 2001"
    >
      <v-card-text class="pa-0">
        <v-alert
          v-if="
            props.itemConfig.type === 'checkboxList' &&
            props.excludeList.length > 0
          "
          density="compact"
          color="light-green-lighten-5"
          class="mb-2 border"
          style="border-width: 2px"
        >
          <slot name="filterExcludeList" />
        </v-alert>
        <v-row class="bg-secondary pr-2" no-gutters dense>
          <v-btn
            variant="text"
            class="pr-0 pl-1"
            style="margin-top: 2px"
            @click.prevent="show = !show"
            ><v-icon size="30" icon="mdi-arrow-left"></v-icon
          ></v-btn>
          <slot name="filterTitleContent" />
        </v-row>
        <v-divider></v-divider>
        <div class="bg-white d-flex flex-column">
          <slot name="filterMainContent" />
        </div>
      </v-card-text>
      <v-card-actions
        class="bg-primary pa-0 w-100 border-top"
        style="height: 72px"
      >
        <v-spacer></v-spacer>
        <slot name="filterFooterContent" />
        <v-btn
          color="white"
          stacked
          size="56px"
          variant="text"
          class="my-2 mr-2 rounded-0"
          @click.prevent="navigationStore.toggleLeftDrawer = false"
          ><v-icon icon="mdi-close" /><span class="mt-n1"> Close</span></v-btn
        >
      </v-card-actions>
    </v-card>
  </v-slide-x-transition>
</template>
