<script setup>
import { ref, defineProps, onMounted, onUnmounted, computed, watch } from 'vue'
import { LayoutConfig } from '@/model/LayoutConfig.model'
import { WidgetMenuConfig } from '@/model/WidgetMenuConfig.model'
import WidgetMenuCellVue from '@/components/WidgetMenuCell.vue'
import useCommonConfig from '@/composables/useCommonConfig'
const { isMobile, isTablet, navigationStore, dataStore } = useCommonConfig()

const props = defineProps({
  device: String,
  config: Object, // WidgetMenuConfig,
})
</script>

<template>
  <div class="d-flex flex-wrap">
    <WidgetMenuCellVue
      v-for="item in config[0].items"
      :key="item.title + device"
      :device="device"
      :config="item"
      :depth="0"
      :class="['py-2', { 'pr-5': !isMobile }]"
    ></WidgetMenuCellVue>
  </div>
</template>
