import imageCompression from 'browser-image-compression'
import {
  base64ImageToBlob,
  base64ImageToFile,
  fileToBase64,
  formatFirestoreDate,
  makeFirestoreId,
} from '@/services/utils'
import { RecordDateHandler } from './FormData.model'
import { Timestamp } from 'firebase/firestore'

/**
 * A lightweight class representing the data format used for storing image
 * references in firestore documents. This class also bundles together some
 * properties that should only ever be used locally and cannot be uploaded to
 * firestore. To extract the firestore value, see `getFirestoreValue()` in the
 * `FirestoreImageHandler` class.
 */
export class FirestoreImageData {
  createUserId?: string | number
  createDateTime?: Timestamp
  updateUserId?: string | number
  updateDateTime?: Timestamp
  createUserName?: string

  original?: string
  local?: string
  file?: File
  thumbnail?: string
  filename?: string
  recordstamp?: string
  fileString?: string // Only to be used in the record queue
}

/**
 * Versatile class for handling and standardizing firestore image data and
 * converting it to different formats. Holds a reference to one
 * `FirestoreImageData` object. Manipulating the image through the handler
 * rather than by directly accessing the image property is preferred when
 * possible.
 */
export class FirestoreImageHandler {
  image: FirestoreImageData

  /**
   * Creates a handler object around a `FirestoreImageData` object
   * @param fsid The FirestoreImageData this handler should handle
   * @param edits A string for whether the creation of this image represents
   * a creation, and update, or something else. This is used to update the
   * create/update user id/time properties of the image.
   */
  constructor(
    fsid: FirestoreImageData | FirestoreImageHandler,
    edits: 'create' | 'update' | 'none' = 'none'
  ) {
    if ((fsid as FirestoreImageHandler)?.image) {
      // If this is a handler object, extract and copy its image
      this.image = JSON.parse(
        JSON.stringify((fsid as FirestoreImageHandler)?.image)
      )
    } else {
      // Check for empty string or falsy values
      this.image = fsid ? (fsid as FirestoreImageData) : {}
    }
    if (edits === 'create' || edits === 'update') {
      RecordDateHandler.setUpdateUserAndTime(this.image, edits === 'create')
      RecordDateHandler.setCreateUserName(this.image)
    }
    // Only make a recordstamp if one does not already exist
    if (!this.image.recordstamp) {
      this.image.recordstamp = makeFirestoreId()
    }
  }

  /**
   * Gets the primary reference for this handler's image
   * @returns A reference that can be plugged into an image's `src` attribute.
   * This will either be a server url or a url to a local blob.
   */
  getSource(): string | null {
    return FirestoreImageHandler.getSource(this.image)
  }

  /**
   * Gets the fallback reference for this handler's image
   * @returns A reference that can be plugged into an image's `lazy-src`
   * attribute, either a base64 string thumbnail or a url to a local blob.
   */
  getFallback(): string | null {
    return FirestoreImageHandler.getFallback(this.image)
  }

  /**
   * Initializes this `FirestoreImageHandler` with the given image file.
   * Generates appropriate local blob url and thumbnail
   * @param file The image file with which to initialize this
   * `FirestoreImageHandler`'s image.
   * @returns A promise to this `FirestoreImageHandler` for constructor chaining
   */
  async initializeWithFile(file: File): Promise<FirestoreImageHandler> {
    const options = {
      maxSizeMB: 0,
      maxWidthOrHeight: 150,
      useWebWorker: true,
    }
    this.image.file = file
    this.image.local = URL.createObjectURL(file)
    return new Promise((resolve, reject) => {
      imageCompression(file, options)
        .then((res: File) => {
          console.log('res ', res)
          fileToBase64(res)
            .then(base64string => {
              if (typeof base64string === 'string') {
                console.log('base64string ', base64string)

                this.image.thumbnail = base64string
              }
              resolve(this)
            })
            .catch(reject)
        })
        .catch(reject)
    })
  }

  /**
   * Initializes this FirestoreImageHandler with a base64 string. Generates a
   * local blob url and thumbnail.
   * @param base64String A base 64 string of the relevant image data
   * @returns A promise that resolves to this `FirestoreImageHandler` for
   * constructor chaining
   */
  async initializeWithString(
    base64String: string
  ): Promise<FirestoreImageHandler> {
    this.image.file = base64ImageToFile(
      base64String,
      this.image.filename ?? makeFirestoreId()
    )
    this.image.local = URL.createObjectURL(this.image.file)
    return new Promise((resolve, reject) => {
      if (!this.image.file) {
        resolve(this)
      }
      imageCompression(this.image.file!, {
        maxSizeMB: 0,
        maxWidthOrHeight: 150,
        useWebWorker: true,
      })
        .then((res: File) => {
          console.log('res ', res)
          fileToBase64(res)
            .then(base64string => {
              if (typeof base64string === 'string') {
                console.log('base64string ', base64string)

                this.image.thumbnail = base64string
              }
              resolve(this)
            })
            .catch(reject)
        })
        .catch(reject)
    })
  }

  /**
   * Gets a blob or file from this handler's image to upload to firebase storage
   * @returns A blob or file from the `FirestoreImageData` that can be uploaded
   * to firebase storage
   */
  getBlobOrFile(): Blob | File | null {
    if (this.image.file) return this.image.file
    return null
  }

  /**
   * Generates a version of this handler's image data that can be uploaded to
   * firestore
   * @returns The subset of this handler's FirestoreImageData that can be safely
   * uploaded in a firestore document.
   */
  getFirestoreValue(): FirestoreImageData {
    const firestoreData = {} as any
    for (const key of Object.keys(this.image)) {
      if (!['file', 'local', 'filestring'].includes(key)) {
        firestoreData[key] = (this.image as any)[key]
      }
    }
    return firestoreData as FirestoreImageData
  }

  /**
   * Generates a version of this handler's image data that can be used as a
   * placeholder before uploading to firestore.
   * @returns The subset of this handler's FirestoreImageData that can be safely
   * stored in a `FirestoreUploadData` object's content.
   */
  getLocalValue(): FirestoreImageData {
    const firestoreData = {} as any
    for (const key of Object.keys(this.image)) {
      if (!['file', 'filestring'].includes(key)) {
        firestoreData[key] = (this.image as any)[key]
      }
    }
    return firestoreData as FirestoreImageData
  }

  /**
   * Generates a version of this handler's image data that can be stored to the
   * outgoing record queue. Notably, this removes the image `file` and replaces
   * it with a base64 `filestring` with all information from the original image.
   * This is done for persistence.
   * @returns A promise that resolves to a queue-friendly version of this
   * handler's image data
   */
  getQueueValue(): Promise<FirestoreImageData> {
    return new Promise((resolve, reject) => {
      const queueData = {} as any
      for (const key of Object.keys(this.image)) {
        if (!['file'].includes(key)) {
          queueData[key] = (this.image as any)[key]
        }
      }
      if (this.image.file) {
        fileToBase64(this.image.file).then(
          (base64String: string | ArrayBuffer | null) => {
            queueData.fileString = base64String as string
            resolve(queueData)
          }
        )
      } else resolve(queueData as FirestoreImageData)
    })
  }

  /**
   * Returns the recordstamp for this handler's image. If no recordstamp exists,
   * a new one is assigned and returned.
   * @returns The recordstamp for this handler's image
   */
  getRecordstamp(): string {
    if (!this.image.recordstamp) {
      this.image.recordstamp = makeFirestoreId()
    }
    return this.image.recordstamp
  }

  /**
   * Gets the primary reference for the given image. If handed a string
   * (presumably an image url), returns that string
   * @returns A reference that can be plugged into an image's `src` attribute.
   * This will either be a server url or a url to a local blob.
   */
  static getSource(image: FirestoreImageData): string | null {
    if (image?.original) return image.original
    if (image?.local) return image.local
    if (typeof image === 'string') return image
    return null
  }

  /**
   * Gets the fallback reference for this handler's image. If handed a string
   * (presumably an image url), returns that string
   * @returns A reference that can be plugged into an image's `lazy-src`
   * attribute, either a base64 string thumbnail or a url to a local blob.
   */
  static getFallback(image: FirestoreImageData | undefined): string | null {
    if (image?.thumbnail) return image.thumbnail
    if (image?.local) return image.local
    if (typeof image === 'string') return image
    return null
  }

  /**
   * Creates a signature caption from a `FirestoreImageData` object
   * @param image The `FirestoreImageData` for which to generate a signature
   * caption
   * @returns A string that can be used as a signature caption for the given
   * `FirestoreImageData`
   */
  static getSignatureCaption(image: FirestoreImageData): string | null {
    if (image?.createUserName)
      return `Signed by ${image?.createUserName} (ID ${
        image?.createUserId
      }) at ${
        formatFirestoreDate(image?.createDateTime) ?? '[Date not available]'
      }`
    return null
  }
}

/**
 * A class to help group together image dimensions and help with their scaling
 */
export class ImageSize {
  width: number
  height: number

  constructor(size: { width: number; height: number }) {
    this.width = size.width
    this.height = size.height
  }

  /**
   * Takes a maximum bounding box of width and height and produces the largest
   * that this image can be scaled to within those parameters. If the original
   * image is larger in at least one dimension, the image will be scaled down to
   * the maximum.
   * @param maxDimensions The maximum allowed width and height
   * @returns An ImageSize object describing the largest the given image can be
   * scaled to within the provided maximum dimensions
   */
  getScaledSize(maxDimensions: { width: number; height: number }): ImageSize {
    return ImageSize.getScaledSize(this, maxDimensions)
  }

  /**
   * Takes the dimensions of an image and the maximum bounding box of width and
   * height and produces the largest that that image can be scaled to within
   * those parameters. If the original image is larger in at least one
   * dimension, the image will be scaled down to
   * the maximum.
   * @param imageAspectRatio an ImageSize object describing the width and height
   * of the target image
   * @param maxDimensions The maximum allowed width and height
   * @returns An ImageSize object describing the largest the given image can be
   * scaled to within the provided maximum dimensions
   */
  static getScaledSize(
    imageAspectRatio: ImageSize,
    maxDimensions: { width: number; height: number }
  ): ImageSize {
    /* Commented code for images less than either maximum */
    // if (
    //   imageAspectRatio.height < maxDimensions.height &&
    //   imageAspectRatio.width < maxDimensions.width
    // ) {
    //   return imageAspectRatio
    // }

    // Get the size as a proportion of the maximum in each dimension
    // The higher one is the limiting factor and should be set to the maximum
    const heightScale = imageAspectRatio.height / maxDimensions.height
    const widthScale = imageAspectRatio.width / maxDimensions.width

    if (heightScale > widthScale) {
      // Set height to max height, use height to scale down
      return new ImageSize({
        height: maxDimensions.height,
        width: imageAspectRatio.width / heightScale,
      })
    } else {
      // Set width to max width, use width to scale down
      return new ImageSize({
        height: imageAspectRatio.height / widthScale,
        width: maxDimensions.width,
      })
    }
  }

  getScaledSizeObject(maxDimensions: {
    width: number
    height: number
  }): ScaledImageSize {
    return new ScaledImageSize(this as ImageSize, maxDimensions)
  }

  /**
   * Wrapper function that takes an expression and a fallback value. Returns the
   * fallback value if the expression evaluates to zero, null, or undefined.
   * Otherwise, returns the evaluation of the expression.
   * @param val The expression that should be returned if non-zero
   * @param fallback The value that should be returned if the expression `val`
   * evaluates to zero
   * @returns The given expression, or the fallback value if the expression
   * evaluates to a falsy value
   */
  static nonZero(val: number | null | undefined, fallback: number) {
    return val ? val : fallback
  }
}

/**
 * A class representing a scaled image, including the original dimensions, the
 * scaled dimensions, and the scale factor used.
 */
export class ScaledImageSize {
  originalSize: ImageSize
  scaledSize: ImageSize
  scale: number
  constructor(
    originalSize: ImageSize,
    maxDimensions: { width: number; height: number }
  ) {
    this.originalSize = originalSize
    this.scaledSize = originalSize.getScaledSize(maxDimensions)
    this.scale = this.scaledSize.height / this.originalSize.height
  }
}
