<script setup>
import { ref, defineProps, computed, watch } from 'vue'
import { isPhone } from '@/services/deviceDetection'
import useWidgetConfig from '../composables/useWidgetConfig'
import useCommonConfig from '@/composables/useCommonConfig'
const { isMobile, isNotMobile, display, dataStore } = useCommonConfig()

const props = defineProps({ data: Object })
const toggleMobileSearch = ref(false)
const isActivated = ref(false)
const isDirty = ref(false)
const searchValue = ref()

const prependIcon = computed(() => {
  if (isDirty.value) return 'fa:fal fa-circle-xmark'

  return 'fa:fal fa-search'
})
// watch
watch(
  () => searchValue.value,
  newVal => {
    if (newVal === null || newVal === '') isDirty.value = false
    else isDirty.value = true

    dataStore.searchString = newVal
  }
)

// methods

const clickHandler = () => {
  if (isPhone() || display.smAndDown.value) {
    isActivated.value = true
    toggleMobileSearch.value = !toggleMobileSearch.value
  }
}

const clear = () => {
  searchValue.value = null
}
</script>

<template>
  <div v-if="isActivated">
    <Teleport to="#pop-over" v-if="isMobile">
      <div
        v-if="toggleMobileSearch"
        class="pa-2 bg-primary d-flex w-100"
        style="position: fixed; bottom: 72px; z-index: 10"
      >
        <v-text-field
          :prepend-inner-icon="prependIcon"
          v-model="searchValue"
          variant="outlined"
          density="comfortable"
          hide-details
          bg-color="white"
          @click:prependInner="isDirty ? clear() : null"
        />
        <v-btn
          color="white"
          icon="mdi-close"
          class="ml-1 pa-0 rounded-0 bg-white-semi-transparent"
          elevation="0"
          height="48"
          width="48"
          size="x-small"
          @click.prevent="clear(), (toggleMobileSearch = false)"
        >
        </v-btn>
      </div>
    </Teleport>
  </div>
  <v-btn
    v-if="isMobile"
    stacked
    class="pa-0 rounded-0"
    @click.stop.prevent="clickHandler"
  >
    <v-icon icon="mdi-magnify" />
    Search
  </v-btn>
  <form v-else id="desktop-search" class="ml-auto" style="width: 250px">
    <v-text-field
      :prepend-inner-icon="prependIcon"
      v-model="searchValue"
      variant="outlined"
      density="comfortable"
      hide-details
      bg-color="white"
      class=""
      @click:prependInner="isDirty ? clear() : null"
    />
  </form>
</template>
