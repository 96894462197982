<template>
  <v-container fluid class="px-5">
    <v-card class="mx-auto text-left">
      <template v-slot:title>
        <v-icon icon="mdi-file-document" size="x-small" /> Terms of Use
      </template>
      <template v-slot:subtitle>
        <em>The Terms of Use were last modified on October 4th, 2018.</em>
      </template>
      <template v-slot:text>
        <h3 class="my-3">Introduction</h3>

        <p>
          This Terms of Use agreement (“<b>Agreement</b>”) governs your access
          to and use of the services, websites, and applications (collectively
          the “<b>Service</b>”) offered by MaxTrax LLC, Inc. (“<b>MaxTrax LLC</b
          >,” “<b>we</b>,” or “<b>us</b>”). Your access to and use of the
          Service is conditioned on your acceptance of and compliance with this
          Agreement. By accessing or using the Service, including clicking to
          agree where these options are presented, you agree to be bound by this
          Agreement. If you are accepting on behalf of your employer or another
          entity, you represent and warrant that: (i) you have full legal
          authority to bind your employer or such other entity to the Agreement;
          (ii) you have read and understand the Agreement, and (iii) you accept
          the terms and conditions of this Agreement on behalf of the entity
          that you represent. If you do not have the legal authority to bind the
          entity on whose behalf you are acting, do not click the "I Accept"
          button or use the Service. Any use or access to the Service by anyone
          under 13 is strictly prohibited and in violation of this Agreement.
        </p>
        <h3 class="my-3">Use of the Service</h3>
        <p>
          Subject to the terms and conditions of this Agreement, you are hereby
          granted a non-exclusive, limited, non-transferable, freely revocable
          license to use the Service. MaxTrax LLC reserves all rights not
          expressly granted herein in the Service and the Content (as defined
          below). MaxTrax LLC may terminate this license at any time for any
          reason or no reason.
        </p>
        <p>
          All right, title, and interest in and to the Service (excluding
          Content provided by you) are and will remain the exclusive property of
          MaxTrax LLC and its licensors. The Service is protected by copyright,
          trademark, and other laws of the United States and foreign countries.
          Nothing in this Agreement gives you a right to use MaxTrax LLC name or
          any of MaxTrax LLC trademarks, logos, domain names, and other
          distinctive brand features. Any feedback, comments, or suggestions you
          may provide regarding MaxTrax LLC or the Service are entirely
          voluntary and we will be free to use such feedback, comments or
          suggestions as we see fit and without any obligation to you.
        </p>
        <p>
          You are responsible for safeguarding the password that you use to
          access the Service and for any activities or actions under your
          password. We encourage you to use “strong” passwords (passwords that
          use a combination of upper and lower case letters, numbers and
          symbols) with your account. MaxTrax LLC will not be liable for any
          loss or damage arising from your failure to comply with these
          requirements. By connecting to MaxTrax LLC with a third-party service,
          you give us permission to access and use your information from that
          service as permitted by that service, and to store your log-in
          credentials and other data received from that service. By providing
          MaxTrax LLC your email address you consent to our using the email
          address to send you Service-related notices, including any notices
          required by law, in lieu of communication by postal mail.
        </p>
        <p>
          We may, without prior notice, change the Service; stop providing the
          Service or features of the Service, to you, or to users generally; or
          we may create usage limits for the Service.
        </p>
        <p>
          You must not do any of the following while accessing or using the
          Service: (i) use the Service for any unlawful purposes or for
          promotion of illegal activities; (ii) post any Content (as defined
          below) or use the Service in violation of any applicable law
          (including intellectual property laws, right of privacy or publicity
          laws, and any laws of a non-U.S. jurisdiction applicable to you), or
          any contractual or other legal obligation; (iii) post Content that is
          hateful, abusive, threatening, profane, or otherwise objectionable;
          (iv) post Content or use the Service to create an impression that you
          know is incorrect, misleading, or deceptive, including by
          impersonating others or otherwise misrepresenting your affiliation
          with a person or entity; (v) publish or post other people's private or
          personally identifiable information without their express
          authorization and permission; (vi) use the Service for the purpose of
          spamming anyone; (vii) publish or link to malicious content intended
          to damage or disrupt another user's browser or computer or to
          compromise a your privacy; (viii) access or tamper with non-public
          areas of the Service, MaxTrax LLC's computer systems, or the technical
          delivery systems of MaxTrax LLC's providers; (ix) probe, scan, or test
          the vulnerability of any system or network or breach or circumvent any
          security or authentication measures; (x) access or search or attempt
          to access or search the Service by any means (automated or otherwise)
          other than through the currently available, published interfaces that
          are provided by MaxTrax LLC (and only pursuant to those terms and
          conditions), unless you have been specifically allowed to do so in a
          separate agreement with MaxTrax LLC (crawling the Service is
          permissible in accordance with these Terms, but scraping the Service
          without the prior consent of MaxTrax LLC except as permitted by these
          Terms is expressly prohibited); (xi) forge any TCP/IP packet header or
          any part of the header information in any email or posting, or in any
          way use the Service to send altered, deceptive or false
          source-identifying information; or (xii) interfere with or disrupt (or
          attempt to do so) the access of any you, host or network, including,
          without limitation, sending a virus, overloading, flooding, spamming,
          mail-bombing the Service, or by scripting the creation of Content in
          such a manner as to interfere with or create an undue burden on the
          Service.
        </p>
        <p>
          MaxTrax LLC may investigate and/or suspend your account if you violate
          any of the above policies. MaxTrax LLC reserves the right to
          immediately terminate your account without further notice in the event
          that, in its judgment, you violate this Agreement.
        </p>
        <h3 class="my-3">Your Content</h3>
        <p>
          “<b>Content</b>” means any information, text, graphics, or other
          materials uploaded, downloaded or appearing on the Service. You retain
          ownership of all Content you submit, post, display, or otherwise make
          available on the Service.
        </p>
        <p>
          By submitting, posting or displaying Content on or through the
          Service, you grant, and you represent and warrant that you have a
          right to grant, to MaxTrax LLC a worldwide, non-exclusive, perpetual,
          royalty-free license (with the right to sublicense) to use, reproduce,
          adapt, modify, publish, transmit, perform, display, distribute, and
          make derivative works of such Content in any and all media or
          distribution methods (now known or later developed). You agree that
          this license includes the right for MaxTrax LLC to make your Content
          available to others for the publication, distribution, syndication, or
          broadcast of such Content on other media and services.
        </p>
        <p>
          Your Content will be able to be viewed by other you of the Service and
          through third party services and websites. You should only provide
          Content that you are comfortable sharing with others under the terms
          of this Agreement. All Content, whether publicly posted or privately
          transmitted, is the sole responsibility of the person who originated
          such Content. We may not monitor or control the Content posted via the
          Service. We do not endorse, support, represent or guarantee the
          completeness, truthfulness, accuracy, or reliability of any Content or
          communications posted via the Service nor does MaxTrax LLC endorse any
          opinions expressed via the Service. Any use or reliance on any Content
          or materials posted via the Service or obtained by you through the
          Service is at your own risk. You understand that by using the Service,
          you may be exposed to Content that might be offensive, harmful,
          inaccurate or otherwise inappropriate, or postings that have been
          mislabeled or are otherwise deceptive. Under no circumstances will
          MaxTrax LLC be liable in any way for any Content, including, but not
          limited to, any errors or omissions in any Content, or any loss or
          damage of any kind incurred as a result of the use of any Content
          posted, emailed, transmitted or otherwise made available via the
          Service or broadcast elsewhere.
        </p>
        <p>
          You are responsible for your use of the Service, for any Content you
          provide, and for any consequences thereof, including the use of your
          Content by other users and third party partners. You understand that
          your Content will be published on the Service and may be republished,
          and if you do not have the right to submit Content for such use, it
          may subject you to liability. MaxTrax LLC will not be responsible or
          liable for any use of your Content by MaxTrax LLC in accordance with
          this Agreement. You represent and warrant that you have all the
          rights, power and authority necessary to grant the rights granted
          herein to any Content that you submit and that MaxTrax LLC’s use of
          your Content in accordance with this Agreement will not violate any
          law or infringe the rights of any third party.
        </p>
        <p>
          The Service may include advertisements, which may be targeted to the
          Content or information on the Service, or other information. In
          consideration for MaxTrax LLC granting you access to and use of the
          Service, you agree that MaxTrax LLC and its third party providers and
          partners may place such advertising on the Service or in connection
          with the display of Content or information from the Service whether
          submitted by you or others.
        </p>
        <p>
          We reserve the right at all times (but will not have an obligation) to
          remove or refuse to distribute any Content on the Service and to
          terminate your access. We also reserve the right to access, read,
          preserve, and disclose any information as we reasonably believe is
          necessary to (i) satisfy any applicable law, regulation, legal process
          or governmental request, (ii) enforce this Agreement, including
          investigation of potential violations hereof, (iii) detect, prevent,
          or otherwise address fraud, security or technical issues, (iv) respond
          to you support requests, or (v) protect the rights, property or safety
          of MaxTrax LLC, its users and the public.
        </p>
        <h3 class="my-3">Copyright Policy</h3>
        <p>
          MaxTrax LLC respects the intellectual property rights of others and
          expects users of the Service to do the same. We will respond to
          notices of alleged copyright infringement that comply with applicable
          law and are properly provided to us. If you believe that your Content
          has been copied in a way that constitutes copyright infringement,
          please provide our copyright agent with the following information in
          accordance with the Digital Millennium Copyright Act: (i) a physical
          or electronic signature of the copyright owner or a person authorized
          to act on their behalf; (ii) identification of the copyrighted work
          claimed to have been infringed; (iii) identification of the material
          that is claimed to be infringing or to be the subject of infringing
          activity and that is to be removed or access to which is to be
          disabled, and information reasonably sufficient to permit us to locate
          the material; (iv) your contact information, including your address,
          telephone number, and an email address; (v) a statement by you that
          you have a good faith belief that use of the material in the manner
          complained of is not authorized by the copyright owner, its agent, or
          the law; and (vi) a statement that the information in the notification
          is accurate, and, under penalty of perjury, that you are authorized to
          act on behalf of the copyright owner.
        </p>

        Our designated copyright agent for notice of alleged copyright
        infringement appearing on the Service is:
        <pre>
MaxTrax LLC, Inc. 
Attn: Copyright Agent
1257 Worcester Road, 144
Framingham, MA 01701
Email: support@netconsultinggroup.com</pre
        >
        <p>
          We reserve the right to remove Content alleged to be infringing
          without prior notice and at our sole discretion. MaxTrax LLC will
          terminate your account if you are determined to be a repeat infringer.
        </p>
        <h3 class="my-3">Privacy</h3>
        <p>
          MaxTrax LLC values your privacy. Please review our Privacy Policy to
          learn more about how we collect and use information about you via the
          Service. By using the Service you consent to the transfer of your
          information to the United States and/or other countries for storage,
          processing and use by MaxTrax LLC.
        </p>
        <h3 class="my-3">Third-Party Links</h3>
        <p>
          The Service may contain links to third-party websites or resources.
          You acknowledge and agree that we are not responsible or liable for:
          (i) the availability or accuracy of such websites or resources; or
          (ii) the content, products, or services on or available from such
          websites or resources. Links to such websites or resources do not
          imply any endorsement by MaxTrax LLC of such websites or resources or
          the content, products, or services available from such websites or
          resources. You acknowledge sole responsibility for and assume all risk
          arising from your use of any such websites or resources.
        </p>
        <h3 class="my-3">Disclaimers; Indemnification</h3>
        <p>
          Your access to and use of the Service, third-party Products or any
          Content is at your own risk. You understand and agree that the Service
          is provided to you on an “AS IS” and “AS AVAILABLE” basis. Without
          limiting the foregoing, COMPANY AND ITS PARTNERS DISCLAIM ANY
          WARRANTIES, EXPRESS OR IMPLIED, OF MERCHANTABILITY, FITNESS FOR A
          PARTICULAR PURPOSE, OR NON-INFRINGEMENT. MaxTrax LLC makes no warranty
          and disclaims all responsibility and liability for the completeness,
          accuracy, availability, timeliness, security or reliability of the
          Service or any content thereon. MaxTrax LLC will not be responsible or
          liable for any harm to your computer system, loss of data, or other
          harm that results from your access to or use of the Service,
          third-party Products and any Content. You also agree that MaxTrax LLC
          has no responsibility or liability for the deletion of, or the failure
          to store or to transmit, any Content and other communications
          maintained by the Service. MaxTrax LLC makes no warranty that the
          Service will meet your requirements or be available on an
          uninterrupted, secure, or error-free basis. No advice or information,
          whether oral or written, obtained from MaxTrax LLC or through the
          Service, will create any warranty not expressly made herein.
        </p>
        <p>
          TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, COMPANY AND ITS
          AFFILIATES, OFFICERS, EMPLOYEES, AGENTS, PARTNERS AND LICENSORS WILL
          NOT BE LIABLE FOR ANY DIRECT, INDIRECT, INCIDENTAL, SPECIAL,
          CONSEQUENTIAL OR PUNITIVE DAMAGES, INCLUDING WITHOUT LIMITATION, LOSS
          OF PROFITS, DATA, USE, GOODWILL, OR OTHER INTANGIBLE LOSSES, RESULTING
          FROM (i) YOUR ACCESS TO OR USE OF OR INABILITY TO ACCESS OR USE THE
          SERVICE, THIRD-PARTY PRODUCTS OR CONTENT; (ii) ANY CONDUCT OR CONTENT
          OF ANY THIRD PARTY ON THE SERVICE, INCLUDING WITHOUT LIMITATION, ANY
          DEFAMATORY, OFFENSIVE OR ILLEGAL CONDUCT OF OTHER USERS OR THIRD
          PARTIES; (iii) ANY CONTENT OBTAINED FROM THE SERVICE; AND (iv)
          UNAUTHORIZED ACCESS, USE OR ALTERATION OF YOUR TRANSMISSIONS OR
          CONTENT, WHETHER BASED ON WARRANTY, CONTRACT, TORT (INCLUDING
          NEGLIGENCE) OR ANY OTHER LEGAL THEORY, WHETHER OR NOT COMPANY HAS BEEN
          INFORMED OF THE POSSIBILITY OF SUCH DAMAGE, AND EVEN IF A REMEDY SET
          FORTH HEREIN IS FOUND TO HAVE FAILED OF ITS ESSENTIAL PURPOSE.
        </p>
        <p>
          Some jurisdictions do not allow the exclusion of certain warranties or
          the exclusion or limitation of liability for consequential or
          incidental damages, so the limitations above may not apply to you.
        </p>
        <p>
          You agree to defend, indemnify and hold harmless MaxTrax LLC and its
          licensees and licensors, and their employees, contractors, agents,
          officers and directors, from and against any and all claims, damages,
          obligations, losses, liabilities, costs or debt, and expenses
          (including but not limited to attorney's fees) arising from: (i) your
          use of and access to the Service, including any data or Content
          transmitted or received by you; (ii) your violation of any provision
          of this Agreement; or (iii) any other party’s access or use of the
          Service with your username and password or other appropriate security
          code.
        </p>
        <h3 class="my-3">General Terms</h3>
        <p>
          These Terms and any action related thereto will be governed by the
          laws of the State of Massachusetts without regard to or application of
          its conflict of law provisions or your state or country of residence.
          Unless submitted to arbitration as set forth in the following
          paragraph, all claims, legal proceedings or litigation arising in
          connection with the Service will be brought solely in Middlesex
          County, Massachusetts, and you consent to the jurisdiction of and
          venue in such courts and waive any objection as to inconvenient forum.
        </p>
        <p>
          For any claim (excluding claims for injunctive or other equitable
          relief) under this Agreement where the total amount of the award
          sought is less than $10,000, the party requesting relief may elect to
          resolve the dispute through binding non-appearance-based arbitration.
          The party electing such arbitration shall initiate the arbitration
          through an established alternative dispute resolution ("ADR") provider
          mutually agreed upon by the parties. The ADR provider and the parties
          must comply with the following rules: a) the arbitration shall be
          conducted by telephone, online and/or be solely based on written
          submissions, as selected by the party initiating the arbitration; b)
          the arbitration shall not involve any personal appearance by the
          parties or witnesses unless otherwise mutually agreed by the parties;
          and c) any judgment on the award rendered by the arbitrator may be
          entered in any court of competent jurisdiction.
        </p>
        <p>
          This Agreement is the entire and exclusive agreement between MaxTrax
          LLC and you regarding the Service (excluding any services for which
          you have a separate agreement with MaxTrax LLC that is explicitly in
          addition or in place of this Agreement), and this Agreement supersedes
          and replaces any prior agreements between MaxTrax LLC and you
          regarding the Service. The failure of MaxTrax LLC to enforce any right
          or provision of this Agreement will not be deemed a waiver of such
          right or provision. In the event that any provision of this Agreement
          is held to be invalid or unenforceable, the remaining provisions of
          this Agreement will remain in full force and effect.
        </p>
        <p>
          MaxTrax LLC may provide notices to you via email, written or hard copy
          notice, or through posting on the Service, as determined by MaxTrax
          LLC in our sole discretion. MaxTrax LLC reserves the right to
          determine the form and means of providing notice to you, provided that
          you may opt out of certain means of notice as described in this
          Agreement.
        </p>
        <p>
          We may revise this Agreement from time to time. The most current
          version will always be on this page. If in our sole discretion the
          revision is material, we will notify you as provided in this Agreement
          at least seven days before the revisions become effective. By
          continuing to access or use the Service after those revisions become
          effective, you agree to be bound by the revised Agreement.
        </p>
        <p class="small fs-i">
          The Service is operated and provided by MaxTrax LLC, Inc., 1257
          Worcester Road, 144, Framingham, MA 01701, United States. If you have
          questions about this Agreement, please contact us at
          <a href="mailto:support@netconsultinggroup.com"
            >support@netconsultinggroup.com</a
          >.
        </p>
      </template>
    </v-card>
  </v-container>
</template>
