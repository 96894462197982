<script setup>
import { onMounted, computed, watch } from 'vue'
import { useDisplay } from 'vuetify'
import { useRouter } from 'vue-router'
import { storeToRefs } from 'pinia'
import useCommonConfig from '@/composables/useCommonConfig'
// components
import DynamicPageLayout from '@/layouts/DynamicPageLayout.vue'
import ToolDispatcher from '@/components/ToolDispatcher.vue'
import WidgetDispatcher from '@/components/WidgetDispatcher.vue'

// vars

const {
  isPhone,
  isMobile,
  isTablet,
  userStore,
  pagesStore,
  navigationStore,
  settingsStore,
  widgetsStore,
  dataStore,
  router,
  isOffline,
  display,
  integrationsStore,
  selectedLayout,
} = useCommonConfig()

const { hasRightDrawer, hasModal } = storeToRefs(navigationStore)

// computed
const pageID = computed(() => {
  if (router.currentRoute.value.params.slug?.length > 0) {
    return (
      navigationStore.getPageId(router.currentRoute.value.params.slug) ||
      navigationStore.getMainMenuItems[0]?.pageId
    )
  }

  // return the first pageId in the menu
  return navigationStore.getMainMenuItems[0]?.pageId
})

/** Page Validation */
/*
watch(
  () => router.currentRoute.value,
  () => {
    // if (router.currentRoute.value.name === 'dynamic') console.log('1')
    if (!router.currentRoute.value.params.module) {

      // if no module is selected, load the first/default // redundant?
      router.push({
        name: 'dynamic',
        params: {
          client: router.currentRoute.value.params.client,
          module: userStore.getDefaultModule.name,
        },
      })
    }
  }
)*/

// methods
const isValid = (src, val) =>
  src.some(x => x.name.toLowerCase().replaceAll(' ', '-') === val)

const loadPage = async () => {
  // check if client is valid
  if (!isValid(userStore.getClients, router.currentRoute.value.params.client)) {
    router.push({
      name: 'NotFound',
    })
  } else if (!router.currentRoute.value.params.module) {
    // if no module is selected, load the first/default
    new Promise(resolve => resolve(userStore.getDefaultModule)).then(v => {
      router.push({
        name: 'dynamic',
        params: {
          client: router.currentRoute.value.params.client,
          module: v.name, // userStore.getDefaultModule.name,
        },
      })
    })
  } else if (
    !isValid(userStore.getModules, router.currentRoute.value.params.module)
  ) {
    // validate legitimate module, etc.
    router.push({
      name: 'NotFound',
    })
  }

  // if no module in the url, add it
  if (!router.currentRoute.value.params.module) {
    router.push({
      name: 'dynamic',
      params: {
        client: userStore.getCurrentClientName,
        module: userStore.getCurrentModuleName,
      },
    })
  }

  if (
    router.currentRoute.value.params.module !== userStore.getCurrentModuleName
  ) {
    // for manually entered modules (make the same thing for clients)
    userStore.setCurrentModule(
      await userStore.getModuleId(router.currentRoute.value.params.module)
    )

    // userStore.setCurrentRole()
  }
  window.scrollTo(0, 0)
  //
  // setCurrentModule(userStore.getModuleId(router.currentRoute.value.params.module))
  // todo: uncomment?
  // validate the slug
  /* if (!pageID.value) {
    console.log('5')
    router.push({
      name: 'dynamic',
      params: {
        client: userStore.getCurrentClientName,
        module: userStore.getCurrentModuleName,
      },
    })
  }*/
}

watch(
  () => router.currentRoute.value,
  () => {
    loadPage()
  }
)

watch(
  () => navigationStore.getMainMenuItems[0],
  () => {
    pagesStore.getCachedPage(pageID.value)
  }
)

/** Loading dynamic data */
/// probably need to break this out into vuetify config

const getBreakPoints = obj => {
  // this may not be necessary
  const returnArr = []
  obj.forEach(x => returnArr.push(x.devices))
  return returnArr
}

onMounted(() => {
  // for version updating
  if (navigationStore.isSigningIn) window.location.reload()

  loadPage()
  // navigationStore.getPageId(router.currentRoute.value.params.slug) ||

  // set to getCachedPage()
  if (pageID.value) Promise.resolve(pagesStore.getCachedPage(pageID.value))
  // also needs to be in a watch
  pagesStore.subscribePages()
  widgetsStore.subscribeWidgets()
  userStore.subscribeUser()
  integrationsStore.subscribeIntegrations()
  dataStore.fetchCollectionData([
    { source: 'lists', name: 'lists', recordsets: ['lists'] },
  ])
  setTimeout(() => {
    dataStore.retryRecordQueue()
  }, 2 * 1000)
  // setTimeout(retryRecordQueue(), 2 * 1000)
})

watch(
  () => router.currentRoute.value,
  () => {
    // set to getCachedPage()
    Promise.resolve(pagesStore.getCachedPage(pageID.value)).then(() => {
      // promise needed?
    })
  }
)

watch(
  () => pagesStore.currentPage,
  newVal => {
    dataStore.fetchCollectionData(newVal?.properties.dataSources)
  }
)

// method
const getAreaContents = (src, area, bp) => {
  let returnArr = []
  if (src.layouts) {
    // this first part extracts the rows
    src.layouts.forEach(l => {
      if (l.devices.find(d => d === bp)) {
        l.areas.forEach(a => {
          // TODO: fix the a.container inconsistency in firestore
          if (a.type === area || a.container === area) returnArr = [...a.rows]
          // see if there's a modal to trigger
          if (a.container === 'modal') {
            hasModal.value = true
          }
        })
      }
    })
    // side-effect: here, we'll tell the interface if there has a rightDrawer to trigger
    if (area.toLowerCase() === 'rightdrawer') {
      hasRightDrawer.value = returnArr.length > 0
      hasModal.value = !hasRightDrawer.value // if it turns out there's a rightdrawer, we need to toggle this
    }

    // now we need to extract the nested widget IDs so we can run them in a query
    returnArr.forEach(row => {
      row.cols.forEach(col => {
        col.widgets.forEach(wgt => {
          widgetsStore.getWidgetById(wgt.widgetId)
        })
      })
    })
    //=== bp}) .devices.find(d => d === bp)
  }

  return returnArr
}

const getFetchedWidget = widgetId => {
  return [
    widgetsStore.getFetchedWidgets.find(w => w.widgetId === widgetId)
      ?.properties,
  ]
}

// probably won't be necessary
const colsConverter = percent =>
  Math.round((parseFloat(percent.slice(0, -1)) / 100) * 12)

// navigationStore.setMenu(navigationStore.module.menu) //
</script>

<template>
  <dynamic-page-layout>
    <!-- left drawer -->
    <template v-if="pagesStore.currentPage?.properties" #leftDrawer>
      <v-row
        no-gutters
        v-for="(row, rIdx) in getAreaContents(
          pagesStore.currentPage.properties,
          'leftDrawer',
          selectedLayout
        )"
        :key="rIdx"
      >
        <div
          v-for="(col, cIdx) in row.cols"
          :class="[
            'mr-5',
            'mb-5',
            { 'w-100': isPhone() || display.smAndDown.value },
          ]"
          :style="`width:${col.width};`"
          :key="cIdx"
        >
          <!-- WIDGETS -->
          <template v-for="(colWidget, cwIdx) in col.widgets" :key="cwIdx">
            <template
              v-for="(widget, wIdx) in getFetchedWidget(colWidget.widgetId)"
              :key="wIdx"
            >
              <widget-dispatcher
                :type="widget?.type"
                :device="selectedLayout"
                :data="
                  widget?.layouts.filter(l =>
                    l.devices.includes(selectedLayout)
                  )
                "
                :properties="widget"
              />
            </template>
          </template>
        </div>
      </v-row>
    </template>
    <!--<template #aside>
      <div>Aside</div>
    </template>
-->
    <template
      v-if="pagesStore.currentPage?.properties?.tools?.length > 0"
      #toolbar
    >
      <tool-dispatcher
        v-for="(tool, tIdx) in pagesStore.currentPage.properties.tools"
        :tool="tool.name"
        :key="tIdx"
      />
    </template>
    <!-- body -->
    <template v-if="pagesStore.currentPage?.properties" #body>
      <v-row
        no-gutters
        v-for="(row, rIdx) in getAreaContents(
          pagesStore.currentPage.properties,
          'body',
          selectedLayout
        )"
        class="pb-0 pb-md-4"
        :key="rIdx"
      >
        <div
          v-for="(col, cIdx) in row.cols"
          :class="[{ 'pr-md-4': cIdx === 0 && cIdx !== row.cols.length - 1 }]"
          :style="`width:${col.width};`"
          :key="cIdx"
        >
          <!-- WIDGETS -->
          <template v-for="(colWidget, cwIdx) in col.widgets" :key="cwIdx">
            <template
              v-for="(widget, wIdx) in getFetchedWidget(colWidget.widgetId)"
              :key="wIdx"
            >
              <widget-dispatcher
                :type="widget?.type"
                :device="selectedLayout"
                :data="
                  widget?.layouts.filter(l =>
                    l.devices.includes(selectedLayout)
                  )
                "
                :properties="widget"
              />
            </template>
          </template>
        </div>
      </v-row>
    </template>
    <!-- find a better condition -->

    <template v-if="pagesStore.currentPage?.properties" #rightDrawer>
      <v-row
        no-gutters
        v-for="(row, rIdx) in getAreaContents(
          pagesStore.currentPage.properties,
          'rightDrawer',
          selectedLayout
        )"
        class="bg-white"
        :key="rIdx"
      >
        <div
          v-for="(col, cIdx) in row.cols"
          :style="`width:${col.width};`"
          :key="cIdx"
        >
          <!-- WIDGETS -->
          <template v-for="(colWidget, cwIdx) in col.widgets" :key="cwIdx">
            <template
              v-for="(widget, wIdx) in getFetchedWidget(colWidget.widgetId)"
              :key="wIdx"
            >
              <widget-dispatcher
                :type="widget?.type"
                :device="selectedLayout"
                :data="
                  widget?.layouts.filter(l =>
                    l.devices.includes(selectedLayout)
                  )
                "
                :properties="widget"
              />
            </template>
          </template>
        </div>
      </v-row>
    </template>

    <template #modal>
      <v-row
        no-gutters
        v-for="(row, rIdx) in getAreaContents(
          pagesStore.currentPage.properties,
          'modal',
          selectedLayout
        )"
        :key="rIdx"
      >
        <div
          v-for="(col, cIdx) in row.cols"
          class="mb-5"
          :style="`width:${col.width};`"
          :key="cIdx"
        >
          <!-- WIDGETS -->
          <template v-for="(colWidget, cwIdx) in col.widgets" :key="cwIdx">
            <template
              v-for="(widget, wIdx) in getFetchedWidget(colWidget.widgetId)"
              :key="wIdx"
            >
              <widget-dispatcher
                :type="widget?.type"
                :device="selectedLayout"
                :data="
                  widget?.layouts.filter(l =>
                    l.devices.includes(selectedLayout)
                  )
                "
                :properties="widget"
              />
            </template>
          </template>
        </div>
      </v-row>
    </template>
  </dynamic-page-layout>
</template>
