<script setup>
/// TODO: consolidate this, privacy policy, and terms of use into one static page and load the content via a component
import { onMounted } from 'vue'
import { useRouter } from 'vue-router'
import { useUserStore } from '@/stores/user'
import { useNavigationStore } from '@/stores/navigation.js'
import { recordLog } from '@/services/logging'

const userStore = useUserStore()
const navigationStore = useNavigationStore()
const router = useRouter()

onMounted(() => {
  if (userStore.user.isValidated)
    router.push({
      name: 'dynamic',
      params: {
        client: userStore.getCurrentClientName,
      },
    })
  console.log('userStore ', userStore.user)
})

const agreeHandler = () => {
  recordLog('License Agreement', 'Agree')
  userStore.user.isValidated = true
  userStore.acceptLicenseAgreement().then(() => {
    router.push({
      path: navigationStore.redirectPath,
    })
  })
}
</script>

<template>
  <v-container fluid>
    <v-card
      :width="$vuetify.display.mdAndUp ? 800 : '100%'"
      class="mx-auto text-left"
    >
      <v-card-text>
        <div
          class="pa-5"
          style="
            height: 300px;
            overflow-y: scroll;
            border: 1px solid lightgray;
            margin-bottom: 10px;
          "
        >
          <div class="text-center font-weight-bold">
            MAX TRAX INC.<br />
            END USER LICENSE AGREEMENT<br />
            Version 20, March 2020<br />
            <br />
            <p class="text-primary">
              DO NOT USE THIS SOFTWARE UNLESS YOU HAVE<br />
              READ, UNDERSTOOD, AND AGREE TO THESE TERMS<br />
              <br />
            </p>
          </div>

          <div class="text-caption lh-13">
            <p>
              Max Trax Inc. (“<span class="font-weight-bold">MTI”</span>) is a
              Massachusetts corporation is licensing a copy of one or more
              MaxTrax cellphone or desktop applications (the “<span
                class="font-weight-bold"
                >Apps”</span
              >) on the terms set forth herein. The Apps are a component of a
              larger system (the “<span class="font-weight-bold">System”</span>)
              in which the Apps communicates with a cloud-based database (the
              “<span class="font-weight-bold">MTI database”</span>) that in turn
              communicates with a database maintained by or for your employer
              (the “<span class="font-weight-bold">Customer Database”</span>).
              The App and the Customer Database are provided pursuant to a
              separate license agreement (the “<span class="font-weight-bold"
                >License”</span
              >) agreed to by your employer or client (the “<span
                class="font-weight-bold"
                >Customer”</span
              >). As used herein, “<span class="font-weight-bold">You”</span>
              refers to the person who installs and uses the App on a mobile or
              desktop device.
            </p>

            <div class="mt-5 text-h6 font-weight-bold text-center">
              Agreement
            </div>
            <p class="my-5">
              On your behalf and that of the Customer, you hereby agree with MTI
              as follows:
            </p>

            <ol class="pl-10">
              <li>
                <p class="font-weight-bold">LICENSE</p>
                <ol type="a" class="pl-10">
                  <li>
                    <span class="text-decoration-underline">Grant</span>.
                    Subject to the terms of this End-User License Agreement
                    (EULA), upon receipt of the fees specified in the Purchase
                    Order and/or the License, MTI grants you a nonexclusive,
                    nontransferable (except within your organization) license to
                    install and use the App during the Term (as defined below)
                    on mobile or desktop devices solely for Customer's internal
                    use.
                  </li>
                  <li>
                    <span class="text-decoration-underline"
                      >Restrictions on Use</span
                    >. Except as otherwise specifically permitted under this
                    License, you may not use, copy, modify, create derivative
                    works of, decompile, disassemble, reverse engineer,
                    distribute, sell, or otherwise transfer the App or any other
                    component of the System or the related documentation, or
                    permit any third party to do any of the foregoing.
                  </li>

                  <li>
                    <span class="text-decoration-underline">Authentication</span
                    >. The App will require you to provide your e-mail address,
                    which MTI may use for the purpose of authenticating you as
                    an employee or consultant of Customer. If you are an
                    employee of Customer, in the event of any inconsistency
                    between the License and this EULA, the License shall govern.
                    If the user is a consultant to the Customer, this EULA shall
                    govern in the event of such inconsistency.
                  </li>
                </ol>
              </li>

              <li>
                <p class="font-weight-bold lh-30">SUPPORT</p>
                <ol type="a" class="pl-10">
                  <li>
                    <span class="text-decoration-underline">In General</span>.
                    During the three year period (the “<span
                      class="font-weight-bold"
                      >Initial License Term”</span
                    >)commencing on the date of the License (the “<span
                      class="font-weight-bold"
                      >Effective Date”</span
                    >) as it may be extended from time to time as provided
                    therein (the “<span class="font-weight-bold">Term”</span>),
                    MTI or Net Consulting Group Inc. (“<span
                      class="font-weight-bold"
                      >NCG”</span
                    >), MTI’s designated system implementer, will provide Tier 1
                    and Tier 2 support to you. In accordance with the License,
                    Tier 1 support will address initial user and IT issues
                    including data and custom configuration issues. If during
                    the Term NCG determines that an issue requires MTI’s
                    assistance, it shall designate the issue as Tier 2 and
                    forward the support ticket to MTI for resolution. During the
                    Term MTI will provide Tier 2 technical support and provide
                    patches and updates intended to fix reported errors with the
                    Apps not related to data errors or custom configuration
                    errors. MTI shall also provide all updates, upgrades and
                    enhancements to the App that MTI generally releases to its
                    customers during the Term. If during the Term Customer
                    purchases licenses to the Apps for additional authorized
                    users, that purchase shall not restart or extend your Term.
                    MTI shall not be responsible for or support customization
                    done by NCG.
                  </li>

                  <li>
                    <span class="text-decoration-underline">Interruption</span>.
                    MTI may temporarily suspend its support in the event of
                    interruptions beyond its reasonable control, such as may
                    arise in the event of flood, earthquake, terrorist attack,
                    failure of third party communications systems and the like.
                  </li>

                  <li>
                    <span class="text-decoration-underline"
                      >Nature of Support</span
                    >. All such maintenance and support will be provided
                    remotely during MTI’s normal business hours. The maintenance
                    and support will consist of:
                    <ol type="i">
                      <li>
                        responding promptly via e-mail to any tier 2 questions
                        from NCG regarding reports of errors or defects in the
                        Apps or the System.
                      </li>

                      <li>
                        if you first received the App electronically, permitting
                        you to re-install the App electronically on the same or
                        similar device until the end of the Term; and
                      </li>

                      <li>
                        providing access during the Term to (1) any error
                        corrections (i.e. patches) and updates intended to fix
                        reported errors, and (2) all product updates, upgrades
                        and enhancements to the App that MTI generally releases
                        to its customers during the Term.
                      </li>
                    </ol>
                  </li>

                  <li>
                    <span class="text-decoration-underline"
                      >Additional Services</span
                    >. Maintenance and support obligations do not include:
                    custom programming, training, implementation, database
                    design or support, content revisions or changes of any kind
                    or matter not specifically described in clause a) of this
                    Section 2. These services may be provided under a separate
                    agreement.
                    <br />
                    <br />
                  </li>
                </ol>
              </li>

              <li>
                <p class="font-weight-bold lh-30">TERM AND TERMINATION</p>
                <ol type="a" class="pl-10">
                  <li>
                    <span class="text-decoration-underline">Term</span>. The
                    license granted hereunder shall commence on the Effective
                    Date and continue to be in effect and remain in effect
                    during the Initial Term. At any time before the expiration
                    of the Term, Customer may extend the Term for an additional
                    one (1) year term. For each annual extension of the Term,
                    Customer shall pay MTI the annual renewal fee then generally
                    charged by MTI for annual licenses of the App. All payments
                    for extensions hereunder must be received by MTI before the
                    expiration of the Term.
                  </li>

                  <li>
                    <span class="text-decoration-underline">Termination</span>.
                    The Term will terminate automatically in the event that you
                    or Customer (a) breaches any term of this Agreement
                    (including without limitation any payment obligation
                    contained herein or in the License), or (b) violates or
                    infringes any of MTI’s intellectual property in any manner.
                  </li>

                  <li>
                    <span class="text-decoration-underline"
                      >Return or Destruction of App and Materials</span
                    >. Upon the expiration or termination of the Term, the
                    license granted herein shall terminate, MTI will have no
                    obligation to provide maintenance or support for the Apps,
                    and you shall delete the Apps from all of the devices on
                    which you have installed them and, at MTI’s option, either
                    return all copies of any user manuals or other technical or
                    other documents pertaining to the App (the “<span
                      class="font-weight-bold"
                      >Materials”</span
                    >) to MTI or destroy them. At MTI’s request, you shall
                    certify in writing as to completion of the deletion and
                    destruction required above.
                    <br />
                    <br />
                  </li>
                </ol>
              </li>

              <li>
                <p class="font-weight-bold lh-30">
                  LIMITED WARRANTIES; DISCLAIMER; REMEDIES; INDEMNITY
                </p>
                <ol type="a" class="pl-10">
                  <li>
                    <span class="text-decoration-underline"
                      >Limited Warranties</span
                    >. MTI warrants that:

                    <ol type="1">
                      <li>
                        During the Term, the App will perform substantially in
                        accordance with the technical functionality described in
                        the user manual that has been provided to Customer; and
                      </li>

                      <li>
                        Any physical media on which the App is delivered will be
                        free from defects in material and workmanship that will
                        prevent Customer from loading the App on the authorized
                        users’ devices for a period of sixty (60) days from the
                        date of shipment to Customer.
                      </li>
                    </ol>
                  </li>

                  <li>
                    These warranties shall be null and void in the case of any
                    defect caused by any of the following: (i) modification of
                    the App by any party other than MTI or NCG; (ii) use of the
                    App with hardware or software other than that supplied or
                    recommended by MTI; (iii) other improper or unauthorized use
                    of the App; (iv) failures or defects in third party software
                    or hardware; or (v) external factors such as, but not
                    limited to, power failures or electrical surges.
                  </li>

                  <li>
                    <span class="text-decoration-underline">App</span>. If the
                    App fails to perform substantially in accordance with the
                    Manual, Customer’s sole remedy is to initiate a technical
                    support ticket and MTI’s sole obligation will be to provide,
                    or cause NCG to provide, reasonable commercial efforts
                    resolve the issue to Customer’s satisfaction.
                  </li>

                  <li>
                    <span class="text-decoration-underline">DISCLAIMERS</span>.
                    EXCEPT AS SET FORTH ABOVE, THE APP IS PROVIDED "AS IS"
                    WITHOUT ANY EXPRESS OR IMPLIED WARRANTY OF ANY KIND. NO
                    WARRANTY IS MADE THAT THE APP WILL GENERATE DATA WITH THE
                    CHARACTERISTICS OR SPECIFICATIONS DESIRED BY YOU OR THAT THE
                    APP WILL BE ERROR-FREE. THESE DISCLAIMERS OF WARRANTY
                    CONSTITUTE AN ESSENTIAL PART OF THIS AGREEMENT.
                  </li>

                  <li>
                    Without limiting the foregoing, MTI provides no guarantees
                    that the Apps or any upgrades or updates to the Apps will
                    function or perform when used on equipment that does not
                    conform to the specifications indicated in the Manual.
                  </li>

                  <li>
                    Because certain jurisdictions prohibit the waiver of certain
                    warranties, the above disclaimer may not apply to you and
                    you may have additional legal rights that vary by
                    jurisdiction.
                  </li>

                  <li>
                    <span class="text-decoration-underline"
                      >LIMITATION ON LIABILITY</span
                    >. TO THE MAXIMUM EXTENT PERMITTED BY LAW, IN NO EVENT SHALL
                    MTI, NCG OR ANYONE ELSE WHO HAS BEEN INVOLVED IN THE
                    CREATION OF THE APP OR SYSTEM BE LIABLE FOR ANY DAMAGES OR
                    LOSSES WHATSOEVER (INCLUDING, WITHOUT LIMITATION, DIRECT OR
                    INDIRECT DAMAGES, INCIDENTAL OR CONSEQUENTIAL DAMAGES,
                    DAMAGES FOR LOSS OF PROFITS, BUSINESS INTERRUPTION, LOSS OF
                    INFORMATION AND/OR LOSS OF DATA), WHETHER ARISING IN TORT,
                    CONTRACT OR OTHERWISE, ARISING OUT OF THE USE OF OR
                    INABILITY TO USE THE MATERIALS, EVEN IF MTI HAS BEEN ADVISED
                    OF THE POSSIBILITY OF SUCH DAMAGES. IN ANY CASE, MTI’S
                    ENTIRE LIABILITY HEREUNDER SHALL BE LIMITED TO THE AMOUNT
                    ACTUALLY PAID FOR THE LICENSE TO THE APPS. Because some
                    jurisdictions prohibit the exclusion or limitation of
                    liability for damages, the above limitation may not apply to
                    you and you may have other legal rights that vary by
                    jurisdiction.
                  </li>

                  <li>
                    <span class="text-decoration-underline"
                      >Indemnity of MTI</span
                    >. You agree to indemnify MTI and its affiliates against any
                    loss, liability or expense (including reasonable legal fees)
                    it incurs arising out of or in connection with any breach or
                    violation of the terms of this Agreement by Customer or its
                    use of the App.
                    <br />
                    <br />
                  </li>
                </ol>
              </li>

              <li>
                <p class="font-weight-bold lh-30">CONFIDENTIALITY</p>
                <p>
                  You agree that the functionality and design of the App and the
                  System (collectively, the “<span class="font-weight-bold"
                    >Software”</span
                  >), the algorithms used by the Software and the performance
                  characteristics of the Software, and the Materials
                  (collectively, the “<span class="font-weight-bold"
                    >Confidential Information”</span
                  >) are or contain the confidential and proprietary information
                  and trade secrets of MTI and that MTI is providing the
                  Software and the Materials to you in confidence. You shall not
                  and shall not permit others to reverse engineer the Software
                  (including by analysis of benchmarking or output information)
                  or to access the source code, algorithms or performance
                  characteristics of the Software. In addition, you agrees (i)
                  to preserve in strictest confidence all Confidential
                  Information, (ii) not to disclose the Confidential Information
                  to any third party except as expressly permitted herein, (iii)
                  only to disclose the Confidential Information within your
                  business organization to those colleagues who have first
                  agreed to be bound by the terms and conditions substantially
                  similar to those contained herein, (iv) not to disclose any
                  Confidential Information to any agents, contractors or
                  consultants except if such disclosure is related to the
                  authorized use of the App hereunder and after having received
                  a commitment from such agents, contractors or consultants to
                  be bound by substantially similar obligations with respect to
                  such information as you are hereunder, and (v) not to use the
                  Confidential Information for any reason except in connection
                  with the authorized use of the App. You shall be responsible
                  and liable for any unauthorized disclosure, publication or
                  dissemination by any person to whom you make disclosure.
                  Confidential Information shall not include any information
                  which: (a) you possessed prior to the Effective Date without
                  obligation of confidentiality; (b) you rightfully receive from
                  a third party without any obligation of confidentiality to
                  such third party, and which such third party received without
                  any obligation of confidentiality, direct or indirect, to MTI;
                  or (c) is or becomes publicly available lawfully and without
                  breach of any obligation to MTI by you. You may disclose
                  Confidential Information if such disclosure is required under
                  the terms of any statute, regulation, order, subpoena or
                  document discovery request, provided that prior written notice
                  of such disclosure is furnished to MTI as soon as practicable
                  in order to afford MTI an opportunity to seek a protective
                  order or otherwise contest or restrict such required
                  disclosure. You agree to cooperate fully to limit disclosure
                  in the event of any apparent legal requirement that
                  Confidential Information be disclosed.
                </p>
              </li>

              <li>
                <p class="font-weight-bold lh-30">GENERAL</p>
                <ol type="a" class="pl-10">
                  <li>
                    <span class="text-decoration-underline"
                      >U.S. Government Restricted And Limited Rights</span
                    >. The Software has been developed entirely at private
                    expense and has been sold and offered for sale to
                    non-governmental customers. The Software is "commercial
                    computer software" as defined in FAR 2.101(a), and
                    "restricted computer software" as defined in FAR 27-401
                    (Oct. 2014) (or any equivalent agency regulation or contract
                    clause). The Software is provided with the rights set forth
                    in FAR 52.227-19 (November 2007). The foregoing grants of
                    Restricted and Limited Rights are only for the benefit of
                    the United States government and its contractors and, in
                    their hands, override any inconsistent restrictions set
                    forth elsewhere in this EULA.
                  </li>

                  <li>
                    <span class="text-decoration-underline">Governing Law</span
                    >. This EULA will be governed by and construed in accordance
                    with the internal laws of The Commonwealth of Massachusetts.
                    Any action or proceeding brought by either party against the
                    other arising out of or related to this Agreement shall be
                    brought only (i) in a Massachusetts state court or federal
                    district court for the District of Massachusetts, or, (ii)
                    in the case of a proceeding brought by or against the United
                    States government, the Federal Court of Claims or any
                    successor thereto.
                  </li>
                  <li>
                    <span class="text-decoration-underline">Assignment</span>.
                    Neither this License nor any rights or obligations under
                    this EULA may be assigned or delegated by you by operation
                    of law or otherwise without the prior written consent of
                    MTI, which consent may be granted or withheld in MTI's
                    discretion.
                  </li>
                  <li>
                    <span class="text-decoration-underline">Amendment</span>. No
                    modification of or amendment to this License will be
                    effective unless in writing and signed by the party to be
                    charged
                  </li>
                  <li>
                    <span class="text-decoration-underline">Feedback</span>. Any
                    feedback, provided by you arising from your use of the App
                    or the System (the “<span class="font-weight-bold"
                      >Feedback”</span
                    >) may be incorporated by MTI into the Apps and/or the
                    System with no obligation to you. MTI may review, analyze
                    and troubleshoot data generated by your use of the System
                    for the purpose of improving its functionality and that of
                    any other software or services of MTI without thereby
                    incurring any obligation to you. You hereby irrevocably
                    assigns to MTI all your right, title and interest in and to
                    the Feedback.
                  </li>
                  <li>
                    <span class="text-decoration-underline">Rights in Data</span
                    >. Customer shall retain all rights in the Customer
                    Database. To the extent such data is also retained in the
                    MTI Database, Customer has granted MTI a limited license to
                    retain and analyze such data for the purpose of developing
                    improvements to the Software during the Term.
                  </li>
                  <li>
                    <span class="text-decoration-underline">Ownership</span>.
                    MTI will own all right, title and interest in and to the
                    Apps, the System, the System’s architecture and
                    documentation, and any changes, modifications or corrections
                    thereto, including those made as a result of the Feedback.
                    You shall not remove the copyright or other proprietary
                    rights notices from the Materials. Except as expressly
                    provided herein, MTI does not grant you any express or
                    implied right in the Software or the Materials or under the
                    patents, copyrights, trademarks, or trade secret information
                    of MTI or its licensors.
                  </li>
                  <li>
                    <span class="text-decoration-underline">Survival</span>.
                    Sections 1(b), and 3 through 6 will survive the termination
                    of this License.
                  </li>
                </ol>
              </li>
            </ol>
          </div>
        </div>
      </v-card-text>
      <v-card-actions class="justify-center">
        <v-btn
          color="grey"
          variant="flat"
          prepend-icon="mdi-close"
          class="font-weight-bold"
          href="/sign-in"
          @click.prevent="
            // prettier-ignore
            async () => {
              await recordLog('License agreement', `Don't Agree`)
              await userStore.signOut()
            }
          "
          >I Don't Agree</v-btn
        >
        <v-btn
          color="primary"
          variant="flat"
          prepend-icon="mdi-check"
          class="font-weight-bold"
          @click.prevent="agreeHandler()"
          >I Agree</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-container>
</template>

<style lang="scss" scoped>
.lh {
  &-13 {
    line-height: 1.3;
  }
  &-30 {
    line-height: 3;
  }
}
</style>
