import { IWidgetConfig } from './IWidgetConfig.model'
import { MapDatasetManager } from './MapData.model'
import { RecordsetFilter } from './Recordset.model'

/**
 * A lightweight class corresponding to the layout handed to the map widget
 */
export class WidgetMapLayout implements IWidgetConfig {
  container?: string
  devices: string[]
  width: string
  options: WidgetMapLayoutOptions
  tools: WidgetMapLayoutTool[]
  // assets: WidgetMapLayoutAsset[]
  data: MapFilteredRecordsetDef[]

  constructor(options: {
    devices?: string[]
    width?: string
    options: WidgetMapLayoutOptions
    tools?: WidgetMapLayoutTool[]
    // assets?: WidgetMapLayoutAsset[]
    data?: MapFilteredRecordsetDef[]
  }) {
    this.devices = options.devices ?? []
    this.width = options.width ?? ''
    this.options = options.options
    this.tools = options.tools ?? []
    // this.assets = options.assets ?? []
    this.data = options.data ?? []
  }
}

/**
 * A lightweight interface corresponding to a tool handed to the map widget
 * through config
 */
export interface IMapSubtool {
  name: string
  title: string
  icon?: string
  icons?: string[]

  value?: boolean | null
  canSelectAll?: any
  recordset?: string
  options?: IMapSubtool[]
  zoom?: MapZoomObj[]

  hasPolylines?: boolean
  hasMarkers?: boolean
}

/**
 * A lightweight class corresponding to a zoom object contained in a map tool's
 * config
 */
export class MapZoomObj {
  category: string
  min: number
  max: number
  constructor(zoom: { category: string; min: number; max: number }) {
    this.category = zoom.category
    this.min = zoom.min
    this.max = zoom.max
  }

  static getZoomForCategory(
    zoomList: MapZoomObj[],
    category: string | undefined
  ): MapZoomObj | null {
    if (!category) return null
    for (const zoomObj of zoomList) {
      if (zoomObj.category === category) return zoomObj
    }
    return null
  }
}

/**
 * A lightweight class corresponding to the options object for a map widget
 * layout
 */
export class WidgetMapLayoutOptions {
  center: {
    lat: number
    lng: number
  }
  zoom: number
  mapType: string
  scrollWheel: boolean
  scaleControl: boolean
  zoomControl: boolean
  panControl: boolean

  constructor(center: { lat: number; lng: number }) {
    this.center = center
    this.zoom = 10
    this.mapType = 'terrain'
    this.scrollWheel = false
    this.scaleControl = false
    this.zoomControl = false
    this.panControl = false
  }
}

/**
 * A lightweight class corresponding to the definition of a map layout tool
 * given in the Map Widget's layout
 */
export class WidgetMapLayoutTool implements IMapSubtool {
  name: string
  type: string
  title: string
  tooltip?: string
  icon: string

  value?: boolean | null
  canSelectAll?: any
  recordset?: string
  options?: IMapSubtool[]

  constructor(options: {
    name?: string
    type?: string
    title?: string
    icon?: string
    function?: string
  }) {
    this.name = options.name ?? ''
    this.title = options.title ?? ''
    this.icon = options.icon ?? ''
    this.type = options.type ?? ''
  }
}

/**
 * A lightweight object corresponding to the definition of a map data filter
 */
export class MapFilteredRecordsetDef {
  name: string
  recordset: string
  filters: RecordsetFilter[]

  constructor(data: {
    name: string
    recordset: string
    filters: RecordsetFilter[]
  }) {
    this.name = data.name
    this.recordset = data.recordset
    this.filters = data.filters
  }
}

/**
 * An object to help manage the UI menu and tooltip state in the map widget
 */
export class MapMenuUIManager {
  menus: { [menuName: string]: boolean }
  tooltips: { [menuName: string]: boolean }
  expandOnToolDict: { [toolName: string]: boolean }

  /**
   * Creates a new UI management object which holds the state for tooltips and
   * menus.
   * @param layout The layout for which to generate a UI state
   */
  constructor(layout: WidgetMapLayout | undefined = undefined) {
    this.menus = {}
    this.tooltips = {}
    this.expandOnToolDict = {}
    if (layout)
      for (const tool of layout?.tools ?? []) {
        if (tool.type.includes('list')) {
          this.menus[tool.name] = false
          this.tooltips[tool.name] = false
        }
        this._initExpansionDict(tool)
      }
  }

  /**
   * Initializes the expansion dict by recursively adding this tool and its
   * children
   * @param tool The tool to add to the expansion dict. All of this tool's
   * children will also be added.
   */
  private _initExpansionDict(tool: IMapSubtool) {
    this.expandOnToolDict[tool.name] = false
    for (const subTool of tool.options ?? []) {
      this._initExpansionDict(subTool)
    }
  }

  /**
   * Closes all menus except for that of the given tool. This helps prevent the
   * user from having multiple menus open at the same time, which would
   * introduce great complexity to managing active and menu state.
   * @param toolName The name of the tool whose menu should be open.
   */
  closeAllMenusExcept(toolName: string) {
    const openMenus = Object.keys(this.menus).filter(
      menuName => this.menus[menuName]
    )
    for (const menuName of openMenus ?? []) {
      if (menuName !== toolName) {
        this.menus[menuName] = false
      }
    }
    if (Object.prototype.hasOwnProperty.call(this.tooltips, toolName))
      this.tooltips[toolName] = false
  }

  /**
   * Expands or collapses the sub-menu for the given tool.
   * @param toolname The name of the tool whose expansion state should be
   * toggled
   */
  toggleExpansionState(toolname: string) {
    if (Object.prototype.hasOwnProperty.call(this.expandOnToolDict, toolname)) {
      this.expandOnToolDict[toolname] = !this.expandOnToolDict[toolname]
    }
  }
}

/**
 * An object to help manage the state of tools and their child tools in the map
 * widget, as well as to help delay the application of changes until the user
 * hits the "Apply" button. This class holds one version of the complete state
 * of the map's tools.
 */
export class MapWidgetToolState {
  stateDict: { [toolName: string]: MapWidgetTool }
  childListDict: { [parentName: string]: string[] }
  parentToolDict: { [childToolName: string]: string }

  /**
   * Creates a tool state object based on the given layout. Each tool in the
   * layout will have a state object if it needs one.
   * @param layout The map widget's layout object
   */
  constructor(layout: WidgetMapLayout | undefined = undefined) {
    this.stateDict = {}
    this.parentToolDict = {}
    this._initStateDict(layout?.tools)
    this.childListDict = {}
    this._initChildListDict(layout?.tools)
  }

  /**
   * Initializes this tool state object's state dict. This method recursively
   * adds the children of each tool. Also records the tool's parent to the
   * parent tool dict.
   * @param tools A list of tools to initialize into the state dict
   * @param parentToolName The name of the parent tool of the given list of
   * tools. Null if the list consists of top-level tools.
   */
  private _initStateDict(
    tools: IMapSubtool[] | undefined,
    parentToolName: string | undefined = undefined
  ) {
    for (const tool of tools ?? []) {
      // Register this tool's parent if it is not top-level
      if (parentToolName) this.parentToolDict[tool.name] = parentToolName

      // If this tool has child tools, only give it a state if it has one
      if (tool.options) {
        if (tool.canSelectAll) {
          this.stateDict[tool.name] = new MapWidgetTool(tool)
        }
        this._initStateDict(tool.options, tool.name)
      } else {
        this.stateDict[tool.name] = new MapWidgetTool(tool)
      }
    }
  }

  /**
   * Initializes the child list dict, a dictionary where each key is the name of
   * a tool and each value is the a list of the tools who have the key tool as a
   * parent.
   * @param tools A list of tools whose child tools to record. The selectable
   * tools from this list will be added to the parent tool's list of child
   * tools.
   * @param childListDict
   * @returns The selectable tools from the given list, which can be added as an
   * entry of child tools in the child tool dict if they have a parent.
   */
  private _initChildListDict(tools: IMapSubtool[] | undefined): string[] {
    const childList = [] as string[]
    for (const tool of tools ?? []) {
      childList.push(tool.name)
      if (tool.options) {
        // Don't add to the child list dict if it's not selectable
        if (tool.canSelectAll) {
          this.childListDict[tool.name] = this._initChildListDict(tool.options)
        } else {
          // Run this function in case any children have child lists
          this._initChildListDict(tool.options)
        }
      }
    }
    return childList
  }

  /**
   * Returns true if the given tool has children and all children are selected,
   * false otherwise. This method is used to help calculate the intermediate
   * checkbox state in the map menu.
   * @param toolName The name of the tool of interest
   * @returns True if all of the given tool's child tools are selected, false if
   * any are not selected or if the given tool has no children.
   */
  hasAllChildrenSelected(toolName: string): boolean {
    if (!this.childListDict[toolName]) {
      return false
    }
    for (const childToolName of this.childListDict[toolName] ?? []) {
      if (!this.stateDict[childToolName].value) return false
    }
    return true
  }

  /**
   * Returns true if the given tool has children any child tool is selected,
   * false otherwise. This method is used to help calculate the intermediate
   * checkbox state in the map menu.
   * @param toolName The name of the tool of interest
   * @returns True if any of the given tool's child tools are selected, false if
   * none are selected or if the given tool has no children.
   */
  hasAnyChildrenSelected(toolName: string): boolean {
    if (!this.childListDict[toolName]) {
      return false
    }
    for (const childToolName of this.childListDict[toolName] ?? []) {
      if (this.stateDict[childToolName].value) return true
    }
    return false
  }

  /**
   * Applies the changes prescribed by a map tool emitted value to the state
   * dict. Applies relevant changes to nested child tools.
   *
   * * This method has been deprecated in favor of the menu manager handling
   * state changes
   *
   * @param mtev The map tool emitted value that is triggering a change in map
   * state
   */
  toggleState(mtev: MapToolEmittedValue) {
    if (Object.prototype.hasOwnProperty.call(this.stateDict, mtev.toolName)) {
      if (mtev.toggleValue) {
        this.stateDict[mtev.toolName].value =
          !this.stateDict[mtev.toolName].value
      }
      if (mtev.toggleMarkers) {
        this.stateDict[mtev.toolName].showMarkers =
          !this.stateDict[mtev.toolName].showMarkers
      }
      if (mtev.togglePolylines) {
        this.stateDict[mtev.toolName].showPolylines =
          !this.stateDict[mtev.toolName].showPolylines
      }
    }
    /* If this tool has children and is toggleable (as evinced by having 
      generated a map tool emitted value), toggling it should toggle the state 
      of each child tool. Notably, this works recursively to affect nested 
      child tools. */
    if (this.childListDict[mtev.toolName]) {
      const targetState = !this.hasAllChildrenSelected(mtev.toolName) // boolean
      for (const childTool of this.childListDict[mtev.toolName] ?? []) {
        this.stateDict[childTool].value = targetState
      }
    }
  }

  /**
   * Gets a list of every parent tool whose list of active children has changed.
   * This can be used to check which dynamic filters must be updated.
   * @param diffList A list of tool state differences used to calculate which
   * tools are relevant to examine.
   * @returns The names of every parent tool whose list of active children has
   * changed in the given diff list.
   */
  getAffectedParentToolList(diffList: MapToolDiff[]): string[] {
    const affectedParentDict: { [parentName: string]: true } = {}
    for (const diff of diffList) {
      if (diff.hasChanged('value') && this.parentToolDict[diff.toolName]) {
        affectedParentDict[this.parentToolDict[diff.toolName]] = true
      }
    }
    return Object.keys(affectedParentDict)
  }

  /**
   * Gets the active children of a given tool. A tool is "active" if its value
   * is true. This is used to recalculate dynamic filter values.
   * @param parentToolName The name of a parent tool form whom to gather a list
   * of active children
   * @returns A list of the given tool's active children, as determined by value
   */
  getActiveChildren(parentToolName: string): string[] {
    const activeChildren: string[] = []
    for (const childToolName of this.childListDict?.[parentToolName] ?? []) {
      if (this.stateDict?.[childToolName]?.value) {
        activeChildren.push(childToolName)
      }
    }
    return activeChildren
  }

  /**
   * Copies the state dict from the given tool state object.
   * @param source The source tool state object whose value to copy
   */
  copyStateFrom(source: MapWidgetToolState) {
    this.stateDict = JSON.parse(JSON.stringify(source.stateDict))
  }
}

/**
 * A lightweight class that stores the state of a given tool. This class must be
 * lightweight so that a `MapToolState`'s state dict can be stringified.
 */
export class MapWidgetTool {
  value: boolean
  showMarkers: boolean
  showPolylines: boolean

  /**
   * Creates a state object corresponding to the given tool definition. The
   * names of the properties differ from those of the original tool.
   * * Initializes showing markers and polylines to true if present.
   * @param tool A tool to create a corresponding state for
   */
  constructor(tool: IMapSubtool) {
    this.value = tool.value ?? false
    this.showMarkers = tool.hasMarkers ?? false
    this.showPolylines = tool.hasPolylines ?? false
  }
}

/**
 * A class that bundles all relevant changes that a toggle on the menu must
 * transmit to the Map Widget.
 */
export class MapToolEmittedValue {
  toolName: string
  toggleValue?: boolean // Whether or not to display this map element
  toggleMarkers?: boolean
  togglePolylines?: boolean

  /**
   * Creates an emitted value object to bundle this tool's changes.
   * @param toolName The name of the tool emitting a change
   * @param state The changes this tool is emitting.
   */
  constructor(
    toolName: string,
    state: {
      toggleValue?: boolean
      toggleMarkers?: boolean
      togglePolylines?: boolean
    }
  ) {
    this.toolName = toolName
    this.toggleValue = state?.toggleValue ?? false
    this.toggleMarkers = state?.toggleMarkers ?? false
    this.togglePolylines = state?.togglePolylines ?? false
  }
}

/**
 * A class that manages the current active tool state and the inactive menu tool
 * state. This class maintains a dictionary of differences between the active
 * and menu states from which changes can be efficiently calculated.
 */
export class MapWidgetToolManager {
  activeState: MapWidgetToolState
  menuState: MapWidgetToolState
  diffDict: { [toolName: string]: MapToolDiff }
  toolDefDict: { [toolName: string]: IMapSubtool }

  /**
   * Creates a tool manager with an active state and menu state for all tools in
   * the given layout
   * @param layout The map widget's layout object, from which the tool states
   * will be calculated.
   */
  constructor(layout: WidgetMapLayout) {
    this.activeState = new MapWidgetToolState(layout)
    this.menuState = new MapWidgetToolState(layout)
    this.diffDict = {}
    this.toolDefDict = {}
    this._initToolDict(layout.tools)
  }

  /**
   * Initializes the tool dict by recursively adding all tools in the list and
   * each of their children.
   * @param tools A list of tools to add to the tool dict
   */
  private _initToolDict(tools: IMapSubtool[]) {
    for (const tool of tools ?? []) {
      this.toolDefDict[tool.name] = tool
      this._initToolDict(tool?.options ?? [])
    }
  }

  /**
   * Toggles the menu state using the changes introduced by the given emitted
   * value. This method propagates down the change in value to child tools
   * recursively and captures all relevant diff in this tool manager's diff
   * dict.
   * @param mtev The emitted value that is triggering a change in state
   */
  toggleMenuState(mtev: MapToolEmittedValue) {
    /* If this tool does not have its own state (because it's just a parent 
      tool), we're done */
    if (
      !Object.prototype.hasOwnProperty.call(
        this.menuState.stateDict,
        mtev.toolName
      )
    ) {
      return
    }
    const menuTool = this.menuState.stateDict[mtev.toolName]
    const activeTool = this.activeState.stateDict[mtev.toolName]
    // Update the state of the menu tool
    if (mtev.toggleValue) {
      menuTool.value = !menuTool.value
    }
    if (mtev.toggleMarkers) {
      menuTool.showMarkers = !menuTool.showMarkers
    }
    if (mtev.togglePolylines) {
      menuTool.showPolylines = !menuTool.showPolylines
    }

    // Incorporate the presence or absence of the diff this introduces
    this.incorporateDiff(new MapToolDiff(mtev.toolName, activeTool, menuTool))

    // If this tool has children, flip their state as needed
    if (this.menuState.childListDict[mtev.toolName]) {
      const targetState = !this.menuState.hasAllChildrenSelected(mtev.toolName) // boolean
      for (const childToolName of this.menuState.childListDict[
        mtev.toolName ?? []
      ]) {
        if (!this.menuState.stateDict[childToolName].value === targetState) {
          /* If the child tool does not have the right state, call this method 
              to flip it so that the diff is properly captured */
          this.toggleMenuState(
            new MapToolEmittedValue(childToolName, { toggleValue: true })
          )
        }
      }
    }
  }

  /**
   * Incorporates the this diff into this tool manager's diff dict. If the diff
   * shows no change, any diff object corresponding to that tool is deleted to
   * represent that no change is necessary.
   * @param diff A diff object whose changes or lack thereof will be
   * incorporated into this tool manager's diff dict.
   */
  incorporateDiff(diff: MapToolDiff) {
    // An empty diff change object indicates no changes
    if (diff.hasChanged()) {
      this.diffDict[diff.toolName] = diff
    } else if (
      Object.prototype.hasOwnProperty.call(this.diffDict, diff.toolName)
    ) {
      // If the diff object is empty, and a diff is still registered, delete it
      delete this.diffDict[diff.toolName]
    }
  }

  /**
   * Returns true if the given tool has a diff, or true if any diff if no tool
   * name is given, false otherwise
   * @param toolName The name of the tool to get the diff for, or undefined if
   * checking for a diff on the whole state
   * @returns True if there are any changes between the menu state and active
   * state, false otherwise.
   */
  hasDiff(toolName: string | undefined = undefined): boolean {
    if (toolName) return !!this.diffDict[toolName]
    return !!Object.keys(this.diffDict).length
  }

  /**
   * Prepares the menu state to accept changes copying it from the active state
   * and clearing the diff dict. This ensures that the menu state starts by
   * reflecting the active state. This is separated from applying changes to
   * help handle the case where a user repeatedly opens menus without applying
   * changes or toggles a single tool.
   */
  openMenu() {
    this.menuState.copyStateFrom(this.activeState)
    this.diffDict = {}
  }

  /**
   * Applies the changes from the menu state to the active state, then updates
   * the active state and resets the diff dict.
   * @param datasetManager The dataset manager that is responsible for
   * propagating state changes to the map elements
   */
  applyChanges(datasetManager: MapDatasetManager) {
    const affectedParentTools = this.menuState.getAffectedParentToolList(
      Object.values(this.diffDict)
    )
    datasetManager.updateFiltersUsingToolList(affectedParentTools, this)
    datasetManager.applyDiff(Object.values(this.diffDict), this)
    this.activeState.copyStateFrom(this.menuState)
    this.diffDict = {} // Reset the diff dict
  }

  /**
   * Toggles the state of a single tool and immediately applies this change. The
   * menu state is first reset to the active state so that no extraneous changes
   * are applied. This method is intended to be used for top-level toggle
   * buttons that are not inside tool menus.
   * @param toolName The name of the tool whose state to toggle
   * @param datasetManager The dataset manager that is responsible for
   * propagating this tool's change to the map elements
   */
  changeSingleToolState(toolName: string, datasetManager: MapDatasetManager) {
    this.openMenu()
    this.toggleMenuState(
      // TODO: Define this behavior better when the time comes
      new MapToolEmittedValue(toolName, {
        toggleValue: true,
        // toggleMarkers: true,
        // togglePolylines: true,
      })
    )
    this.applyChanges(datasetManager)
  }
}

/**
 * A class representing the difference between a tool's active state and menu
 * state. This class helps abstract the calculations of these differences.
 */
export class MapToolDiff {
  toolName: string
  activeState?: MapWidgetTool
  menuState?: MapWidgetTool

  constructor(
    toolName: string,
    activeState: MapWidgetTool,
    menuState: MapWidgetTool
  ) {
    this.toolName = toolName
    this.activeState = activeState
    this.menuState = menuState
  }

  /**
   * Calculates whether or not the given comparison has changed between the
   * active and menu states. If no comparison is given, returns true if any
   * value has changed, false otherwise.
   * @param comparison The type of difference to calculate. If no comparison
   * argument is given, the presence of any difference is calculated.
   * @returns True if the given comparison has changed from the active state
   * to the menu state, false otherwise.
   */
  hasChanged(
    comparison: undefined | 'value' | 'markers' | 'polylines' = undefined
  ): boolean {
    if (comparison === 'value') {
      return this.activeState?.value !== this.menuState?.value
    } else if (comparison === 'markers') {
      return this.activeState?.showMarkers !== this.menuState?.showMarkers
    } else if (comparison === 'polylines') {
      return this.activeState?.showPolylines !== this.menuState?.showPolylines
    } else {
      // If no argument was given, return true if any difference is present
      return (
        this.activeState?.value !== this.menuState?.value ||
        this.activeState?.showMarkers !== this.menuState?.showMarkers ||
        this.activeState?.showPolylines !== this.menuState?.showPolylines
      )
    }
  }

  /**
   * Calculates what changes to map element visibility are required to execute
   * this diff.
   * @returns An object describing the required changes in map element
   * visibility that this diff introduces
   */
  getVisibilityChange(): { showMarkers?: boolean; showPolylines?: boolean } {
    const visChanges: { showMarkers?: boolean; showPolylines?: boolean } = {}

    // If the value has changed
    if (this.hasChanged('value')) {
      // If the value is newly true, show what should be shown
      if (this.menuState?.value) {
        if (this.menuState.showMarkers) visChanges.showMarkers = true
        if (this.menuState.showPolylines) visChanges.showPolylines = true
        return visChanges

        // If the value is newly false, hide what's currently there
      } else {
        if (this.activeState?.showMarkers) visChanges.showMarkers = false
        if (this.activeState?.showPolylines) visChanges.showPolylines = false
        return visChanges
      }
    }
    // If the markers/polylines change without the value^, adjust in isolation
    if (this.hasChanged('markers'))
      visChanges.showMarkers = this.menuState?.showMarkers
    if (this.hasChanged('polylines'))
      visChanges.showPolylines = this.menuState?.showPolylines

    return visChanges
  }
}
