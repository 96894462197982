/**
 * Utility class for storing a count in an object
 */
export class Incrementor {
  previous: number
  first: number

  /**
   * @param first The first number in the counting sequence, defaults to 0
   */
  constructor(first = 0) {
    this.first = first
    this.previous = first - 1
  }

  /**
   * @returns The next number in the counting sequence
   */
  getNext() {
    this.previous++
    return this.previous
  }

  /**
   * Resets this Incrementor to the first number given at initialization, or to
   * the given number
   * @param first If provided, the number to reset the count to. This will be
   * the first number returned when `getNext()` is next called
   */
  reset(first = this.first) {
    this.previous = first - 1
  }
}
