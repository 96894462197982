import { lookupDefaultValueSubstitution } from '@/services/lookup'
import {
  FirestoreUploadData,
  FormFieldEmittedValue,
  FormNoteData,
  FormNoteHandler,
  FormSubmitDelayData,
} from './FormData.model'
import { IWidgetConfig } from './IWidgetConfig.model'
import { LayoutConfig } from './LayoutConfig.model'
import {
  FormLogicCondition,
  FormLogicOperation,
  FormLogicOperationHandler,
} from './FormLogic.model'
import { FormFieldHandler } from './FormHandlers.model'
import { Incrementor } from './Util.model'
import { makeid } from '@/services/utils'
import { ErrorObject } from '@vuelidate/core'
/**
 * Config object for the form widget. Allows type-checking on the converted JSON
 * data.
 */
export class WidgetFormConfig implements IWidgetConfig {
  recordset: string
  width: string
  rows?: WidgetFormRowConfig[]
  sections?: FormSectionConfig[]
  tabs?: FormTabConfig[]
  summary?: { isEnabled: boolean }
  buttons?: {
    icon?: string
    label?: string
    type:
      | 'submit'
      | 'cancel'
      | 'save'
      | 'delete'
      | 'close'
      | 'navigation'
      | 'edit'
    position: 'left' | 'right' | 'floatRight'
    isHidden?: boolean
  }[]

  constructor(recordset: string, width: string) {
    this.recordset = recordset
    this.width = width
    this.sections = []
    this.tabs = []
  }
}

/**
 * Config object for a form tab. Allows intellisense.
 */
export class FormTabConfig {
  label: string
  width: string
  sections?: FormSectionConfig[]
  rows?: WidgetFormRowConfig[]
  isHidden?: boolean
  icon?: string

  constructor(label: string, width: string) {
    this.label = label
    this.width = width
    this.sections = []
    this.rows = []
  }
}

/**
 * Config object for a form section. Allows intellisense.
 */
export class FormSectionConfig {
  label: string
  width: string
  level?: number
  isHidden?: boolean
  rows: WidgetFormRowConfig[]

  constructor(label: string, width: string) {
    this.label = label
    this.width = width
    this.rows = []
  }
}

/**
 * Config object for a form row widget. Allows type-checking on data converted
 * from JSON.
 */
export class WidgetFormRowConfig {
  width: string
  cols: {
    width: string
    fields?: WidgetFormFieldConfig[]
    rows?: WidgetFormRowConfig[]
  }[]

  constructor(width: string) {
    this.width = width
    this.cols = []
  }
}

/**
 * Config object for a form field. Allows intellisense.
 */
export class WidgetFormFieldConfig {
  type: string
  width: string
  options: any | RadioFormFieldOptions
  name: string
  property: string
  label: string
  labelAbove?: string
  tooltip: string
  help: string
  placeholder: string
  isDisabled: boolean
  isHidden?: boolean
  isSearchable?: boolean
  isRequired: boolean
  isExcusable?: boolean
  style?: string
  value?: any
  optionsFilter?: any
  icon?: string
  addNew?: boolean

  // Convenience fields set by the handlers and other facets of form operations
  focus?: boolean
  readOnly?: boolean
  isUnlockable?: boolean

  constructor(
    type: string,
    width: string,
    name: string,
    property: string,
    label: string
  ) {
    this.type = type
    this.width = width
    this.options = null
    this.name = name
    this.property = property
    this.label = label
    this.tooltip = ''
    this.help = ''
    this.placeholder = ''
    this.isDisabled = false
    this.isRequired = false
  }
}

/**
 * Handler object that stores and operates on a widget form config. Also stores
 * a generated logic dict and firestore prop dict, which help the form widget
 * operate.
 */
export class WidgetFormConfigHandler {
  rawConfig: WidgetFormConfig
  config: WidgetFormConfig
  fsud: FirestoreUploadData

  fieldHandlersDict: { [fieldName: string]: FormFieldHandler }

  fieldToTabDict: { [fieldName: string]: string | null }
  fieldToSectionDict: { [fieldName: string]: number | null }
  tabToFieldsDict: { [tabName: string]: string[] }
  sectionIsHiddenDict: { [sectionName: string]: boolean }

  errorList: any[]
  errorsByTabDict: { [tabName: string]: any[] }
  fieldsWithValidationDict: { [tabName: string]: { [fieldName: string]: true } } // which fields have validation
  fieldValidationStateDict: {
    [tabName: string]: { [fieldName: string]: boolean }
  } // the validation state of each field with validation
  tabValidationStateDict: {
    [tabName: string]: {
      numerator: number
      denominator: number
      state: 'full' | 'partial' | 'empty'
    }
  } // Quick access dictionary expressing the validation state of each tab
  // Saves computational power for checking whether or not the form is valid

  logicDict?: { [triggerPropName: string]: FormLogicOperation[] }
  logicList?: FormLogicOperation[]
  firestorePropDict: { [fieldName: string]: string }
  propNameToFieldNameDict: { [propName: string]: string }
  fieldTypeDict: { [fieldName: string]: string }
  fieldDiffDict: { [fieldName: string]: FormFieldDiff }
  id: string
  instanceId: string

  private _focusIsInitialized: boolean

  constructor(
    config: WidgetFormConfig,
    mode: string,
    firestoreRecord: any = undefined,
    noteId: string | undefined = undefined
  ) {
    this.config = JSON.parse(JSON.stringify(config))
    this.rawConfig = JSON.parse(JSON.stringify(this.config))

    this.firestorePropDict = {}
    this.propNameToFieldNameDict = {}
    this.fieldTypeDict = {}
    this.sectionIsHiddenDict = {}

    this.fieldHandlersDict = {}
    this.fieldToTabDict = {}
    this.fieldToSectionDict = {}
    this.tabToFieldsDict = {}
    this.errorList = []
    this.errorsByTabDict = {}
    this.fieldsWithValidationDict = {}
    this.fieldValidationStateDict = {}
    this.tabValidationStateDict = {}
    this.fieldDiffDict = {}

    this._focusIsInitialized = false

    this._init(this.config)

    this.fsud = new FirestoreUploadData(
      this.config.recordset,
      firestoreRecord
    ).initializeWith(this.config, mode)
    if (firestoreRecord) {
      this.initializeWithData(firestoreRecord, mode, noteId)
    }
    this.initializeFocus()
    this.id = this.fsud.id
    this.instanceId = makeid(7)
  }

  /**
   * Initializes the quick lookup dicts for this handler. This method should
   * only be called once, and that call should be in this object's constructor.
   * @param config The `WidgetFormConfig` that will be used to initialize this
   * `WidgetFormConfigHandler`
   */
  private _init(config: WidgetFormConfig) {
    const fieldWithinTabIndexer = new Incrementor(1)
    const absoluteFieldIndexer = new Incrementor(1)
    const sectionWithinTabIndexer = new Incrementor(0)
    const tabIndexer = new Incrementor(0)
    for (const tab of config?.tabs ?? []) {
      const tabIndex = tabIndexer.getNext()
      this.fieldsWithValidationDict[tab.label] = {}
      this.fieldValidationStateDict[tab.label] = {}
      this.errorsByTabDict[tab.label] = []
      fieldWithinTabIndexer.reset() // Reset field index for new tab
      for (const section of tab?.sections ?? []) {
        const sectionIndex = sectionWithinTabIndexer.getNext()
        this.sectionIsHiddenDict[section.label] = !!section?.isHidden
        for (const row of section?.rows ?? []) {
          this._initRow(row, tabIndex, sectionIndex, {
            fieldWithinTabIndexer: fieldWithinTabIndexer,
            absoluteFieldIndexer: absoluteFieldIndexer,
          })
        }
      }
      for (const row of tab?.rows ?? []) {
        this._initRow(row, tabIndex, null, {
          fieldWithinTabIndexer: fieldWithinTabIndexer,
          absoluteFieldIndexer: absoluteFieldIndexer,
        })
      }
      sectionWithinTabIndexer.reset()
    }
    fieldWithinTabIndexer.reset() // Clear if there's anything listed without a tab
    for (const section of config?.sections ?? []) {
      const sectionIndex = sectionWithinTabIndexer.getNext()
      this.sectionIsHiddenDict[section.label] = !!section?.isHidden
      for (const row of section?.rows ?? []) {
        this._initRow(row, null, sectionIndex, {
          fieldWithinTabIndexer: fieldWithinTabIndexer,
          absoluteFieldIndexer: absoluteFieldIndexer,
        })
      }
    }
    for (const row of config?.rows ?? []) {
      this._initRow(row, null, null, {
        fieldWithinTabIndexer: fieldWithinTabIndexer,
        absoluteFieldIndexer: absoluteFieldIndexer,
      })
    }
  }

  /**
   * Adds the given `WidgetFormRowConfig` to this handler's initialization
   * process. This initializes various quick lookup dictionaries, assigns a
   * handler object to each field in the row, and initializes each nested row
   * within this row.
   * @param row The `WidgetFormRowConfig` object to add to the initialization
   * process for this `WidgetFormConfigHandler`
   * @param tabIndex The index of the form tab in which this row resides
   * @param sectionIndex The index of the form section in which this row resides
   * @param indexers A dictionary of indexers used to help assign each field a
   * meaningful ID that is unique within the form
   */
  private _initRow(
    row: WidgetFormRowConfig,
    tabIndex: number | null = null,
    sectionIndex: number | null = null,
    indexers: {
      fieldWithinTabIndexer: Incrementor
      absoluteFieldIndexer: Incrementor
    }
  ) {
    for (const col of row.cols) {
      for (const field of col.fields ?? []) {
        // Don't make handlers for info blocks
        if (
          field.type.toLocaleLowerCase() === 'infoimage' ||
          field.type.toLocaleLowerCase() === 'infotext'
        )
          continue
        let id = `${indexers.fieldWithinTabIndexer.getNext()}`
        // Remove section number from ID for now
        // if (sectionIndex !== null) id = `S${sectionIndex + 1}.${id}`
        if (tabIndex !== null) id = `${tabIndex + 1}.${id}`

        // Set up dicts
        const fieldName = field.name
        this.fieldHandlersDict[fieldName] = new FormFieldHandler(
          field,
          id,
          indexers.absoluteFieldIndexer.getNext()
        )
        const tabName = this.rawConfig?.tabs?.[tabIndex ?? 0].label
        if (tabIndex !== null) {
          this.fieldToTabDict[fieldName] = tabName ?? null
        } else {
          this.fieldToTabDict[fieldName] = null
        }
        this.fieldToSectionDict[fieldName] = sectionIndex
        if (tabName) {
          if (!this.tabToFieldsDict[tabName]) this.tabToFieldsDict[tabName] = []
          this.tabToFieldsDict[tabName].push(fieldName)
        }

        // Set up property lookup dicts
        if (field.property) {
          this.firestorePropDict[fieldName] = field.property
          this.propNameToFieldNameDict[field.property] = fieldName
        }
        this.fieldTypeDict[fieldName] = field.type

        // Initialize focus on the first interactive field
        if (
          !this._focusIsInitialized &&
          this.fieldHandlersDict[fieldName].isInteractive()
        ) {
          this.fieldHandlersDict[fieldName].focus()
          this._focusIsInitialized = true
        }
      }

      for (const row of col.rows ?? []) {
        this._initRow(row, tabIndex, sectionIndex, indexers)
      }
    }
  }

  /**
   * Incorporates the given error list into this handler to produce a new
   * validation snapshot. This sets both the error list and the errors by tab.
   * To clear this state, call this method with an empty list.
   * @param errorList The vuelidate error list to incorporate into this handler
   */
  setErrors(errorList: ErrorObject[]) {
    this.errorList = this.sortErrorList(errorList)
    for (const tabName of Object.keys(this.errorsByTabDict)) {
      this.errorsByTabDict[tabName] = this.errorList.filter(
        (error: ErrorObject) => {
          this.fieldToTabDict[error.$property] === tabName
        }
      )
    }
  }

  /**
   * Returns a sorted version of the given list of vuelidate errors. This method
   * is for internal private use of the `setErrors` function
   * @param errorList The list of Vuelidate errors to sort
   * @returns A sorted version of the given list
   */
  private sortErrorList(errorList: ErrorObject[]): ErrorObject[] {
    return errorList.sort((errorA: ErrorObject, errorB: ErrorObject) => {
      return (
        this.fieldHandlersDict[errorA.$property].sortableId -
        this.fieldHandlersDict[errorB.$property].sortableId
      )
    })
  }

  /**
   * Returns a list of validation errors captured at this form's last validation
   * snapshot. This helps the form display persistent errors when the user
   * attempts an action that requires validation.
   * @param tabName The name of the tab whose errors to retrieve
   * @returns A list of all errors present on a tab at the last validation
   * snapshot.
   */
  getErrorsByTab(tabName: string): any[] {
    if (this.config.summary?.isEnabled) {
      return this.errorsByTabDict[tabName] ?? []
    }
    return this.errorList
  }

  /**
   * Calculates a validation score component for the specified tab. This method
   * is for this class's internal use and does not produce any side-effects.
   * @param tabName The name of the tab whose validation score to calculate
   * @param mode The validation score component to calculate (either the
   * numerator or the denominator)
   * @returns The requested component of the validation score from the tab
   * specified
   */
  private _calculateValidationScoreComponent(
    tabName: string,
    mode: 'numerator' | 'denominator'
  ): number {
    let count = 0
    if (mode === 'numerator') {
      count = Object.values(
        this.fieldValidationStateDict[tabName] ?? {}
      ).filter(isValid => isValid).length
    } else if (mode === 'denominator')
      count = Object.keys(this.fieldsWithValidationDict[tabName] ?? {}).length
    return count
  }

  getTabValidationState(tabName: string): {
    numerator: number
    denominator: number
    state: 'empty' | 'full' | 'partial'
  } {
    return (
      this.tabValidationStateDict[tabName] ?? {
        numerator: 0,
        denominator: 0,
        state: 'empty',
      }
    )
  }

  /**
   * @returns True if the form is free of validation errors, false otherwise
   */
  formIsValid(): boolean {
    for (const tabValidationState of Object.values(
      this.tabValidationStateDict
    )) {
      if (tabValidationState.state !== 'full') return false
    }
    return true
  }

  /**
   * Populates this handler's form configuration with the given firestore data.
   * If a noteId is provided, the handler will search the firestore data for the
   * specified note and populate the form with only that. Otherwise, the
   * configuration will be populated with all applicable fields.
   * * This distinction is necessary because searching for a note is
   * * sufficiently different from using applicable fields.
   * @param firestoreData The firestore data with which to initialize the form
   * config
   * @param noteId If initialized, the id of the note that this form corresponds
   * to. Left uninitialized if this form should instead be populated with the
   * full firestore data
   * @returns The handler object for constructor chaining
   */
  private initializeWithData(
    firestoreData: any,
    mode: string,
    noteId: string | null = null
  ): WidgetFormConfigHandler {
    this.config = JSON.parse(JSON.stringify(this.rawConfig)) // Clear any data
    if (noteId) {
      this.populateWithNote(firestoreData, noteId)
    } else {
      this.populateWithRecord(firestoreData)
    }
    const formAlisedValues = {} as { [fieldName: string]: any }
    for (const propName of Object.keys(this.propNameToFieldNameDict) ?? {}) {
      const fieldName = this.propNameToFieldNameDict[propName]
      if (firestoreData[propName]) {
        formAlisedValues[fieldName] = firestoreData[propName]
      }
    }
    this.fsud = new FirestoreUploadData(
      this.config.recordset,
      firestoreData,
      formAlisedValues
    ).initializeWith(this.config, mode)
    return this
  }

  /**
   * Uses a firestore record to populate the form field values in this handler
   * object's form field configuration
   * @param firestoreData The firestore record with which to populate the form
   * config
   * @returns The newly populated form configuration
   */
  private populateWithRecord(firestoreData: any): WidgetFormConfig {
    for (const row of this.config.rows ?? []) {
      this._populateRowWithRecord(row, firestoreData)
    }
    for (const section of this.config.sections ?? []) {
      for (const row of section.rows ?? []) {
        this._populateRowWithRecord(row, firestoreData)
      }
    }
    for (const tab of this.config.tabs ?? []) {
      for (const row of tab.rows ?? []) {
        this._populateRowWithRecord(row, firestoreData)
      }
      for (const section of tab.sections ?? []) {
        for (const row of section.rows ?? []) {
          this._populateRowWithRecord(row, firestoreData)
        }
      }
    }
    return this.config
  }

  private _populateRowWithRecord(row: WidgetFormRowConfig, firestoreData: any) {
    for (const col of row?.cols ?? []) {
      for (const field of col.fields ?? []) {
        if (field.property) {
          if (field.property && firestoreData?.[field.property]) {
            field.value = firestoreData[field.property]
          }
        }
      }
      for (const nestedRow of col.rows ?? []) {
        this._populateRowWithRecord(nestedRow, firestoreData)
      }
    }
  }

  /**
   * Populates a form config with note data.
   * ! Relies on the field for the note having property set to `note`
   * @param firestoreData The firestore record from which to pull the note data
   * @param noteId The id of the note to pull
   */
  private populateWithNote(
    firestoreData: any,
    noteId: string
  ): WidgetFormConfig {
    const noteContent = new FormNoteHandler(firestoreData).getNoteById(
      noteId
    )?.note
    this.populateWithRecord({ note: noteContent })
    return this.config
  }

  /**
   * Takes a form configuration and creates a dictionary that maps the `name` of
   * each form field to their corresponding form field values
   * @param config The form configuration for which to extract the init values
   * @returns A dictionary of the form's init values where each key is the name
   * of a form field and each value is that form field's corresponding value
   */
  static extractInitValues(
    config: WidgetFormConfig,
    mode: string
  ): { [key: string]: any } {
    const values = {} as { [key: string]: any }
    for (const row of config?.rows ?? []) {
      WidgetFormConfigHandler._extractInitValuesFromRow(row, values, mode)
    }
    for (const section of config?.sections ?? []) {
      for (const row of section?.rows ?? []) {
        WidgetFormConfigHandler._extractInitValuesFromRow(row, values, mode)
      }
    }
    for (const tab of config?.tabs ?? []) {
      for (const row of tab?.rows ?? []) {
        WidgetFormConfigHandler._extractInitValuesFromRow(row, values, mode)
      }
      for (const section of tab?.sections ?? []) {
        for (const row of section?.rows ?? []) {
          WidgetFormConfigHandler._extractInitValuesFromRow(row, values, mode)
        }
      }
    }
    return values
  }

  private static _extractInitValuesFromRow(
    row: WidgetFormRowConfig,
    values: { [key: string]: any },
    mode: string
  ) {
    for (const col of row.cols ?? []) {
      for (const field of col?.fields ?? []) {
        values[field.name] =
          lookupDefaultValueSubstitution(
            field.value,
            !mode.toLowerCase().includes('add')
          ) ?? null
      }
      for (const row of col?.rows ?? []) {
        this._extractInitValuesFromRow(row, values, mode)
      }
    }
  }

  /**
   * Obtains a reference to the configuration for the form field of the
   * requested name
   * @param key The name of the form field to return the key for
   * @returns Either a reference to the requested form field or null if no such
   * field is found
   */
  getFormFieldConfig(key: string): WidgetFormFieldConfig | null {
    for (const row of this.config?.rows ?? []) {
      const fieldOrNull = this._getFormFieldConfigFromRow(row, key)
      if (fieldOrNull) return fieldOrNull
    }
    for (const section of this.config?.sections ?? []) {
      for (const row of section?.rows ?? []) {
        const fieldOrNull = this._getFormFieldConfigFromRow(row, key)
        if (fieldOrNull) return fieldOrNull
      }
    }
    for (const tab of this.config?.tabs ?? []) {
      for (const row of tab?.rows ?? []) {
        const fieldOrNull = this._getFormFieldConfigFromRow(row, key)
        if (fieldOrNull) return fieldOrNull
      }
      for (const section of tab?.sections ?? []) {
        for (const row of section?.rows ?? []) {
          const fieldOrNull = this._getFormFieldConfigFromRow(row, key)
          if (fieldOrNull) return fieldOrNull
        }
      }
    }
    return null
  }

  private _getFormFieldConfigFromRow(
    row: WidgetFormRowConfig,
    key: string
  ): WidgetFormFieldConfig | null {
    for (const col of row.cols ?? []) {
      for (const field of col.fields ?? []) {
        if (field.name === key) {
          // ! Keyed on name in this case
          return field
        }
      }
      for (const row of col.rows ?? []) {
        const fieldOrNull = this._getFormFieldConfigFromRow(row, key)
        if (fieldOrNull) return fieldOrNull
      }
    }
    return null
  }

  /**
   * Sets the first field in this configuration as focused if at least one field
   * is present.
   * @returns This handler for constructor chaining
   */
  initializeFocus(): WidgetFormConfigHandler {
    for (const row of this.config?.rows ?? []) {
      if (this._setFocusOnRow(row)) return this
    }
    for (const section of this.config?.sections ?? []) {
      for (const row of section?.rows ?? []) {
        if (this._setFocusOnRow(row)) return this
      }
    }
    for (const tab of this.config?.tabs ?? []) {
      for (const row of tab?.rows ?? []) {
        if (this._setFocusOnRow(row)) return this
      }
      for (const section of tab?.sections ?? []) {
        for (const row of section?.rows ?? []) {
          if (this._setFocusOnRow(row)) return this
        }
      }
    }
    // Case: This handler's form config has no fields
    return this
  }

  /**
   * Attempts to set the first field of a given row as focused. Returns the
   * success state.
   * @param row The row on which to set the first field as focused
   * @returns True if the given row has at least one field and if that field is
   * now set as focused, false otherwise
   */
  private _setFocusOnRow(row: WidgetFormRowConfig): boolean {
    for (const col of row?.cols ?? []) {
      for (const field of col?.fields ?? []) {
        if (!field?.isDisabled && !field?.isHidden && field.type !== 'button') {
          field.focus = true
          return true
        }
      }
      for (const row of col?.rows ?? []) {
        if (this._setFocusOnRow(row)) {
          return true
        }
      }
    }
    return false
  }

  markEveryFieldReadOnly(config: { except?: string | string[] } = {}) {
    let exceptions: string[] = []
    if (config && config?.except) {
      exceptions =
        typeof config.except === 'string' ? [config.except] : config.except
    }
    for (const row of this.config?.rows ?? []) {
      this._markRowReadOnly(row, exceptions)
    }
    for (const section of this.config?.sections ?? []) {
      for (const row of section?.rows ?? []) {
        this._markRowReadOnly(row, exceptions)
      }
    }
    for (const tab of this.config?.tabs ?? []) {
      for (const row of tab?.rows ?? []) {
        this._markRowReadOnly(row, exceptions)
      }
      for (const section of tab?.sections ?? []) {
        for (const row of section?.rows ?? []) {
          this._markRowReadOnly(row, exceptions)
        }
      }
    }
  }

  private _markRowReadOnly(row: WidgetFormRowConfig, exemptedFields: string[]) {
    for (const col of row?.cols ?? []) {
      for (const field of col?.fields ?? []) {
        if (!exemptedFields.includes(field.name)) field.readOnly = true
      }
      for (const row of col?.rows ?? [])
        this._markRowReadOnly(row, exemptedFields)
    }
  }

  /**
   * Incorporates the form's logic into the handler to manipulate the
   * configuration responsively
   * @param logic The form logic to incorporate into this handler
   * @returns The WidgetFormConfigHandler for constructor chaining
   */
  initializeLogicDict(
    logic: FormLogicOperation[] | undefined
  ): WidgetFormConfigHandler {
    this.logicList = logic ?? []
    this.logicDict = new FormLogicOperationHandler(
      logic ?? []
    ).extractOperationDictionary()

    // Apply the logic dict immediately upon initialization for every possible field
    for (const fieldName of Object.keys(this.fieldTypeDict)) {
      this.applyLogic(
        new FormFieldEmittedValue(null, fieldName, 'other', {
          firestoreProp: this.firestorePropDict[fieldName] ?? null,
        })
      )
    }

    // TODO: This is absolutely a hack and should be reworked at soonest opportunity
    if (this.fsud.content.status === 'Complete') {
      for (const button of this.config.buttons ?? []) {
        if (!['close', 'export', 'navigation'].includes(button.type)) {
          button.isHidden = true
        }
      }
    }
    return this
  }

  /**
   * Applies this form's logic to its own configuration. This method consumes
   * the emit data from the field that just changed and applies any actions
   * that this change invokes. These changes are applied by modifying this
   * form's configuration object.
   * @param ffev The FormFieldEmittedValue from the change that just occurred
   * @param formState The FirestoreUploadData corresponding to the form whose
   * logic is being applied
   */
  applyLogic(ffev: FormFieldEmittedValue) {
    if (this.logicDict?.[ffev?.name ?? '']) {
      const actions = FormLogicOperationHandler.getActionsToApply(
        this.logicDict[ffev?.name ?? ''],
        this.fsud
      )
      for (const action of actions ?? []) {
        // Non-Form-Field Operations
        if (action.actionType === 'SetSectionHidden') {
          if (
            Object.hasOwnProperty.call(this.sectionIsHiddenDict, action.target)
          ) {
            this.sectionIsHiddenDict[action.target] = action.value
          } else {
            console.error(
              `Failed to set form section ${action.target} hidden val to ${action.value}`
            )
          }
          continue
        }

        // Form Field Operations
        const targetField = this.getFormFieldConfig(action.target)
        const targetFieldHandler = this.fieldHandlersDict[action.target]
        if (!targetField) {
          console.error(
            `Could not find field ${action.target} as target for form logic operation`
          )
          continue
        }
        let actionValue = JSON.parse(JSON.stringify(action.value))
        if (typeof actionValue === 'string') {
          actionValue = action.value.replaceAll(
            // Dynamically find the name of the field needed
            /\{formValue\.[^{"}}]+\}/g,
            (str: string) => this.fsud.getValue(str.slice(11, -1))
          )
        } else if (Array.isArray(actionValue)) {
          for (let i = 0; i < actionValue.length; i++) {
            if (typeof actionValue[i] === 'string') {
              actionValue[i] = actionValue[i].replaceAll(
                // Dynamically find the name of the field needed
                /\{formValue\.[^{"}}]+\}/g,
                (str: string) => this.fsud.getValue(str.slice(11, -1))
              )
            }
          }
        }
        switch (action.actionType) {
          case 'SetEnabled':
            targetField.isDisabled = !actionValue
            targetFieldHandler.isDisabled = !actionValue
            break
          case 'SetHidden':
            targetField.isHidden = actionValue
            targetFieldHandler.isHidden = actionValue
            break
          case 'SetLabel':
            targetField.label = actionValue
            break
          case 'SetRequired':
            targetField.isRequired = actionValue
            targetFieldHandler.isRequired = actionValue
            break
          case 'SetSelectOptions':
            targetField.optionsFilter =
              JSON.parse(JSON.stringify(actionValue)) ?? []
            // New object pointer every time
            break
          case 'SetProp':
            if (action.targetProp) {
              ;(targetField as any)[action.targetProp] = actionValue
            }
            break
          case 'SetValue':
            targetField.value = undefined
            // Delay so that the watch has time to register both changes
            setTimeout(() => (targetField.value = actionValue), 200)
            break
          case 'LockAll':
            this.markEveryFieldReadOnly({ except: (action as any)?.exceptions })
            break
          default:
            console.error(
              `Unrecognized action requested by form logic: ${action.actionType}`
            )
        }
      }
    }
  }

  getData(): FirestoreUploadData {
    return this.fsud
  }

  /**
   * @returns True if the form this config manages has user-changes that may be
   * lost, false otherwise
   */
  hasChanges(): boolean {
    return !!Object.keys(this.fieldDiffDict).length
  }

  /**
   * @returns A list of diff objects that describes each change in this form
   */
  getChanges(): FormFieldDiff[] {
    return Object.values(this.fieldDiffDict)
  }

  /**
   * Updates a FirestoreUploadData with the value emitted from a form field or
   * packaged together with a ffev. Sends the change to the FSUD object and
   * updates this handler's diffDict and validation dicts.
   * @param ffev The FormFieldEmittedValue that describes which property to
   * update
   */
  updateValue(ffev: FormFieldEmittedValue) {
    if (ffev.source === 'init') {
      // Set initial value if this is an initialization
      this.fieldHandlersDict[ffev.name].originalValue = ffev.value
      delete this.fieldDiffDict[ffev.name]
    } else {
      // Calculate the diff
      const diff = this.fieldHandlersDict[ffev.name].getDiff(ffev)
      if (diff.hasChanged()) {
        this.fieldDiffDict[ffev.name] = diff
      } else {
        delete this.fieldDiffDict[ffev.name] // Remove existing diff if no change
      }
    }

    // Update the validation score
    const tabName = this.fieldToTabDict[ffev.name]
    if (tabName) {
      if (ffev.hasValidation) {
        this.fieldsWithValidationDict[tabName][ffev.name] = true
        this.fieldValidationStateDict[tabName][ffev.name] =
          (ffev.value && ffev.isValid) ?? false
      } else {
        delete this.fieldsWithValidationDict[tabName][ffev.name]
        delete this.fieldValidationStateDict[tabName][ffev.name]
      }
      const denominator = this._calculateValidationScoreComponent(
        tabName,
        'denominator'
      )
      if (denominator) {
        const numerator = this._calculateValidationScoreComponent(
          tabName,
          'numerator'
        )
        let state: 'partial' | 'full' | 'empty' = 'partial'
        if (numerator === denominator) state = 'full'
        else if (numerator === 0) state = 'empty'
        this.tabValidationStateDict[tabName] = {
          numerator: numerator,
          denominator: denominator,
          state: state,
        }
      } else {
        this.tabValidationStateDict[tabName] = {
          numerator: 0,
          denominator: 0,
          state: 'full',
        }
      }
    }

    // Pass the value to the FirestoreUploadData class
    this.fsud.updateValue(ffev)
  }
}

/**
 * A class representing the difference between a form field's original value and
 * its current value. This allows the form to retain which fields have changed
 * and allows the form to abstract the calculation of these differences.
 */
export class FormFieldDiff {
  originalValue: any
  activeValue: any
  fieldName: string
  fieldType: string
  isDelayChange?: boolean

  constructor(
    activeValue: any,
    fieldHandler: FormFieldHandler,
    delayUpdate: FormSubmitDelayData | undefined = undefined
  ) {
    this.originalValue = fieldHandler.originalValue
    this.activeValue = activeValue
    this.fieldName = fieldHandler.liveConfig.name
    this.fieldType = fieldHandler.liveConfig.type
    if (delayUpdate && delayUpdate.action === 'registerDelay')
      this.isDelayChange = true
  }

  /**
   * @returns True if the active value has changed meaningfully from the
   * original value, false otherwise
   */
  hasChanged(): boolean {
    if (this.isDelayChange) return true // A submit delay should register as a change
    let valOrig = this.originalValue
    let valAct = this.activeValue
    if (
      !valOrig ||
      valOrig === '' ||
      (Array.isArray(valOrig) && valOrig?.length === 0)
    ) {
      valOrig = null
    }
    if (
      !valAct ||
      valAct === '' ||
      (Array.isArray(valOrig) && valOrig?.length === 0)
    ) {
      valAct = null
    }
    return valOrig !== valAct
  }
}

export interface ButtonProps {
  'class': string
  'color': string
  'disabled': boolean
  'elevation': number
  'icon'?: string | null
  'min-width'?: number | null
  'prepend-icon'?: string
  'rounded'?: number | string
  'size'?: string
  'stacked'?: boolean
  'style'?: string
  'variant'?: string | null
}

export class RadioFormFieldOptions {
  icon?: string
  width: string | number
  label: string
  color?: string

  constructor(label: string, width: string | number) {
    this.label = label
    this.width = width
  }
}
