import { storeToRefs } from 'pinia'
import { ref, computed, watch, onMounted } from 'vue'
import { useDisplay } from 'vuetify'
import { useRoute, useRouter } from 'vue-router'

import {
  isAndroid,
  isPhone,
  isIPhone,
  isIPhoneSE,
  isPWA,
  isTablet,
  isPortrait,
} from '@/services/deviceDetection.ts'

// stores
import { useDataStore } from '@/stores/data'
import { usePagesStore } from '@/stores/pages'
import { useNavigationStore } from '@/stores/navigation'
import { useWidgetsStore } from '@/stores/widgets'
import { useUserStore } from '@/stores/user'
import { useLinearStore } from '@/stores/linear'
import { useIntegrationsStore } from '@/stores/integrations'
import { retryRecordQueue, setOfflineState } from '@/services/recordQueue'
import { useSettingsStore } from '@/stores/settings'
export default function useCommonConfig() {
  //vars
  const route = useRoute()
  const router = useRouter()
  const display = useDisplay()
  const dataStore = useDataStore()
  const pagesStore = usePagesStore()
  const navigationStore = useNavigationStore()
  const integrationsStore = useIntegrationsStore()
  const widgetsStore = useWidgetsStore()
  const userStore = useUserStore()
  const linearStore = useLinearStore()
  const settingsStore = useSettingsStore()
  const isOffline = ref(false)

  const { userId } = storeToRefs(userStore)

  // computed
  const isMobile = computed(
    () => isPhone() || (display.smAndDown.value && !isTablet()) //(isTablet() && isPortrait()) ||
  )
  const isNotMobile = computed(() => !isPhone() && !display.smAndDown.value)

  const selectedLayout = computed(() => {
    // device detection for phones and tablets
    if (isPhone()) return 'phone'

    if (isTablet()) {
      return 'tablet'
    }
    // if (isTablet() && isPortrait()) return 'phone'

    // if (isTablet() && !isPortrait()) return 'desktop'

    // if it's not a mobile device they we'll just use breakpoints
    if (display.smAndDown.value) {
      return 'phone'
    } else return 'desktop'
    // else if (display.md.value) {
    //   return 'tablet' // no need?
    // } else return 'desktop'
  })
  // const isOffline = computed(() => isOff())

  const offlineStatus = status => {
    setOfflineState(status)
    isOffline.value = status
  }
  /* not ready yet
  watch(
    () => userId.value,
    newVal => {
      if (newVal) settingsStore.subscribeSettings(newVal)
    }
  )*/

  onMounted(() => {
    // this is for the initial load
    offlineStatus(!window.navigator.onLine)

    window.addEventListener('offline', e => {
      offlineStatus(true)
      console.log('offline ', e)
      dataStore.hasTriedQueue = false
    })

    window.addEventListener('online', async e => {
      offlineStatus(false)
      console.log('online ', e)
      //retryRecordQueue()
      if (!dataStore.hasTriedQueue) {
        // Prevent repeated events from attempting repeated data submissions
        dataStore.hasTriedQueue = true
        dataStore.retryRecordQueue()
      }
    })

    // settingsStore.subscribeSettings(userId.value) // coming later
  })

  return {
    route,
    router,
    display,
    dataStore,
    pagesStore,
    navigationStore,
    widgetsStore,
    integrationsStore,
    userStore,
    linearStore,
    settingsStore,
    isMobile,
    isNotMobile,
    isAndroid,
    isPhone,
    isIPhone,
    isIPhoneSE,
    isPWA,
    isTablet,
    isPortrait,
    isOffline,
    selectedLayout,
  }
}
