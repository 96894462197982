import { useDataStore } from '@/stores/data'
import { usePagesStore } from '@/stores/pages'
import { useUserStore } from '@/stores/user'
import { useWidgetsStore } from '@/stores/widgets'
import { Timestamp } from 'firebase/firestore'

let isInitialized = false
let widgetStore: null | ReturnType<typeof useWidgetsStore> = null
let pageStore: null | ReturnType<typeof usePagesStore> = null
let userStore: null | ReturnType<typeof useUserStore> = null
let dataStore: null | ReturnType<typeof useDataStore> = null

function initStores() {
  if (isInitialized) {
    return
  }
  isInitialized = true
  widgetStore = useWidgetsStore()
  pageStore = usePagesStore()
  userStore = useUserStore()
  dataStore = useDataStore()
}

export function lookupFSCollectionName(recordsetName: string): string | null {
  initStores()
  if (recordsetName === 'logs') return 'logs'
  return (dataStore as any).getFSCollectionName(recordsetName)
}

/**
 * Performs substitutions on a dialog title, or leaves it unaltered if no valid
 * substitution strings are found
 * @param title The input text of a dialog
 * @returns The dialog text with any substitution text replaced
 */
export function lookupTitle(title: string): string {
  initStores()
  let formattedTitle = title
  if (formattedTitle.includes('{recordLabel}')) {
    let recordLabel = 'Record'
    if (
      (pageStore!.currentPage?.properties as any)?.dataSources?.[0]?.titles?.[0]
    ) {
      recordLabel = (pageStore!.currentPage.properties as any).dataSources[0]
        .titles[0]
    }
    formattedTitle = formattedTitle.replaceAll('{recordLabel}', recordLabel)
  }

  if (formattedTitle.includes('{formMode}')) {
    let formMode = ''
    if (
      !widgetStore!.currentForm ||
      (widgetStore?.currentForm as any)?.note?.isNewNote
    ) {
      formMode = 'Add'
    } else if ((widgetStore!.currentForm as any)?.readOnly) {
      formMode = 'View'
    } else if ((widgetStore!.currentForm as any)?.id) {
      formMode = 'Edit'
    } else {
      formMode = 'Add'
    }
    formattedTitle = formattedTitle.replaceAll('{formMode}', formMode)
  }
  return formattedTitle
}

/**
 * Takes the icon text provided to a dialog and returns the proper icon text. If
 * a substitution is needed, it will be made. If no substitution is needed, the
 * text will be returned unaltered.
 * @param iconText The input substitution text for a dialog icon
 * @returns The icon text that the dialog should use
 */
export function lookupIcon(iconText: string | undefined): string | undefined {
  initStores()
  switch (iconText) {
    case '{formModeIcon}':
      if (
        !(widgetStore?.currentForm as any)?.id ||
        (widgetStore?.currentForm as any)?.note?.isNewNote
      ) {
        return 'mdi-plus'
      }
      return (widgetStore?.currentForm as any)?.readOnly
        ? 'mdi-eye'
        : 'mdi-pencil'
    default:
      return iconText ?? undefined
  }
}

/**
 * Finds the name of the field that a given recordset uses as a primary key
 * @param recordsetName The name of the recordset whose key field to look up
 * @returns The name of the field on which the given recordset keys
 */
export function lookupRecordsetKey(
  recordsetName: string | undefined
): string | null {
  if (!recordsetName) return null
  const fsCollectionName = lookupFSCollectionName(recordsetName)
  switch (fsCollectionName ?? recordsetName) {
    case 'issues': // deliberate fallthrough
    case 'issues1':
      return 'issueId'
    case 'lists':
      return 'listId'
    case 'notes':
    case 'notes1':
      return 'noteId'
    case 'inspections':
    case 'inspections1':
    case 'inspections2':
    case 'inspections3':
    case 'inspections4':
    case 'inspections5':
    case 'inspections6':
    case 'inspections7':
    case 'inspections8':
      return 'inspectionId'
    case 'inspectionActions':
      return 'actionId'
    case 'inspectionSignoff':
      return 'signoffId'
    case 'observations':
    case 'observations1':
      return 'observationId'
    case 'mapElements':
    case 'rails':
      return 'railId'
    case 'logs':
      return 'logId'
    default:
      console.error(`Couldn't find a key for recordset ${recordsetName}`)
      return lookupFirestoreProps(recordsetName)?.key ?? null
  }
}

/**
 * Returns the id of a given record given the record and the name of its
 * recordset. Returns null if unable to supply the id.
 * @param record The record whose id to retrieve
 * @param recordsetName The name of the recordset the record is from
 * @returns The id of the given record, or null if no id is found
 */
export function getId(record: any, recordsetName: string): string | null {
  const keyProp = lookupRecordsetKey(recordsetName)
  if (!keyProp) return null
  return record[keyProp]
}

export function lookupRecordType(recordsetName: string): string | null {
  return { issues: 'Issue', issues1: 'Issue' }[recordsetName] ?? null
}

/**
 * Retrieves the name to use for a record or multiple records from a given
 * recordset
 * @param recordsetName The name of the recordset for which to look up the name
 * of a record
 * @param plural Whether or not the returned name should be plural
 * @returns The name of a record of the requested recordset, or `null` if no
 * name is available
 */
export function lookupRecordName(
  recordsetName: string | undefined,
  plural = false
): string | null {
  if (!recordsetName) return null
  return (
    (dataStore as any).recordsetCollectionsMap?.find(
      (rset: { recordsets: string[] }) =>
        rset?.recordsets?.includes(recordsetName) ?? false
    )?.titles?.[plural ? 1 : 0] ?? null
  )
}

export function lookupDefaultValueSubstitution(
  str: string | any,
  initialized = false
): string | any {
  initStores()
  if (typeof str !== 'string') {
    return str
  }
  switch (str) {
    case 'user:displayName':
    case '{user.displayName}':
      return initialized ? '' : userStore!.user.displayName
    case 'user:emailAddress':
    case '{user.emailAddress}':
      return initialized ? '' : (userStore!.user as any).emailAddress
    case '{system.currentDateTime}':
      return initialized ? null : Timestamp.fromDate(new Date())
    default:
      return str
  }
}

/**
 * Returns an object with the key and properties of documents in the specified
 * firestore collection
 * @param recordset the name of a recordset to look up props for
 * @returns An object with two properties: `key`, which is the name of the
 * property that serves as the record's key, and `props`, which is a list of
 * strings that the record will have as properties
 */
export function lookupFirestoreProps(
  recordset: string
): { key: string; props: string[] } | undefined {
  const keysObj: { [key: string]: { key: string; props: string[] } } = {
    mapElements: {
      key: 'mapElementId',
      props: [],
    },
    inspections1: {
      key: 'inspectionId',
      props: [
        'inspectionId',
        'status',
        'dateTime',
        'inspector',
        'supervisor',
        'geograpicalLocation',
        'typeOfPMI',
        'workOrderNumber',
        'sSRMNumber',
        'inspectCleanOutsideOfSSRM',
        'inspectCleanOutsideOfSSRM_Answer',
        'examineCoordinatorPCBDisplay',
        'inspectLooseHardware',
        'inspectLooseHardware_Answer',
        'inspectLocks',
        'inspectLocks_Answer',
        'inspectCSCTransportAssembly',
        'inspectCSCTransportAssembly_Answer',
        'inspectTHMPowerSupply',
        'inspectTHMPowerSupply_Answer',
        'inspectCleanTestPrinter',
        'inspectCleanTestPrinter_Answer',
        'inspectCleanCoinVaultSensor',
        'inspectCleanCoinVaultSensor_Answer',
        'inspectCleanEscrowSensor',
        'inspectCleanEscrowSensor_Answer',
        'inspectCleanCoinAcceptor',
        'inspectCleanCoinAcceptor_Answer',
        'inspectCleanCoinDispensers',
        'inspectCleanCoinDispensers_Answer',
        'inspectCleanBillHandlingUnit',
        'inspectCleanBillHandlingUnit_Answer',
        'verifySmartstripReader',
        'verifySmartstripReader_Answer',
        'testPowerSupply',
        'testPowerSupply_Answer',
        'turnOffPower',
        'turnOffPower_Answer',
        'cleanGeneralCabinetInterior',
        'cleanGeneralCabinetInterior_Answer',
        'lubricateDoorLockingBar',
        'lubricateDoorLockingBar_Answer',
        'turnOnPowerAndEnterTestMode',
        'turnOnPowerAndEnterTestMode_Answer',
        'verifyHeaterOperational',
        'verifyHeaterOperational_Answer',
        'performSSRMMachineTest',
        'performSSRMMachineTest_Answer',
        'enterRevenueMode',
        'enterRevenueMode_Answer',
        'closeAndLockFrontDoor',
        'closeAndLockFrontDoor_Answer',
        'comments',
        'photo',
        'techSignature',
      ],
    },
    inepections2: {
      key: 'inspectionId',
      props: [
        'auditLocation',
        'date',
        'jobDescription',
        'employeeName',
        'employeeNumber',
        'employeeCallNumber',
        'supervisorName',
        'group',
        'region',
        'safetyHelmetHardhat',
        'eyeProtection',
        'ehCtSafetyBoots',
        'approvedHighVisibility',
        'reliableWatch',
        'metroId',
        'hood',
        'longSleveShirt',
        'jacket',
        'pants',
        'hearingProtections',
        'multimeterSerialNumber',
        'multimeterCalibrationDueDate',
        'radioSerialNumber',
        'radioCalibrationDueDate',
        'voltageAwarenessDeviceSerialNumber',
        'voltageAwarenessDeviceCalibrationDueDate',
        'class0RubberGlovesSerialNumber',
        'class0RubberGlovesTestDate',
        'class4RubberGlovesSerialNumber',
        'class4RubberGlovesTestDate',
        'leatherProtection',
        'documents',
        'nonRevenueVehicle',
        'operationalCondition',
        'freeExtinguisher',
        'firstAidKit',
        'cleanliness',
        'shunts',
        'mats',
        'eFlares',
        'wDisk',
        'WASD1SerialNumber',
        'WASD1CalibrationDueDate',
        'WASD2SerialNumber',
        'WASD2CalibrationDueDate',
        'WASD3SerialNumber',
        'WASD3CalibrationDueDate',
        'compliant',
        'comment',
        'photo',
        'auditorName',
        'auditorSignature',
      ],
    },
    inspections3: {
      key: 'inspectionId',
      props: [
        'auditLocationLine',
        'location',
        'shift',
        'auditDateTime',
        'safetyContactReadAndDiscussed',
        'cooValuesContactReadAndDiscussed',
        'rwpCardinalRulesReviewed',
        'rwpProtectionLevelsReviewed',
        'crewParticipation',
        'conductedBy',
        'isFinished',
        'signature',
      ],
    },
    inspections4: {
      key: 'inspectionId',
      props: [
        'dateTime',
        'station',
        'room',
        'maximoWorkOrder',
        'inspector',
        'entryDoor',
        'entryDoorComment',
        'lighting',
        'lightingComment',
        'fireExtinguishers',
        'fireExtinguishersComment',
        'phone',
        'phoneComment',
        'blockTag',
        'blockTagComment',
        'logBook',
        'lastInspectionDateTime',
        'unitNumber1',
        'unitNumber2',
        'SUPVInspectionDateTime',
        'unitNumber3',
        'unitNumber4',
        'logBookComment',
        'fldFirstAid',
        'firstAidComment',
        'oilyWasteCan',
        'oilyWasteCanComment',
        'fldEyewashStation',
        'permanentEyewash',
        'eyewashRefillExpiration',
        'eyewashRefillExpirationCap',
        'fldEyewashStationComment',
        'spillKit',
        'spillKitComment',
        'distilledWater',
        'distilledWaterComment',
        'ventilationEquipment',
        'ventilationEquipmentComment',
        'ladders',
        'laddersComment',
        'floor',
        'floorComment',
        'interiorEquipment',
        'fldInteriorEquipmentComment',
        'ETSCabinet',
        'ETSCabinetComment',
        'exteriorEquipment',
        'exteriorEquipmentComment',
        'requiredItems',
        'requiredItemsComment',
        'additionalComments',
        'isFinished',
        'inspectorSignature',
        'reviewerSignature',
      ],
    },
    inspections5: {
      key: 'inspectionId',
      props: [
        'auditLocationLine',
        'auditLocation',
        'inspectionDatetime',
        'RWICName',
        'RWICEmployeeNumber',
        'RWICCellPhoneNumber',
        'RWICCallNumber',
        'RWPDateTime',
        'RID',
        'typeOfProtection',
        'workAssignment',
        'safetyBriefing',
        'WMATAID',
        'properFlashlight',
        'whistle',
        'safetyHelmet',
        'eyeProtction',
        'EHCTFootwear',
        'hiVisApparel',
        'reliableWatch',
        'roadwayAccessGuide',
        'MSRHP',
        'radioSN',
        'radioCalInspDateTime',
        'VATHotstickSN',
        'highVoltageGloves',
        'highVoltageGlovesCalInspDateTime',
        'lowVoltageGloves',
        'lowVoltageGlovesCalInspDateTime',
        'shuntsInstalledCorrectly',
        'reflectiveMatsPlacedCorrectly',
        'redLanternsEFlaresPlacedCorrectly',
        'WDiskUsedCorrectly',
        'AmberLanternsEFlaresPlacedCorrectly',
        'OrangeFlagAMFPlacedCorrectly',
        'WSAD1SN',
        'WSAD1CalInspDateTime',
        'WSAD2SN',
        'WSAD2CalInspDateTime',
        'WSAD3SN',
        'WSAD3CalInspDateTime',
        'WSAD4SN',
        'WSAD4CalInspDateTime',
        'WSAD5SN',
        'WSAD5CalInspDateTime',
        'WSAD6SN',
        'WSAD6CalInspDateTime',
        'employee1Name',
        'employee1SafetyVest',
        'employee1WMATAID',
        'employee1ProperFlashlight',
        'employee1SafetyHelmet',
        'employee1VisorSafetyGlasses',
        'employee1SafetyShoes',
        'employee1Comments',
        'isThereEmployee2',
        'employee2Name',
        'employee2SafetyVest',
        'employee2WMATAID',
        'employee2ProperFlashlight',
        'employee2SafetyHelmet',
        'employee2VisorSafetyGlasses',
        'employee2SafetyShoes',
        'employee2Comments',
        'isThereEmployee3',
        'employee3Name',
        'employee3SafetyVest',
        'employee3WMATAID',
        'employee3ProperFlashlight',
        'employee3SafetyHelmet',
        'employee3VisorSafetyGlasses',
        'employee3SafetyShoes',
        'employee3Comments',
        'isThereEmployee4',
        'employee4Name',
        'employee4SafetyVest',
        'employee4WMATAID',
        'employee4ProperFlashlight',
        'employee4SafetyHelmet',
        'employee4VisorSafetyGlasses',
        'employee4SafetyShoes',
        'employee4Comments',
        'isThereEmployee5',
        'employee5Name',
        'employee5SafetyVest',
        'employee5WMATAID',
        'employee5ProperFlashlight',
        'employee5SafetyHelmet',
        'employee5VisorSafetyGlasses',
        'employee5SafetyShoes',
        'employee5Comments',
        'isThereEmployee6',
        'employee6Name',
        'employee6SafetyVest',
        'employee6WMATAID',
        'employee6ProperFlashlight',
        'employee6SafetyHelmet',
        'employee6VisorSafetyGlasses',
        'employee6SafetyShoes',
        'employee6Comments',
        'isThereEmployee7',
        'employee7Name',
        'employee7SafetyVest',
        'employee7WMATAID',
        'employee7ProperFlashlight',
        'employee7SafetyHelmet',
        'employee7VisorSafetyGlasses',
        'employee7SafetyShoes',
        'employee7Comments',
        'isThereEmployee8',
        'employee8Name',
        'employee8SafetyVest',
        'employee8WMATAID',
        'employee8ProperFlashlight',
        'employee8SafetyHelmet',
        'employee8VisorSafetyGlasses',
        'employee8SafetyShoes',
        'employee8Comments',
        'comments',
        'auditorName',
        'isFinished',
        'auditorSignature',
      ],
    },
    inspections6: {
      key: 'inspectionId',
      props: [
        'auditorName2',
        'inspectionDatetime',
        'material1Compliant',
        'material1Item',
        'material1PartNumber',
        'material1Location',
        'material1QuantityLastReported',
        'material1QuantityVerified',
        'hasMaterial2',
        'material2Compliant',
        'material2Item',
        'material2PartNumber',
        'material2Location',
        'material2QuantityLastReported',
        'material2QuantityVerified',
        'hasMaterial3',
        'material3Compliant',
        'material3Item',
        'material3PartNumber',
        'material3Location',
        'material3QuantityLastReported',
        'material3QuantityVerified',
        'hasMaterial4',
        'material4Compliant',
        'material4Item',
        'material4PartNumber',
        'material4Location',
        'material4QuantityLastReported',
        'material4QuantityVerified',
        'hasMaterial5',
        'material5Compliant',
        'material5Item',
        'material5PartNumber',
        'material5Location',
        'material5QuantityLastReported',
        'material5QuantityVerified',
        'hasMaterial6',
        'material6Compliant',
        'material6Item',
        'material6PartNumber',
        'material6Location',
        'material6QuantityLastReported',
        'material6QuantityVerified',
        'hasMaterial7',
        'material7Compliant',
        'material7Item',
        'material7PartNumber',
        'material7Location',
        'material7QuantityLastReported',
        'material7QuantityVerified',
        'hasMaterial8',
        'material8Compliant',
        'material8Item',
        'material8PartNumber',
        'material8Location',
        'material8QuantityLastReported',
        'material8QuantityVerified',
        'hasMaterial9',
        'material9Compliant',
        'material9Item',
        'material9PartNumber',
        'material9Location',
        'material9QuantityLastReported',
        'material9QuantityVerified',
        'hasMaterial10',
        'material10Compliant',
        'material10Item',
        'material10PartNumber',
        'material10Location',
        'material10QuantityLastReported',
        'material10QuantityVerified',
        'comments',
        'auditorName',
        'isFinished',
        'auditorSignature',
        'MATCONName',
        'MATCONSignature',
      ],
    },
    inspections7: {
      key: 'inspectionId',
      props: [
        'auditLocationLine',
        'auditLocation',
        'maximoWorkOrder',
        'inspectionDatetime',
        'employee1Name',
        'employee1Number',
        'employee1Grade',
        'employee2Name',
        'employee2Number',
        'employee2Grade',
        'employee3Name',
        'employee3Number',
        'employee3Grade',
        'preventativeMaintenance',
        'preventativeMaintenanceProcedure',
        'preventativeMaintenanceComments',
        'requiredTools',
        'requiredToolsComments',
        'safetyBriefings',
        'safetyBriefingsComments',
        'PPEUtilized',
        'PPEUtilizedComments',
        'testEquipment',
        'testEquipment1',
        'testequipment1CalibrationDateTime',
        'testEquipment2',
        'testequipment2CalibrationDateTime',
        'testEquipment3',
        'testequipment3CalibrationDateTime',
        'testEquipment4',
        'testequipment4CalibrationDateTime',
        'testEquipment5',
        'testequipment5CalibrationDateTime',
        'testEquipment6',
        'testequipment6CalibrationDateTime',
        'comments',
        'auditorName',
        'isFinished',
        'auditorSignature',
      ],
    },
    issues: {
      key: 'issueId',
      props: [
        'assetCategory',
        'barcode',
        'createDateTime',
        'createUserId',
        'departmentCategory',
        'description',
        'importance',
        'issueId',
        'location',
        'picture1',
        'picture2',
        'picture3',
        'recordStamp',
        'reportedByEmail',
        'reportedByName',
        'reportedDateTime',
        'stationName',
        'status',
        'updateDateTime',
        'updateUserId',
      ],
    },
    logs: {
      key: 'logId',
      props: [
        'recordStamp',
        'createDateTime',
        'createUserId',
        'updateDateTime',
        'updateUserId',
        'deletedFlag',
        'clientId',
        'moduleId',
        'roleId',
        'action',
        'result',
        'details',
        'logId',
      ],
    },
    notes: {
      key: 'noteId',
      props: [
        'note',
        'createUserId',
        'updateUserId',
        'noteId',
        'updateDateTime',
        'parentType',
        'parentId',
        'recordStamp',
        'createDateTime',
      ],
    },
    settings: {
      key: 'settingsId',
      props: [
        'settingsId',
        'recordStamp',
        'createDateTime',
        'createUserId',
        'updateDateTime',
        'updateUserId',
        'pageId',
        'name',
        'isDefault',
        'widgets',
      ],
    },
  }

  return (
    keysObj?.[recordset] ??
    keysObj?.[lookupFSCollectionName(recordset) ?? recordset]
  )
}
