<script setup>
import { ref, computed, useSlots, onMounted } from 'vue'
import { useDisplay } from 'vuetify'
import { storeToRefs } from 'pinia'
import { usePagesStore } from '@/stores/pages'
import { useNavigationStore } from '@/stores/navigation'
import { useUserStore } from '@/stores/user'
import { useRouter } from 'vue-router'
import useCommonConfig from '@/composables/useCommonConfig'

import { lookupTitle, lookupIcon } from '@/services/lookup'
import { isPhone } from '@/services/deviceDetection.ts'

//vars
const {
  isTablet,
  isMobile,
  isNotMobile,
  display,
  navigationStore,
  pagesStore,
  widgetsStore,
  userStore,
  selectedLayout,
  isPortrait,
} = useCommonConfig()

const {
  hasRightDrawer,
  hasModal,
  showModal,
  modalProperties,
  toggleLeftDrawer,
} = storeToRefs(navigationStore)
const router = useRouter()
const showConfirmCloseModal = ref(false)

// const toggleLeftDrawer = ref(false)
// const toggleRightDrawer = ref(false)
// const toggleModal = ref(false)

// method
const percentToPx = val => {
  let newVal = parseFloat(String(val).replace('%', '')) / 100
  return window.innerWidth * newVal
}

// computed
const showLeftDrawer = computed(() => {
  return pagesStore.areas.leftDrawer.some(item =>
    Object.keys(display)
      .find(key => display[key].value === true)
      .includes(item.breakPoint)
  )
})

const showBody = computed(() => {
  return pagesStore.areas.body.some(item =>
    Object.keys(display)
      .find(key => display[key].value === true)
      .includes(item.breakPoint)
  )
})

const showRightDrawer = computed(() => {
  return pagesStore.areas.rightDrawer.some(item =>
    Object.keys(display)
      .find(key => display[key].value === true)
      .includes(item.breakPoint)
  )
})

const renderModal = computed(() => {
  return pagesStore.areas.modal.some(item =>
    Object.keys(display)
      .find(key => display[key].value === true)
      .includes(item.breakPoint)
  )
})

const leftDrawerProperties = computed(() => {
  let returnVal
  pagesStore.areas.leftDrawer.forEach(item => {
    if (
      Object.keys(display).find(key => display[key].value === true) ===
      item.breakPoint
    )
      returnVal = item // percentToPx(item.width)
  })

  return returnVal
})

const rightDrawerProperties = computed(() => {
  let returnVal
  pagesStore.areas.rightDrawer.forEach(item => {
    if (
      Object.keys(display).find(key => display[key].value === true) ===
      item.breakPoint
    )
      returnVal = item // percentToPx(item.width)
  })

  return returnVal
})

const bodyWidth = computed(() => {
  let returnVal
  pagesStore.areas.body.forEach(item => {
    if (
      Object.keys(display).find(key => display[key].value === true) ===
      item.breakPoint
    )
      returnVal = item.width
  })

  return returnVal
})

const toggleModal = computed(() => {
  // note you may want to get the devuce breakpoint
  return hasModal.value && showModal.value
})

const toggleRightDrawer = computed(() => {
  return hasRightDrawer.value && showModal.value
})

function showConfirmOrCloseModal() {
  if (modalProperties.value.confirmCloseOptions) {
    showConfirmCloseModal.value = true
  } else {
    showModal.value = false
  }
}

// pagesStore.areas.leftDrawer.length >

onMounted(() => {
  // reset open
  toggleLeftDrawer.value = false
  showModal.value = false
})
</script>

<template>
  <v-container fluid class="py-0 py-md-4 px-0">
    <v-row
      no-gutters
      :class="[
        { 'mx-5': !isPhone() && !display.smAndDown.value },
        {
          'mr-md-0':
            router.currentRoute.value.params.slug === '' ||
            !router.currentRoute.value.params.slug,
        },
      ]"
    >
      <v-navigation-drawer
        v-if="pagesStore.areas.leftDrawer.length > 0 && showLeftDrawer"
        temporary
        :color="leftDrawerProperties?.backgroundColor"
        class="pt-10"
        v-model="navigationStore.toggleLeftDrawer"
        :width="percentToPx(leftDrawerProperties?.width)"
        touchless
      >
        <v-btn
          variant="plain"
          icon="mdi-close"
          @click.prevent="navigationStore.toggleLeftDrawer = false"
          style="position: absolute; top: 0; right: 0"
        />
        <slot name="leftDrawer" />
      </v-navigation-drawer>
      <section v-if="pagesStore.areas.body.length > 0" class="w-100">
        <div class="d-flex justify-space-between">
          <div
            v-if="pagesStore.areas.leftDrawer.length > 0 && showLeftDrawer"
            :class="[{ 'ma-md-3': !isPhone() }]"
          >
            <!-- <v-btn
              @click.prevent="toggleLeftDrawer = !toggleLeftDrawer"
              icon="mdi-format-list-bulleted"
              class="rounded"
              style=""
            >
            </v-btn> -->
          </div>
          <Teleport to="#breadcrumbs" v-if="isNotMobile">
            <div
              v-if="
                $vuetify.display.mdAndUp &&
                pagesStore.currentPage.properties?.tools?.length > 0
              "
              class="px-5 py-1 w-100 d-flex align-center bg-grey-lighten-3 border-top border-bottom"
              style="border-color: #bdbdbd"
            >
              <slot name="toolbar" />
            </div>
          </Teleport>
          <!-- <div
            v-if="pagesStore.areas.rightDrawer.length > 0 && showRightDrawer"
            class="ma-3"
          >
            <v-btn
              @click.prevent="toggleRightDrawer = !toggleRightDrawer"
              icon="mdi-format-list-bulleted"
              class="rounded"
            >
            </v-btn>
          </div>-->
        </div>
        <div v-if="pagesStore.areas.body.length > 0">
          <div :style="`width: ${bodyWidth}`">
            <slot name="body" />
          </div>
        </div>
      </section>

      <v-navigation-drawer
        v-if="pagesStore.areas.rightDrawer.length > 0 && showRightDrawer"
        temporary
        location="right"
        :color="rightDrawerProperties?.backgroundColor"
        v-model="toggleRightDrawer"
        :width="percentToPx(rightDrawerProperties?.width)"
        touchless
      >
        <!--<div :style="{ position: 'absolute', top: 0, left: 5 }">
          <div class="d-flex">
            <v-btn
              variant="plain"
              icon="mdi-close"
              @click.prevent="showModal = false"
            />
            <h2
              v-if="navigationStore.modalProperties.title"
              class="text-center"
              :style="{ width: '100%' }"
            >
              <v-icon
                v-if="navigationStore.modalProperties?.icon"
                :icon="navigationStore.modalProperties?.icon"
                class="pr-2"
              />
              {{ navigationStore.modalProperties.title }}
            </h2>
          </div>
        </div>-->
        <div>
          <slot name="rightDrawer" />
        </div>
        <footer
          id="rightDrawerFooter"
          class="d-flex align-center bg-primary w-100"
          style="
            height: 72px;
            position: fixed;
            bottom: 0;
            width: 100%;
            z-index: 1000;
          "
        ></footer>
      </v-navigation-drawer>
    </v-row>
    <div>
      <!-- to be determined where the activator is -->
      <v-dialog v-model="toggleModal" width="800">
        <v-card>
          <!-- class="pt-5 px-5" -->
          <v-card-title
            class="px-0 pt-0 d-flex justify-end"
            style="color: white; background-color: rgb(70, 130, 45)"
          >
            <h2 v-if="navigationStore.modalProperties?.title" class="pl-4 pt-4">
              <v-icon
                v-if="navigationStore.modalProperties?.icon"
                :icon="navigationStore.modalProperties?.icon"
                class="pr-2"
              />
              {{ navigationStore.modalProperties?.title }}
            </h2>
            <v-spacer />
            <v-btn
              v-if="modalProperties.close"
              icon="mdi-close"
              variant="text"
              elevation="0"
              @click="showConfirmOrCloseModal()"
              color="white"
            ></v-btn>
          </v-card-title>
          <v-sheet style="height: 20px; width: 100%"></v-sheet>
          <v-card-text
            class="px-5 py-0"
            :style="{ 'max-height': '70vh', 'overflow-y': 'auto' }"
          >
            <slot name="modal" />
            <!-- {{ pagesStore.areas.modal }} -->
          </v-card-text>
          <div v-if="modalProperties.footer">
            <v-divider />
            <v-card-actions id="modalFooter" class="px-5" />
          </div>
        </v-card>
      </v-dialog>
    </div>

    <v-expansion-panels
      v-if="pagesStore.currentPage.properties.showDebug"
      class="px-5"
    >
      <v-expansion-panel
        title="Debug Data"
        expand-icon="mdi-chevron-down"
        collapse-icon="mdi-chevron-up"
      >
        <v-expansion-panel-text>
          <table>
            <tr
              class="d-flex"
              style="border: 1px solid black; background-color: blanchedalmond"
            >
              <td style="border: 1px solid black; background-color: cadetblue">
                Page ID:
              </td>
              <td class="px-5">
                <div>
                  <span
                    v-if="
                      router.currentRoute.value.params.slug &&
                      navigationStore.getPageId(
                        router.currentRoute.value.params.slug
                      )
                    "
                  >
                    {{
                      navigationStore.getPageId(
                        router.currentRoute.value.params.slug
                      )
                    }}
                  </span>
                  <span v-else>{{
                    navigationStore.getMainMenuItems[0]?.pageId
                  }}</span>
                </div>
              </td>
            </tr>
            <tr
              class="d-flex"
              style="border: 1px solid black; background-color: blanchedalmond"
            >
              <td style="border: 1px solid black; background-color: cadetblue">
                Current Client:
              </td>
              <td class="px-5">
                {{ userStore.currentClient }}
              </td>
            </tr>
            <tr
              class="d-flex"
              style="border: 1px solid black; background-color: blanchedalmond"
            >
              <td style="border: 1px solid black; background-color: cadetblue">
                Current Module:
              </td>
              <td class="px-5">
                {{ userStore.currentModule }}
              </td>
            </tr>
            <tr
              class="d-flex"
              style="border: 1px solid black; background-color: blanchedalmond"
            >
              <td style="border: 1px solid black; background-color: cadetblue">
                Current Role:
              </td>
              <td class="px-5">
                {{ userStore.currentRole }}
              </td>
            </tr>
            <tr
              class="d-flex"
              style="border: 1px solid black; background-color: blanchedalmond"
            >
              <td style="border: 1px solid black; background-color: cadetblue">
                Menu ID:
              </td>
              <td class="px-5">
                {{ userStore.currentRole.menuId }}
              </td>
            </tr>
          </table>
        </v-expansion-panel-text>
      </v-expansion-panel>
    </v-expansion-panels>
  </v-container>
  <v-dialog v-model="showConfirmCloseModal" width="auto">
    <v-card>
      <v-card-text
        v-html="
          navigationStore.modalProperties?.confirmCloseOptions?.html ??
          'Are you sure you want to close this modal?'
        "
      >
      </v-card-text>
      <v-card-actions>
        <div class="d-flex flex-row" style="width: 100%">
          <v-spacer />
          <v-btn
            class="text-capitalize"
            color="#B5B5B5"
            variant="flat"
            @click="
              () => {
                showModal = false
                showConfirmCloseModal = false
              }
            "
          >
            Yes
          </v-btn>
          <v-btn
            class="text-capitalize"
            variant="flat"
            color="primary"
            @click="showConfirmCloseModal = false"
          >
            No
          </v-btn>
        </div>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
