import { storeToRefs } from 'pinia'

// stores
import { useDataStore } from '@/stores/data'
import { usePagesStore } from '@/stores/pages'
export default function useWidgetConfig() {
  const dataStore = useDataStore()
  const pagesStore = usePagesStore()
  const { recordsets } = storeToRefs(dataStore)
  const readRecordsets = recordset => {
    return recordsets.value.find(rs => {
      return Object.prototype.hasOwnProperty.call(
        rs,
        Object.keys(rs).find(k => k === recordset)
      )
    })
  }
  return {
    dataStore,
    pagesStore,
    readRecordsets,
  }
}
