import signatureBackground from '@/assets/img/util/signature_background.png'
import { base64ImageToBlob, base64ImageToFile } from './utils'

let signatureBackgroundURL: string | undefined

export function getSignatureBackgroundURL(): string {
  if (!signatureBackgroundURL)
    signatureBackgroundURL = URL.createObjectURL(
      base64ImageToBlob(signatureBackground)
    )
  return signatureBackgroundURL
}

export function getSignatureBackgroundFile(): File {
  return base64ImageToFile(
    signatureBackground,
    `signature-${new Date().getTime()}.png`
  )
}
