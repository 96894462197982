<script setup>
import { defineEmits, defineProps, computed } from 'vue'
import useCommonConfig from '@/composables/useCommonConfig'
import SystemMenu from '@/components/SystemMenu.vue'
import UserMenu from '@/components/UserMenu.vue'
import ModuleMenu from './ModuleMenu.vue'
import mtaLogo from '@/assets/img/client/mta_logo.png'
const { display, isPhone, navigationStore } = useCommonConfig()
// pinia
import MainMenu from './MainMenu.vue'
import { useUserStore } from '@/stores/user'

// vars
const userStore = useUserStore()
const emits = defineEmits(['toggleNav'])
const props = defineProps({ isDrawerOpen: Boolean })

const isAuthenticated = computed(() => userStore.isAuthenticated)
const clientLogo = computed(() => userStore.currentClient?.logo)
const moduleLogo = computed(() => userStore.currentModule?.logo)
const showModuleMenu = computed(
  () => userStore.currentClient?.modules.length > 1
)
</script>

<template>
  <v-app-bar
    fixed
    flat
    app
    color="primary"
    height="54"
    :class="[
      'nav-top-bar__root',
      { 'pl-5': !isPhone() && display.mdAndUp },
      { 'pr-5': !showModuleMenu },
    ]"
  >
    <!-- <v-app-bar-nav-icon
      v-if="
        isAuthenticated &&
        $vuetify.display.mdAndDown &&
        navigationStore.getMainMenuItems.length > 0
      "
      :icon="props.isDrawerOpen ? 'mdi-close' : 'mdi-menu'"
      class="align-self-center"
      @click.stop="emits('toggleNav')"
    /> -->
    <system-menu
      v-if="
        isAuthenticated && userStore.user.isValidated && !display.lgAndUp.value
      "
      :menu-items="navigationStore.getMainMenuItems"
      :client="userStore.getCurrentClientName"
      :module="userStore.getCurrentModuleName"
      mode="dropdown"
    />
    <div class="d-flex align-center justify-start overflow-hidden">
      <!-- Logos -->
      <!-- our logo  -->
      <img
        :src="mtaLogo"
        class="mr-2"
        style="
          max-height: 34px;
          height: 100%;
          max-width: 110px;
          width: 100%;
          object-fit: cover;
        "
      />
      <!-- Client Logo -->
      <!-- TODO: images sometimes disappear -->
      <v-img
        v-if="isAuthenticated && userStore.user.isValidated"
        :src="`/img/client/${clientLogo}.png`"
        width="36"
        max-height="36"
        max-width="36"
        class="mr-2"
      />
      <!-- module logo -->
      <img
        v-if="
          display.mdAndUp &&
          isAuthenticated &&
          userStore.user.isValidated &&
          moduleLogo
        "
        :src="`/img/module/${moduleLogo}.png`"
        class="mr-2"
        style="
          max-height: 36px;
          height: 100%;
          max-width: 110px;
          width: 100%;
          object-fit: cover;
        "
      />
    </div>
    <!-- main menu -->
    <main-menu v-if="isAuthenticated && userStore.user.isValidated" />

    <v-spacer />
    <v-icon
      v-if="
        isAuthenticated &&
        userStore.user.isValidated &&
        userStore.user.notifications &&
        userStore.user.notifications.length > 0
      "
      icon="mdi-bell-alert-outline"
      color="yellow"
      size="large"
      class="mr-4"
    />

    <user-menu v-if="isAuthenticated && userStore.user.isValidated" />
    <v-btn v-else :to="{ name: 'signIn' }" class="mr-0 text-capitalize">
      <v-avatar color="white" density="compact">
        <v-icon icon="mdi-account" color="grey" size="x-large"></v-icon>
      </v-avatar>
      <span class="ml-2">Sign In</span></v-btn
    >

    <module-menu
      v-if="isAuthenticated && userStore.user.isValidated && showModuleMenu"
    />
  </v-app-bar>
</template>

<style lang="scss" scoped>
@media (orientation: landscape) {
  .notch-left .nav-top-bar__root {
    padding-left: env(safe-area-inset-left);
  }
  .notch-right .nav-top-bar__root {
    padding-right: env(safe-area-inset-right);
  }
}
</style>
