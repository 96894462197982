import { defineStore } from 'pinia'
import {
  collection,
  query,
  onSnapshot,
  getCountFromServer,
  orderBy,
  startAfter,
  limit,
  where,
  getDocs,
} from 'firebase/firestore'

import { firestore, firestoreQuery } from '../services/firebase'
import idb from '@/api/idb'

let unsubPage = () => {
  // initializes the variable
}

const resetArea = () => ({
  leftDrawer: [],
  body: [],
  rightDrawer: [],
  modal: [],
})

const resetCurrentClient = () => ({
  properties: { layouts: [], tools: [] },
})

function deviceTypeToBreakPoints(device) {
  if (device === 'phone') {
    return ['xs', 'sm']
  } else if (device === 'tablet') {
    return ['lg']
  } else {
    return ['lg', 'xl']
  }
}

export const usePagesStore = defineStore('pages', {
  state: () => ({
    pages: [],
    currentPage: resetCurrentClient(),
    areas: resetArea(),
  }),
  getters: {},
  actions: {
    async checkIDBExists() {
      const isExistDB = (await indexedDB.databases())
        .map(db => db.name)
        .includes('MaxTrax')
      console.log('checkIDBExists ', isExistDB)
      if (!isExistDB) idb.getDatabase()

      //getDatabase
    },
    setLayouts() {
      // once currentPage is set...
      this.currentPage.properties.layouts?.forEach(layout => {
        // construct the display conditions
        layout.areas.forEach(area => {
          // TODO: there's a data inconsistency bug in the firestore
          if (area.type === 'leftDrawer' || area.container === 'leftDrawer')
            layout.devices.forEach(device => {
              deviceTypeToBreakPoints(device).forEach(x => {
                this.areas.leftDrawer.push({
                  breakPoint: x,
                  width: area.width,
                })
              })
            })
          // make this more efficient
          if (area.type === 'body' || area.container === 'body')
            layout.devices.forEach(device => {
              deviceTypeToBreakPoints(device).forEach(x => {
                this.areas.body.push({
                  breakPoint: x,
                  width: area.width,
                })
              })
            })

          if (area.type === 'rightDrawer' || area.container === 'rightDrawer')
            layout.devices.forEach(device => {
              deviceTypeToBreakPoints(device).forEach(x => {
                this.areas.rightDrawer.push({
                  breakPoint: x,
                  width: area.width,
                })
              })
            })

          // may not be necessary for modal
          if (area.type === 'modal')
            layout.devices.forEach(device => {
              deviceTypeToBreakPoints(device).forEach(x => {
                this.areas.modal.push({
                  breakPoint: x,
                  width: area.width,
                })
              })
            })
        })
      })
    },
    async getLivePage(pageId) {
      await firestoreQuery(firestore, pageId, {
        coll: 'pages',
        field: 'pageId',
      }).then(res => {
        this.areas = resetArea()
        this.currentPage = res[0]

        this.setLayouts()
      })
    },
    async getCachedPage(pageId) {
      this.checkIDBExists()

      const _pullFirestore = async () => {
        await firestoreQuery(firestore, pageId, {
          coll: 'pages',
          field: 'pageId',
        }).then(async res => {
          this.areas = resetArea()
          this.currentPage = res[0]

          // push to IndexedDB
          idb.addToDatabase('pages', res[0])
          // pull indexedDB to Pinia widgets
          this.pages = await idb.getDataStore('pages')

          this.setLayouts()
        })
      }
      // if indexedDB is empty, pull from firestore
      if (!(await idb.getDataStore('pages'))) _pullFirestore()
      // if pages array is empty pull from indexeddb
      if (this.pages.length === 0) this.pages = await idb.getDataStore('pages')
      // check that the page isn't already stored in Pinia
      if (this.pages.find(p => p.pageId === pageId)) {
        this.areas = resetArea()
        this.currentPage = this.pages.find(p => p.pageId === pageId)
        this.setLayouts()
      } else {
        _pullFirestore()
      }
    },
    subscribePages() {
      // the previous action is purely for retrieving specific data, this one is to keep IndexedDB up-to-date
      const q = query(collection(firestore, 'pages'))
      unsubPage = onSnapshot(q, querySnapshot => {
        querySnapshot.docChanges().forEach(async change => {
          // this.defects = [] // empty first
          // this.defects = [...doc.data()]
          idb.addToDatabase('pages', change.doc.data())
          this.pages = await idb.getDataStore('pages')
        })
      })
    },
    unsubscribePages() {
      unsubPage()
    },
    resetCurrentPage() {
      this.currentPage = resetCurrentClient()
    },
  },
  persist: true,
})
