import { ListItem } from '@/model/List.model'
import useWidgetConfig from '../composables/useWidgetConfig'

let isInitialized = false
let widgetConfig: any = null

function initStores() {
  if (!isInitialized) {
    isInitialized = true
    widgetConfig = useWidgetConfig()
  }
}

/**
 * Retrieves the given list in default order, or returns an error list of one
 * item with an error message in the name if the original list cannot be loaded
 * or is empty.
 * @param listName The name of the list whose items to retrieve
 * @returns A list of `ListItem` objects, sorted according to their `itemIndex`
 */
export function getListItems(listName: string, namesOnly = false): ListItem[] {
  initStores()
  const listRecordset = widgetConfig.readRecordsets('lists')
  let listItems: ListItem[]
  if (listRecordset) {
    listItems = (listRecordset['lists'] as any)
      .find((list: any) => list?.name === listName)
      ?.items?.sort(orderItems)
    if (listItems?.length) {
      return listItems
    }
  }
  // If the recordset is empty or not loaded
  return [
    {
      listId: 0,
      listItemId: 0,
      custom: null,
      description: null,
      name: `Could not find data for list ${listName}`,
      orderIndex: 0,
      recordStamp: 'error',
    },
  ] as ListItem[]
}

/**
 * A comparator function for ordering list items
 * @param a The first item to be compared by orderIndex
 * @param b The second item to be compared by orderIndex
 * @returns A comparator value for ordering
 */
function orderItems(a: ListItem, b: ListItem): number {
  if (a.orderIndex !== b.orderIndex) {
    return a.orderIndex - b.orderIndex
  }
  if (!a?.name || !b?.name) {
    return 0
  }
  return a.name.localeCompare(b.name) // alphabetize
}
