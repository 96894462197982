<script setup>
import { ref, watch, onMounted, onBeforeMount, computed } from 'vue'

import { useRouter } from 'vue-router'
import { useUserStore } from '@/stores/user'
import mtaLogo from '@/assets/img/client/mta_logo.png'
import { useNavigationStore } from '@/stores/navigation'
import { recordLog } from '@/services/logging'
// import { watch } from 'fs'

const router = useRouter()
const userStore = useUserStore()
const navigationStore = useNavigationStore()

const formError = ref('')
const formIsValid = ref(false)
const isSubmittingData = ref(false)
const passwordIsHidden = ref(true)

const username = ref('')
const password = ref('')
const passwordRules = ref([
  v => !!v || 'Password is required',
  v => v.length <= 50 || 'Password must be less than 50 characters',
])
const nameRules = ref([
  v => !!v || 'Email address is required',
  v => /.+@.+/.test(v) || 'This must be a valid email address',
])

const clientUpdated = ref(false)
const moduleUpdated = ref(false)
const roleUpdated = ref(false)

watch(
  [
    () => userStore.currentClient,
    () => userStore.currentModule,
    () => userStore.currentRole,
  ],
  ([newA, newB, newC]) => {
    if (newA && newA.name) clientUpdated.value = true
    if (newB && newB.name) moduleUpdated.value = true
    if (newC && newC.name) roleUpdated.value = true

    if (clientUpdated.value && moduleUpdated.value && roleUpdated.value) {
      navigationStore.subscribeSystem()
      router.push({
        name: 'dynamic',
        params: {
          client: userStore.getCurrentClientName,
          module: userStore.getCurrentModuleName,
        },
      })
    }
  }
)

const fieldsFilled = computed(() => {
  return username.value.length > 0 && password.value.length > 0
})

onBeforeMount(() => {
  if (userStore.getIsAuthenticated) userStore.logOut()
})

onMounted(() => {
  // const id = $refs.username.$el.lastChild.firstChild.firstChild.lastChild.id
})

function handleFirebaseError(error) {
  switch (error.code) {
    case 'auth/email-already-in-use':
      formError.value =
        'An account with this email already exists but could not be accessed.'
      break
    case 'auth/wrong-password':
      formError.value = 'Please enter the correct password for this user.'
      recordLog('Sign In', 'Fail', `The password you entered is incorrect`)

      break
    case 'auth/user-not-found':
      formError.value =
        'No user exists for this email address. If you believe this to be an error, please contact a MaxTrax Administrator.'
      recordLog(
        'Sign In',
        'Fail',
        `The email address you entered is not recognized: (${username.value})`
      )
      break
    case 'auth/account-exists-with-different-credential':
      formError.value =
        'This sign in has not yet been linked to your MaxTrax account. Please sign in with your email and password first, then link this sign-on method when prompted.'
      break
    default:
      formError.value =
        error.message +
        '\nIf this problem persists, please contact a MaxTrax administrator.'
  }
  console.error(error.message)
  isSubmittingData.value = false
}

async function submitForm(e) {
  isSubmittingData.value = true
  await userStore
    .signIn(username.value, password.value)
    .catch(e => {
      handleFirebaseError(e)
      isSubmittingData.value = false
    })
    .then(() => {
      // after signing in, verify they are authenticated
      if (!userStore.isAuthenticated) {
        formError.value =
          'Invalid credentials, please check your email address and password and try again.'
      } else {
        userStore
          .getUserData()
          .then(() => {
            // Everything has gone right, and the user should be navigated away automatically
            recordLog('Sign In', 'Success')
            setTimeout(() => {
              formError.value =
                'The user was signed in successfully but does not have proper permissions to access modules. Please contact a MaxTrax Administrator.'
            }, 3000)
            isSubmittingData.value = false
          })
          .catch(e => {
            handleFirebaseError(e)
            isSubmittingData.value = false
          })
      }
    })
}

async function signInWithMicrosoft() {
  userStore.signInWithMicrosoft().catch(handleFirebaseError)
}
</script>

<template>
  <div class="pa-5">
    <v-card class="login-card ma-auto py-5" :loading="isSubmittingData">
      <!-- <span class="logo text-h5" v-text="'MaxTrax Desktop II'"></span> -->
      <v-img :src="mtaLogo" height="35" />
      <v-alert
        v-if="formError !== ''"
        type="error"
        icon="fa: fas fa-exclamation-triangle"
        class="ma-5"
      >
        {{ formError }}
      </v-alert>
      <v-container>
        <v-row>
          <v-col class="mx-auto" cols="12" sm="6">
            <v-card-title>
              <span v-text="'Sign in to your account'"></span>
            </v-card-title>
            <v-card-subtitle class="mb-6">
              <span v-text="'Enter your credentials below'"></span>
            </v-card-subtitle>
            <v-form
              v-model="formIsValid"
              :readonly="isSubmittingData"
              @submit.prevent="submitForm"
            >
              <v-container>
                <v-row>
                  <v-col>
                    <v-text-field
                      prepend-inner-icon="mdi-account"
                      v-model="username"
                      :rules="nameRules"
                      :validate-on="username ? 'blur' : 'submit'"
                      label="Email Address"
                      variant="outlined"
                      required
                      autofocus
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <v-text-field
                      prepend-inner-icon="mdi-lock"
                      v-model="password"
                      :rules="passwordRules"
                      :validate-on="password ? 'input' : 'submit'"
                      label="Password"
                      variant="outlined"
                      :type="passwordIsHidden ? 'password' : 'text'"
                      :append-inner-icon="
                        passwordIsHidden ? 'mdi-eye-off' : 'mdi-eye'
                      "
                      @click:append-inner="passwordIsHidden = !passwordIsHidden"
                      required
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <v-btn
                      type="submit"
                      color="primary"
                      :disabled="!fieldsFilled || isSubmittingData"
                      :loading="isSubmittingData"
                      prepend-icon="mdi-check"
                      class="d-inline-flex"
                    >
                      Sign In
                    </v-btn>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <v-btn href="/reset-password" variant="plain"
                      >Forgot password?</v-btn
                    >
                  </v-col>
                </v-row>
              </v-container>
            </v-form>
          </v-col>
          <!-- <v-col
            :class="{
              'd-flex': true,
              'flex-column': $vuetify.display.smAndUp,
            }"
            cols="12"
            sm="1"
          >
            <v-divider
              class="ma-auto"
              :vertical="$vuetify.display.smAndUp"
            ></v-divider>
            <p class="d-block ma-1">OR</p>
            <v-divider
              class="ma-auto"
              :vertical="$vuetify.display.smAndUp"
            ></v-divider>
          </v-col>
          <v-col class="ma-auto" cols="12" sm="5">
            <v-btn
              style="color: white"
              type="button"
              color="#2c75e9"
              :disabled="true"
              @click="signInWithMicrosoft"
              prepend-icon="mdi-microsoft"
              class="d-inline-flex"
            >
              Sign In with Microsoft
            </v-btn>
          </v-col> -->
        </v-row>
      </v-container>
    </v-card>
  </div>
</template>

<style scoped>
.login-card {
  max-width: 700px;
  width: 100%;
}
</style>
