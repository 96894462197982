import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'

import DynamicView from '../views/DynamicView.vue'
import NotFoundView from '../views/NotFoundView.vue'
import PrivacyPolicyView from '../views/PrivacyPolicyView.vue'
import SignInView from '@/views/SignInView.vue'
import TermsView from '../views/TermsView.vue'
import ResetPasswordView from '@/views/ResetPasswordView.vue'
import LinkAuthView from '@/views/LinkAuthView.vue'
import LicenseAgreementView from '@/views/LicenseAgreementView.vue'
import ChangePasswordView from '@/views/ChangePasswordView.vue'

import { useUserStore } from '@/stores/user.js'
import { useNavigationStore } from '@/stores/navigation.js'
import { usePagesStore } from '@/stores/pages'
import { recordLog } from '@/services/logging'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/sign-in',
    name: 'signIn',
    component: SignInView,
    meta: {
      title: 'Sign In',
    },
  },
  {
    path: '/404',
    name: 'NotFound',
    component: NotFoundView,
    meta: {
      title: 'Not Found',
    },
  },
  {
    path: '/terms-of-use',
    name: 'terms',
    component: TermsView,
    meta: {
      title: 'Terms of Use',
    },
  },
  {
    path: '/link-auth',
    name: 'linkAuth',
    component: LinkAuthView,
  },
  {
    path: '/privacy-policy',
    name: 'privacy',
    component: PrivacyPolicyView,
    meta: {
      title: 'Privacy Policy',
    },
  },
  {
    path: '/change-password',
    name: 'changePassword',
    component: ChangePasswordView,
    meta: {
      title: 'Change Password',
    },
  },
  {
    path: '/reset-password',
    name: 'resetPassword',
    component: ResetPasswordView,
    meta: {
      title: 'Reset Password',
    },
  },
  {
    path: '/license-agreement',
    name: 'licenseAgreement',
    component: LicenseAgreementView,
  },
  {
    path: '/:client?/:module?/:slug*',
    name: 'dynamic',
    component: DynamicView,
  },
  { path: '/:pathMatch(.*)*', component: NotFoundView },
]

const router = createRouter({
  history: createWebHistory(), // process.env.BASE_URL
  routes,
})

router.beforeEach(to => {
  // sets title via slugs
  if (to.params.slug && typeof to.params.slug === 'object') {
    const capitalize = (word: string) => {
      return word.charAt(0).toUpperCase() + word.slice(1)
    }
    let slugTitle

    for (let i = 0; i < to.params.slug.length; i++) {
      slugTitle = capitalize(to.params.slug[i])
      if (to.params.slug[i].indexOf('-') > -1) {
        const slugArr = to.params.slug[i].split('-')
        const capitalArr: string[] = []
        slugArr.forEach(s => {
          capitalArr.push(capitalize(s))
        })
        slugTitle = capitalArr.join(' ')
      }
    }
    to.meta.title = slugTitle
  }
  const userStore = useUserStore()
  const navigationStore = useNavigationStore()
  const pagesStore = usePagesStore()

  navigationStore.showModal = false // makes sure the modal is closed on each load

  // this will be useful for authentication later on
  // redirect to login page if not logged in and trying to access a restricted page
  const publicPages = [
    '/sign-in',
    '/reset-password',
    '/link-auth',
    '/privacy-policy',
    '/terms-of-use',
  ]
  const authRequired = !publicPages.includes(to.path)
  // const authRequired = !publicPages.some(x => to.path.includes(x)) // will be useful later
  userStore.checkUserStatus()
  if (authRequired && !userStore.isAuthenticated) {
    recordLog('Change Page', 'Success', '/sign-in')
    return '/sign-in'
  }

  //  reset current page before entering each page
  if (pagesStore?.currentPage) {
    pagesStore.resetCurrentPage()
  }
  //

  // verify user has agreed to license
  if (
    userStore.isAuthenticated &&
    !userStore.user.isValidated &&
    to.name !== 'licenseAgreement' &&
    to.name !== 'signIn'
  ) {
    navigationStore.redirectPath = to.path
    recordLog('Change Page', 'Success', '/license-agreement')
    return '/license-agreement'
  }
  recordLog('Change Page', 'Success', to.path)
})

router.afterEach((to, from) => {
  const navigationStore = useNavigationStore()
  if (from.name === 'signIn') {
    // for the update handler
    navigationStore.isSigningIn = true
  }
})

router.afterEach(to => {
  if (to.meta.title) document.title = 'MaxTrax - ' + to.meta.title
  else document.title = 'MaxTrax'
})

export default router
