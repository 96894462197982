<script setup>
import { watch, computed, onMounted } from 'vue'
import SystemMenu from '@/components/SystemMenu.vue'
import { useNavigationStore } from '@/stores/navigation'
import { useUserStore } from '@/stores/user'
import { storeToRefs } from 'pinia'

const userStore = useUserStore()
const navigationStore = useNavigationStore()
const { currentRole } = storeToRefs(userStore)

/*watch(
  currentRole,
  (newVal, oldVal) => {
    console.log('watch role ', newVal.menu?.properties?.items)
    console.log('watch oldVal ', oldVal?.menu?.properties?.items)

    // newVal?.menu?.properties.items && newVal !== oldVal
    if (
      newVal?.menu?.properties?.items &&
      newVal.menu?.properties?.items !== oldVal.menu?.properties?.items
    ) {
      console.log('PASSED!')
      navigationStore.setMenu(newVal?.menu?.properties?.items)
    }
  },
  {
    deep: true,
  }
)*/

const newMenuItems = computed(() => {
  return currentRole.value?.menu?.properties?.items
})

watch(
  () => [userStore.isAuthenticated, newMenuItems.value],
  () => {
    if (newMenuItems.value) navigationStore.setMenu(newMenuItems.value)
  }
)

navigationStore.setMenu(newMenuItems.value)

onMounted(async () => {
  navigationStore.setMenu(await userStore.getMenuFromRole)
})
</script>

<template>
  <system-menu
    v-if="!$vuetify.display.mdAndDown"
    :menu-items="navigationStore.getMainMenuItems"
    :client="userStore.getCurrentClientName"
    :module="userStore.getCurrentModuleName"
  />
</template>
