// this may need to be more generic

import { defineStore } from 'pinia'
import {
  where,
  collection,
  query,
  onSnapshot,
  doc,
  getDocs,
  setDoc,
  addDoc,
  updateDoc,
  deleteDoc,
} from 'firebase/firestore'
import {
  firestore,
  storage,
  ref,
  getDownloadURL,
  mtcfProd,
  mtcfDev,
} from '../services/firebase'
import idb from '@/api/idb'
import { uploadBytes } from 'firebase/storage'
import { lookupRecordsetKey } from '@/services/lookup'
import { base64ImageToBlob } from '@/services/utils'
import { generateLogRecord } from '@/services/logging'
import {
  RecordQueueEntry,
  RecordQueueEntryHandler,
} from '@/model/RecordQueue.model'
import { isOffline } from '@/services/recordQueue'
import { FirestoreUploadData } from '@/model/FormData.model'
import { firestoreUploadDataToEmail } from '@/services/emailFormat'
import { default as axios } from 'axios'
import { useIntegrationsStore } from './integrations'

let unsubscribeRecordset = () => {
  // initializes the variable
}

/**
 * Adds a new record to the firestore database with the specified id
 */
const _firestoreSetDoc = async (db, coll, docId, vals) => {
  console.log('### firestoreSetDoc ###')
  if (isOffline()) {
    // Throw a manual error because firestore does not error while offline
    throw new Error(`Cannot create ${coll} ${docId} while offline`)
  }
  await setDoc(doc(collection(db, coll), docId), vals).then(res => {
    //await addDoc(doc(collection(db, coll)), vals).then(res => {
    console.log('update: ', res)
  })
}

const _firestoreEditDoc = async (db, coll, docId, vals, editIsAdd = false) => {
  console.log('### firestoreEditDoc ###')
  if (isOffline()) {
    // Throw a manual error because firestore does not error while offline
    throw new Error(`Cannot edit ${coll} ${docId} while offline`)
  }
  // TODO: Resolve for editing records added offline before sync
  // eslint-disable-next-line
  if (editIsAdd || true) {
    return setDoc(doc(collection(db, coll), docId), vals).then(res => {
      console.log('update: ', res)
    })
  }
  return updateDoc(doc(db, coll, docId.toString()), vals).then(res => {
    console.log('update: ', res)
  })
}

const _firestoreDeleteDoc = async (db, coll, docId) => {
  console.log('### firestoreDeleteDoc ###')
  if (isOffline()) {
    // Throw a manual error because firestore does not error while offline
    throw new Error(`Cannot delete ${coll} ${docId} while offline`)
  }
  return deleteDoc(doc(db, coll, docId.toString())).then(res => {
    console.log('delete: ', res)
  })
}

/**
 * Processes any non-image files attached to a FirestoreUploadData object by
 * uploading them to firebase storage. Mutates the FirestoreUploadData by
 * replacing placeholder local data with the link to the file in storage. If no
 * non-image files are present, acts as a no-op.
 * @param {FirestoreUploadData} firestoreUploadData The FirestoreUploadData
 * object whose files to upload.
 */
const _uploadFilesV1 = async firestoreUploadData => {
  if (isOffline()) {
    throw new Error(`Cannot upload files while offline`)
  }
  for (let fileListKey of Object.keys(firestoreUploadData.files)) {
    let fileUrls = firestoreUploadData.content[fileListKey] ?? []
    for (let file of firestoreUploadData.files[fileListKey] ?? []) {
      console.log('file ', file)
      let filename = `${new Date().getTime()}-${file.name}`
      const storageRef = ref(storage, filename)
      let snapshot
      if (typeof file === 'string') {
        snapshot = await uploadBytes(storageRef, base64ImageToBlob(file))
      } else {
        snapshot = await uploadBytes(storageRef, file)
      }
      let url = await getDownloadURL(storageRef)
      console.log(
        `File upload snapshot: ${JSON.stringify(snapshot)}\nURL: ${url}`
      )
      fileUrls.push(url)
    }
    if (fileUrls.length === 1) {
      firestoreUploadData.content[fileListKey] = fileUrls[0]
    } else {
      firestoreUploadData.content[fileListKey] = fileUrls
    }
  }
}

/**
 * Looks up the firestore collection name of a given recordset name. Returns
 * null if the given recordset is not loaded in memory.
 * @param {DataStore} ds The state of the data store
 * @param {string} recordsetName The name of the recordset whose firestore
 * collection name to look up
 * @returns {string | null} The name of the firestore collection that corresponds to the
 * given recordset, or `null` if the map for the given recordset is not
 * loaded in memory
 */
const _getFSCollectionName = (ds, recordsetName) => {
  if (!ds.recordsetCollectionsMap) return null
  let rsetCollMap = ds.recordsetCollectionsMap.find(rsetCollMap =>
    rsetCollMap.recordsets.includes(recordsetName)
  )
  return rsetCollMap?.source ?? null
}

/**
 * Processes any image files attached to a FirestoreUploadData object by
 * uploading them to firebase storage. Mutates the FirestoreUploadData by
 * replacing placeholder local data with the link to the image in storage. If no
 * image files are present, acts as a no-op.
 * @param {FirestoreUploadData} firestoreUploadData The FirestoreUploadData
 * object whose files to upload.
 */
const _uploadFilesV2 = async firestoreUploadData => {
  if (isOffline()) {
    throw new Error(`Cannot upload images while offline`)
  }
  for (let propertyWithImage of Object.keys(firestoreUploadData.images)) {
    const imageHandler = firestoreUploadData.images[propertyWithImage]
    const uploadData = imageHandler.getBlobOrFile()
    const filename =
      imageHandler?.image?.filename ??
      `${new Date().getTime()}-${uploadData?.name ?? 'markup'}`
    const storageRef = ref(storage, filename)
    let snapshot
    if (uploadData) {
      snapshot = await uploadBytes(storageRef, uploadData).catch(reason => {
        console.error(`Image upload failed: ${reason.message ?? reason}`)
      })
      const url = await getDownloadURL(storageRef)
      console.log(
        `File upload snapshot: ${JSON.stringify(snapshot)}\nURL: ${url}`
      )
      imageHandler.image.original = url
      firestoreUploadData.content[propertyWithImage] =
        imageHandler.getFirestoreValue()
    }
  }
}

/**
 * Adds a record to both pinia and indexed DB
 * @param {DataStore} ds This data store object
 * @param {string} recordset The name of the recordset where the record should
 * be cached
 * @param {*} data The content of the record that is being added. Implicitly
 * contains ID
 * @returns {Promise | null} A promise for adding the record to indexed DB
 */
const _addCachedRecord = (ds, recordset, data) => {
  const rset = ds.recordsets?.find(rset =>
    Object.prototype.hasOwnProperty.call(rset, recordset)
  )?.[recordset]
  if (rset) {
    rset.push(data)
    return idb.addToDatabase('recordsets', {
      recordset: recordset,
      content: JSON.stringify(rset),
    })
  }
}

/**
 * Replaces a record in both pinia and in indexed DB
 * @param {DataStore} ds This data store object
 * @param {string} recordset The name of the recordset where the record should
 * be cached
 * @param {*} data The content of the record that is being updated
 * @param {string} id The ID of the record that is being edited
 * @returns {Promise | null} A promise for updating the record in indexed DB
 */
const _replaceCachedRecord = (ds, recordset, data, id) => {
  const rset = ds.recordsets?.find(rset =>
    Object.prototype.hasOwnProperty.call(rset, recordset)
  )?.[recordset]
  if (rset) {
    rset[
      rset.findIndex(record => record[lookupRecordsetKey(recordset)] === id)
    ] = data
    // copy to indexed db
    return idb.addToDatabase('recordsets', {
      recordset: recordset,
      content: JSON.stringify(rset),
    })
  } else {
    // Log an error rather than throwing an error in case a record is being saved to a recordset that hasn't yet been loaded
    console.error(`Could not cache record: ${recordset} recordset not found`)
  }
}

/**
 * Deletes a record from both pinia and indexed DB
 * @param {DataStore} ds This data store object
 * @param {string} recordset The name of the recordset where the record should
 * be cached
 * @param {string} id The id of the record that is being deleted
 * @returns {Promise | null} A promise for deleting the record from indexed DB
 */
const _deleteCachedRecord = (ds, recordset, id) => {
  const rset = ds.recordsets?.find(rset =>
    Object.prototype.hasOwnProperty.call(rset, recordset)
  )?.[recordset]
  if (rset) {
    let indexToUpdate = rset.findIndex(
      record => record[lookupRecordsetKey(recordset)] === id
    )
    if (indexToUpdate > -1) {
      rset.splice(indexToUpdate, 1)
      return idb.addToDatabase('recordsets', {
        recordset: recordset,
        content: JSON.stringify(rset),
      })
    }
  }
}

/**
 * Receives data that failed to send, converts that data into a RecordQueueEntry
 * object, then adds that object to both indexed DB and to the local queue in
 * this store's memory
 * @param {'add' | 'edit' | 'delete'} type The queue entry type
 * @param {FirestoreUploadData | {
 *   recordset: string, id: string, fsCollection: string
 * }} data The data whose submission failed, either as a firestore upload data
 * object for an add/edit or a delete data object for a delete
 * @param {{[key: string]: RecordQueueEntry}} localQueue This store's local
 * queue
 */
const _addRecordToQueue = (type, data, localQueue) => {
  console.log(type, ' _addRecordToQueue ', data)

  idb.getDataStore('queue').then(queue => {
    if (!localQueue[data.id]) {
      // If there is not already a queue entry for this record
      if (type === 'delete') {
        const recordQueueEntry = RecordQueueEntryHandler.fromDeleteData(
          data.recordset,
          data.id,
          data.fsCollection
        )
        idb.addToDatabase('queue', recordQueueEntry) // Copy to indexed db
        localQueue[data.id] = recordQueueEntry // Copy to local queue
        console.log(`Updated queue entry ${data.id}`)
      } else {
        RecordQueueEntryHandler.fromFirestoreUploadData(type, data)
          .then(recordQueueEntry => {
            idb.addToDatabase('queue', recordQueueEntry) // Copy to indexed db
            localQueue[data.id] = recordQueueEntry // Copy to local queue
            console.log(`Updated queue entry ${data.id}`)
          })
          .catch(console.error)
      }
    } else {
      // If this record already has a change queued
      idb.removeFromQueue(data.id).then(result => {
        console.log(result)
        if (type === 'delete') {
          const recordQueueEntry = RecordQueueEntryHandler.fromDeleteData(
            data.recordset,
            data.id,
            data.fsCollection,
            localQueue[data.id]?.originalAttemptDateTime ?? undefined
          )
          idb.addToDatabase('queue', recordQueueEntry) // Copy to indexed db
          localQueue[data.id] = recordQueueEntry // Copy to local queue
          console.log(`Updated queue entry ${data.id}`)
        }
        RecordQueueEntryHandler.fromFirestoreUploadData(
          type,
          data,
          localQueue[data.id]?.originalAttemptDateTime ?? undefined
        ).then(recordQueueEntry => {
          idb.addToDatabase('queue', recordQueueEntry) // Copy to indexed db
          localQueue[data.id] = recordQueueEntry // Copy to local queue
          console.log(`Updated queue entry ${data.id}`)
        })
      })
    }
  })
}

const _firestoreDataSourceQuery = async (db, coll, params = [{}]) => {
  // field, compare, value
  const returnArr = []
  const conditions = []

  params?.forEach(p => conditions.push(where(p.field, p.compare, p.value)))
  const fsColl = collection(db, coll)
  const q = query(fsColl, ...conditions)

  const querySnapshot = await getDocs(q)
  querySnapshot.forEach(doc => {
    returnArr.push(doc.data())
    // callback(doc.data())
  })

  return returnArr
}

export const useDataStore = defineStore('data', {
  state: () => ({
    recordsets: [],
    recordsetCollectionsMap: [],
    idMap: [
      { collection: 'issues', id: 'issueId' },
      { collection: 'lists', id: 'listId' },
      { collection: 'inspections1', id: 'inspectionId' },
    ], // TODO: make this dynamic in the future
    filters: [],
    searchString: '',
    localQueue: [],
    hasTriedQueue: false,
  }),
  getters: {
    idMapper: state => obj => {
      return lookupRecordsetKey(obj)
    },

    filteredRecordset: state => recordset => {
      let returnArr = []
      let tempRS = state.recordsets.find(rs => {
        return Object.prototype.hasOwnProperty.call(
          rs,
          Object.keys(rs).find(k => k === recordset)
        )
      })

      if (tempRS && tempRS[recordset]?.length > 0) {
        // generate formatted date properties in each object
        tempRS[recordset].forEach(x => {
          Object.keys(x).forEach((k, i) => {
            if (
              k.toLowerCase().includes('datetime') &&
              !k.includes('Formatted')
            ) {
              x[k + 'Formatted'] = `${new Date(
                Math.abs(x[k]?.seconds) * 1000
              ).toLocaleDateString('en-US')}`
              /*${new Date(Math.abs(x[k]?.seconds) * 1000).toLocaleTimeString(
                  'en-US'
                )}`*/
              // console.log('>>> ', k + 'Formatted')
            }
          })
        })

        // search filter

        let searchRS = JSON.parse(JSON.stringify(tempRS[recordset]))
        if (state.searchString) {
          searchRS = tempRS[recordset].filter(x => {
            return Object.values(x)
              .join(' ')
              .toLowerCase()
              .includes(state.searchString?.toLowerCase())
          })
        }
        returnArr = [...searchRS]
        for (let i = 0; i < state.filters.length; i++) {
          if (state.filters[i].compare?.toLowerCase() === 'notanyof') {
            if (i === 0) {
              returnArr = [
                ...searchRS.filter(x => {
                  return !state.filters[i].value.some(y => {
                    return (
                      x[state.filters[i].field]?.toLowerCase() ===
                      y?.toLowerCase()
                    )
                  })
                }),
              ]
            } else {
              returnArr = [
                ...returnArr.filter(x => {
                  return !state.filters[i].value.some(y => {
                    return (
                      x[state.filters[i].field]?.toLowerCase() ===
                      y?.toLowerCase()
                    )
                  })
                }),
              ]
            }
          }
          if (!state.filters[i].value?.includes('{Min}'))
            if (state.filters[i].compare?.toLowerCase() === 'between') {
              if (!returnArr && i === 0) {
                returnArr = [
                  ...tempRS[recordset].filter(x => {
                    return !state.filters[i].value.find(y => {
                      return (
                        x[state.filters[i].field] >= y[0] &&
                        x[state.filters[i].field] <= y[1]
                      )
                    })
                  }),
                ]
              } else {
                returnArr = [
                  ...returnArr.filter(x => {
                    const filterField = state.filters[i].field
                      .toLowerCase()
                      .includes('datetime')
                      ? x[state.filters[i].field]?.seconds
                      : x[state.filters[i].field]
                    if (filterField)
                      return state.filters[i].value.find(y => {
                        return (
                          // x[state.filters[i].field] !== null &&
                          filterField >= state.filters[i].value[0] &&
                          filterField <= state.filters[i].value[1]
                        )
                      })
                  }),
                ]
              }
            }
        }
      }

      return returnArr
    },
    getSearchString: state => {
      return state.searchString
    },
    /**
     * Looks up the firestore collection name of a given recordset name. Returns
     * null if the given recordset is not loaded in memory.
     * @param {DataStore} state The state of the data store
     * @param {string} recordsetName The name of the recordset whose firestore
     * collection name to look up
     * @returns The name of the firestore collection that corresponds to the
     * given recordset, or `null` if the map for the given recordset is not
     * loaded in memory
     */
    getFSCollectionName: state => recordsetName => {
      return _getFSCollectionName(state, recordsetName)
    },
  },
  actions: {
    subscribeRecordset(sources) {
      sources.forEach(async (src, sIdx) => {
        const conditions = []
        const queryParams = []
        //src.filters[0].compare = not-in
        src.filters?.forEach(filter => {
          queryParams.push({
            value: filter.values,
            field: filter.property,
            compare: 'not-in', // this will be set dynamically. They need to be properly mapped first
          })
        })
        /* /// uncomment before going live
        this.filters?.forEach(p =>
          conditions.push(where(p.field, p.compare, p.value))
        )
        console.log('conditions ', conditions)
        console.log('hello this.filters ', this.filters) */
        /////
        const q = query(collection(firestore, src.source), ...conditions)
        unsubscribeRecordset = onSnapshot(q, querySnapshot => {
          querySnapshot.docChanges().forEach(change => {
            // console.log('snap change ', change)
            // console.log('snap data ', change.doc.data())
            if (
              this.recordsets[
                this.recordsets?.indexOf(
                  this.recordsets.find(x =>
                    Object.prototype.hasOwnProperty.call(
                      x,
                      Object.keys(x).find(k => k === src.recordsets[sIdx])
                    )
                  )
                )
              ]
            ) {
              const recordsetIndex = this.recordsets[
                this.recordsets?.indexOf(
                  this.recordsets.find(x =>
                    Object.prototype.hasOwnProperty.call(
                      x,
                      Object.keys(x).find(k => k === src.recordsets[sIdx])
                    )
                  )
                )
              ][src.recordsets[sIdx]].indexOf(
                this.recordsets[
                  this.recordsets?.indexOf(
                    this.recordsets.find(x =>
                      Object.prototype.hasOwnProperty.call(
                        x,
                        Object.keys(x).find(k => k === src.recordsets[sIdx])
                      )
                    )
                  )
                ][src.recordsets[sIdx]].find(y => {
                  return y[this.idMapper(src.source)] !== null
                    ? y[this.idMapper(src.source)] ===
                        change.doc.data()[this.idMapper(src.source)]
                    : y.reportedDateTime?.seconds ===
                        change.doc.data().reportedDateTime?.seconds // false // TODO: if ID is null, use created timestamp. This should be temporary until the auto id generator is created
                })
              )
              if (change.type === 'removed') {
                console.log('remove')
                if (recordsetIndex >= 0) {
                  // Only remove the record if it has not already been removed locally
                  this.recordsets[
                    this.recordsets.indexOf(
                      this.recordsets.find(x =>
                        Object.prototype.hasOwnProperty.call(
                          x,
                          Object.keys(x).find(k => k === src.recordsets[sIdx])
                        )
                      )
                    )
                  ][src.recordsets[sIdx]].splice(recordsetIndex, 1)
                }
              } else {
                // adding or editing
                // check if issueId exists in recordset already, if so, remove it so we can replace it with the new one
                if (recordsetIndex >= 0) {
                  this.recordsets[
                    this.recordsets.indexOf(
                      this.recordsets.find(x =>
                        Object.prototype.hasOwnProperty.call(
                          x,
                          Object.keys(x).find(k => k === src.recordsets[sIdx])
                        )
                      )
                    )
                  ][src.recordsets[sIdx]][recordsetIndex] = change.doc.data() //
                } else {
                  // add the new entry
                  this.recordsets[
                    this.recordsets.indexOf(
                      this.recordsets.find(x =>
                        Object.prototype.hasOwnProperty.call(
                          x,
                          Object.keys(x).find(k => k === src.recordsets[sIdx])
                        )
                      )
                    )
                  ][src.recordsets[sIdx]].push(change.doc.data())
                }
              }

              // for adding to indexeddb
              let content = this.recordsets.find(w => {
                return Object.keys(w).find(k => {
                  return k === src.recordsets[sIdx]
                })
              })
              idb.addToDatabase('recordsets', {
                recordset: src.recordsets[sIdx],
                content: JSON.stringify(content), // JSON.stringify(res[sIdx]),
              })
            }
          })
        })
      })
    },
    async fetchCollectionData(sources) {
      if (!sources) {
        return // If there were no sources, there's nothing to fetch
      }

      for (let sIdx = 0; sIdx < sources.length; sIdx++) {
        /**
         * Retrieves the index of the given recordset within the datastore, or
         * -1 if the recordset is not in the data store. Implemented for
         * readability.
         * @param {string} recordsetName The name of the recordset whose index
         * to retrieve
         * @returns The index under which the requested recordset is stored in
         * the data store, or -1 if the recordset is not in the datastore
         */
        const getStoredRecordsetIndex = recordsetName => {
          return this.recordsets?.indexOf(
            this.recordsets.find(x =>
              Object.prototype.hasOwnProperty.call(
                x,
                Object.keys(x).find(k => k === recordsetName)
              )
            )
          )
        }

        /*
          TODO: src.recordsets[sIdx] was erroneously used to index into a recordset,
          but `sIdx` refers to the index of the source rather than the index of the 
          recordset. This needs to be extended in the future, and the best time
          to rewrite it would be when handling per-recordset filters as well.
          
          The temporary fix is to always index into the first recordset, as no
          recordsets after the first are currently used.
        */

        const src = sources[sIdx]
        if (
          !this.recordsetCollectionsMap.some(x => {
            return x.source === src.source
          })
        )
          this.recordsetCollectionsMap.push(src)
        // if recordset array is empty pull from indexeddb
        if (this.recordsets.length === 0) {
          console.log(
            '### dataStore recordsets is empty. Pulling from Indexeddb ### '
          )
          let idbRecordsets = await idb.getDataStore('recordsets')
          for (const idbRecordset of idbRecordsets ?? []) {
            if (idbRecordset.recordset === src.recordsets[0]) {
              idbRecordset.content = JSON.parse(idbRecordset.content)

              let indexToUpdate = this.recordsets.findIndex(x =>
                Object.prototype.hasOwnProperty.call(
                  x,
                  Object.keys(x).find(k => {
                    return k === src.recordsets[0]
                  })
                )
              )

              // sometimes indexeddb .content is erroneously posted as an array instead of an object
              const uploadContent = Array.isArray(idbRecordset.content)
                ? {
                    [idbRecordset.recordset]: idbRecordset.content,
                  }
                : idbRecordset.content

              if (indexToUpdate > -1) {
                this.recordsets[indexToUpdate] = idbRecordset.content
                this.recordsets[indexToUpdate] = uploadContent
              } else {
                this.recordsets.push(uploadContent)
              }

              console.log(`${idbRecordset.recordset} recordset came from cache`)
            }
          }
        }
        console.log('this.recordsets ', this.recordsets)
        // now check that the recordset isn't already stored in Pinia
        if (
          !this.recordsets.find(w =>
            Object.keys(w).some(k => src.recordsets.includes(k))
          )
        ) {
          const queryParams = []
          //src.filters[0].compare = not-in
          // not being used
          if (src.filters)
            src.filters.forEach(filter => {
              queryParams.push({
                value: filter.values,
                field: filter.property,
                compare: 'not-in', // this will be set dynamically. They need to be properly mapped first
              })
            })

          await _firestoreDataSourceQuery(firestore, src.source, []) // this.filters
            .then(res => {
              if (getStoredRecordsetIndex(src.recordsets[0]) > -1) {
                this.recordsets[getStoredRecordsetIndex(src.recordsets[0])][
                  src.recordsets[0]
                ] = [...res]
              } else {
                this.recordsets.push({ [src.recordsets[0]]: res })
              }

              return this.recordsets
            })
            .then(res => {
              let content = res.find(w => {
                return Object.keys(w).find(k => {
                  return k === src.recordsets[0]
                })
              })
              idb.addToDatabase('recordsets', {
                recordset: src.recordsets[0],
                content: JSON.stringify(content), // JSON.stringify(res[sIdx]),
              })
            })
        }
      }
      this.subscribeRecordset(sources)
    },
    unsubscribeRecordset() {
      unsubscribeRecordset()
    },
    /**
     * Takes a `FirestoreUploadData` object and adds its contents to the local
     * recordset and to the server. If the server cannot be reached, stores the
     * data to the queue in a `RecordQueueEntry`.
     * @param {FirestoreUploadData} firestoreUploadData The FirestoreUploadData
     * object whose data should be added to the local and remote recordsets
     * @param {boolean} addLocal Whether or not to cache the record locally.
     * Defaults to true and should always be true.
     */
    async addToRecordset(firestoreUploadData, addLocal = true) {
      console.log('add ', firestoreUploadData)
      try {
        if (addLocal)
          await _addCachedRecord(
            this,
            firestoreUploadData.recordset,
            firestoreUploadData.content
          )
        await _uploadFilesV1(firestoreUploadData)
        await _uploadFilesV2(firestoreUploadData)
        if (addLocal)
          await _replaceCachedRecord(
            this,
            firestoreUploadData.recordset,
            firestoreUploadData.content,
            firestoreUploadData.id
          )

        await _firestoreSetDoc(
          firestore,
          firestoreUploadData.fsCollection ??
            _getFSCollectionName(this, firestoreUploadData.recordset) ??
            firestoreUploadData.recordset,
          firestoreUploadData.id,
          firestoreUploadData.content
        )
      } catch (reason) {
        // Errors should be caught at the point of failure so that the record
        // can still be cached locally before attempting network requests
        _addRecordToQueue('add', firestoreUploadData, this.localQueue)
        console.error(
          `Queued outgoing record ${firestoreUploadData.id}: ${
            reason //.message ?? reason
          }`
        )
      }
      // Apply integrations if applicable
      useIntegrationsStore().applyIntegrations(firestoreUploadData, 'add')
    },
    /**
     * Takes a `FirestoreUploadData` object and uses its contents to edit the
     * local recordset and server. If the server cannot be reached, stores the
     * data to the queue in a `RecordQueueEntry`.
     * @param {FirestoreUploadData} firestoreUploadData The FirestoreUploadData
     * object whose data should be edited in the local and remote recordsets
     * @param {boolean} addLocal Whether or not to cache the record locally.
     * Defaults to true and should always be true.
     * @param {boolean} recordWasQueued True if the record is coming from the
     * queue, false otherwise. This is used to remove the record from the queue
     * on success.
     */
    async editRecordset(
      firestoreUploadData,
      editIsAdd = false,
      addLocal = true,
      recordWasQueued = false
    ) {
      console.log('edit')
      try {
        if (addLocal) {
          await _replaceCachedRecord(
            this,
            firestoreUploadData.recordset,
            firestoreUploadData.content,
            firestoreUploadData.id
          )
        }
        await _uploadFilesV1(firestoreUploadData)
        await _uploadFilesV2(firestoreUploadData)
        if (addLocal) {
          await _replaceCachedRecord(
            this,
            firestoreUploadData.recordset,
            firestoreUploadData.content,
            firestoreUploadData.id
          )
        }

        await _firestoreEditDoc(
          firestore,
          firestoreUploadData.fsCollection ??
            _getFSCollectionName(this, firestoreUploadData.recordset) ??
            firestoreUploadData.recordset,
          firestoreUploadData.id,
          firestoreUploadData.content,
          editIsAdd
        )
        // If everything went right, and if the record was queued, remove the entry from the queue
        if (recordWasQueued) this.removeFromQueue(firestoreUploadData.id)
      } catch (reason) {
        _addRecordToQueue(
          editIsAdd ? 'add' : 'edit',
          firestoreUploadData,
          this.localQueue
        )
        console.error(
          `Queued outgoing record ${firestoreUploadData.id}: ${
            reason.message ?? reason
          }\nStack Trace: ${JSON.stringify(reason)}`
        )
      }
    },
    /**
     * Deletes a record locally and from firestore using the information given.
     * If offline, adds the deletion request to the queue.
     * @param {string} recordset The recordset from which a record is being
     * deleted
     * @param {string} id The id of the record being deleted
     * @param {string} fsCollection The name of the firestore collection
     * corresponding to this record's recordset
     * @param {boolean} deletionWasQueued True if this deletion request came
     * from the record queue, false otherwise. Used to remove the queue entry
     * on success.
     */
    async deleteRecord(
      recordset,
      id,
      fsCollection = null,
      deletionWasQueued = false
    ) {
      _deleteCachedRecord(this, recordset, id)
      _firestoreDeleteDoc(
        firestore,
        fsCollection ?? _getFSCollectionName(this, recordset) ?? recordset,
        id
      )
        .then(result => {
          // Remove the entry from the queue. If the data sync fails again, it will be re-added through the same channels that queued it originally
          if (deletionWasQueued) this.removeFromQueue(id)
        })
        .catch(reason => {
          _addRecordToQueue(
            'delete',
            {
              recordset: recordset,
              id: id,
              fsCollection:
                fsCollection ?? _getFSCollectionName(this, recordset),
            },
            this.localQueue
          )
          console.error(
            `Queued outgoing deletion ${id}: ${reason.message ?? reason}`
          )
        })
    },
    /**
     * A method for exclusive use by the logging service. Calls to this method
     * directly are discouraged, especially within stores, the router, and high-
     * level components, as this may lead to compile-time errors. To create a
     * new log entry, please see a method by the same name in the logging service.
     * @param {string} action The action to which the log is relevant
     * @param {string} result The result of the action
     * @param {string} details Details to explain the action's result, usually
     * for explaining why an action wasn't successful. Defaults to undefined.
     */
    async recordLog(action, result, details = undefined) {
      const firestoreUploadData = generateLogRecord(action, result, details)
      await _firestoreSetDoc(
        firestore,
        'logs',
        firestoreUploadData.id,
        firestoreUploadData.content
      )
    },
    /**
     * __Async method__ that returns the length of the record queue. If the
     * local queue is empty, attempts to restore the queue from indexedDB.
     * @returns __A promise__ that resolves to the number of items in the queue
     */
    async queuedRecordCount() {
      return new Promise((resolve, reject) => {
        const localQueueLength = Object.keys(this.localQueue ?? []).length
        // if localQueue is 0, let's check to see if there's anything stored in indexeddb
        if (localQueueLength > 0) {
          console.log(`Fetching queue from Pinia: ${localQueueLength} entries`)
          resolve(localQueueLength)
        } else {
          idb
            .getDataStore('queue')
            .then(idbQueue => {
              console.log(
                `Fetching queue from IndexedDB: ${localQueueLength} entries`
              )
              // if the indexeddb queue is also 0, we're done.
              if (idbQueue?.length < 1 && this.localQueue.length < 0)
                return resolve(idbQueue?.length ?? 0)
              else {
                // copy the values from indexeddb into localQueue
                console.log('queue -> ', idbQueue)
                for (const entry of idbQueue) {
                  this.localQueue[entry.queueId] = entry
                }
                return resolve(idbQueue?.length ?? 0)
              }
            })
            .catch(reject)
        }
      })
    },
    /**
     * Removes an entry from the queue in Pinia and in indexedDB
     * @param {string} id The id of the queue entry
     * @returns a promise that resolves to the queue's new length
     */
    async removeFromQueue(id) {
      console.log('id -> ', id)
      return new Promise((resolve, reject) => {
        delete this.localQueue[id]
        idb
          .removeFromQueue(id)
          .catch(reject)
          .then(() => {
            resolve(Object.keys(this.localQueue ?? []).length)
          })
      })
    },
    /**
     * Attempts to send every update from the record queue to the firestore
     * database. Each entry will be removed from the queue only after success.
     */
    async retryRecordQueue() {
      // extracts everything from indexeddb if the local queue is empty
      if ((await this.queuedRecordCount()) > 0)
        for (const rqeId of Object.keys(this.localQueue)) {
          const recordQueueEntry = this.localQueue[rqeId]
          console.log('## recordQueueEntry -> ', recordQueueEntry)
          if (recordQueueEntry.type === 'delete') {
            this.deleteRecord(
              recordQueueEntry.recordset,
              recordQueueEntry.queueId,
              recordQueueEntry.fsCollection,
              true
            )
          } else {
            const firestoreUploadData =
              RecordQueueEntryHandler.toFirestoreUploadData(recordQueueEntry)
            this.editRecordset(
              firestoreUploadData,
              recordQueueEntry.type === 'add',
              true,
              true
            )
          }
        }
      // }
    },
    clearIndexedDB() {
      idb.clearData()
    },
    deleteIndexedDB() {
      idb.deleteDatabase()
    },
    /*fetchCollectionData(sources) {
      if (sources)
        sources.forEach(async (src, sIdx) => {
          // if recordset array is empty pull from indexeddb
          if (this.recordsets.length === 0) {
            let parsedJSONArr = []
            await idb.getDataStore('recordsets').then((res, rIdx) => {
              if (res.length > 0) {
                parsedJSONArr.push(res)
                res.forEach((x, xIdx) => {
                  parsedJSONArr[rIdx] = []
                  parsedJSONArr[rIdx].push(x)
                  parsedJSONArr[rIdx][xIdx].content = JSON.parse(x.content)
                })
                // this.recordsets.push(parsedJSON)

                this.recordsets.push(parsedJSONArr[0][0].content)

                console.log('recordset came from cache')
              }
            })
          }

          // check that the recordset isn't already stored in Pinia
          if (
            !this.recordsets.find(w =>
              Object.keys(w).some(k => src.recordsets.includes(k))
            )
          ) {
            const queryParams = []
            //src.filters[0].compare = not-in
            src.filters.forEach(filter => {
              queryParams.push({
                value: filter.values,
                field: filter.property,
                compare: 'not-in', // this will be set dynamically. They need to be properly mapped first
              })
            })

            await firestoreDataSourceQuery(firestore, src.source, queryParams)
              .then(res => {
                this.recordsets.push({ [src.recordsets[sIdx]]: res })
                return this.recordsets
              })
              .then(res => {
                idb.addToDatabase('recordsets', {
                  recordset: src.recordsets[sIdx],
                  content: JSON.stringify(res[sIdx]),
                })
              })
            /////
          }
        })
    },*/
  },
  persist: true,
})
