<script setup></script>

<template>
  <v-card>
    <v-card-title
      class="px-0 pt-0 d-flex justify-start"
      style="color: white; background-color: rgb(70, 130, 45)"
    >
      <slot name="header" />
    </v-card-title>
    <v-card-text
      class="px-5 py-0"
      :style="{ 'max-height': '70vh', 'overflow-y': 'auto' }"
    >
      <slot name="body"
    /></v-card-text>

    <v-card-actions class="px-5 d-flex justify-end">
      <slot name="actions" />
    </v-card-actions>
  </v-card>
</template>
