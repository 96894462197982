<script setup>
import { ref, defineProps } from 'vue'
import useCommonConfig from '@/composables/useCommonConfig'
const { isMobile, isNotMobile, display, dataStore } = useCommonConfig()
const props = defineProps({ data: Object })

const exportModel = ref(false)

const clickHandler = () => {
  exportModel.value = !exportModel.value
}
</script>

<template>
  <v-btn
    v-if="isMobile"
    :value="exportModel"
    variant="text"
    class="pa-0 rounded-0"
    exact
    @click.stop.prevent="clickHandler"
  >
    <v-icon icon="mdi-export" />
    Export
  </v-btn>
  <v-btn
    v-else
    :value="exportModel"
    prepend-icon="mdi-export"
    color="grey-lighten-2"
    size="large"
    elevation="2"
    class="rounded order-first"
    >Export</v-btn
  >
</template>
