<script setup>
import { ref } from 'vue'
import useCommonConfig from '@/composables/useCommonConfig'
import mtaLogo from '@/assets/img/client/mta_logo.png'

const { isPWA, isIPhone, isAndroid } = useCommonConfig()
//vars
const a2hs = ref(!isPWA())
</script>
<template>
  <v-overlay
    v-model="a2hs"
    class="flex-column align-center justify-center a2hs__overlay"
  >
    <v-card class="mx-auto pa-4 w-75 rounded-0">
      <v-card-title class="text-center text-primary">
        <v-img
          :src="mtaLogo"
          width="110"
          max-width="110"
          heigth="34"
          max-height="34"
          class="mx-auto"
        />
        Add to Home Screen
      </v-card-title>
      <v-card-text class="text-center">
        <h3 class="mb-3 text-h6 lh-12">
          Place our MaxTrax app on your home screen for easy fullscreen access.
        </h3>
        <template v-if="isIPhone()">
          <p>
            Press "Share"
            <v-icon icon="mdi-export-variant" size="small" color="#2e72b7" />
            below
          </p>
          <p class="mt-2">
            Then "Add to Home Screen"
            <v-icon icon="mdi-plus-box-outline" size="small" />
          </p>
        </template>
        <template v-if="isAndroid()">
          <p>
            In Chrome, press "Settings"
            <v-icon icon="mdi-dots-vertical" size="small" />
            above
          </p>
          <p class="mt-2">
            Then "<v-icon
              icon="mdi-cellphone-arrow-down-variant"
              size="small"
            />
            Install App"
          </p>
        </template>
      </v-card-text>
      <v-card-actions>
        <v-btn
          class="mx-auto text-capitalize bg-primary"
          @click.prevent="a2hs = false"
          >Dismiss</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-overlay>
</template>

<style lang="scss">
.a2hs__overlay .v-overlay__scrim {
  opacity: 80%;
}
</style>
