import { initializeApp } from 'firebase/app'
import { getFirestore } from 'firebase/firestore'
import {
  getAuth,
  linkWithPopup,
  OAuthProvider,
  onAuthStateChanged,
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
  signInWithPopup,
  signOut,
} from 'firebase/auth'
// import { getAnalytics } from "firebase/analytics";

const firebaseConfig = {
  apiKey: process.env.VUE_APP_FIREBASE_KEY,
  authDomain: process.env.VUE_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.VUE_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.VUE_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.VUE_APP_FIREBASE_MESSENGER_SENDER_ID,
  appId: process.env.VUE_APP_FIREBASE_APP_ID,
}

const dbConfig = {
  apiKey: process.env.VUE_APP_FIRESTORE_KEY,
  authDomain: process.env.VUE_APP_FIRESTORE_AUTH_DOMAIN,
  projectId: process.env.VUE_APP_FIRESTORE_PROJECT_ID,
  storageBucket: process.env.VUE_APP_FIRESTORE_STORAGE_BUCKET,
  messagingSenderId: process.env.VUE_APP_FIRESTORE_MESSENGER_SENDER_ID,
  appId: process.env.VUE_APP_FIRESTORE_APP_ID,
}

const mtcfConfig = {
  apiKey: process.env.VUE_APP_MTCF_KEY,
  authDomain: process.env.VUE_APP_MTCF_AUTH_DOMAIN,
  projectId: process.env.VUE_APP_MTCF_PROJECT_ID,
  storageBucket: process.env.VUE_APP_MTCF_STORAGE_BUCKET,
  messagingSenderId: process.env.VUE_APP_MTCF_MESSENGER_SENDER_ID,
  appId: process.env.VUE_APP_MTCF_APP_ID,
}

const mtcfDevConfig = {
  apiKey: process.env.VUE_APP_MTCF_DEV_KEY,
  authDomain: process.env.VUE_APP_MTCF_DEV_AUTH_DOMAIN,
  projectId: process.env.VUE_APP_MTCF_DEV_PROJECT_ID,
  storageBucket: process.env.VUE_APP_MTCF_DEV_STORAGE_BUCKET,
  messagingSenderId: process.env.VUE_APP_MTCF_DEV_MESSENGER_SENDER_ID,
  appId: process.env.VUE_APP_MTCF_DEV_APP_ID,
}

const app = initializeApp(firebaseConfig)
const dbApp = dbConfig.apiKey
  ? initializeApp(dbConfig, 'db-firebase-project')
  : app
const db = getFirestore(dbApp)
const mtcfApp = mtcfConfig.apiKey
  ? initializeApp(mtcfConfig, 'mtcf-firebase-project')
  : app // Use the dev mtcf as backup
const mtcfDevApp = mtcfDevConfig.apiKey
  ? initializeApp(mtcfDevConfig, 'mtcf-dev-firebase-project')
  : app // Use the dev mtcf as backup

export {
  app,
  db,
  mtcfApp,
  mtcfDevApp,
  getAuth,
  linkWithPopup,
  OAuthProvider,
  onAuthStateChanged,
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
  signInWithPopup,
  signOut,
}
