<script setup>
import { onMounted, watch } from 'vue'
import DefaultLayout from '@/layouts/DefaultLayout'
import { useUserStore } from '@/stores/user'
import { useNavigationStore } from '@/stores/navigation'
import updateHandler from '@/components/UpdateHandler.vue'
const userStore = useUserStore()
const navigationStore = useNavigationStore()

watch(
  () => userStore.getIsAuthenticated,
  newVal => {
    if (newVal) {
      userStore.subscribeUser()
      navigationStore.subscribeSystem()
    }
  }
)

onMounted(() => {
  if (userStore.getIsAuthenticated) navigationStore.subscribeSystem()
})
</script>
<template>
  <default-layout>
    <router-view />
  </default-layout>
  <update-handler />
</template>

<style lang="scss" scoped>
// overrides
// letter spacing
:deep(.v-btn),
:deep(.text-capitalize),
:deep(.text-h1),
:deep(.text-h2),
:deep(.text-h3),
:deep(.text-h4),
:deep(.text-h5),
:deep(.text-h6),
:deep(.text-caption) {
  letter-spacing: 0px !important;
}

:deep(.v-btn) {
  text-transform: capitalize;
  min-width: 0;
  // display: inline-flex;
}
</style>

<style lang="scss">
#app {
  font-family: Roboto, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    'Helvetica Neue', Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji',
    'Segoe UI Symbol', 'Noto Color Emoji';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #333;
  line-height: 1.2 !important;
  letter-spacing: 0 !important;
}

.v-autocomplete__content {
  max-height: 60% !important;
}

.v-select__content {
  max-height: 60% !important;
}

.v-img__img--preload {
  filter: none !important;
}

/*
nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
} */
</style>
