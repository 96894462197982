import { IWidgetConfig } from './IWidgetConfig.model'

export class LayoutConfig<WC extends IWidgetConfig> {
  container: string
  devices: string[]
  modes?: string[]
  options: WC
  icon?: string
  title?: string
  card?: {
    icon?: string
    title?: string
    hasFooter?: boolean
    hasHeader?: boolean
    isClosable?: boolean
    isCollapsable?: string
    isExpanded?: boolean
  }

  constructor(
    container: string,
    devices: string[],
    modes: string[],
    options: WC
  ) {
    this.container = container
    this.devices = devices
    this.modes = modes
    this.options = options
  }
}

export class LayoutConfigHandler {
  layouts: LayoutConfig<IWidgetConfig>[]

  constructor(layouts: LayoutConfig<IWidgetConfig>[]) {
    this.layouts = layouts
  }

  /**
   * Given a list of layouts and a target mode, returns the layout that includes
   * the target mode
   * @param layouts A list of layouts from which to select the requested one
   * @params device The device type that a layout should be selected for
   * @param mode The mode of the layout for which to search
   * @returns The layout of the requested mode from the given list, or null if
   * such a layout does not exist
   */
  static findLayout(
    layouts: LayoutConfig<IWidgetConfig>[],
    device: string,
    mode: string
  ): LayoutConfig<IWidgetConfig> | null {
    return (
      layouts?.find(
        layout =>
          layout.modes?.includes(mode) && layout.devices?.includes(device)
      ) ?? null
    )
  }
}
