<script setup>
import { ref } from 'vue'

import { useRouter } from 'vue-router'
import { useUserStore } from '@/stores/user'

const router = useRouter()
const userStore = useUserStore()

const formError = ref('')
const isSubmittingData = ref(false)

const providers = ref([
  {
    name: 'Microsoft',
    icon: 'mdi-microsoft',
    str: 'microsoft.com',
    color: '#2c75e9',
    connected: false,
  },
])

function handleFirebaseError(error) {
  switch (error.code) {
    case 'auth/email-already-in-use':
      formError.value =
        'An account with this email already exists but could not be accessed.'
      break
    case 'auth/wrong-password':
      formError.value = 'Please enter the correct password for this user.'
      break
    case 'auth/user-not-found':
      formError.value =
        'No user exists for this email address. If you believe this to be an error, please contact a MaxTrax Administrator.'
      break
    case 'auth/account-exists-with-different-credential':
      formError.value =
        'This sign in has not yet been linked to your MaxTrax account. Please sign in with your email and password first, then link this sign-on method when prompted.'
      break
    default:
      formError.value =
        error.message +
        '\nIf this problem persists, please contact a MaxTrax administrator.'
  }
  console.error(error.message)
  isSubmittingData.value = false
}

async function addProvider(provider) {
  userStore.linkProvider(provider)
}
</script>

<template>
  <div class="pa-5">
    <v-card class="login-card ma-auto py-5" :loading="isSubmittingData">
      <v-img src="../img/client/mta_logo.png" height="35" />
      <span class="logo text-h6" v-text="'Link your SSO accounts'"></span>
      <v-container>
        <v-row>
          <v-col> <strong>Sign-In Method</strong> </v-col>
          <v-col> <strong>Link to Your Account</strong> </v-col>
        </v-row>
        <v-divider class="ma-4" />
        <v-row>
          <v-col> Email </v-col>
          <v-col>
            <v-btn
              color="success"
              prepend-icon="mdi-check"
              class="text-capitalize"
              disabled
            >
              Email Connected
            </v-btn>
          </v-col>
        </v-row>
        <v-row v-for="provider in providers" :key="provider.name">
          <v-col>
            {{ provider.name }}
            <v-icon v-if="provider.icon" :icon="provider.icon"></v-icon>
          </v-col>
          <v-col>
            <v-btn
              v-if="provider.connected"
              color="success"
              disabled
              prepend-icon="mdi-check"
              class="text-capitalize"
            >
              {{ provider.name }} Connected
            </v-btn>
            <v-btn
              v-else
              :color="provider.color ?? 'primary'"
              @click="addProvider(provider.str)"
              :style="provider.color ? 'color:white' : ''"
              prepend-icon="mdi-link-variant"
              class="text-capitalize"
            >
              Link to {{ provider.name }}
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
  </div>
</template>

<style scoped>
.login-card {
  max-width: 700px;
  width: 100%;
}

.error {
  color: red;
}
</style>
