<script setup>
import { onMounted, watch } from 'vue'
import useCommonConfig from '@/composables/useCommonConfig'
const {
  isPhone,
  isMobile,
  isNotMobile,
  display,
  navigationStore,
  router,
  widgetsStore,
  userStore,
} = useCommonConfig()

// change when the module changes // this might not be necessary
watch(
  () => userStore.currentModule,
  () => {
    navigationStore.setModuleMenu(userStore.getModules)
  }
)
// change when we get new modules
watch(
  () => userStore.currentClient,
  () => {
    navigationStore.setModuleMenu(userStore.getModules)
  }
)

onMounted(() => {
  navigationStore.setModuleMenu(userStore.getModules)
})

// methods
const clickHandler = async (moduleId, external = false) => {
  if (!external) {
    // needed?
    Promise.resolve(userStore.setCurrentModule(moduleId)).then(async () => {
      if (
        router.currentRoute.value.params.client &&
        router.currentRoute.value.params.module
      ) {
        const linkObj = {
          name: 'dynamic',
          params: {
            client: router.currentRoute.value.params.client,
            module: userStore.getCurrentModuleName,
          },
        }

        router.push(linkObj)
      } else {
        // terms and privacy
        const linkObj = {
          name: 'dynamic',
          params: {
            client: userStore.getCurrentClientName,
            module: await userStore.getModuleName(moduleId), // userStore.getCurrentModuleName,
          },
        }

        router.push(linkObj)
      }
    })
  } else {
    const linkObj = {
      name: 'dynamic',
      params: {
        client: router.currentRoute.value.params.client,
        module: await userStore.getModuleName(moduleId),
      },
    }
    const routeData = router.resolve(linkObj)
    window.open(routeData.href, '_blank')
  }
  //  userStore.setCurrentRole(item.moduleId)
}
</script>

<template>
  <v-menu max-width="600">
    <template v-slot:activator="{ props }">
      <v-btn color="white" v-bind="props" class="mr-0 pl-0 pl-sm-5 pr-5"
        ><v-icon
          icon="mdi-dots-vertical"
          :size="isPhone() || display.smAndDown ? '30' : 'default'"
        ></v-icon
      ></v-btn>
    </template>
    <v-list min-width="200">
      <v-list-item
        v-for="(item, index) in navigationStore.moduleMenu"
        :active="item.module === router.currentRoute.value.params.module"
        :key="index"
      >
        <v-hover v-slot="{ isHovering, props }">
          <v-list-item-title class="text-capitalize" v-bind="props">
            <button
              :class="{ 'mr-6': !isHovering }"
              @click.prevent="clickHandler(item.moduleId)"
            >
              <v-icon
                v-if="item.module === router.currentRoute.value.params.module"
                icon="mdi-view-module"
              ></v-icon>
              <v-icon v-else icon="mdi-view-module-outline"></v-icon>

              {{ item.title }}
            </button>

            <v-icon
              v-if="isHovering"
              size="x-small"
              icon="mdi-open-in-new"
              class="mx-1"
              @click.prevent="clickHandler(item.moduleId, true)"
            >
            </v-icon>
          </v-list-item-title>
          <!--
              @click.prevent="clickHandler(item.moduleId, true)"-->
        </v-hover>
      </v-list-item>
    </v-list>
  </v-menu>
</template>
