import { Timestamp } from 'firebase/firestore'

/**
 * @param length The id's character count
 * @returns A string of random alphanumeric characters of the given length
 */
export function makeid(length: number): string {
  let result = ''
  const characters =
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
  const charactersLength = characters.length
  let counter = 0
  while (counter < length) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength))
    counter += 1
  }
  return result
}

export function makeFirestoreId(date: number | null = null): string {
  return `v1-${date ?? new Date().getTime()}-${makeid(6)}`
}

export function base64ImageToFile(str: string, filename: string) {
  // https://stackoverflow.com/questions/35940290/how-to-convert-base64-string-to-javascript-file-object-like-as-from-file-input-f
  const mime = str.split(',')[0].match(/:(.*?);/)![1]
  return new File([base64ImageToBlob(str)], filename, { type: mime })
}

export function base64ImageToBlob(str: string) {
  // extract content type and base64 payload from original string
  const pos = str.indexOf(';base64,')
  const type = str.substring(5, pos)
  const b64 = str.substr(pos + 8)

  // decode base64
  const imageContent = atob(b64)

  // create an ArrayBuffer and a view (as unsigned 8-bit)
  const buffer = new ArrayBuffer(imageContent.length)
  const view = new Uint8Array(buffer)

  // fill the view, using the decoded base64
  for (let n = 0; n < imageContent.length; n++) {
    view[n] = imageContent.charCodeAt(n)
  }

  // convert ArrayBuffer to Blob
  const blob = new Blob([buffer], { type: type })

  return blob
}

export function typedArrayToURL(
  data: Uint8Array,
  filename: string,
  mimeType: string
) {
  const blob = new Blob([data], {
    type: mimeType,
  })

  // const a = document.createElement('a')
  const url = window.URL.createObjectURL(blob)
  const file = new File([blob], filename, { type: mimeType })
  return url
}

/**
 * Formats a JS date object into a string
 * @param date A JavaScript date object
 * @format A string to specify how to format the date object
 * @returns A formatted string representation of the JS date
 */
export function formatDate(
  date: Date,
  withTime = true,
  format: '24hour' | 'monthYear' | undefined = undefined
): string {
  if (!date || !date.getDate) {
    return ''
  }
  let d: string | number = date.getDate()
  const m = date.getMonth() + 1
  const y = date.getFullYear()
  let hr: string | number = ((date.getHours() + 11) % 12) + 1
  let min: string | number = date.getMinutes()
  if (min < 10) min = '0' + min
  if (d < 10) d = '0' + d

  let ampm = date.getHours() < 12 ? 'am' : 'pm'

  if (format === '24hour') {
    hr = date.getHours()
    // hr = hr + (ampm === 'pm' ? 12 : 0)
    ampm = ''
    if (hr < 10) {
      hr = '0' + hr
    }
  }
  let dateString = `${m}/${d}/${y}`
  if (format === 'monthYear') {
    dateString = `${m}/${y}`
  }
  if (withTime) {
    return `${dateString} ${hr}:${min}${ampm}`
  }
  return dateString
}

/**
 * Formats a given string or number by padding it with leading zeros to meet the
 * minimum length
 * @param input The string or number to pad with leading zeros
 * @param length The minimum length of the output string
 * @returns A string of the given input padded with leading zeros to meet the
 * given minimum length
 */
export function padWithLeadingZeros(input: string | number, length: number) {
  let output = input + ''
  const zerosNeeded = length - output.length
  for (let i = 0; i < zerosNeeded; i++) {
    output = '0' + output
  }
  return output
}

/**
 * Consumes a firestore timestamp and produces a string representation thereof
 * @param fsdate A firestore timestamp object to format as a string
 * @returns A formatted string from the given firestore date object
 */
export function formatFirestoreDate(
  fsdate: Timestamp | undefined
): string | null {
  return fsdate ? formatDate(dateFStoJS(fsdate)) : null
}

/**
 * Consumes a firestore timestamp and returns an equivalent JS date
 * @param d A firestore timestamp
 * @returns The equivalent JS date
 */
export function dateFStoJS(d: Timestamp): Date {
  return new Date(d.seconds * 1000)
}

export function fileToBase64(file: File): Promise<string | ArrayBuffer | null> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result)
    reader.onerror = reject
  })
}

/**
 * @param inputText: text to be measured
 * @param backupRatio: if something goes wrong, this number will be used to give a width estimate, the average open-sans font height-width ratio is 0.5
 *
 * @returns {number} numeric value of width in pixels
 */
export const getTextWidth = (() => {
  const container = document.createElement('canvas')

  return function (
    inputText?: string | number | null,
    backupRatio = 0.5
  ): number {
    let width = 0
    let text = inputText ?? ''
    text = text.toString()

    const context = container.getContext('2d')

    if (context) {
      context.font = window
        .getComputedStyle(document.body)
        .getPropertyValue('font')
      width = context.measureText(text).width
      return width
    } else {
      /* if something goes wrong mounting the canvas, return an estimate calculated using
       * the backup ratio, the average open-sans font height-width ratio of 0.5
       */
      const fontSize = parseFloat(
        window.getComputedStyle(document.body).getPropertyValue('font-size')
      )
      return fontSize * backupRatio * text.length
    }
  }
})()
