<script setup>
import { defineProps, ref, defineEmits, toRef } from 'vue'

const props = defineProps({
  min: {
    type: Number || String,
  },
  max: {
    type: Number || String,
  },
  initial: {
    type: Number || String,
  },
})
const initialRef = toRef(props, 'initial')
const emit = defineEmits(['updated'])
const minVal = ref(props.min || 0)
const innerValue = ref(initialRef.value) // TODO: this step may be redundant
const increment = val => {
  if (props.max && val >= props.max) {
    //
  } else {
    val++
    emit('updated', val)
  }
}
const decrement = val => {
  if (props.min && val <= props.min) {
    //
  } else {
    val--
    emit('updated', val)
  }
}
</script>

<template>
  <div class="ma-2 d-flex justify-center">
    <v-btn
      elevation="0"
      variant="tonal"
      color="grey-darken-4"
      rounded="e-0"
      width="36"
      @click="decrement(initialRef)"
      :disabled="parseInt(min) === initialRef"
      style="border: 1px solid #000"
      ><v-icon icon="mdi-minus"
    /></v-btn>
    <input
      type="number"
      v-model="initialRef"
      :min="minVal"
      :max="max"
      @change="emit('updated', innerValue)"
      class="field-number rounded-0 text-center text-h5 font-weight-bold"
      style="
        height: 36px;
        border-top: 1px solid #000;
        border-bottom: 1px solid #000;
      "
    />
    <v-btn
      elevation="0"
      variant="tonal"
      color="grey-darken-4"
      rounded="s-0"
      width="36"
      :disabled="parseInt(max) === initialRef"
      @click="increment(initialRef)"
      style="border: 1px solid #000"
      ><v-icon icon="mdi-plus"
    /></v-btn>
  </div>
</template>

<style lang="scss" scoped>
.field-number::-webkit-outer-spin-button,
.field-number::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.field-number {
  -moz-appearance: textfield;
}
</style>
