import { FirestoreUploadData } from '@/model/FormData.model'
import { PWAEmailData } from '@/model/MTCF.model'
import { useUserStore } from '@/stores/user'
import { makeid } from './utils'
import { MTCFIntegration } from '@/model/Integration.model'

let userStore: null | ReturnType<typeof useUserStore> = null
let isInitialized = false

function initStores() {
  if (isInitialized) return
  isInitialized = true
  userStore = useUserStore()
}

/**
 * Takes a firestore upload data object and integration record and produces a
 * corresponding object to be used as a request body for MTCF by bundling in
 * some additional data, such as the user object.
 * @param data The record for which an email is being generated
 * @param integration The MTCF integration object that describes how to generate
 * the email
 * @returns A `PWAEmailData` object to be sent to MTCF as a request body
 */
export function firestoreUploadDataToEmail(
  data: FirestoreUploadData,
  integration: MTCFIntegration
): PWAEmailData {
  initStores()
  const email = {
    record: data.content,
    user: (userStore as any).user,
    userEmail: (userStore as any).user.emailAddress,
    collection: data.fsCollection,
    document: data.id,
    timestamp: new Date().getTime(),
    requestID: makeid(6),
    email: integration.properties.options.email,
  } as PWAEmailData
  return email
}
