/**
 * A lightweight class corresponding to the data sent to MTCF to generate an
 * email from a record
 */
export class PWAEmailData {
  record: { [propertyName: string]: any }
  user: {
    userId: number
    displayName: string
    emailAddress: string
  }
  collection: string
  document: string
  timestamp: number
  requestID: string
  email: MailConfig
  messageType?: string

  constructor(data: {
    record: { [propertyName: string]: any }
    user: {
      userId: number
      displayName: string
      emailAddress: string
    }
    collection: string
    document: string
    timestamp: number
    requestID: string
    email: MailConfig
    messageType?: string
  }) {
    this.record = data.record
    this.user = data.user
    this.collection = data.collection
    this.document = data.document
    this.timestamp = data.timestamp
    this.requestID = data.requestID
    this.email = data.email
    if (data.messageType) this.messageType = data.messageType
  }
}

/**
 * A lightweight class corresponding to the JSON representation of an email fed
 * in by an SQL record's config
 */
export class MailConfig {
  attachments?: IAttachmentConfig[] | any[]
  to: RecipientConfig[] | string[]
  cc: RecipientConfig[] | string[]
  bcc: RecipientConfig[] | string[]
  replyTo?: string
  from?: { name: string; address?: string }
  subject: string
  body: string

  constructor(subject: string, body: string) {
    this.subject = subject
    this.body = body
    this.to = []
    this.cc = []
    this.bcc = []
  }
}

/**
 * A lightweight class representing the formats that an email recipient may have
 * in an email config
 */
export class RecipientConfig {
  address?: string
  groupName?: string
  groupId?: number
}

/**
 * A lightweight interface for an attachment config as specified in an SQL record
 */
export interface IAttachmentConfig {
  type?: 'pdf' | 'csv' | 'xlsx'
  width?: string | number
  loadElementId?: string | number
  url?: string
  filename?: string
  waitTime?: number
  data?: any
}

export class PWADataRequest {
  recordsets: {
    [recordsetName: string]: PWADataRequestRecordset
  }

  constructor(request: {
    recordsets: {
      [recordsetName: string]: PWADataRequestRecordset
    }
  }) {
    this.recordsets = request.recordsets
  }
}

export class PWADataRequestRecordset {
  mode: 'updates' | 'all'
  pagination?:
    | { start: number; end: number }
    | { page: number; pageSize: number }
  query?: string

  constructor(data: {
    mode: 'updates' | 'all'
    pagination?:
      | { start: number; end: number }
      | { page: number; pageSize: number }
    query?: string
  }) {
    this.mode = data.mode
    if (data.pagination) this.pagination = data.pagination
    if (data.query) this.query = data.query
  }
}

export class PWADataResponse {
  status: number
  message: string
  changes: {
    [tableName: string]: {
      records?: any[]
      creates?: any[]
      updates?: any[]
      deletions?: string[]
      currentSize: number
    }
  }

  constructor(status: number, message: string) {
    this.status = status
    this.message = message
    this.changes = {}
  }

  initializeWithChange(
    tableName: string,
    data: {
      records?: any[]
      creates?: any[]
      updates?: any[]
      deletions?: any[]
      currentSize: number
    }
  ) {
    this.changes[tableName] = data
  }
}
