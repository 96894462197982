<script setup>
import { defineProps, computed } from 'vue'
import useWidgetConfig from '../composables/useWidgetConfig'

const props = defineProps({ device: String, config: Object })
const { dataStore } = useWidgetConfig()

const fieldsetCount = computed(() => {
  // count the number of rows in the recordset array
  if (dataStore.recordsets.length > 0)
    return dataStore
      .filteredRecordset(props.config[0].options.recordset)
      .length?.toLocaleString('en-US')
  // return readRecordsets(props.config.options.recordset)[
  //  props.config.options.recordset
  // ].length?.toLocaleString('en-US')
  else return null
})
</script>

<template>
  <div class="my-2">
    <h4 class="text-h5">{{ props.config[0].options?.label }}</h4>
    <div :class="fieldsetCount < 10000 ? 'text-h3' : 'text-h5'">
      {{ fieldsetCount }}
    </div>
  </div>
</template>
