<script setup>
import { defineProps, ref, onMounted, computed } from 'vue'
import { storeToRefs } from 'pinia'
import { useNavigationStore } from '@/stores/navigation'
import { lookupTitle, lookupIcon } from '@/services/lookup.ts'
import { makeid } from '@/services/utils.ts'

const props = defineProps({
  container: String,
  config: { type: Object, required: false },
})

const { modalProperties } = storeToRefs(useNavigationStore())

const panel = ref(props.config?.card?.isExpanded ? [0] : [])

const showExpansion = computed(() => {
  return props.config?.card?.isCollapsible
})

const cardTitle = computed(() => {
  // return props.config?.card?.title
  if (props.config?.card?.title) {
    return lookupTitle(props.config?.card?.title)
  } else return ''
})
const icon = computed(() => {
  return lookupIcon(props.config?.card?.icon)
})

onMounted(() => {
  modalProperties.value.header = props.config?.card?.hasHeader
  modalProperties.value.close = props.config?.card?.isClosable
  modalProperties.value.footer = props.config?.card?.hasFooter
})
</script>
<template>
  <div class="widget-container">
    <v-card
      v-if="container.toLowerCase() === 'card' && !showExpansion"
      class="mb-4 pa-0"
    >
      <v-card-title
        v-if="cardTitle"
        class="text-left pl-5"
        style="color: white; background-color: rgb(70, 130, 45)"
      >
        <v-icon v-if="props.config?.card?.icon" :icon="icon" class="pr-2" />
        {{ cardTitle }}
      </v-card-title>
      <slot />
    </v-card>
    <v-expansion-panels
      v-else-if="container.toLowerCase() === 'card' && showExpansion"
      v-model="panel"
      class="mb-4"
    >
      <v-expansion-panel>
        <v-expansion-panel-title
          color="white"
          expand-icon="mdi-chevron-down"
          collapse-icon="mdi-chevron-up"
        >
          <template v-slot:default="{}">
            <v-row no-gutters class="align-center">
              <v-icon
                v-if="props.config?.card?.icon"
                :icon="icon"
                class="mr-2"
              />
              <h4 class="text-h6">{{ cardTitle }}</h4>
              <v-spacer />
              <!--<v-btn
                elevation="0"
                size="small"
                icon="mdi-format-list-bulleted-square"
                @click.stop="clickHandler('list')"
              ></v-btn>
              <v-btn
                elevation="0"
                size="small"
                icon="mdi-replay"
                @click.stop="clickHandler('refresh')"
              ></v-btn>-->
            </v-row>
          </template>
        </v-expansion-panel-title>
        <v-expansion-panel-text>
          <slot />
        </v-expansion-panel-text>
      </v-expansion-panel>
    </v-expansion-panels>
    <div v-else class="mb-0">
      <slot />
    </div>
  </div>
</template>

<style lang="scss">
.widget-container {
  .v-expansion-panel-title {
    min-height: auto;
    padding: 0.75rem;
  }

  .v-expansion-panel-text__wrapper {
    padding: 0.5rem;
  }
  .v-expansion-panel-title__overlay {
    opacity: 0 !important;
  }
}
</style>
