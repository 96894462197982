<script>
import WidgetContainer from '@/layouts/WidgetContainer.vue'
import WidgetFilter from './WidgetFilter.vue'
import WidgetGrid from './WidgetGrid.vue'
import WidgetMap from './WidgetMap.vue'
import WidgetMenu from './WidgetMenu.vue'
import WidgetForm from './WidgetForm.vue'
import WidgetIndicator from './WidgetIndicator.vue'
import WidgetLinear from './WidgetLinear.vue'
import WidgetDocument from './WidgetDocument.vue'

import { h } from 'vue'
export default {
  props: {
    type: String,
    device: String,
    data: Object,
    properties: { type: Object, required: false },
  },

  setup(props) {
    const widgetRenderer = (type, data) => {
      if (type?.toLowerCase() === 'filter')
        return h(WidgetFilter, { device: props.device, config: data })
      if (type?.toLowerCase() === 'grid')
        return h(WidgetGrid, { device: props.device, config: props.data })
      if (type?.toLowerCase() === 'map')
        return h(WidgetMap, { device: props.device, config: data })
      if (type?.toLowerCase() === 'menu')
        return h(WidgetMenu, { device: props.device, config: props.data })
      if (type?.toLowerCase() === 'form')
        return h(WidgetForm, {
          device: props.device,
          config: props.data,
          properties: props.properties,
        })
      if (type?.toLowerCase() === 'pdfviewer')
        return h(WidgetDocument, {
          device: props.device,
          config: props.data,
          properties: props.properties,
        })
      if (type?.toLowerCase() === 'indicator')
        return h(WidgetIndicator, { device: props.device, config: props.data })
      if (type?.toLowerCase() === 'linearchannel') {
        return h(WidgetLinear, { device: props.device, config: props.data })
      }
    }

    return () => {
      let returnVal
      props.data?.forEach(x => {
        returnVal = h(
          WidgetContainer,
          {
            container: x?.container,
            config: x,
          },
          () => h(widgetRenderer(props.type, x))
        )
      })
      return returnVal
      // return h('div', {}, returnVal)
    }
  },
}
</script>
