<script setup>
import { defineProps, watch, onMounted } from 'vue'
import { storeToRefs } from 'pinia'
import useCommonConfig from '@/composables/useCommonConfig'

const props = defineProps({
  pageID: String,
  device: String,
})

const { userStore, settingsStore, dataStore, router, isOffline } =
  useCommonConfig()
const { showUserMenuSettings, toggleSettings } = storeToRefs(settingsStore)
const { user, currentRole } = storeToRefs(userStore)

const showUserMenuSettingsHandler = () => {
  // resets the user menu item
  showUserMenuSettings.value = false

  if (
    (currentRole.value?.roleId && currentRole.value?.roleId === 4) ||
    user.value.permissions?.canDeleteDataRecords ||
    user.value.permissions?.canDeleteDataTables
  )
    showUserMenuSettings.value = true
}

const clearHandler = (del = false) => {
  if (del) dataStore.deleteIndexedDB()
  else dataStore.clearIndexedDB()

  toggleSettings.value = false
  localStorage.clear()

  window.location.href = '/sign-in'
  userStore.logOut()
  window.location.reload()
}

watch(
  () => currentRole.value,
  () => {
    showUserMenuSettingsHandler()
  }
)
/* Future work
watch(
  () => props.pageID,
  newVal => {
    showUserMenuSettingsHandler(newVal)
  }
) */
/* Future work
const openList = computed(() => {
  const returnArr = ['abc', 'def', 'ghi']
  // settings.value?.foreach(x => {
  //   if (x.pageId == props.pageID) returnArr.push(x.title)
  // })
  return returnArr
})
const openConfig = ref({
  isDisabled: false,
  help: '',
  placeholder: '',
  options: {
    list: openList,
  },
  name: 'selectDepartmentCategory',
  width: '100%',
  type: 'select',
  isRequired: true,
  tooltip: '',
})

const selectedValue = val => {
  console.log('val ', val)
}*/

onMounted(() => {
  toggleSettings.value = false
  showUserMenuSettingsHandler()
})
</script>

<template>
  <v-dialog v-model="toggleSettings" width="800">
    <v-card>
      <v-card-title
        class="px-0 pt-0 d-flex justify-end"
        style="color: white; background-color: rgb(70, 130, 45)"
      >
        <h2 class="pl-4 pt-4">
          <v-icon icon="mdi-cog-outline" class="mr-2" />
          Settings
        </h2>
        <v-spacer />
        <v-btn
          icon="mdi-close"
          variant="text"
          elevation="0"
          @click="toggleSettings = false"
          color="white"
        ></v-btn>
      </v-card-title>
      <v-sheet style="height: 20px; width: 100%"></v-sheet>
      <v-card-text
        class="px-5 py-0"
        :style="{ 'max-height': '70vh', 'overflow-y': 'auto' }"
      >
        <div
          v-if="
            user.permissions?.canDeleteDataRecords ||
            user.permissions?.canDeleteDataTables
          "
          class="my-2"
        >
          <h3 class="lh-1">Clear Browser Cache</h3>
          <small class="lh-1 text-subtitle-1">(You will be logged out.)</small>
          <div v-if="user.permissions?.canDeleteDataRecords" class="my-2">
            <v-btn
              color="primary"
              class="text-capitalize"
              @click.prevent="clearHandler"
              ><v-icon icon="mdi-trash-can-outline" /> Clear Data</v-btn
            >
          </div>
          <div v-if="user.permissions?.canDeleteDataTables">
            <v-btn
              color="primary"
              class="text-capitalize"
              @click.prevent="clearHandler(true)"
              ><v-icon
                icon="fa: fas fa-radiation"
                size="small"
                class="mr-1"
              />Delete All</v-btn
            >
          </div>
        </div>
      </v-card-text>
      <v-divider />
      <v-card-actions class="px-5 d-flex justify-end">
        <v-btn
          class="bg-primary text-capitalize"
          @click="toggleSettings = false"
          ><v-icon icon="mdi-close" />Close</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
