import { formatFirestoreDate, typedArrayToURL } from '@/services/utils'
import { WidgetFormConfig, WidgetFormRowConfig } from './WidgetForm.model'
import { Incrementor } from './Util.model'
import {
  PDFDocument,
  StandardFonts,
  rgb,
  PDFPage,
  PDFPageDrawTextOptions,
  PDFImage,
} from 'pdf-lib'

/**
 * Helper class that helps handle locally generating a PDF export for a given
 * record and form layout.
 */
export class RecordExportHelper {
  url?: string
  layout: WidgetFormConfig
  record: any
  builder: PDFSchemaBuilder
  options?: PDFExportOptions

  /**
   * Instantiates a Record Export Helper object to generate a PDF using a given
   * record and layout
   * @param layout The form layout that should be used to export the given
   * record to a PDF
   * @param record The data that should populate the given form layout
   * @param options Optional parameters that may help in creating the PDF, such
   * as the record type and record ID
   */
  constructor(
    layout: WidgetFormConfig,
    record: any,
    options?: PDFExportOptions
  ) {
    this.layout = layout
    this.record = record
    this.builder = new PDFSchemaBuilder()
    this.options = options
  }

  /**
   * Asynchronously initializes this export helper's PDF builder. This private
   * method is called in `getData()` to create the PDF before its data is
   * exported.
   */
  private async initPDFConfig() {
    const builder = new PDFSchemaBuilder()
    if (this.options?.recordType)
      await builder.addElement('header', this.options?.recordType)
    if (this.options?.recordId)
      await builder.addElement('header', this.options?.recordId)
    for (const tab of this.layout.tabs ?? []) {
      await builder.addElement('tab', tab.label)
      for (const section of tab.sections ?? []) {
        await builder.addElement('section', section.label)
        for (const row of section.rows ?? []) {
          await this.initPDFConfigRow(builder, row)
        }
      }
      for (const row of tab.rows ?? []) {
        await this.initPDFConfigRow(builder, row)
      }
    }
    for (const section of this.layout.sections ?? []) {
      await builder.addElement('section', section.label)
      for (const row of section.rows ?? []) {
        await this.initPDFConfigRow(builder, row)
      }
    }
    for (const row of this.layout.rows ?? []) {
      await this.initPDFConfigRow(builder, row)
    }
    this.builder = builder
  }

  /**
   * Adds a WidgetFormRowConfig's data to the given PDF builder
   * @param builder The builder object that helps create a PDF export
   * @param row The row config that will be added to the builder's PDF
   */
  async initPDFConfigRow(builder: PDFSchemaBuilder, row: WidgetFormRowConfig) {
    for (const col of row.cols) {
      for (const row of col.rows ?? []) {
        await this.initPDFConfigRow(builder, row)
      }
      for (const field of col.fields ?? []) {
        if (
          ['signature', 'image'].includes(field.type) &&
          this.record[field.property]
        ) {
          await builder.addElement(
            'image',
            field.label,
            this.record[field.property]
          )
        } else {
          await builder.addElement(
            'field',
            field.label,
            this.record[field.property]
          )
        }
      }
    }
  }

  /**
   * Generates a PDF using PDF Schema Builder, then saves it to a blob and
   * returns the resource's URL.
   * @returns The url for this export helper's PDF
   */
  async getData(): Promise<string> {
    await this.initPDFConfig()
    return this.builder.getData()
  }
}

/**
 * A PDF Builder class to help generate record exports. This class should be
 * instantiated by a `RecordExportHelper` object rather than created on its own
 * unless a PDF is being generated outside of a record export.
 */
export class PDFSchemaBuilder {
  incrementor: Incrementor
  doc?: PDFDocument
  font?: any
  activePage: {
    page: PDFPage | undefined
    width: number
    height: number
  }
  pages: PDFPage[]

  pageCount: number
  yPos: number

  /**
   * Initializes a new PDF builder object. The builder has elements added to it
   * but is agnostic of the layout for whatever data it's being handed.
   */
  constructor() {
    this.incrementor = new Incrementor(1)
    this.pageCount = 0
    this.yPos = 0
    this.activePage = {
      page: undefined,
      width: 0,
      height: 0,
    }
    this.pages = []
  }

  /**
   * Adds the given element to this builder's PDF.
   * @param type The type of element that's being added to the PDF
   * @param label The element's label (text for a section, tab, etc., or the
   * field label for a form field)
   * @param value The value of the given form field, if the element type is a
   * form field.
   */
  async addElement(
    type: 'tab' | 'section' | 'field' | 'image' | 'header',
    label: string,
    value = ''
  ) {
    if (!this.doc) {
      this.doc = await PDFDocument.create()
      this.font = await this.doc.embedFont(StandardFonts.TimesRoman)
      this.createNewPage()
    }

    const maxPageHeight = this.activePage.height - 100
    const heightDict: { [key: string]: number } = {
      tab: 20,
      section: 16,
      field: 12,
      image: 100,
      header: 22,
    }
    let heightToAdd = heightDict[type]

    // if (this.yPos + heightToAdd > maxPageHeight) {
    // this.createNewPage()
    // }

    let schemaObj: PDFPageDrawTextOptions = {
      y: this.activePage.height - (this.yPos + 70),
      x: 30,
      size: heightDict[type],
      font: this.font,
      color: rgb(0.27, 0.51, 0.18), // Secondary
    }

    if (type === 'header') {
      schemaObj = {
        y: this.activePage.height - (this.yPos + 70),
        x: 30,
        size: heightDict[type],
        font: this.font,
        color: rgb(0, 0, 0), // Primary
      }
    }

    if (type === 'section') {
      schemaObj = {
        y: this.activePage.height - (this.yPos + 70),
        x: 50,
        size: heightDict[type],
        font: this.font,
        color: rgb(0.21, 0.38, 0.57), // Primary
      }
    }

    if (type === 'field' || type === 'image') {
      schemaObj = {
        y: this.activePage.height - (this.yPos + 70),
        x: 70,
        size: 12,
        font: this.font,
        color: rgb(0.1, 0.4, 0.8), // Content
      }
    }

    // Add the field value if applicable
    if (type === 'field' || type === 'image') {
      if (!value) {
        // /* Remove "No Data" label */
        // this.activePage.page!.drawText('[No Data]', {
        //   y: this.activePage.height - (this.yPos + 70),
        //   x: 310,
        //   size: 12,
        //   font: this.font,
        //   color: rgb(0.7, 0.7, 0.7),
        // })
      } else {
        let writtenVal: any = value
        if (Array.isArray(writtenVal)) writtenVal = writtenVal.join(', ')
        if (writtenVal?.seconds) writtenVal = formatFirestoreDate(writtenVal)
        if (typeof writtenVal === 'string') {
          const s = this.breakUpString(writtenVal)
          heightToAdd = this.getTextHeight(s)

          if (this.yPos + heightToAdd > maxPageHeight) {
            this.createNewPage()
          }

          this.activePage.page!.drawText(this.breakUpString(writtenVal), {
            y: this.activePage.height - (this.yPos + 70),
            x: 310,
            font: this.font,
            size: 12,
            lineHeight: 14,
          })
        } else if (writtenVal.thumbnail) {
          try {
            let image: undefined | PDFImage
            if (writtenVal.thumbnail.split(',')[0].includes('png')) {
              image = await this.doc.embedPng(writtenVal.thumbnail)
            } else {
              image = await this.doc.embedJpg(writtenVal.thumbnail)
            }
            const imageDims = image!.scale(0.5)

            this.activePage.page!.drawImage(image!, {
              y: this.activePage.height - (this.yPos + 70) - 90,
              x: 310,
              width: imageDims.width,
              height: imageDims.height,
            })
          } catch (e) {
            this.activePage.page!.drawText('[Image Embed Unsuccessful]', {
              y: this.activePage.height - (this.yPos + 70),
              x: 310,
              size: 12,
              font: this.font,
              color: rgb(0, 0.1, 0.3),
            })
            console.error(`Image embed failed`)
          }
        }
      }
    }

    if (this.yPos + heightToAdd > maxPageHeight) {
      this.createNewPage()
      this.yPos = 0
    }
    // Reset label y value if needed
    schemaObj.y = this.activePage.height - (this.yPos + 70)
    this.activePage.page!.drawText(label, schemaObj)

    this.yPos += heightToAdd + 10
  }

  /**
   * Adds a new page to this builder's PDF so that the builder can resume
   * populating the next page
   */
  private async createNewPage() {
    this.activePage.page = this.doc?.addPage()
    this.pages.push(this.activePage.page!)
    const { width, height } = this.activePage.page!.getSize()
    this.activePage.height = height
    this.activePage.width = width
    this.yPos = 0
  }

  /**
   * Adds page numbering to this builder's PDF, then returns this PDF's resource
   * URL.
   * @returns A url to this builder's finished PDF file
   */
  async getData(): Promise<string> {
    for (let i = 0; i < this.pages.length; i++) {
      this.pages[i]!.drawText(`Page ${i + 1} of ${this.pages.length}`, {
        y: 30,
        x: 510,
        size: 10,
        font: this.font,
        color: rgb(0, 0, 0),
      })
    }

    return typedArrayToURL(
      await this.doc!.save(),
      'sample.pdf',
      'application/pdf'
    )
  }

  /**
   * Tokenizes a string and breaks it into lines of a set max length
   * @param s The string to break into new lines
   * @returns A string broken into lines which wrap at a set character length
   */
  private breakUpString(s: string): string {
    const MAX_LINE_LENGTH = 50
    if (s.length < MAX_LINE_LENGTH) return s
    const wordList = s.split(' ')
    let currentLine = wordList[0]
    const lineList: string[] = []
    wordList.shift()

    for (const word of wordList) {
      if (currentLine.length + word.length + 1 < MAX_LINE_LENGTH) {
        currentLine += ` ${word}`
      } else {
        lineList.push(currentLine)
        currentLine = word
      }
    }
    lineList.push(currentLine)

    console.log(`Multiline message: ${lineList.join('\n')}`)
    return lineList.join('\n')
  }

  /**
   * Produces the PDF-useful height of the given string
   * @param s The string whose prescribed height to get
   * @returns The PDF height of the given string
   */
  private getTextHeight(s: string): number {
    return (s.split('\n').length - 1) * 14 + 14
  }
}

export class PDFExportOptions {
  recordId?: string
  recordType?: string
}
