import Vuetify from 'vuetify'
import { createVuetify } from 'vuetify'
import { aliases, fa } from 'vuetify/iconsets/fa'
import { mdi } from 'vuetify/iconsets/mdi'
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'
import 'vuetify/styles'
import '@/assets/sass/main.scss'
import '@mdi/font/css/materialdesignicons.css'
import '@/assets/icons/fontawesome.min.css'
import '@/assets/icons/all.min.css'
// import '@fortawesome/fontawesome-free/css/all.css'

const allColors = {
  primary: '#376191',
  secondary: '#46822d',
}
const vuetify = createVuetify({
  components,
  directives,
  display: {
    mobileBreakpoint: 'sm',
  },
  icons: {
    defaultSet: 'mdi',
    aliases,
    sets: {
      fa,
      mdi,
    },
  },
  theme: {
    themes: {
      light: {
        dark: false,
        colors: {
          primary: allColors.primary,
          secondary: allColors.secondary,
        },
      },
    },
  },
})

export default vuetify
