<script setup>
import { ref, watch, onMounted } from 'vue'
import { storeToRefs } from 'pinia'
import { useUserStore } from '@/stores/user'
import { useNavigationStore } from '@/stores/navigation'
import { onUnmounted } from 'vue'
import { isPhone } from '@/services/deviceDetection'

const userStore = useUserStore()
const navigationStore = useNavigationStore()

const { showSnackbar, newVersion, version, isReloading, isSigningIn } =
  storeToRefs(navigationStore)
const { isAuthenticated } = storeToRefs(userStore)
const refreshing = ref(false)
const registration = ref(null)
const updatesExist = ref(false) // necessary?
const showOverlay = ref(false)
const overlayText = ref('')

const updateHandler = event => {
  console.log('update handler')
  showOverlay.value = true
  registration.value = event.detail
  updatesExist.value = true
  refreshApp()
}

const timeout = setTimeout(() => {
  showOverlay.value = false

  isReloading.value = false
}, 8000)

const clickHandler = (mode = '') => {
  // userStore.logOut()
  version.value = newVersion.value
  showSnackbar.value = false
  showOverlay.value = true
  isReloading.value = true
  if (mode === 'check') overlayText.value = 'Checking for Updates'
  else if (mode === 'patch') overlayText.value = 'Applying Patch Update'
  else overlayText.value = 'Applying Updates'
  window.location.reload(true)
}

// Called when the user accepts the update
const refreshApp = () => {
  updatesExist.value = false
  // Make sure we only send a 'skip waiting' message if the SW is waiting
  if (!registration.value || !registration.value.waiting) return
  // send message to SW to skip the waiting and activate the new SW
  registration.value.waiting.postMessage({ type: 'SKIP_WAITING' })
}

document.addEventListener('swUpdated', updateHandler, { once: true })

// Prevent multiple refreshes
navigator?.serviceWorker?.addEventListener('controllerchange', () => {
  showOverlay.value = false
  overlayText.value = 'Applying Updates'
  isReloading.value = false
  if (refreshing.value) return
  refreshing.value = true
  // Here the actual reload of the page occurs
  window.location.reload()
})

watch(
  () => newVersion.value,
  (newVal, oldVal) => {
    // this will only catch when the status changes after sign-in
    // versions are coming in as a string. To properly compare them, split them by . and compare each element (int) with the newVersion counterpart

    if (!isSigningIn.value)
      if (version.value) {
        if (newVal !== oldVal) {
          {
            const newValArr = newVal.split('.')
            const oldValArr = oldVal.split('.')

            for (let i = 0; i < newValArr.length; i++) {
              if (newValArr[i] !== oldValArr[i]) {
                if (i <= 2) {
                  // 0 is major, 1 is minor, 2 is patches
                  showSnackbar.value = true
                  break
                } else {
                  // this is basically not necessary anymore, but it may be helpful in the future.
                  // this case applies the patch automatically
                  clickHandler('patch')
                }
              }
            }

            // set the systemUpdate value to false to prevent infinite reloads!
            //navigationStore.showSnackbar = true
            // location.reload(true)
          }
        }
      } else {
        version.value = newVersion.value
      }
  },
  { deep: true }
)

watch(
  () => isSigningIn.value,
  newVal => {
    if (newVal) {
      // if the user is signing in, handle the updates automatically
      isSigningIn.value = false
      // refreshApp()
      if (newVersion.value !== version.value) clickHandler('check')
    }
  }
)

onMounted(() => {
  showOverlay.value = isReloading.value
  // catch in case the overlay displays for too long
  if (showOverlay.value === true) {
    overlayText.value = 'Applying Updates'
    timeout
  }
})

onUnmounted(() => {
  clearTimeout(timeout)
})
</script>

<template>
  <v-snackbar v-model="showSnackbar" color="warning" timeout="-1">
    An update is available
    <template v-slot:actions>
      <v-btn class="text-capitalize" @click="clickHandler">
        {{ isPhone ? 'Tap' : 'Click' }} to Update
      </v-btn>
    </template>
  </v-snackbar>
  <v-overlay
    v-model="showOverlay"
    class="flex-column align-center justify-center update-snackbar__overlay"
  >
    <h2 class="mb-2 text-h3 text-white">{{ overlayText }}</h2>
    <div class="w-100 text-center">
      <v-progress-circular
        color="primary"
        indeterminate
        size="64"
      ></v-progress-circular>
    </div>
  </v-overlay>
</template>

<style lang="scss">
.update-snackbar__overlay .v-overlay__scrim {
  opacity: 80%;
}
</style>
