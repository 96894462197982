<script setup>
import { defineProps, defineEmits, computed, ref, watch, onMounted } from 'vue'
import useWidgetConfig from '../composables/useWidgetConfig'
import FormNumberIncrementer from './FormNumberIncrementer.vue'
import Datepicker from '@vuepic/vue-datepicker'
import WidgetFilterLayoutExpand from './WidgetFilterLayoutExpand.vue'
import WidgetFilterLayoutSlide from './WidgetFilterLayoutSlide.vue'

const props = defineProps({
  device: Object,
  itemConfig: Object,
  recordset: Object,
  styles: String,
})
const { dataStore } = useWidgetConfig()

const emit = defineEmits(['filter'])
const selectAllModel = ref(true)
const rangeModel = ref([0, 1])
const checklistSearch = ref()
const checkboxesModel = ref([])
const tempExcludeList = ref([]) // ref(props.itemConfig.values)
const excludeList = ref(
  props.itemConfig.type === 'checkboxList' ? props.itemConfig.values : []
)
// initialize filter value only for checkboxes ... for now
const filterValue = ref(
  props.itemConfig.type === 'checkboxList'
    ? {
        value: checkboxesModel.value,
        field: props.itemConfig.property,
        compare: props.itemConfig.compare,
      }
    : {
        value: rangeModel.value,
        field: props.itemConfig.property,
        compare: props.itemConfig.compare,
      }
)
const initialMinMax = ref([])
const minMax = ref([0, 1])
const hasChanges = ref(false)
const showCheckmark = ref(false)
const expandExcludeList = ref(false)
const showRangeReset = ref(false)
const nonValue = '[none]'

const rangeFinder = computed(() => {
  if (props.itemConfig.type.toLowerCase() === 'range')
    if (dataStore.recordsets.length > 0) {
      const property = props.itemConfig.property
      const tempArray = props.recordset ? [...props.recordset] : []
      let min, max
      for (let i = 0; i < tempArray.length; i++) {
        if (props.itemConfig.dataType === 'date') {
          min = tempArray[i][property]?.seconds
          max = tempArray[i][property]?.seconds
          // as soon as we have our initial value, let's go
          if (tempArray[i][property]?.seconds) break
        } else {
          if (tempArray[i][property]) {
            // we don't want to add null values
            min = tempArray[i][property] ? parseInt(tempArray[i][property]) : 0 // no nulls
            max = tempArray[i][property] ? parseInt(tempArray[i][property]) : 0

            // as soon as we have our initial value, let's go
            break
          }
        }
      }

      for (let i = 1; i < tempArray.length; i++) {
        let val

        if (props.itemConfig.dataType === 'date')
          if (tempArray[i][property]?.seconds)
            val = tempArray[i][property]?.seconds
          else val = tempArray[i][property]
        if (val !== null) {
          // filter out null values
          min = val < min ? val : min
          max = val > max ? val : max
        }
      }
      // set initial values to enable reset
      if (initialMinMax.value.length <= 0 && min && max) {
        // eslint-disable-next-line
        initialMinMax.value = JSON.parse(JSON.stringify([min, max]))
        // eslint-disable-next-line
        initialMinMax.value.sort((a, b) => a - b)
      }
      return [min, max]
    }

  return []
})

// methods
const listGenerator = (exclude = false) => {
  if (dataStore.recordsets.length > 0) {
    let tempSet = new Set()
    let tempArray = []
    for (let item of props.recordset) {
      const itemValue = item[props.itemConfig.property]
        ? item[props.itemConfig.property].toLowerCase()
        : nonValue.toLowerCase()
      if (tempSet.has(itemValue)) {
        tempArray[
          tempArray.findIndex(el => {
            return el.value.toLowerCase() === itemValue
          })
        ].count++
      } else {
        tempSet.add(itemValue)
        tempArray.push({
          value: itemValue,
          count: 1,
        })
      }
      if (!exclude) {
        // sets default checks
        checkboxesModel.value = Array.from(tempSet)
      } else {
        // really only for the de-select all
        excludeList.value = Array.from(tempSet)
      }
    }

    return tempArray
  }

  return null
}

// click handlers
const selectAllClickHandler = () => {
  const tempCheckboxesModel = []
  checklistItems.value.forEach(x => {
    tempCheckboxesModel.push(x.value)
  })
  // toggle
  if (selectAllModel.value) {
    // if checked, we want to uncheck
    selectAllModel.value = false
    tempExcludeList.value = [...tempCheckboxesModel]
    // unselect all checkboxes
    checkboxesModel.value = []
    // listGenerator(true)
    //
    filterValue.value.value = [...excludeList.value]
  } else {
    selectAllModel.value = true
    tempExcludeList.value = []
    // listGenerator()
    filterValue.value.value = [...excludeList.value]
    checkboxesModel.value = [...tempCheckboxesModel] // props.itemConfig.values // something else
  }

  // emit('filter', filterValue.value)
  if (tempCheckboxesModel.length > 0) hasChanges.value = true
}

const checklistClickHandler = val => {
  if (tempExcludeList.value.includes(val)) {
    tempExcludeList.value.splice(tempExcludeList.value.indexOf(val), 1)
  } else {
    tempExcludeList.value.push(val)
  }
}

const emitHandler = (val, idx) => {
  rangeModel.value[idx] = val
  showRangeReset.value = true
}

const resetHandler = () => {
  // excludeList.value = props.itemConfig.values
  if (props.itemConfig.type === 'checkboxList') {
    listGenerator()
    excludeList.value = []
    selectAllModel.value = true
    filterValue.value.value = [...excludeList.value]
    tempExcludeList.value = []
    emit('filter', filterValue.value)
  } else {
    // rangeModel.value = [...minMax.value]
    emit('filter', ['{Min}', '{Max}'])
    // there's a watcher on rangemodel that also sets the filterValue... we don't want to set this until after the filter has been emitted
    rangeModel.value = JSON.parse(JSON.stringify(minMax.value)) // [...minMax.value]
    // rangeModel.value.sort((a, b) => a - b)
  }
  showCheckmark.value = false
  showRangeReset.value = false
}

const applyHandler = () => {
  showCheckmark.value = true
  // if checklist
  if (props.itemConfig.type === 'checkboxList') {
    // excludeList.value = [...tempExcludeList.value]
    if (tempExcludeList.value.length > 0) {
      // if the tempExcludeList has items, add them to the exclude list
      tempExcludeList.value.forEach(x => {
        excludeList.value.push(x)
      })
    } else {
      // if there are no items in the tempExcludeList, then we want to clear out the exclude list
      excludeList.value = []
    }
    filterValue.value.value = [...excludeList.value]
    tempExcludeList.value = []

    listGenerator()
    hasChanges.value = false
    selectAllModel.value = true
  } else {
    showRangeReset.value = true
    filterValue.value.value = [...rangeModel.value]
  }

  // replace none values
  if (filterValue.value.value.indexOf(nonValue) > -1) {
    filterValue.value.value[filterValue.value.value.indexOf(nonValue)] = null
  }
  emit('filter', filterValue.value)
}

const checklistItems = computed(() => {
  if (props.itemConfig.type === 'checkboxList') return listGenerator()

  return []
})

// computed properties

const isCompact = computed(() => {
  return props.styles?.toLowerCase() === 'compact'
  // TODO: may need to account for "stacked" if this isn't the default
})

const searching = computed(() => {
  const itemsList = checklistItems.value.filter(x => {
    return !excludeList.value.some(y => {
      return x.value === y
    })
  })

  if (!checklistSearch.value) return itemsList

  const search = checklistSearch.value.toLowerCase()

  return itemsList.filter(item => {
    const text = item.value?.toLowerCase()
    return text?.indexOf(search) > -1
  })
})

const customStyle = computed(() => {
  return props.itemConfig.options.style === 'text'
    ? null
    : props.itemConfig.options.style
})

const computedExcludeList = computed(() => {
  if (!expandExcludeList.value && excludeList.value.length > 10) {
    const returnArr = []
    for (let i = 0; i < 11; i++) {
      returnArr.push(excludeList.value[i])
    }
    return returnArr
  }

  return excludeList.value
})

const showReset = computed(() => {
  if (props.itemConfig.type === 'checkboxList')
    return excludeList.value.length > 0

  return showRangeReset.value
  /* return (
    minMax.value[0] !== initialMinMax.value[0] ||
    minMax.value[1] !== initialMinMax.value[1]
  ) */
})

const dateFormatterMin = computed(
  // getter
  {
    get() {
      if (rangeModel.value) return Math.abs(rangeModel.value[0]) * 1000

      return null
    },
    // setter
    set(newValue) {
      const d = new Date(newValue)
      const s = d.getTime() / 1000
      rangeModel.value[0] = s
    },
  }
)

const dateFormatterMax = computed(
  // getter
  {
    get() {
      if (rangeModel.value) return Math.abs(rangeModel.value[1]) * 1000

      return null
    },
    // setter
    set(newValue) {
      const d = new Date(newValue)
      const s = d.getTime() / 1000
      rangeModel.value[1] = s
    },
  }
)

const ticks = computed(() => {
  const returnArr = []
  if (props.itemConfig.options.hasSlider)
    props.recordset?.forEach(x => {
      if (x[props.itemConfig.property]) {
        if (props.itemConfig.dataType === 'date') {
          if (x[props.itemConfig.property].seconds)
            returnArr.push(x[props.itemConfig.property].seconds)
        } else returnArr.push(parseInt(x[props.itemConfig.property]))
      }
    })
  return returnArr.sort((a, b) => a - b)
})

// watchers
// toggle the select all checkbox by checking if the exclude list is in use
watch(
  () => tempExcludeList.value,
  newVal => {
    if (newVal.length > 0) selectAllModel.value = false
    else selectAllModel.value = true
  },
  { deep: true }
)

watch(
  () => rangeFinder.value,
  newVal => {
    rangeModel.value = [...newVal].sort((a, b) => a - b)
    minMax.value = [...newVal].sort((a, b) => a - b)
  },
  { deep: true }
)

// change filters when range values change
watch(
  () => rangeModel.value,
  newVal => {
    if (newVal) filterValue.value.value = newVal
  },
  { deep: true }
)

watch(
  () => ticks.value,
  newVal => {
    if (props.itemConfig.options.hasSlider)
      if (newVal.length === 0) hasChanges.value = false
  },
  { deep: true }
)

const labelFormatter = val => {
  if (props.itemConfig?.dataType === 'date') {
    return new Date(Math.abs(val) * 1000).toLocaleDateString('en-US')
  }
  return val
}

onMounted(() => {
  // only for range values
  if (props.itemConfig.type.toLowerCase() === 'range') {
    // minMax.value = [...rangeFinder.value]
    hasChanges.value = true
  }
})
</script>

<template>
  <div class="widget-filter-item">
    <div>
      <component
        :is="isCompact ? WidgetFilterLayoutSlide : WidgetFilterLayoutExpand"
        :item-config="itemConfig"
        :exclude-list="excludeList"
      >
        <template #filterExcludeList>
          <ul class="text-left">
            <template v-for="(item, iIdx) of computedExcludeList" :key="iIdx">
              <li v-if="!expandExcludeList && iIdx > 9">
                <v-btn
                  variant="text"
                  class="pa-0"
                  @click.prevent="expandExcludeList = true"
                  >...</v-btn
                >
              </li>
              <li v-else :class="[{ 'mt-2': iIdx !== 0 }]">
                <span
                  :class="
                    item
                      ? `${customStyle} ${customStyle}--${item
                          .toLowerCase()
                          .replaceAll(' ', '-')}`
                      : null
                  "
                >
                  <em class="text-decoration-line-through text-capitalize">
                    {{ item ? item : nonValue }}
                  </em>
                </span>
              </li>
            </template>
          </ul>
        </template>
        <template #filterTitleContent>
          <div class="d-flex align-center">
            <v-icon
              v-if="props.itemConfig.icon"
              :icon="props.itemConfig.icon"
              class="mr-2"
            />
            <div
              v-if="props.itemConfig.options.canSelectAll"
              class="d-flex align-center justify-center"
            >
              <v-checkbox
                v-model="selectAllModel"
                hide-details
                :false-icon="
                  isCompact
                    ? 'mdi-checkbox-blank-outline'
                    : 'mdi-checkbox-blank'
                "
                :true-icon="
                  isCompact
                    ? 'mdi-checkbox-marked-outline'
                    : 'mdi-checkbox-marked'
                "
                @click.stop="selectAllClickHandler"
              ></v-checkbox>
              <div class="d-flex align-center justify-center">
                {{ props.itemConfig.label }}
                <span class="mt-n2"
                  ><v-icon
                    v-if="showCheckmark"
                    icon="mdi-check-bold"
                    color="yellow-lighten-2"
                    size="x-small"
                /></span>
              </div>
            </div>

            <div v-else class="d-flex align-start">
              <span> {{ props.itemConfig.label }}</span>
              <span class="mt-n2"
                ><v-icon
                  v-if="showCheckmark"
                  icon="mdi-check-bold"
                  color="yellow-lighten-2"
                  size="x-small"
              /></span>
            </div>

            <v-spacer />
            <v-btn
              v-if="showReset"
              icon="mdi-refresh"
              size="small"
              variant="text"
              width="auto"
              @click.stop="resetHandler"
            />
          </div>
        </template>
        <template #filterMainContent>
          <div v-if="props.itemConfig.type === 'checkboxList'">
            <div v-if="props.itemConfig.options.isSearchable" class="pa-2">
              <v-text-field
                v-model="checklistSearch"
                prepend-icon="mdi-magnify"
                variant="outlined"
                density="compact"
                hide-details
                ><template v-slot:append="{ isDirty, reset }">
                  <v-icon
                    v-if="isDirty.value"
                    icon="mdi-close"
                    @click.stop="reset"
                  ></v-icon>
                  <div v-else class="mr-6"></div>
                </template>
              </v-text-field>
            </div>
            <!--
              this is pre-selected stuff:
              (value, vIdx) of props.itemConfig.values
            -->
            <div class="overflow-x-auto w-100" style="max-height: 400px">
              <div
                v-for="(item, idx) of searching"
                class="overflow-x-auto border-top w-100"
                :key="idx"
              >
                <v-checkbox
                  v-model="checkboxesModel"
                  :value="item.value"
                  hide-details
                  false-icon="mdi-checkbox-blank-outline"
                  true-icon="mdi-checkbox-marked-outline"
                  @click.stop="checklistClickHandler(item.value)"
                  @change="hasChanges = true"
                  style="height: auto"
                >
                  <template v-slot:label>
                    <div class="d-flex align-start text-capitalize">
                      <span
                        :class="`${customStyle} ${customStyle}--${
                          item.value
                            ? item.value.toLowerCase().replaceAll(' ', '-')
                            : null
                        }`"
                      >
                        {{ item.value ? item.value : nonValue }}</span
                      >
                      <span class="ml-1 mt-n1 text-caption"
                        >({{ item.count }})</span
                      >
                    </div>
                  </template>
                </v-checkbox>
              </div>
            </div>
          </div>
          <div
            v-else-if="
              props.itemConfig.dataType === 'date' &&
              props.itemConfig.options.hasDataEntry
            "
          >
            <Datepicker
              v-model="dateFormatterMin"
              :enable-time-picker="false"
            ></Datepicker>
            <Datepicker
              v-model="dateFormatterMax"
              :enable-time-picker="false"
            ></Datepicker>
          </div>
          <div
            v-else-if="
              props.itemConfig.dataType === 'number' &&
              props.itemConfig.options.hasDataEntry
            "
          >
            <template v-if="!rangeModel.some(x => typeof x !== 'number')">
              <form-number-incrementer
                v-for="(value, vIdx) of props.itemConfig.values"
                :min="minMax ? minMax[0] : null"
                :max="minMax ? minMax[1] : null"
                @updated.prevent="emitHandler($event, vIdx)"
                :initial="rangeModel[vIdx]"
                :key="vIdx"
            /></template>
          </div>
          <div
            v-if="props.itemConfig.options.hasSlider"
            class="pa-3 range-model-override"
          >
            <v-range-slider
              v-if="ticks.length > 1"
              v-model="rangeModel"
              color="primary"
              hide-details
              :min="minMax[0]"
              :max="minMax[1]"
              :step="1"
              :ticks="ticks"
              density="compact"
              class="mx-0"
              @update:model-value="hasChanges = true"
            >
              <template v-slot:prepend>
                <v-label
                  :text="labelFormatter(rangeModel[0])"
                  hide-details
                ></v-label>
              </template>
              <template v-slot:append>
                <v-label
                  :text="labelFormatter(rangeModel[1])"
                  hide-details
                  class="text-right"
                ></v-label>
              </template>
            </v-range-slider>
            <span v-else>No data available</span>
          </div>
        </template>
        <template #filterFooterContent>
          <div
            v-if="hasChanges"
            :class="[
              { 'pa-2': !isCompact },
              { 'px-2': isCompact },
              { 'border-top': !isCompact },
              { 'w-100': isCompact },
            ]"
          >
            <v-btn
              :class="['px-2', { 'my-2 bg-primary rounded-0': isCompact }]"
              :color="isCompact ? 'white' : 'primary'"
              :size="isCompact ? '56px' : 'default'"
              @click.stop="applyHandler"
              :stacked="isCompact"
            >
              <v-icon icon="mdi-check" /><span
                :class="[{ 'mt-n1': isCompact }]"
              >
                Apply</span
              ></v-btn
            >
          </div>
        </template>
      </component>
    </div>
  </div>
</template>

<style lang="scss">
// overrides
.widget-filter-item {
  .v-selection-control__input > .v-icon,
  .v-label {
    opacity: 1;
  }

  .v-checkbox .v-selection-control {
    height: auto;
  }

  .v-expansion-panel-text__wrapper {
    padding: 0;
  }
  .v-expansion-panel--active > .v-expansion-panel-title {
    min-height: auto;
  }
}

.range-model-override {
  .v-range-slider {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    .v-input__prepend {
      @media only screen and (max-width: 1200px) {
        margin: 0;
        padding-left: -5px;
        display: flex;
        width: 50%;
      }
    }

    .v-input__control {
      margin: 0;
      width: 100%;
      order: 3;
    }

    .v-input__append {
      @media only screen and (max-width: 1200px) {
        margin: 0;
        margin-left: -5px;
        display: flex;
        width: 50%;
      }
    }
  }

  .v-slider-thumb__surface::before {
    margin-left: -50%;
  }
}
</style>

<style lang="scss" scoped>
.customIssueImportance {
  display: inline-block;
  padding: 2px 4px;
  border-radius: 4px;
  &--very-high {
    background-color: #000;
    color: white;
  }
  &--high {
    background-color: #f44336;
    color: white;
  }
  &--medium {
    background-color: #e3e309;
  }
  &--low {
    background-color: #4c8021;
    color: white;
  }
  &--not-sure {
    //
  }
}
</style>
