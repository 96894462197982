//
const DB_NAME = 'MaxTrax'
// const DB_VERSION = 1
let DB
import { lookupRecordsetKey } from '@/services/lookup'

export default {
  async getDatabase(dbVersion = 1) {
    return new Promise((resolve, reject) => {
      if (DB) {
        return resolve(DB)
      }
      let request = window.indexedDB.open(DB_NAME, dbVersion)

      request.onerror = e => {
        console.log('Error opening db', e)
        reject('Error')
      }

      request.onsuccess = e => {
        DB = e.target.result
        resolve(DB)
      }

      request.onupgradeneeded = e => {
        DB = e.target.result

        if (!DB.objectStoreNames.contains('pages'))
          DB.createObjectStore('pages', { keyPath: 'pageId' })

        if (!DB.objectStoreNames.contains('widgets'))
          DB.createObjectStore('widgets', { keyPath: 'widgetId' })

        if (!DB.objectStoreNames.contains('recordsets'))
          DB.createObjectStore('recordsets', {
            keyPath: 'recordset',
          })

        if (!DB.objectStoreNames.contains('queue'))
          DB.createObjectStore('queue', {
            keyPath: 'queueId',
          })

        if (!DB.objectStoreNames.contains('images'))
          DB.createObjectStore('images', {
            keyPath: 'imageId',
          })

        if (!DB.objectStoreNames.contains('integrations'))
          DB.createObjectStore('integrations', {
            keyPath: 'integrationId',
          })
      }
    })
  },
  async getDataStore(collName) {
    DB = await this.getDatabase()
    let data = []

    return new Promise((resolve, reject) => {
      let transaction = DB.transaction([collName], 'readonly')
      transaction.oncomplete = () => {
        resolve(data)
      }

      let store = transaction.objectStore(collName)

      //store.openKeyCursor(IDBKeyRange.bound(0, maxRows)).onsuccess = e => {
      store.openCursor().onsuccess = e => {
        const cursor = e.target.result
        if (cursor) {
          data.push(cursor.value)
          cursor.continue()
        }
      }

      transaction.oncomplete = () => {
        resolve(data)
      }

      transaction.onerror = e => {
        reject(e)
      }
    })
  },
  async addToDatabase(collName, row) {
    DB = await this.getDatabase()

    return new Promise((resolve, reject) => {
      const transaction = DB.transaction(collName, 'readwrite')
      const store = transaction.objectStore(collName)

      store.put(row)

      transaction.oncomplete = () => {
        resolve('Saved locally')
      }

      transaction.onerror = e => {
        reject(e)
      }
    })
  },
  /**
   * Removes the specified entry from the record queue.
   * @param {string} queueId The id of the queue entry to remove
   * @returns A __promise__ that resolves to a string on success that can be
   * used for logging.
   */
  removeFromQueue(queueId) {
    return new Promise((resolve, reject) => {
      const transaction = DB.transaction('queue', 'readwrite')
      const store = transaction.objectStore('queue')
      store.delete(queueId)

      transaction.oncomplete = () => resolve(`Deleted queue entry ${queueId}`)
      transaction.onError = reject
    })
  },
  async editIDBRecordsetEntry(recordsetName, recordId, newRecord) {
    DB = await this.getDatabase()

    return new Promise((resolve, reject) => {
      const transaction = DB.transaction('recordsets', 'readwrite')
      const store = transaction.objectStore('recordsets')
      transaction.onerror = reject
      const request = store.get(recordsetName)
      request.onerror = reject
      request.onsuccess = event => {
        // let data = JSON.parse(event.target.result)
        let data = event.target.result
        let content = JSON.parse(data.content)
        let indexToUpdate = content.findIndex(
          record => record[lookupRecordsetKey(recordsetName)] === recordId
        )
        content[indexToUpdate] = newRecord
        data.content = JSON.stringify(content)
        const requestUpdate = store.put(data)
        requestUpdate.onerror = reject
        requestUpdate.onsuccess = () => {
          resolve(`Record ${recordId} updated in cache`)
        }
      }
    })
  },
  async clearData() {
    DB = await this.getDatabase()

    for (let i = 0; i < DB.objectStoreNames.length; i++) {
      if (DB.objectStoreNames.contains(DB.objectStoreNames.item(i))) {
        // open a read/write db transaction, ready for clearing the data
        const transaction = DB.transaction(
          [`${DB.objectStoreNames.item(i)}`],
          'readwrite'
        )

        // report on the success of the transaction completing, when everything is done
        transaction.oncomplete = event => {
          console.log('Transaction completed')
        }

        transaction.onerror = event => {
          console.log(
            `Transaction not opened due to error: ${transaction.error}`
          )
        }

        // create an object store on the transaction
        const objectStore = transaction.objectStore(
          `${DB.objectStoreNames.item(i)}`
        )

        // Make a request to clear all the data out of the object store
        const objectStoreRequest = objectStore.clear()

        objectStoreRequest.onsuccess = event => {
          // report the success of our request
          console.log('Request successful ', event)
        }
      }
    }
  },
  async deleteDatabase() {
    const DBDeleteRequest = window.indexedDB.deleteDatabase(DB_NAME)

    DBDeleteRequest.onerror = event => {
      console.error('Error deleting database.')
    }

    DBDeleteRequest.onsuccess = event => {
      console.log('Database deleted successfully')

      console.log(event.result) // should be undefined
    }
  },
}
