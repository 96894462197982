<script setup>
import { ref, computed, onMounted } from 'vue'
import { firebaseAuth, sendPasswordResetEmail } from '@/services/firebase'
import mtaLogo from '@/assets/img/client/mta_logo.png'
import { useUserStore } from '@/stores/user'
import { useRoute, useRouter } from 'vue-router'
import { recordLog } from '@/services/logging'

const userStore = useUserStore()
const route = useRoute()
const router = useRouter()
const resetCode = ref('')
const newPassword = ref(null)
const confirmPassword = ref(null)
const formIsValid = ref(false)
const isSubmittingData = ref(false)
const hasSentEmail = ref(false)
const passwordIsHidden = ref(true)
const confirmPasswordIsHidden = ref(true)
const successfullyContactedEmail = ref('')
const formError = ref('')
const username = ref('')
const nameRules = ref([
  v => !!v || 'Email address is required',
  v => /.+@.+/.test(v) || 'This must be a valid email address',
])
const passwordRules = ref([
  v => !!v || 'Password is required',
  v =>
    /^(?=.*[A-Z])(?=.*[!@#$&*])(?=.*[0-9])(?=.*[a-z]).{10,}$/.test(v) ||
    'This must be a valid password',
])
const confirmRules = computed(() => {
  return newPassword.value === confirmPassword.value || 'Password must match'
})

const fieldsFilled = computed(() => {
  return (
    username.value.length > 0 &&
    (!resetCode.value ||
      (newPassword.value?.length && confirmPassword.value?.length))
  )
})

async function attemptPasswordReset() {
  if (formIsValid.value === false) {
    formError.value = resetCode.value?.length
      ? 'This must be a valid password'
      : 'Please enter a valid email.'
    return
  } else if (resetCode.value.length && !newPassword.value?.length) {
    formError.value = 'Please enter a valid new password'
    recordLog(
      'Password Reset',
      'Fail',
      'You did not enter a valid new password'
    )
    return
  } else {
    formError.value = ''
  }
  isSubmittingData.value = true
  if (!resetCode.value?.length) {
    await userStore
      .requestPasswordReset(username.value)
      .then(e => {
        hasSentEmail.value = true
        console.log(`Password Reset Request Status: ${JSON.stringify(e)}`)
        recordLog('Forgot Password', 'Success')
      })
      .catch(e => {
        recordLog('Forgot Password', 'Fail', e?.message)
        console.error(e)
        formError.value = e?.response?.data ?? e.message
        isSubmittingData.value = false
      })
  } else {
    await userStore
      .executePasswordReset(username.value, resetCode.value, newPassword.value)
      .then(e => {
        hasSentEmail.value = true
        recordLog('Password reset', 'Success')
        setTimeout(() => router.push({ path: 'sign-in' }), 5 * 1000)
      })
      .catch(e => {
        recordLog('Password reset', 'Fail', e?.message)
        console.error(e)
        formError.value = e?.response?.data ?? e.message
        isSubmittingData.value = false
      })
  }
  successfullyContactedEmail.value = username.value
  isSubmittingData.value = false
}

onMounted(() => {
  if (route.query?.email) username.value = decodeURIComponent(route.query.email)
  if (route.query?.code) resetCode.value = decodeURIComponent(route.query.code)
  userStore.logOut()
})
</script>

<template>
  <div class="pa-5">
    <v-card class="login-card ma-auto py-5" :loading="isSubmittingData">
      <v-img :src="mtaLogo" height="35" />

      <v-alert v-if="formError !== ''" color="error" class="ma-5">
        {{ formError }}
      </v-alert>
      <v-alert
        v-else-if="formError === '' && hasSentEmail"
        color="success"
        class="ma-5 pa-2"
      >
        <div v-if="resetCode">
          The password for the account
          <strong>{{ successfullyContactedEmail }}</strong> has successfully
          been reset. You will be redirected to the Sign In page.
        </div>
        <div v-else>
          A password reset email has been sent to
          <strong>{{ successfullyContactedEmail }} </strong> <br />Please check
          your inbox and junk folder.
        </div>
      </v-alert>
      <v-card-title class="pb-0">
        <span v-text="'Reset your account password'"></span>
      </v-card-title>
      <v-card-subtitle class="mb-6">
        <span
          v-text="
            resetCode
              ? 'Enter your new password below'
              : 'Enter your email address below'
          "
        ></span>
      </v-card-subtitle>
      <v-form
        v-model="formIsValid"
        :readonly="isSubmittingData || hasSentEmail"
        @submit.prevent="attemptPasswordReset"
      >
        <v-container>
          <v-row>
            <v-col>
              <v-text-field
                prepend-inner-icon="mdi-account"
                v-model="username"
                :rules="nameRules"
                :validate-on="username ? 'blur' : 'submit'"
                label="Email Address"
                variant="outlined"
                required
                :disabled="!!resetCode?.length"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row v-if="resetCode">
            <v-col>
              <v-text-field
                prepend-inner-icon="mdi-lock"
                v-model="newPassword"
                :rules="passwordRules"
                validate-on="blur"
                :type="passwordIsHidden ? 'password' : 'text'"
                :label="`New Password`"
                variant="outlined"
                :required="resetCode?.length ? true : false"
                :append-inner-icon="
                  passwordIsHidden ? 'mdi-eye-off' : 'mdi-eye'
                "
                @click:append-inner="passwordIsHidden = !passwordIsHidden"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row v-if="resetCode">
            <v-col>
              <v-text-field
                prepend-inner-icon="mdi-lock"
                v-model="confirmPassword"
                :rules="[passwordRules, confirmRules]"
                validate-on="blur"
                :type="confirmPasswordIsHidden ? 'password' : 'text'"
                :label="`Confirm Password`"
                variant="outlined"
                :required="resetCode?.length ? true : false"
                :append-inner-icon="
                  confirmPasswordIsHidden ? 'mdi-eye-off' : 'mdi-eye'
                "
                @click:append-inner="
                  confirmPasswordIsHidden = !confirmPasswordIsHidden
                "
              ></v-text-field>
              <v-alert
                v-if="resetCode"
                color="yellow-lighten-4"
                class="pa-2 text-left"
                style="border: 1px solid olive; line-height: 1"
              >
                <v-alert-title class="text-subtitle-1">
                  Password requirements
                </v-alert-title>
                <em class="text-body-2">
                  Minimum of 10 characters, at least 1 lower case letter, 1
                  uppercase letter, 1 number, and 1 special character.
                </em>
              </v-alert>
            </v-col>
          </v-row>

          <v-row>
            <v-col>
              <v-btn
                type="submit"
                color="primary"
                :disabled="!fieldsFilled || isSubmittingData"
                :loading="isSubmittingData"
                :prepend-icon="resetCode ? 'mdi-check' : 'mdi-email-outline'"
              >
                {{ resetCode ? 'Change My Password' : 'Reset Password' }}
              </v-btn>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-btn :to="{ name: 'signIn' }" variant="plain"
                >I remember my password</v-btn
              >
            </v-col>
          </v-row>
        </v-container>
      </v-form>
    </v-card>
  </div>
</template>

<style scoped>
.login-card {
  max-width: 400px;
  width: 100%;
}
</style>
