import { FirestoreUploadData } from './FormData.model'
import { FirestoreImageData, FirestoreImageHandler } from './Image.model'
import { base64ImageToFile } from '@/services/utils'
import { makeFirestoreId } from '@/services/utils'

/**
 * A lightweight class corresponding to a record stored in the outgoing record
 * queue. Meant to be mainly handled through a `RecordQueueEntryHandler`.
 */
export class RecordQueueEntry {
  type: 'add' | 'edit' | 'delete'
  recordset: string
  fsCollection: string | null
  queueId: string
  content?: string // any
  images?: string // { [key: string]: FirestoreImageData }
  fsDataTimestamp: number
  firstAttemptDateTime: Date
  lastAttemptDateTime: Date

  /**
   * `RecordQueueEntryHandler.fromFirestoreUploadData()` should be favored over
   * this constructor
   */
  constructor(
    type: 'add' | 'edit' | 'delete',
    data:
      | FirestoreUploadData
      | { id: string; recordset: string; fsCollection: string | null },
    firstAttemptDateTime: Date | undefined = undefined
  ) {
    this.type = type
    this.recordset = data.recordset
    this.fsCollection = data?.fsCollection
    this.queueId = data.id
    this.lastAttemptDateTime = new Date()
    this.firstAttemptDateTime = firstAttemptDateTime ?? new Date()
    if (type === 'delete') {
      this.fsDataTimestamp = new Date().getTime()
      return
    } else {
      // For an add or edit, data should be a FirestoreUploadData object
      const fsData = data as FirestoreUploadData
      this.content = JSON.stringify(fsData.content)
      this.fsDataTimestamp = fsData.time

      this.images = '{}'
    }
  }
}

/**
 * A handler class for `RecordQueueEntry`.
 */
export class RecordQueueEntryHandler {
  queueEntry: RecordQueueEntry

  /**
   * Wraps the given `RecordQueueEntry` in a new `RecordQueueEntryHandler`
   * @param queueEntry The queue entry this handler should wrap
   */
  constructor(queueEntry: RecordQueueEntry) {
    this.queueEntry = queueEntry
  }

  /**
   * Consumes the data needed for a deletion and generates a corresponding
   * `RecordQueueEntry`
   * @param recordset The recordset for which the delete request is being made.
   * @param id The id of the record being deleted
   * @param fsCollection The name of the firestore collection from which data is
   * being deleted (saved so that the deletion request persists on logout)
   * @returns A `RecordQueueEntry` corresponding to the given delete data
   */
  fromDeleteData(
    recordset: string,
    id: string,
    fsCollection: string
  ): RecordQueueEntry {
    this.queueEntry = RecordQueueEntryHandler.fromDeleteData(
      recordset,
      id,
      fsCollection
    )
    return this.queueEntry
  }

  /**
   * An __async__ method that creates a `RecordQueueEntry` from the given firestore
   * data
   * @param type Whether this `FirestoreUploadData` corresponds to an add or an
   * edit
   * @param data The `FirestoreUploadData` object from which the
   * `RecordQueueEntry` will be generated
   * @param firstAttemptDateTime The datetime this request was originally
   * submitted, or `undefined` if this is the first attempt. Used for repeated
   * queueing.
   * @returns __A promise__ that resolves to a `RecordQueueEntry` generated from
   * the given `FirestoreUploadData`
   */
  async fromFirestoreUploadData(
    type: 'add' | 'edit',
    data: FirestoreUploadData,
    firstAttemptDateTime: Date | undefined
  ): Promise<RecordQueueEntry> {
    this.queueEntry = await RecordQueueEntryHandler.fromFirestoreUploadData(
      type,
      data,
      firstAttemptDateTime
    )
    return this.queueEntry
  }

  /**
   * Consumes the data needed for a deletion and generates a corresponding
   * `RecordQueueEntry`
   * @param recordset The recordset for which the delete request is being made.
   * @param id The id of the record being deleted
   * @param fsCollection The name of the firestore collection from which data is
   * being deleted (saved so that the deletion request persists on logout)
   * @returns A `RecordQueueEntry` corresponding to the given delete data
   */
  static fromDeleteData(
    recordset: string,
    id: string,
    fsCollection: string
  ): RecordQueueEntry {
    const date = new Date()
    return {
      type: 'delete',
      recordset: recordset,
      fsCollection: fsCollection,
      queueId: id,
      fsDataTimestamp: date.getTime(),
      firstAttemptDateTime: date,
      lastAttemptDateTime: date,
    } as RecordQueueEntry
  }

  /**
   * An __async__ method that creates a `RecordQueueEntry` from the given firestore
   * data
   * @param type Whether this `FirestoreUploadData` corresponds to an add or an
   * edit
   * @param data The `FirestoreUploadData` object from which the
   * `RecordQueueEntry` will be generated
   * @param firstAttemptDateTime The datetime this request was originally
   * submitted, or `undefined` if this is the first attempt. Used for repeated
   * queueing.
   * @returns __A promise__ that resolves to a `RecordQueueEntry` generated from
   * the given `FirestoreUploadData`
   */
  static async fromFirestoreUploadData(
    type: 'add' | 'edit',
    data: FirestoreUploadData,
    firstAttemptDateTime: Date | undefined
  ): Promise<RecordQueueEntry> {
    const rqe = {
      type: type,
      recordset: data.recordset,
      fsCollection: data.fsCollection,
      queueId: data.id,
      content: JSON.stringify(data.content),
      fsDataTimestamp: data.time,
      firstAttemptDateTime: firstAttemptDateTime ?? new Date(),
      lastAttemptDateTime: new Date(),
    } as RecordQueueEntry
    if (data.images) {
      const rawImageDataDict = {} as { [key: string]: FirestoreImageData }
      for (const key of Object.keys(data.images)) {
        rawImageDataDict[key] = await data.images[key].getQueueValue()
      }
      rqe.images = JSON.stringify(rawImageDataDict)
    }
    return rqe
  }

  /**
   * Restores a `FirestoreUploadData` object from the given `RecordQueueEntry`.
   * This method should only be used for adds and edits.
   * @param rqe A `RecordQueueEntry` to convert to a `FirestoreUploadData`
   * object
   * @returns A `FirestoreUploadData` object restored from the given
   * `RecordQueueEntry`
   */
  static toFirestoreUploadData(rqe: RecordQueueEntry) {
    const fsData = new FirestoreUploadData(
      rqe.recordset,
      JSON.parse(rqe?.content ?? '{}')
    )
    fsData.fsCollection = fsData.fsCollection ?? rqe.fsCollection
    if (rqe.images) {
      const parsedImageData = JSON.parse(rqe.images) as {
        [key: string]: FirestoreImageData
      }
      const restructuredImages = {} as { [key: string]: FirestoreImageHandler }
      for (const firestoreImageProperty of Object.keys(parsedImageData)) {
        restructuredImages[firestoreImageProperty] = new FirestoreImageHandler(
          parsedImageData[firestoreImageProperty]
        )
        const handler = restructuredImages[firestoreImageProperty]
        if (handler.image.fileString) {
          handler.image.file = base64ImageToFile(
            handler.image.fileString!,
            handler.image.filename ?? makeFirestoreId()
          )
          delete handler.image.fileString
          handler.image.local = URL.createObjectURL(handler.image.file)
        }
      }
      fsData.images = restructuredImages
    }
    return fsData
  }

  /**
   * Retrieves the entry from the queue that was attempted least recently.
   * @param queue The outgoing record queue
   * @returns The queue entry attempted least recently
   */
  static findRecordAttemptedLeastRecently(
    queue: RecordQueueEntry[]
  ): RecordQueueEntry | null {
    if (!queue?.length) {
      return null
    }
    let leastRecentRecord: RecordQueueEntry = queue[0]
    for (const entry of queue) {
      if (entry.lastAttemptDateTime < leastRecentRecord.lastAttemptDateTime) {
        leastRecentRecord = entry
      }
    }
    return leastRecentRecord
  }
}
