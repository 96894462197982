<script setup>
import { defineProps, ref, computed, onMounted, onUnmounted } from 'vue'
import useCommonConfig from '@/composables/useCommonConfig'
import WidgetFilterItem from './WidgetFilterItem.vue'
const { isMobile, navigationStore, dataStore } = useCommonConfig()

const props = defineProps({ device: String, config: Object })

const filters = ref([])

const recordsetValue = computed(() => {
  if (dataStore.recordsets.length > 0)
    return dataStore.filteredRecordset(props.config.options.recordset)
  else return null
})

const emittedFilterHandler = (val, idx) => {
  filters.value[idx] = val
  const tempArray = []

  // if value array is empty, we need to remove that filter from the list
  // if (val.value?.length < 1 || !val.value) {
  // possible index misalignment in pinia. We may not want to splice this, but let the values be empty
  // filters.value.splice(idx, 1)
  // }

  // prevent entries with empty values from being passed to pinia
  // if (val.value.length > 0) applyFilters()

  for (let val of filters.value) {
    tempArray.push(val)
  }

  // pass to pinia
  dataStore.filters = JSON.parse(JSON.stringify(tempArray))

  // pagesStore.currentPage.properties.dataSources
  // no need to run the query again?
  // dataStore.fetchCollectionData(pagesStore.currentPage.properties.dataSources)
}

onMounted(() => {
  for (let item of props.config.options.items) {
    //  if (item.type === 'checkboxList') {
    filters.value.push({
      value: item.values,
      field: item.property,
      compare: item.compare,
    })
    dataStore.filters = [...filters.value]
    // }
  }
  navigationStore.showMobileFilter = true
})
onUnmounted(() => {
  navigationStore.showMobileFilter = false
})
</script>

<template>
  <div>
    <widget-filter-item
      v-for="(item, iIdx) of config.options.items"
      :device="config.devices"
      :item-config="item"
      :recordset="recordsetValue"
      :styles="config.options.style"
      @filter="emittedFilterHandler($event, iIdx)"
      :key="iIdx"
    />
  </div>
  <Teleport v-if="isMobile" to="#bottom-navigation">
    <v-btn
      stacked
      variant="text"
      prepend-icon="mdi-filter"
      class="pa-0 rounded-0"
      @click.stop="navigationStore.toggleLeftDrawer = true"
    >
      Filters
    </v-btn>
  </Teleport>
</template>
