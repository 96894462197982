import { defineStore } from 'pinia'
import { collection, query, onSnapshot } from 'firebase/firestore'
import { firestore, firestoreQuery } from '../services/firebase'
import idb from '@/api/idb'
import { MTCFIntegration } from '@/model/Integration.model'
import { FirestoreUploadData } from '@/model/FormData.model'
import { mtcfProd } from '../services/firebase'
import { firestoreUploadDataToEmail } from '@/services/emailFormat'

let unsubIntgration = () => {
  // initializes the variable
}

export const useIntegrationsStore = defineStore('integrations', {
  state: () => ({
    integrations: [] as MTCFIntegration[],
  }),
  getters: {
    getIntegrations: (state: any) => state.integrations,
  },
  actions: {
    subscribeIntegrations() {
      const q = query(collection(firestore, 'integrations'))
      unsubIntgration = onSnapshot(q, querySnapshot => {
        querySnapshot.docChanges().forEach(async change => {
          idb.addToDatabase('integrations', change.doc.data())
          this.integrations = await idb.getDataStore('integrations')
        })
      })
    },
    unsubscribeIntegrations() {
      unsubIntgration()
    },
    /**
     * Applies all active integrations for the given record in the given
     * context. An integration is only active if it matches in collection,
     * context, and filters.
     * @param data The record for which the integration is being applied
     * @param context A string representing the context in which this
     * integration is being called.
     */
    applyIntegrations(data: FirestoreUploadData, context: string) {
      for (const integration of this.integrations) {
        if (MTCFIntegration.isActiveOnData(integration, data, context)) {
          const email = firestoreUploadDataToEmail(data, integration)
          console.log(`--- Sending email to prod ---`)
          mtcfProd(integration.properties.task, email)
            .then(result => {
              console.log(`Email sent: ${result}`)
            })
            /* 
          Catching the error here means it won't  queue the record. This is 
          intentional. A consequence of this is that attempting sends is a 
          one-shot type of action until an email send queue is built out.
          */
            .catch(console.error)
        }
      }
    },
  },
  persist: true,
})
