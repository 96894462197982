<script setup>
import { ref, defineProps, computed, watch, onMounted, onUnmounted } from 'vue'
import { storeToRefs } from 'pinia'
import useWidgetConfig from '../composables/useWidgetConfig'
import WidgetGridTable from '@/components/WidgetGridTable'
import useCommonConfig from '@/composables/useCommonConfig'
// components
// import DataHandler from '@/components/DataHandler.vue'

const props = defineProps({ device: String, config: Object })
const { readRecordsets, dataStore } = useWidgetConfig()
const {
  isPhone,
  isMobile,
  isNotMobile,
  display,
  navigationStore,
  widgetsStore,
} = useCommonConfig()

const { showModal } = storeToRefs(navigationStore)

const paginationStart = ref(1)
const rowsPerPage = ref(50)
const rowsPerPageDisplay = ref(50)
const pageModel = ref(1)
const isScrolling = ref(false)
const isLoading = ref(false)
const refWindowHeight = ref(window.innerHeight)
// const infiniteGrid = ref(null)

const fieldsetCount = computed(() => {
  // count the number of rows in the recordset array
  if (dataStore.recordsets.length > 0)
    return dataStore
      .filteredRecordset(props.config[0].options.recordset)
      .length?.toLocaleString('en-US')
  //return readRecordsets(props.config.options.recordset)[
  //  props.config.options.recordset
  //].length?.toLocaleString('en-US')
  else return null
})

const paginationPages = computed(() => {
  if (fieldsetCount.value) {
    return Math.ceil(fieldsetCount.value / rowsPerPage.value)
  }

  return null
})

watch(
  () => rowsPerPageDisplay.value,
  newVal => {
    if (newVal === 'All') rowsPerPage.value = 50 // makes 50 the default count
    else rowsPerPage.value = rowsPerPageDisplay.value
  }
)

watch(
  () => pageModel.value,
  newVal => {
    // the pagination model page number tells us how to calculate the next starting point
    if (paginationPages.value)
      paginationStart.value = newVal * rowsPerPage.value - rowsPerPage.value + 1
  }
)

const rowMax = computed(() => {
  return paginationStart.value + rowsPerPage.value > fieldsetCount.value
    ? fieldsetCount.value
    : paginationStart.value + rowsPerPage.value - 1
})

const windowHeight = computed(() => {
  return refWindowHeight.value - 130
})

const showInfinite = computed(() => {
  if (isMobile.value) return true
  return rowsPerPageDisplay.value === 'All' ? true : false
})

// methods

/* no longer needed?
const photoRenderer = photo => {
  console.log('photoRenderer ', JSON.stringify(photo))
  // if (photo !== null) return h(compile('<v-img src=' + photo + ' />'))
  // if (JSON.stringify(photo) !== null) return h('div', JSON.stringify(photo))
  if (photo !== null) return h('div', { innerHTML: 'hi' })
  else return null
} */

const addHandler = note => {
  showModal.value = true
  widgetsStore.currentForm = { id: null, readOnly: false }
  if (Object.keys(note).length > 0) {
    widgetsStore.currentForm = note
  }
}

const editHandler = data => {
  showModal.value = true

  widgetsStore.currentForm = data
}

const mapHandler = id => {
  console.log(id, ' clicked')
}

const emittedScroll = val => {
  // console.log('val ', val)

  isScrolling.value = val.isScrolling
  let loadMoreData
  // console.log('isScrolling ', isScrolling.value)

  if (
    val.position <= 1 &&
    val.isScrolling === false &&
    pageModel.value < paginationPages.value
  ) {
    isLoading.value = true
    window.clearTimeout(loadMoreData)
    loadMoreData = setTimeout(() => {
      pageModel.value++
      isLoading.value = false
    }, 2000) // give it a bit of time to reduce excess calls
  }
}

const getChildRecordset = () => {
  dataStore.fetchCollectionData([
    { source: 'notes', name: 'notes', recordsets: ['notes1'] },
  ])
}

watch(
  () => navigationStore.showModal,
  newVal => {
    if (!newVal) setTimeout(() => (widgetsStore.currentForm = null), 200)
  }
)

onMounted(() => {
  navigationStore.showMobileAdd = props.config[0].options?.isAddAllowed
})

onUnmounted(() => {
  navigationStore.showMobileAdd = false
})
</script>

<template>
  <div ref="infiniteGrid">
    <v-container fluid class="pa-0 mt-grid--root">
      <v-row
        v-if="!isMobile"
        no-gutters
        class="mb-2 pa-2 align-center border rounded"
      >
        <v-btn
          v-if="props.config[0].options?.isAddAllowed"
          color="primary"
          elevation="0"
          class="px-2"
          @click.prevent="addHandler({})"
          ><v-icon icon="mdi-plus" />
          <span>Add</span>
        </v-btn>
        <v-spacer />
        <div class="pr-2">
          <div v-if="display.mdAndUp">
            <span v-if="fieldsetCount > 0">
              Showing {{ showInfinite ? '1' : paginationStart }} to
              {{ rowMax }} of {{ fieldsetCount }}
            </span>
            <span v-else>There are no records</span>
          </div>
          <!-- <span v-else class="fs-13">
          {{ paginationStart }}-{{ rowMax }} of {{ fieldsetCount }} Results
        </span> -->
        </div>
        <v-select
          color="secondary"
          v-model="rowsPerPageDisplay"
          variant="outlined"
          density="compact"
          hide-details
          menu-icon="mdi-menu-down"
          :items="[5, 10, 25, 50, 100]"
          style="max-width: 100px"
        ></v-select>
      </v-row>
      <Teleport to="#bottom-navigation" v-else>
        <v-btn
          stacked
          variant="text"
          class="pa-0 rounded-0"
          :active="false"
          @click.prevent="addHandler({})"
          style="order: 100"
        >
          <v-icon icon="fa:fas fa-plus" style="margin-bottom: 2px" />
          Add
        </v-btn>
      </Teleport>
      <v-row
        no-gutters
        class="ma-0"
        :style="{
          'width': props.config[0].options?.width,
          'max-width': props.config[0].options?.width,
        }"
      >
        <div class="w-100 bg-white" style="height: 2px"></div>
        <widget-grid-table
          :config="props.config[0]"
          :head="props.config[0].options?.head"
          :height="!isMobile ? props.config[0].options?.height : windowHeight"
          :data="props.config[0].options?.data"
          :pagination-start="paginationStart"
          :rowMax="rowMax"
          :is-infinite="showInfinite"
          @emit-map="mapHandler"
          @emit-edit="editHandler"
          @emit-add="addHandler"
          @emit-scroll="emittedScroll"
        >
          <template #bottom v-if="isLoading">
            <v-progress-linear
              indeterminate
              color="primary"
              model-value="20"
              class="mx-auto"
            ></v-progress-linear>
          </template>
        </widget-grid-table>
      </v-row>
      <!-- TODO: the footer is supposed to be dynamically generated via the data. 
      Currently it's empty, but we need to demo this -->
      <v-row
        v-if="!isMobile && !showInfinite"
        no-gutters
        class="mt-2 pa-2 align-center border rounded justify-end"
      >
        <v-col cols="12" md="5">
          <v-pagination
            v-model="pageModel"
            :length="paginationPages"
            prev-icon="mdi-chevron-left"
            next-icon="mdi-chevron-right"
            variant="tonal"
            color="white"
            class="py-0 pagination-override"
          ></v-pagination>
        </v-col>
      </v-row>

      <Transition>
        <v-chip
          v-if="isScrolling"
          color="secondary"
          size="x-large"
          variant="elevated"
          style="position: fixed; right: 1%; bottom: 15%"
        >
          <span v-if="fieldsetCount > 0">
            1-{{ rowMax }} of {{ fieldsetCount }} Results
          </span>
          <span v-else>There are no records</span>
        </v-chip>
      </Transition>
    </v-container>
  </div>
</template>

<style lang="scss">
.checkbox-override {
  .v-icon--size-default {
    font-size: large;
  }
}

.pagination-override {
  padding-right: 0 !important;
  padding-left: 0 !important;

  .v-pagination__item,
  .v-pagination__next,
  .v-pagination__prev {
    background-color: white;
  }
  .v-btn {
    background-color: #376191;
  }
  .v-btn__overlay {
    opacity: 0;
  }
  .v-pagination__list {
    justify-content: flex-end;
  }
  .v-pagination__item--is-active {
    color: white;
    .v-btn__overlay {
      // background-color: #376191;
      opacity: 0.5;
    }
  }
}
</style>
