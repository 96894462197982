// TODO: this may need to be renamed
import { defineStore } from 'pinia'
import { mtcfProd } from '../services/firebase'
import { PWADataRequest } from '@/model/MTCF.model'

const markerToFootage = (marker, offset, clientId) => {
  // patco = 5280 = 1 mile
  // wmata = 100

  const mileMarker = clientId === 5 ? 5280 : 100

  return parseInt(marker) * mileMarker + parseInt(offset)
}

export const useLinearStore = defineStore('linear', {
  state: () => ({
    measurements: [],
    surveys: [],
    channels: [],
    client: null,
    surveyProperties: [],
    trackProperties: [],
    isNewData: true,
    isLoading: false,
    surveyIsLoading: false,
    backData: [],
    endValues: { surveyEndValue: null, endValue: null },
    // xTicks: 10,
    scrollWidth: null,
    xScrollPos: 0,
    yScrollPos: 0,
    toolbarIdx: 0,
    chartZoomReset: null,
    isXZoomEnabled: false,
    isYZoomEnabled: false,
    isCrossHairModeEnabled: false,
    xTicks: 10,
    axTop: null,
    xAxis: null,
    yAxis: null,
    yAxisLeft: null,
    yVal: null,
    xTop: null,
    y: null,
    y2: null,
    yGrid: null,
    line: [],
    path: [],
    lineValues: [],
  }),
  getters: {
    getIsLoading: state => state.isLoading,
    getIsNewData: state => state.isNewData,
    getSurveyIsLoading: state => state.surveyIsLoading,
  },
  actions: {
    async getMeasurements(start, end, surveyIds = null) {
      this.isLoading = true
      if (start === 0) this.measurements = [] // reset when starting over
      const orderBy = ' ORDER BY POSITION'
      let whereClause = `WHERE (POSITION >= ${start} AND POSITION <=  ${end})` // TODO: Use SQLSERVER BETWEEN
      if (surveyIds) {
        console.log('surveyIds ', surveyIds)
        surveyIds.forEach((x, xIdx) => {
          if (xIdx === 0) whereClause += ` AND (SURVEY_ID = ${x}`
          else whereClause += ` OR SURVEY_ID = ${x}`
        })
        whereClause += ')'
      }

      whereClause = whereClause + orderBy
      console.log('measurement whereClause >> ', whereClause)
      return mtcfProd(
        'pwaData',
        new PWADataRequest({
          recordsets: {
            measurements: {
              mode: 'all',
              pagination: {
                start: 0,
                end: 20000,
              },
              query: whereClause,
            },
          },
        })
      ).then(async res => {
        console.log(',measurement res ', res)
        // this.measurements = []
        this.isLoading = false
        if (res.data.status === 200) {
          if (res.data.changes.measurements.currentSize > 0) {
            // we need to add a new property to the data set, since measurements gets larger and larger and the data is already saved,
            // we'll apply the algorithm to the incoming data which will be smaller.
            // since we can't add the property to the response data directly, we'll create a temporary array to then copy
            const tempArr = [...res.data.changes.measurements.records]
            const localSurvey = this.surveys.find(
              x => x.Survey_ID === tempArr[0].Survey_ID
            )
            // determine if data is ascending
            const isAscending =
              localSurvey.StartPosition < localSurvey.EndPosition
            // this step would better be handled at the DB
            // create a new x axis
            tempArr.forEach((ta, taIdx) => {
              let diffPosition
              const sp = this.surveys.find(
                s => s.Survey_ID === ta.Survey_ID
              )?.StartPosition

              if (isAscending) {
                diffPosition = parseFloat(sp + parseFloat(ta.Position))
              } else {
                diffPosition = parseFloat(sp - parseFloat(ta.Position)) // may be an issue with yards?
              } // - parseFloat(ta.Position)
              ta.positionMod = diffPosition
              // console.log('ta.positionMod ', ta.positionMod)
            })

            this.measurements = [...this.measurements, ...tempArr] // ...res.data.changes.measurements.records,
            console.log('this.measurements ', this.measurements)
            surveyIds.forEach(sId => {
              // this is more for error handling: if there are no results, it allows the graph to print without crashing... we can still display a helpful message

              if (
                !res.data.changes.measurements.records.find(x => {
                  return x.Survey_ID === sId
                }) &&
                !this.measurements.find(x => {
                  return x.Survey_ID === sId
                })
              ) {
                this.measurements = [
                  ...this.measurements,
                  ...[{ Survey_ID: sId }],
                ]
              }
            })
            // this.measurements.push({
            //   endRange: end,
            //   data: res.data.changes.measurements.records,
            // })
            this.xTicks = this.xTicks + 10

            // another check is to see if the currentSize is smaller than the end size
            if (res.data.changes.measurements.currentSize > end) {
              this.isNewData = false
            } else {
              this.isNewData = true
            }
          } else {
            this.isNewData = false
            this.measurements = [
              ...this.measurements,
              ...res.data.changes.measurements.records,
            ]
          }

          return this.measurements // back to the UI
        } else return res.data.status
      })
    },
    getChannels(clientId) {
      // this is executed after surveys
      console.log('clientId ', clientId)
      this.channels = []
      return mtcfProd(
        'pwaData',
        new PWADataRequest({
          recordsets: {
            channels: {
              mode: 'all',
              pagination: {
                start: 0,
                end: 1000,
              },
              query: `WHERE CLIENT_ID =${clientId}`,
            },
          },
        })
      ).then(async res => {
        this.surveyIsLoading = true
        console.log('channels res ', res)
        if (res.data.status === 200) {
          this.surveyProperties = [...res.data.changes.channels.records]

          this.surveyProperties.forEach(x => {
            console.log('x ', x)
            x.Thresholds = x.Thresholds.replaceAll('[', '')
              .replaceAll(']', '')
              .split(',')
          })
          console.log('1 this.surveyProperties > ', this.surveyProperties)
          //add these to the surveys table
          // this.surveys.forEach(x => {
          //   x.Properties = this.channels
          // })

          // this.surveyProperties = [...this.channels]
          this.surveyIsLoading = false
          // nothing to return
          console.log('this.surveys ', this.surveys)
        } //else return res.data.status
      })
    },
    getSurveys(clientId) {
      this.surveyIsLoading = true

      // this.getChannels(clientId)
      // check that the measurement isn't already stored in Pinia
      this.surveys = []
      return mtcfProd(
        'pwaData',
        new PWADataRequest({
          recordsets: {
            surveys: {
              mode: 'all',
              pagination: {
                start: 0,
                end: 1000,
              },
              query: `WHERE CLIENT_ID =${clientId}`,
            },
          },
        })
      ).then(async res => {
        this.surveyProperties = [] // reset
        this.surveyIsLoading = false
        console.log('survey res ', res)
        if (res.data.status === 200) {
          this.surveys = [...res.data.changes.surveys.records]
          console.log('1 this.surveys > ', this.surveys)

          // nothing to return

          //populate surveyProperties
          this.surveys.forEach(s => {
            const parsedJson = JSON.parse(s.Properties)
            if (
              this.surveyProperties.findIndex(
                sp => sp.label === parsedJson.label
              ) < 0
            )
              this.surveyProperties.push(parsedJson)
          })
          // console.log('surveyProperties ', this.surveyProperties)
        } //else return res.data.status
      })
    },
    getTrackProperties(clientId, line) {
      let whereClause = `WHERE CLIENT_ID = ${clientId} AND LINE = '${line}' `
      console.log('whereClause ', whereClause)
      // if (clientId === 2) whereClause += ` AND TYPE = 'Track'`
      this.trackProperties = []
      return mtcfProd(
        'pwaData',
        new PWADataRequest({
          recordsets: {
            rails: {
              mode: 'all',
              pagination: {
                start: 0,
                end: 1000,
              },
              query: whereClause,
            },
          },
        })
      ).then(async res => {
        console.log('track ', res)

        this.isLoading = false
        if (res.data.status === 200) {
          //TODO: add to trackProperties or replace?
          this.trackProperties = [...res.data.changes.rails.records]

          this.trackProperties.forEach(x => {
            x.Properties = JSON.parse(x.Properties)
          })

          return true
          // return this.measurements // back to the UI
        } //else return res.data.status
      })
    },
  },
  // persist: true,
})
