import { FirestoreUploadData } from './FormData.model'

/**
 * A lightweight class representing an MTCF Integration Record Object
 */
export class MTCFIntegration {
  createUserId: number
  updateUserId: number
  integrationId: number
  name: string
  description: string | null
  updateDateTime: any
  createDateTime: any
  recordStamp: string | null
  properties: MTCFIntegrationProperties

  constructor(obj: MTCFIntegration) {
    this.createUserId = obj.createUserId
    this.updateUserId = obj.updateUserId
    this.integrationId = obj.integrationId
    this.name = obj.name
    this.description = obj.description
    this.updateDateTime = obj.updateDateTime
    this.createDateTime = obj.createDateTime
    this.recordStamp = obj.recordStamp
    this.properties = obj.properties
  }

  /**
   * Evaluates whether or not the given integration should apply to the given
   * data and context.
   * @param data The record for which this integration might be triggered
   * @param context The context in which this integration is being triggered
   * @returns True if this integration should be triggered for the given record
   * in the given context, false otherwise
   */
  static isActiveOnData(
    integration: MTCFIntegration,
    data: FirestoreUploadData,
    context: string
  ): boolean {
    // If this is the wrong action, return false
    if (integration.properties.trigger !== context) return false
    // If this is not one of the right recordsets, return false
    if (
      !integration.properties.collections.includes(
        data.fsCollection ?? data.recordset
      )
    )
      return false
    // If any of the conditions evaluate to false, return false
    for (const filter of integration.properties.criteria ?? []) {
      if (!MTCFIntegrationFilter.applyFilter(data, filter)) {
        return false
      }
    }
    return true
  }
}

/**
 * A lightweight object representing the properties of an MTCF Integration Object
 */
export class MTCFIntegrationProperties {
  task: string
  collections: string[]
  criteria: MTCFIntegrationFilter[]
  trigger: string
  options: any

  constructor(obj: MTCFIntegrationProperties) {
    this.task = obj.task
    this.collections = obj.collections
    this.criteria = obj.criteria
    this.trigger = obj.trigger
    this.options = obj.options
  }
}

export class MTCFIntegrationFilter {
  name: string
  property: string
  compare: string
  values: string[]

  constructor(obj: MTCFIntegrationFilter) {
    this.name = obj.name
    this.property = obj.property
    this.compare = obj.compare
    this.values = obj.values
  }

  /**
   * Returns true if the record meets the filter's condition, false otherwise
   * @param data The record on which this filter is being compared
   * @param filter The filter being applied to this record
   * @returns True if the record meets the filter's condition, false otherwise
   */
  static applyFilter(
    data: FirestoreUploadData,
    filter: MTCFIntegrationFilter
  ): boolean {
    switch (filter.compare) {
      case 'isEqualTo':
        return filter.values.includes(data.content[filter.property])
      default:
        return false // Fail on filters not yet implemented
    }
  }
}
