<script setup>
import { defineProps, defineEmits, computed, ref, watch, onMounted } from 'vue'
const props = defineProps({
  itemConfig: Object,
  excludeList: Object,
})

const panel = ref(props.itemConfig?.isExpanded ? [0] : [])
</script>

<template>
  <v-alert
    v-if="
      props.itemConfig.type === 'checkboxList' && props.excludeList.length > 0
    "
    density="compact"
    color="light-green-lighten-5"
    class="mb-2 border"
    style="border-width: 2px"
  >
    <slot name="filterExcludeList" />
  </v-alert>

  <v-expansion-panels v-model="panel" class="mb-4">
    <v-expansion-panel>
      <v-expansion-panel-title
        color="secondary"
        expand-icon="mdi-chevron-down"
        collapse-icon="mdi-chevron-up"
        :class="{ 'pa-0 pr-2': props.itemConfig.options.canSelectAll }"
      >
        <!--style="max-height: 40px"-->
        <template v-slot:default="{}">
          <slot name="filterTitleContent" />
        </template>
      </v-expansion-panel-title>
      <v-expansion-panel-text class="border">
        <slot name="filterMainContent" />

        <slot name="filterFooterContent" />
      </v-expansion-panel-text>
    </v-expansion-panel>
  </v-expansion-panels>
</template>
