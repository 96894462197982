import { initializeApp } from 'firebase/app'
import {
  getFirestore,
  getDocs,
  query,
  collection,
  where,
  Timestamp,
} from 'firebase/firestore'
import { getFunctions, httpsCallable } from 'firebase/functions'
import {
  getAuth,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  OAuthProvider,
  signInWithPopup,
  sendPasswordResetEmail,
} from 'firebase/auth'
import {
  getStorage,
  ref,
  uploadBytes,
  getDownloadURL,
  getBytes,
  getBlob,
} from 'firebase/storage'
import {
  app as firebaseApp,
  db as firestore,
  mtcfApp,
  mtcfDevApp,
} from '../../firebase'

const firebaseAuth = getAuth(firebaseApp)
const microsoftProvider = new OAuthProvider('microsoft.com')
const storage = getStorage(firebaseApp)
const prodFunctions = getFunctions(mtcfApp)
const devFunctions = getFunctions(mtcfDevApp)

/**
 * Calls the given MTCF with the given argument.
 * @param functionName The name of the MaxTrax Cloud Function to call
 * @param requestBody The request body to hand off to the cloud function
 * @returns The response from the cloud function using the promise API
 */
const mtcfProd = async (functionName: string, requestBody: any) =>
  await httpsCallable(prodFunctions, functionName, { timeout: 300 * 1000 })(
    requestBody
  )

/**
 * Calls the given MTCF with the given argument.
 * @param functionName The name of the MaxTrax Cloud Function to call
 * @param requestBody The request body to hand off to the cloud function
 * @returns The response from the cloud function using the promise API
 */
const mtcfDev = async (functionName: string, requestBody: any) =>
  await httpsCallable(devFunctions, functionName)(requestBody)

const firestoreQuery = async (
  db: any,
  id: number,
  params: { coll: string; field: string }
) => {
  const returnArr: unknown[] = []
  const q = query(collection(db, params.coll), where(params.field, '==', id))

  const querySnapshot = await getDocs(q)
  querySnapshot.forEach(doc => {
    returnArr.push(doc.data())
    // callback(doc.data())
  })

  return returnArr
}

function getCloudStorageFile(filepath: string) {
  const pathReference = ref(storage, filepath)
  console.log(`Ref: ${JSON.stringify(pathReference)}`)
  return getBytes(pathReference)
}

/**
 * Produces a signed URL for a given firestore path
 * @param filepath The firestore reference for which to retrieve a signed URL
 * @returns A signed URL to access the file at the given firestore location
 */
async function getCloudStorageUrl(filepath: string): Promise<string | null> {
  const pathReference = ref(storage, filepath)
  const result = await getDownloadURL(pathReference).catch(console.error)
  return result ?? null
}

export {
  firebaseApp,
  firebaseAuth,
  firestore,
  microsoftProvider,
  storage,
  getCloudStorageFile,
  getCloudStorageUrl,
  mtcfProd,
  mtcfDev,
  httpsCallable,
  ref,
  getDownloadURL,
  OAuthProvider,
  Timestamp,
  signInWithPopup,
  sendPasswordResetEmail,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  firestoreQuery,
}
