<script>
import ToolExport from './ToolExport.vue'
import ToolSearch from './ToolSearch.vue'
import { onMounted, defineProps, h } from 'vue'
export default {
  props: {
    tool: String,
    data: Object,
  },
  setup(props) {
    // const props = defineProps({ tool: String })

    const toolRenderer = tool => {
      if (tool?.toLowerCase() === 'export')
        return h(ToolExport, { data: props.data })
      if (tool?.toLowerCase() === 'search')
        return h(ToolSearch, { data: props.data })
    }

    return () => h(toolRenderer(props.tool))
  },
}
</script>
