<script setup>
import { watch, onMounted, computed } from 'vue'
import { storeToRefs } from 'pinia'
import useCommonConfig from '@/composables/useCommonConfig'

// vars
const { router, isMobile, navigationStore, settingsStore, userStore } =
  useCommonConfig()
const { showModal, modalProperties, toggleLeftDrawer } =
  storeToRefs(navigationStore)
const { showUserMenuSettings, toggleSettings } = storeToRefs(settingsStore)
const dividerColor = '#E0E0E0'
const showRoleMenu = computed(() => userStore.currentModule?.roles.length > 1)

const settingsHandler = () => {
  toggleSettings.value = true
  modalProperties.value = {}
}

watch(
  () => userStore.currentModule,
  newVal => {
    Promise.resolve(navigationStore.setRoleMenu(newVal?.roles))
    // when the module changes we also need to change the role and role menu
    userStore.setCurrentRole()
  }
)
watch(
  () => userStore.currentClient,
  () => {
    Promise.resolve(navigationStore.setClientMenu(userStore.getClients))
    // we also need to update the default module / role
  }
)

watch(
  () => userStore.isAuthenticated,
  () => {
    // automatically redirect site once authantication is changed
    window.location.replace(`${process.env.VUE_APP_BASE_PATH}/sign-in`)
  }
)

onMounted(() => {
  // only the first time the site loads
  navigationStore.setClientMenu(userStore.getClients)
  navigationStore.setRoleMenu(userStore.getRoles)
})
</script>

<template>
  <v-menu>
    <template v-slot:activator="{ props }">
      <v-btn color="white" v-bind="props" class="text-capitalize">
        <v-avatar color="white" density="compact">
          <v-icon icon="mdi-account" color="grey" size="x-large"></v-icon>
        </v-avatar>
        <span v-if="$vuetify.display.smAndUp" class="ml-2">
          {{ userStore.user.displayName }}
        </span>
        <span
          v-if="
            $vuetify.display.smAndUp && navigationStore.moduleMenu.length > 1
          "
        >
          ({{ userStore.currentModule?.name }})
        </span>
        <v-icon
          icon="mdi-chevron-down"
          end
          color="white"
          :size="isMobile ? 28 : 'x-large'"
        ></v-icon>
      </v-btn>
    </template>
    <v-list>
      <v-list-item v-if="isMobile">
        <v-list-item-title class="text-capitalize text-h6">
          {{ userStore.user.displayName }}</v-list-item-title
        >
      </v-list-item>
      <v-divider v-if="isMobile" :color="dividerColor"></v-divider>
      <v-list-item>
        <v-list-item-title>
          MaxTrax Version {{ navigationStore.version }}</v-list-item-title
        >
      </v-list-item>
      <v-divider />
      <v-divider v-if="isMobile" :color="dividerColor"></v-divider>
      <template v-if="navigationStore.clientMenu.length > 1">
        <v-list-item
          v-for="(item, index) in navigationStore.getClientMenuItems"
          :to="{
            name: 'dynamic',
            params: {
              client: item.client,
              // module: userStore.getCurrentModuleName, // dynamicView will load the default module
            },
          }"
          @click="userStore.setCurrentClient(item.clientId)"
          :key="index"
        >
          <v-list-item-title class="text-capitalize">
            <v-icon
              v-if="item.client === router.currentRoute.value.params.client"
              icon="mdi-office-building"
            ></v-icon>
            <v-icon v-else icon="mdi-office-building-outline"></v-icon>
            {{ item.title }}</v-list-item-title
          >
        </v-list-item>
        <v-divider :color="dividerColor"></v-divider>
      </template>
      <template v-if="showRoleMenu">
        <v-list-item
          v-for="(item, index) in navigationStore.getRoleMenuItems"
          @click="userStore.setCurrentRole(item.roleId)"
          :key="index"
          :active="item.roleId === userStore.currentRole.roleId"
        >
          <v-list-item-title class="text-capitalize">
            <v-icon
              v-if="item.roleId === userStore.currentRole.roleId"
              icon="mdi-account-cog"
            ></v-icon>
            <v-icon v-else icon="mdi-account-cog-outline"></v-icon>
            {{ item.title }}</v-list-item-title
          >
        </v-list-item>
        <v-divider :color="dividerColor"></v-divider>
      </template>
      <template v-if="showUserMenuSettings">
        <v-list-item @click.prevent="settingsHandler()">
          <v-list-item-title class="text-capitalize cursor-pointer">
            <v-icon icon="mdi-cog-outline"></v-icon> Settings
          </v-list-item-title>
        </v-list-item>

        <v-divider :color="dividerColor"></v-divider>
      </template>
      <v-list-item href="/change-password">
        <v-list-item-title class="text-capitalize">
          <v-icon icon="mdi-account-lock"></v-icon> Change Password
        </v-list-item-title>
      </v-list-item>

      <v-divider :color="dividerColor"></v-divider>
      <v-list-item
        @click.prevent="navigationStore.clearNavigation(), userStore.logOut()"
      >
        <v-list-item-title class="text-capitalize">
          <v-icon icon="mdi-power"></v-icon> Sign Out
        </v-list-item-title>
      </v-list-item>
    </v-list>
  </v-menu>
</template>
