import { FirestoreUploadData } from './FormData.model'

/**
 * Object for a form logic operation for type checking after JSON conversion
 */
export class FormLogicOperation {
  conditions: FormLogicCondition[]
  actions: FormLogicAction[]

  constructor(conditions: FormLogicCondition[], actions: FormLogicAction[]) {
    this.conditions = conditions
    this.actions = actions
  }
}

/**
 * Object for a form logic action for type checking after JSON conversion
 */
export class FormLogicAction {
  actionType: string
  target: string
  targetProp?: string
  value?: any
  values?: any
  group?: string

  constructor(
    actionType: string,
    target: string,
    value: any,
    group: string | undefined = undefined
  ) {
    this.actionType = actionType
    this.target = target
    this.value = value
    if (group) this.group = group
  }
}

/**
 * Object for a form logic condition for type checking after JSON conversion
 */
export class FormLogicCondition {
  compare: string
  source: string
  values: any | any[]
  group?: number

  constructor(
    compare: string,
    source: string,
    values: any | any[],
    group: number | undefined = undefined
  ) {
    this.compare = compare
    this.source = source
    this.values = values
    if (group) this.group = group
  }
}

export class FormResponseConditionalStyling {
  conditions: FormLogicCondition[]
  style: string
  class: string

  constructor(obj: FormResponseConditionalStyling) {
    this.conditions = obj.conditions
    this.style = obj.style
    this.class = obj.class
  }
}

/**
 * Handler object for an array of form logic operations. Can prepare an
 * operation dict, and offers static methods to work with form logic operations.
 */
export class FormLogicOperationHandler {
  operations: FormLogicOperation[]

  constructor(operations: FormLogicOperation[]) {
    this.operations = operations ?? []
  }

  /**
   * Converts this handler's list of `FormLogicOperation`s into a dictionary
   * wherein each operation is a value under the key of each property whose
   * change might trigger it.
   * @returns A dictionary where each key is the name of a property and each
   * value is a list of form logic operations that are triggered by the given
   * property
   */
  extractOperationDictionary(): { [key: string]: FormLogicOperation[] } {
    const operationDict: { [key: string]: FormLogicOperation[] } = {}

    for (const op of this.operations) {
      const conditionProperties: { [key: string]: undefined } = {}
      for (const condition of op.conditions) {
        conditionProperties[condition.source] = undefined
      }
      for (const prop of Object.keys(conditionProperties)) {
        if (!operationDict[prop]) {
          operationDict[prop] = [] as FormLogicOperation[]
        }
        operationDict[prop].push(op)
      }
    }

    return operationDict
  }

  /**
   * Produces an array of form logic actions to apply given an array of form
   * logic operations and the current form state
   * @param operations An array of form logic operations that may be applied to
   * the form
   * @param formState A FirestoreUploadData that has the entire state of the
   * form for easy reference to check conditions against all possible fields
   * @returns An array of actions to apply from the given operations whose
   * condition groups evaluate to true given the current form state
   */
  static getActionsToApply(
    operations: FormLogicOperation[],
    formState: FirestoreUploadData
  ): FormLogicAction[] {
    let actions: FormLogicAction[] = []
    for (const operation of operations) {
      const groups: (string | undefined)[] =
        FormLogicOperationHandler.extractGroups(operation)
      for (const group of groups) {
        if (
          FormLogicOperationHandler.evaluateConditionGroup(
            operation.conditions.filter(cond => cond.group === group),
            formState
          )
        ) {
          // If any one group is true, add all this operation's actions and
          // stop evaluating the conditions
          actions = actions.concat(operation.actions)
          break
        }
      }
    }
    return actions
  }

  /**
   * Returns true if every condition in the given condition group is true,
   * false otherwise. Short-circuits.
   * @param conditionGroup The logic condition group to evaluate
   * @param formState A FirestoreUploadData object representing the full state
   * of the form
   * @returns True if every condition in the group evaluates to true, false
   * otherwise
   */
  static evaluateConditionGroup(
    conditionGroup: FormLogicCondition[],
    formState: FirestoreUploadData
  ) {
    for (const condition of conditionGroup) {
      if (
        !FormLogicOperationHandler.evaluateCondition(
          condition,
          formState.getValue(condition.source)
        )
      )
        return false
    }
    return true
  }

  /**
   * Vacuously evaluates whether or not a value meets a form logic condition
   * @param condition The condition to evaluate
   * @param stateVal The source value being evaluated against the condition
   * @returns True if the value meets the condition, false otherwise
   */
  static evaluateCondition(
    condition: FormLogicCondition,
    stateVal: any
  ): boolean {
    // const stateVal = formState.getValue(condition.source)
    const targetList = Array.isArray(condition.values)
      ? condition.values
      : [condition.values]
    switch (condition.compare) {
      case 'Clicked':
        return true
      case 'Contain':
        for (const targetVal of targetList) {
          if (stateVal.includes(targetVal)) return true
        }
        return false
      case 'NotContain':
        for (const targetVal of targetList) {
          if (stateVal.includes(targetVal)) return false
        }
        return true
      case 'Equal':
        return targetList.includes(stateVal)
      case 'NotEqual':
        return !targetList.includes(stateVal)
      case 'Start':
        for (const targetVal of targetList) {
          if (stateVal.startsWith(targetVal)) return true
        }
        return false
      case 'NotStart':
        for (const targetVal of targetList) {
          if (stateVal.startsWith(targetVal)) return false
        }
        return true
      case 'End':
        for (const targetVal of targetList) {
          if (stateVal.endsWith(targetVal)) return true
        }
        return false
      case 'NotEnd':
        for (const targetVal of targetList) {
          if (stateVal.endsWith(targetVal)) return false
        }
        return true
      case 'More':
        for (const targetVal of targetList) {
          if (stateVal > targetVal) return true
        }
        return false
      case 'Less':
        for (const targetVal of targetList) {
          if (stateVal < targetVal) return true
        }
        return false
      case 'Empty':
        return !stateVal
      case 'NotEmpty':
        return !!stateVal
      case 'Between':
        if (targetList.length !== 2) {
          console.error(
            `Invalid argument count for 'Between' operation: ${targetList.length}`,
            `Arguments given: ${JSON.stringify(targetList)}`
          )
        }
        if (targetList[0] < targetList[1])
          return targetList[0] < stateVal && stateVal < targetList[1]
        else return targetList[1] < stateVal && stateVal < targetList[0]
      case 'NotBetween':
        if (targetList.length !== 2) {
          console.error(
            `Invalid argument count for 'NotBetween' operation: ${targetList.length}`,
            `Arguments given: ${JSON.stringify(targetList)}`
          )
        }
        if (targetList[0] < targetList[1])
          return !(targetList[0] < stateVal && stateVal < targetList[1])
        else return !(targetList[1] < stateVal && stateVal < targetList[0])
      default:
        console.error(
          `Cannot handle unexpected comparison type: ${condition.compare}`
        )
        return false
    }
  }

  /**
   * Produces an array of the names of all condition groups that exist for the
   * given operation. This array will contain `undefined` if a group is not
   * specified for any condition, which can still be keyed on as the group name.
   * @param operation The operation whose groups to extract
   * @returns An array of keys representing the names of condition groups. May
   * include `undefined` to represent the condition group where no group is
   * specified.
   */
  static extractGroups(operation: FormLogicOperation): (string | undefined)[] {
    let addUndefined = false
    const groups: { [key: string]: null } = {}
    for (const condition of operation.conditions) {
      if (condition.group) groups[condition.group] = null
      else addUndefined = true
    }
    return addUndefined
      ? ([undefined] as (undefined | string)[]).concat(Object.keys(groups))
      : Object.keys(groups)
  }
}
