<script setup>
import { ref, defineProps, defineEmits, computed, watch, onMounted } from 'vue'
// common config
import useCommonConfig from '@/composables/useCommonConfig'
import useWidgetConfig from '../composables/useWidgetConfig'
import { lookupRecordsetKey } from '@/services/lookup'
import { getTextWidth } from '@/services/utils'
import { FirestoreImageHandler } from '@/model/Image.model'

const props = defineProps({
  config: Object,
  head: Array,
  data: Array,
  height: [Number, String, null],
  dataSet: Array,
  paginationStart: Number,
  rowMax: [Number, String],
  isChild: Boolean,
  rootParentId: [Number, String],
  rootParentIdx: Number,
  isInfinite: Boolean,
})

const { isMobile, dataStore, isNotMobile } = useCommonConfig()

const emits = defineEmits([
  'emitAdd',
  'emitEdit',
  'emitMap',
  'emitToggleChild',
  'emitScroll',
])
const activeColumn = ref([])
const photoOverlay = ref(false)
const selectedPhoto = ref()
const sortControls = ref({
  recordset: props.config.options?.recordset,
  direction: props.config.options?.sortDirection,
  property: props.config.options?.sortProperty,
})
const sortDirections = ref([])
const showChild = ref([])
const parentId = ref(props.rootParentId)
const childObj = ref(new Array(props.data?.length))
const gridTable = ref(null)
const isScrolling = ref(false)
const styleHeight = ref(props.config.rowHeight)
const isEllipsis = ref([])
const activeTab = ref(null)

watch(
  () => props.head,
  newVal => {
    sortDirections.value = []
    // activeColumn.value = []
    newVal.forEach(() => {
      sortDirections.value.push('asc')
      // activeColumn.value.push(false)
    })
  }
)

// computed
const childIcons = computed(() => {
  // backwards compatible for childNotes
  if (props.config.options?.children[0]?.icons)
    return props.config.options?.children[0]?.icons
  return props.config.options?.children[0]?.childItems?.icons // 0 needs to be a variable
})

const childColor = computed(() => {
  // backwards compatible for childNotes
  if (props.config.options?.children[0]?.color)
    return props.config.options?.children[0]?.color
  return props.config.options?.children[0]?.childItems.color
})

const rowChildren = (row, idx = 0) => {
  if (row.childNotes) {
    // backwards compatible fail-safe
    return row.childNotes
  }
  return row?.children?.find(
    x =>
      x.name?.toLowerCase() ===
      props.config.options?.children[idx]?.childItems?.recordset.toLowerCase()
  )?.content

  // return props.config.options?.children[idx]?.childItems?.recordset // This will need to refer a separate table
}

const tabs = computed(() => {
  const returnArr = []
  // splice together any possible childNotes with child Tables...
  // childNotes is old, but it's possible they may include tables in the future,
  // this should put them together in the tabs format
  props.config.options?.children.forEach(x => {
    if (x.childItems) {
      returnArr.push(x)
    } else {
      let newX = x
      newX.label = 'Notes'
      returnArr.push({ childItems: newX })
    }
  })

  return returnArr
})

const isInfiniteEnabled = computed(() => {
  return props.isInfinite
})

watch(
  () => isInfiniteEnabled.value,
  newVal => {
    if (newVal === true) scrollHandler()
  }
)

// methods
const importanceFormatter = val => {
  let returnVal = val
  if (
    val
      ?.toLowerCase()
      ?.replaceAll('<mark>', '')
      ?.replaceAll('</mark>', '')
      ?.includes('low')
  )
    returnVal = { color: '#00b11f', icon: 'fa: fa-solid fa-flag', text: val }
  if (
    val
      ?.toLowerCase()
      ?.replaceAll('<mark>', '')
      ?.replaceAll('</mark>', '')
      ?.includes('medium')
  )
    returnVal = {
      color: '#c3ad00',
      icon: 'fa: fa-solid fa-lightbulb',
      text: val,
    }
  if (
    val
      ?.replaceAll('<mark>', '')
      ?.replaceAll('</mark>', '')
      ?.toLowerCase()
      ?.includes('high')
  )
    returnVal = { color: '#cc0b0b', icon: 'fa: fa-solid fa-star', text: val }
  if (
    val
      ?.replaceAll('<mark>', '')
      ?.replaceAll('</mark>', '')
      ?.toLowerCase()
      ?.includes('very high')
  )
    returnVal = {
      color: '#000000',
      icon: 'fa: fa-solid fa-bolt-lightning',
      text: val,
    }
  if (
    val
      ?.replaceAll('<mark>', '')
      ?.replaceAll('</mark>', '')
      ?.toLowerCase()
      ?.includes('not sure')
  )
    returnVal = {
      color: '#037be4',
      icon: 'fa: fa-solid fa-circle-question',
      text: val,
    }

  return (
    returnVal ?? {
      color: '#037be4',
      icon: 'fa: fa-solid fa-circle-question',
      text: 'Invalid Importance',
    }
  )
}

const colorTextFormatter = (val, rules) => {
  const formatRules = rules.find(x => x.value === val)
  return `<span class="${formatRules?.class}" style="${formatRules?.styles};">${val}</span>`
}

const rowHasNotes = row => {
  // delete?
  return row?.childNotes && row?.childNotes.length > 0
}

const rowHasChildren = row => {
  return row?.children && row?.children.length > 0
  // return (
  //   rowHasNotes(row) || (row[rowChildren()] && row[rowChildren()].length > 0)
  // )
}

const marginSelector = (idx, arr) => {
  if (idx === arr.length - 1) return

  return 'mr-3'
}

const toggleAction = (criteria = null, row) => {
  if (!criteria) return true
  if (criteria.compare) {
    switch (criteria.compare.toLowerCase()) {
      case 'not-equal-to':
        return row[criteria.property] !== criteria.value
      case 'greater-than':
        return row[criteria.property] > criteria.value
      case 'less-than':
        return row[criteria.property] < criteria.value
      default:
        return row[criteria.property] === criteria.value
    }
  } else return row[criteria.property] !== criteria.value
}

const childHandler = idx => {
  if (showChild.value.includes(idx))
    showChild.value.splice(showChild.value.indexOf(idx), 1)
  else showChild.value.push(idx)
}

const addHandler = child => {
  emits('emitAdd', child)
}

const editHandler = data => {
  emits('emitEdit', data)
}

const mapHandler = id => {
  emits('emitMap', { id })
}

const secondsToDateTime = timeObj => {
  // TODO: delete?
  let returnVal = null
  if (timeObj?.seconds) {
    returnVal = `${new Date(
      Math.abs(timeObj.seconds) * 1000
    ).toLocaleDateString('en-US')}`
    // ${new Date(Math.abs(timeObj._seconds) * 1000).toLocaleTimeString(
    //   'en-US'
    // )}`
  }
  return returnVal
}

const scrollHandler = () => {
  if (isInfiniteEnabled.value) {
    let scrolling
    // attaches to the v-table itself
    gridTable.value.$el.children[0].addEventListener(
      'scroll',
      () => {
        // emits when scrolling
        isScrolling.value = true
        emits('emitScroll', {
          isScrolling: isScrolling.value,
          position:
            gridTable.value.$el.children[0].scrollHeight -
            gridTable.value.$el.children[0].clientHeight -
            gridTable.value.$el.children[0].scrollTop,
        })

        window.clearTimeout(scrolling)
        scrolling = setTimeout(() => {
          // emits when stopped
          isScrolling.value = false
          emits('emitScroll', {
            isScrolling: isScrolling.value,
            position:
              gridTable.value.$el.children[0].scrollHeight -
              gridTable.value.$el.children[0].clientHeight -
              gridTable.value.$el.children[0].scrollTop,
          })
        }, 1000) // give it a bit of time to reduce excess calls
      },
      false
    )
  }
}

const sortHandler = (property, idx) => {
  // reset everything // if this is executed before sortDirections is set, it fails
  sortDirections.value.forEach((x, xIdx) => {
    if (xIdx !== idx) {
      sortDirections.value[xIdx] = 'asc'
      activeColumn.value[xIdx] = false
    }
  })
  sortControls.value.property = property
  sortControls.value.direction = sortDirections.value[idx]

  sortDirections.value[idx] =
    sortDirections.value[idx] === 'asc' ? 'desc' : 'asc'

  activeColumn.value[idx] = true
}

const photoOverlayHandler = src => {
  photoOverlay.value = true
  selectedPhoto.value = src
}

const formatter = (format, value, rules = null) => {
  // console.log('format ', format)
  // console.log('value ', value)
  switch (format) {
    case 'function':
      return null
    case 'customIssueImportance':
      return importanceFormatter(value)
    //return secondsToDateTime(value) // datetime will already be formatted
    case 'text':
    case 'datetime':
      return value
    case 'colorText':
      return colorTextFormatter(value, rules)
    case 'photo':
    case 'image':
      return // format, value // photoRenderer(value)
    default:
      return format, value
  }
}

// coming soon...
// const isDateNotFormatted = property => {
//   return (
//     property.toLowerCase().includes('datetime') &&
//     !property.includes('Fomatted')
//   )
// }

// const rowData = (data, row, idx) => {
//   if (data.property.indexOf('.') > -1) {
//     console.log('here')
//     dataProperty(data.property, idx)
//   } else {
//     return isDateNotFormatted(data.property)
//       ? row[data.property + 'Formatted']
//       : row[data.property]
//   }
// }

const recordsetData = computed(() => {
  // if (props.dataSet?.length > 0) return props.dataSet // TODO: add sorting
  if (!props.dataSet && dataStore.recordsets.length < 1) return null
  else {
    const data =
      props.dataSet?.length > 0
        ? props.dataSet
        : dataStore.filteredRecordset(props.config.options.recordset)
    let recordsetDataArr
    // to be determined: If sorting has NOT been initially set does that mean other sorting options won't happen?
    if (props.config.options?.sortProperty) {
      // sort
      if (sortControls.value.property.toLowerCase().includes('datetime')) {
        recordsetDataArr =
          sortControls.value.direction === 'asc'
            ? data.sort(
                (a, b) =>
                  (a[sortControls.value.property]?.seconds ?? 0) -
                  (b[sortControls.value.property]?.seconds ?? 0)
              )
            : data.sort(
                (a, b) =>
                  (b[sortControls.value.property]?.seconds ?? 0) -
                  (a[sortControls.value.property]?.seconds ?? 0)
              )
      } else {
        recordsetDataArr =
          sortControls.value.direction === 'asc'
            ? data.sort((a, b) =>
                (a[sortControls.value.property] ?? '')?.localeCompare(
                  b[sortControls.value.property] ?? ''
                )
              )
            : data.sort((a, b) =>
                (b[sortControls.value.property] ?? '')?.localeCompare(
                  a[sortControls.value.property] ?? ''
                )
              )
      }
    } else {
      // no sort
      recordsetDataArr = data
    }

    let highlightedRecordsetDataArr

    if (dataStore.getSearchString) {
      // deep clone
      highlightedRecordsetDataArr = JSON.parse(JSON.stringify(recordsetDataArr))
      // add highlights
      highlightedRecordsetDataArr.forEach(x => {
        Object.keys(x).forEach(y => {
          if (
            x[y] &&
            x[y]
              .toString()
              .toLowerCase()
              .includes(dataStore.getSearchString.toLowerCase())
          ) {
            x[y] = x[y]
              .toString()
              .replace(
                new RegExp(dataStore.getSearchString.toLowerCase(), 'gi'),
                match => `<mark>${match}</mark>`
              )
          }
        })
      })

      return highlightedRecordsetDataArr
    }

    let returnArr = []
    // pagination
    if (isInfiniteEnabled.value) {
      // for infinite scrolling, we always return the beginning to the new max
      for (let i = 0; i < props.rowMax; i++) {
        returnArr.push(recordsetDataArr[i])
        /* if (i >= props.paginationStart - 1) {
          // eslint-disable-next-line
          infiniteRecordsetData.value.push(recordsetDataArr[i])
        }*/
      }
    } else if (props.paginationStart) {
      for (let i = props.paginationStart - 1; i < props.rowMax; i++) {
        returnArr.push(recordsetDataArr[i])
      }
    } else return recordsetDataArr
    return returnArr
  }
})

// populate wrap handler
watch(
  () => recordsetData.value,
  newVal => {
    newVal.forEach(() => {
      isEllipsis.value.push(true)
    })
  }
)

// infinite scroll computed property / method

const recordId = computed(() => {
  return lookupRecordsetKey(
    props.isChild
      ? props.config.options.children[0]?.childItems?.recordset // TODO: make this iterable
      : props.config.options.recordset
  )
})

const tableHeight = computed(() => {
  return props.height
})
// Row Highlighting
const prevRow = ref(null)
const activeRowClickCount = ref(0)

const setActiveRow = row => {
  // get the current active row
  const activeElement = document.getElementsByClassName(
    'widget-grid-table__row--active'
  )[0]
  if (activeElement) {
    // we can't have two active rows, so remove the active class
    activeElement.classList.remove('widget-grid-table__row--active')
    // we need to know the new versus the previous row so the click counter can work properly
    prevRow.value = activeElement.id
  }
  // highlight new active row
  document.getElementById(row).classList.add('widget-grid-table__row--active')
}

const resetRow = row => {
  activeRowClickCount.value = 1
  document
    .getElementById(row)
    .classList.remove('widget-grid-table__row--active')
}

const rowClickHandler = (rIdx, row) => {
  // highlight the active row
  setActiveRow(rIdx)
  // if the current row is the same as the previous row clicked, then increment the click counter
  // otherwise reset this as the first click on the new row
  if (rIdx === prevRow.value) activeRowClickCount.value++
  else activeRowClickCount.value = 1

  // we can only ever have a maximum of 3 clicks per row
  if (activeRowClickCount.value < 3) {
    const idx = rIdx.split('-')
    if (activeRowClickCount.value === 2 && rIdx === prevRow.value) {
      editHandler({
        id: props.isChild ? props.rootParentId : row[recordId.value],
        readOnly: true,
        note: props.isChild
          ? {
              id: row[recordId.value],
              isNewNote: false,
            }
          : null,
      })
    }
  } else {
    // once we get to 3 clicks, reset it as unclicked
    resetRow(rIdx)
  }
}
// End Row Highlighting

onMounted(() => {
  scrollHandler()
  // set the initial active column by getting the index of the sortProperty
  if (props.config.options.sortProperty)
    sortHandler(
      props.config.options.sortProperty,
      props.config.options.head.findIndex(
        x =>
          x.property.toLowerCase() ===
          props.config.options.sortProperty.toLowerCase()
      )
    )
})

const dateFormatting = (row, property, value = null) => {
  // this will be necessary when we have child properties as column names
  if (property.indexOf('.') > -1) {
    if (eval(`row?.${property.replaceAll('.', '?.')}Formatted`)) {
      return eval(`row?.${property.replaceAll('.', '?.')}Formatted`)
    }
  }
  // for the time-being this de-clutters the markup section a bit
  return property.toLowerCase().includes('datetime') &&
    !property.includes('Fomatted')
    ? row[property + 'Formatted']
    : row[property]
}

// coming soon
/*const dataProperty = (property, rowIndex) => {
  if (property.indexOf('.') > -1) {
    const splitProperty = property.split('.')
    console.log('data.property ', property)
    console.log('rowIndex ', rowIndex)
    console.log(
      'recordsetData >>',
      recordsetData.value[rowIndex][splitProperty[0]][splitProperty[1]]
    )
    //return splitProperty[1]
    return recordsetData.value[rowIndex][splitProperty[0]][
      splitProperty[1] + 'Formatted'
    ]
  }

  return property
}*/

const dataProperty = (row, property) => {
  // this will be necessary when we have child properties as column names
  let returnObj = { property: property, value: row[property] }
  if (property.indexOf('.') > -1) {
    if (eval(`row?.${property.replaceAll('.', '?.')}`)) {
      returnObj.value = eval(`row?.${property.replaceAll('.', '?.')}`)
    }
  }
  return returnObj
}
</script>

<template>
  <div class="widget-grid-table w-100">
    <v-table
      density="compact"
      :fixed-header="!props.isChild"
      class="w-100"
      :style="`max-height: ${props.height};`"
      :height="!props.isChild ? props.height : 'auto'"
      ref="gridTable"
    >
      <thead v-if="props.head.length > 0">
        <tr>
          <th
            v-for="(item, hIdx) in props.head"
            :key="hIdx"
            :class="[
              `th-height ` + item.cellClass,
              {
                'cursor-pointer': item.isSortable,
              },
              {
                'border-left': hIdx > 0,
              },
            ]"
            :style="[
              `min-width: ${item.width}` ?? item.width,

              `background-color: ${
                isChild ? childColor : '#46822d'
              } !important`,
            ]"
            :width="item.width ? item.width : ''"
            :height="item.height ? item.height : ''"
            @click="item.isSortable ? sortHandler(item.property, hIdx) : null"
          >
            <div
              v-if="item.isSortable"
              variant="text"
              :class="[
                'd-flex lh-12',
                {
                  'font-weight-bold': activeColumn[hIdx],
                },
                item.contentClass,
              ]"
              style="height: auto"
              elevation="0"
            >
              <div class="text-left" style="white-space: normal">
                {{ item.label }}
              </div>
              <v-icon
                v-if="activeColumn[hIdx]"
                :class="['align-self-start', 'bold-icon']"
                :icon="
                  sortDirections[hIdx] === 'asc'
                    ? 'mdi-chevron-down'
                    : 'mdi-chevron-up'
                "
                style="height: 15px"
              />
            </div>

            <div v-else :class="['lh-13', item.contentClass]">
              <v-btn
                v-if="isChild"
                icon="mdi-chevron-up"
                height="auto"
                width="auto"
                size="x-small"
                variant="text"
                class="pa-0"
                @click.stop="emits('emitToggleChild', props.rootParentIdx)"
              />

              <v-btn
                v-if="isChild"
                icon="mdi-plus"
                height="auto"
                width="auto"
                size="x-small"
                variant="text"
                class="pa-0"
                @click.stop="
                  addHandler({
                    id: props.rootParentId,
                    readOnly: false,
                    note: {
                      id: props.rootParentId === parentId ? null : parentId,
                      isNewNote: true,
                    },
                  })
                "
              />
              {{ item.label }}
            </div>
          </th>
        </tr>
      </thead>
      <tbody>
        <template v-for="(row, rIdx) in recordsetData" :key="rIdx">
          <tr
            v-if="row"
            :id="`row-${rIdx}`"
            class="widget-grid-table__row"
            @click.prevent="rowClickHandler(`row-${rIdx}`, row)"
          >
            <td
              v-for="(data, dIdx) in props.data"
              :key="dIdx"
              :class="`border table-border lh-1 ${data.cellClass}`"
              :style="[`min-width: ${data.width}` ?? data.width]"
              :width="data.width ? data.width : ''"
              :height="data.height ? data.height : ''"
            >
              <template v-if="data.actions">
                <!-- desktop mode -->
                <div v-if="!isMobile">
                  <template v-for="(action, aIdx) in data.actions" :key="aIdx">
                    <!-- TODO: Find a more efficient way of duplicating this below -->
                    <div :class="['d-inline', data.contentClass]">
                      <v-btn
                        v-if="action?.name?.toLowerCase() === 'view'"
                        :id="`${rIdx}-view`"
                        :size="action.size"
                        height="auto"
                        width="auto"
                        elevation="0"
                        class="mr-1"
                        variant="text"
                        @click.stop="
                          editHandler({
                            id: props.isChild
                              ? props.rootParentId
                              : row[recordId],
                            readOnly: true,
                            note: props.isChild
                              ? {
                                  id: row[recordId],
                                  isNewNote: false,
                                }
                              : null,
                          })
                        "
                      >
                        <v-icon :size="action.size" icon="fa:fas fa-search" />
                      </v-btn>
                      <span class="d-none"
                        >{{ (parentId = row.parentId) }}
                        {{
                          (childObj[rIdx] = {
                            id: props.isChild
                              ? props.rootParentId
                              : row[recordId],
                            readOnly: false,
                            note: {
                              id: props.isChild ? row[recordId] : null,
                              isNewNote: true,
                            },
                          })
                        }}</span
                      >
                      <v-btn
                        v-if="action?.name?.toLowerCase().includes('children:')"
                        :size="action.size"
                        height="auto"
                        width="auto"
                        elevation="0"
                        variant="text"
                        :class="[
                          'pa-0',
                          { 'mr-1': childIcons[1].includes('fa:') },
                        ]"
                        @click.stop="
                          rowHasChildren(row)
                            ? childHandler(rIdx)
                            : addHandler(childObj[rIdx])
                        "
                        ><v-icon
                          :icon="
                            rowHasChildren(row) ? childIcons[1] : childIcons[0]
                          "
                          :color="childColor"
                          :size="action.size"
                          style="margin-top: 2px"
                        />
                        <v-tooltip activator="parent" location="top">
                          <span
                            v-if="
                              rowChildren(row) && rowChildren(row)?.length === 1
                            "
                          >
                            There is 1
                            {{
                              action?.label?.singular
                                ? action?.label?.singular
                                : 'child'
                            }}
                          </span>
                          <span
                            v-else-if="
                              rowChildren(row) && rowChildren(row)?.length > 1
                            "
                          >
                            There are {{ rowChildren(row)?.length }}
                            {{
                              action?.label?.plural
                                ? action?.label?.plural
                                : 'children'
                            }}
                          </span>
                          <span v-else>
                            There are no
                            {{
                              action?.label?.plural
                                ? action?.label?.plural
                                : 'children'
                            }}, click to add
                          </span></v-tooltip
                        >
                      </v-btn>
                      <template v-if="action?.name?.toLowerCase() === 'edit'">
                        <v-btn
                          v-if="toggleAction(action?.criteria, row)"
                          :size="action.size"
                          height="auto"
                          width="auto"
                          elevation="0"
                          variant="text"
                          class="mr-1"
                          @click.stop="
                            editHandler({
                              id: props.isChild
                                ? props.rootParentId
                                : row[recordId],
                              readOnly: false,
                              note: props.isChild
                                ? {
                                    id: row[recordId],
                                    isNewNote: false,
                                  }
                                : null,
                            })
                          "
                        >
                          <v-icon
                            :size="action.size"
                            icon="fa:fas fa-pencil" /></v-btn
                      ></template>
                      <v-btn
                        v-if="action?.name?.toLowerCase() === 'map'"
                        height="auto"
                        width="auto"
                        :size="action.size"
                        elevation="0"
                        variant="text"
                        class="mr-1"
                        @click.stop="mapHandler(row[recordId])"
                        ><v-icon
                          :size="action.size"
                          icon="fa:fas fa-map-marker-alt"
                      /></v-btn>
                    </div>
                  </template>
                </div>
                <div
                  v-else
                  :class="['d-inline-flex flex-wrap', data.contentClass]"
                >
                  <!-- mobile mode -->

                  <v-menu location="top">
                    <template v-slot:activator="{ props }">
                      <v-btn
                        v-bind="props"
                        elevation="0"
                        variant="text"
                        class="pa-0"
                        height="auto"
                      >
                        <template
                          v-for="(action, aIdx) in data.actions"
                          :key="aIdx"
                          ><v-btn
                            v-if="action?.name?.toLowerCase() === 'view'"
                            :id="`${rIdx}-view-mobile`"
                            :size="action.zoomSize"
                            height="auto"
                            width="auto"
                            elevation="0"
                            class="d-none"
                            variant="text"
                            @click.stop="
                              editHandler({
                                id: props.isChild
                                  ? props.rootParentId
                                  : row[recordId],
                                readOnly: true,
                                note: props.isChild
                                  ? {
                                      id: row[recordId],
                                      isNewNote: false,
                                    }
                                  : null,
                              }),
                                (menu = false)
                            "
                            >View</v-btn
                          >
                          <v-icon
                            v-if="action?.name?.toLowerCase() === 'view'"
                            icon="fa:fas fa-search"
                            :size="action.size"
                            height="auto"
                            width="auto"
                            elevation="0"
                            class="mr-1"
                          />
                          <v-icon
                            v-if="
                              action?.name?.toLowerCase().includes('children:')
                            "
                            :icon="
                              rowHasChildren(row)
                                ? childIcons[1]
                                : childIcons[0]
                            "
                            :size="action.size"
                            height="auto"
                            width="auto"
                            elevation="0"
                            :color="childColor"
                            :class="[
                              'pa-0',
                              { 'mr-1': childIcons[1].includes('fa:') },
                            ]"
                          >
                          </v-icon>
                          <v-icon
                            v-if="action?.name?.toLowerCase() === 'edit'"
                            icon="fa:fas fa-pencil"
                            :size="action.size"
                            height="auto"
                            width="auto"
                            elevation="0"
                            class="mr-1"
                          />
                          <v-icon
                            v-if="action?.name?.toLowerCase() === 'map'"
                            icon="fa:fas fa-map-marker-alt"
                            height="auto"
                            width="auto"
                            :size="action.size"
                            elevation="0"
                            class="mr-1"
                          />
                        </template>
                      </v-btn>
                    </template>
                    <v-card
                      border
                      color="grey-lighten-3"
                      class="pa-1 rounded-0"
                      min-height="auto"
                    >
                      <template
                        v-for="(action, aIdx) in data.actions"
                        :key="aIdx"
                      >
                        <v-btn
                          v-if="action?.name?.toLowerCase() === 'view'"
                          :size="
                            action.zoomSize ? action.zoomSize : action.size
                          "
                          height="auto"
                          width="auto"
                          elevation="0"
                          variant="text"
                          :class="marginSelector(aIdx, data.actions)"
                          @click.stop="
                            editHandler({
                              id: props.isChild
                                ? props.rootParentId
                                : row[recordId],
                              readOnly: true,
                              note: props.isChild
                                ? {
                                    id: row[recordId],
                                    isNewNote: false,
                                  }
                                : null,
                            }),
                              (menu = false)
                          "
                          ><v-icon
                            icon="fa:fas fa-search"
                            :size="
                              action.zoomSize ? action.zoomSize : action.size
                            "
                        /></v-btn>
                        <span class="d-none">{{
                          (childObj[rIdx] = {
                            id: props.isChild
                              ? props.rootParentId
                              : row[recordId],
                            readOnly: false,
                            note: {
                              id: props.isChild ? row[recordId] : null,
                              isNewNote: true,
                            },
                          })
                        }}</span>
                        <v-btn
                          v-if="
                            action.name?.toLowerCase().includes('children:')
                          "
                          elevation="0"
                          variant="text"
                          :size="
                            action.zoomSize ? action.zoomSize : action.size
                          "
                          height="auto"
                          width="auto"
                          :class="[
                            marginSelector(aIdx, data.actions),
                            'pa-0 mt-n1',
                          ]"
                          style="min-width: 0 !important"
                          @click.stop="
                            rowHasChildren(row)
                              ? childHandler(rIdx)
                              : addHandler(childObj[rIdx]),
                              (menu = false)
                          "
                          ><v-icon
                            :icon="
                              rowHasChildren(row)
                                ? childIcons[1]
                                : childIcons[0]
                            "
                            :color="childColor"
                            :size="
                              action.zoomSize ? action.zoomSize : action.size
                            "
                            style="margin-top: 2px"
                          />
                        </v-btn>
                        <v-btn
                          v-if="action?.name?.toLowerCase() === 'edit'"
                          variant="text"
                          :size="
                            action.zoomSize ? action.zoomSize : action.size
                          "
                          height="auto"
                          width="auto"
                          elevation="0"
                          :class="marginSelector(aIdx, data.actions)"
                          @click.stop="
                            editHandler({
                              id: props.isChild
                                ? props.rootParentId
                                : row[recordId],
                              readOnly: false,
                              note: props.isChild
                                ? {
                                    id: row[recordId],
                                    isNewNote: false,
                                  }
                                : null,
                            })
                          "
                          ><v-icon
                            icon="fa:fas fa-pencil"
                            :size="
                              action.zoomSize ? action.zoomSize : action.size
                            "
                        /></v-btn>
                        <v-btn
                          v-if="action?.name?.toLowerCase() === 'map'"
                          height="auto"
                          width="auto"
                          variant="text"
                          :size="
                            action.zoomSize ? action.zoomSize : action.size
                          "
                          elevation="0"
                          @click.stop="mapHandler(row[recordId])"
                          ><v-icon
                            icon="fa:fas fa-map-marker-alt"
                            :size="
                              action.zoomSize ? action.zoomSize : action.size
                            "
                        /></v-btn>
                      </template>
                    </v-card>
                  </v-menu>
                </div>
              </template>
              <span v-if="data.format == 'customIssueImportance'">
                <div>
                  <v-icon
                    v-if="formatter(data.format, row[data.property]).icon"
                    :icon="formatter(data.format, row[data.property]).icon"
                    :color="formatter(data.format, row[data.property]).color"
                    :size="isMobile ? 'x-small' : 'small'"
                  ></v-icon>
                  <span
                    :class="['ml-1 font-weight-bold', data.contentClass]"
                    :style="`color: ${
                      formatter(data.format, row[data.property]).color
                    }`"
                    v-html="row[data.property]"
                  ></span>
                </div>
              </span>
              <div v-else-if="data.format === 'colorText'">
                <span
                  v-html="
                    formatter(
                      data.format,
                      row[data.property],
                      data.colorTextRules
                    )
                  "
                >
                </span>
              </div>
              <div
                v-else-if="data.format !== 'image' && !data.actions"
                :class="[
                  data.contentClass,
                  {
                    'cursor-pointer d-inline-block text-truncate':
                      data.wrap === 'ellipsis' &&
                      isEllipsis[rIdx] &&
                      getTextWidth(
                        formatter(
                          data.format,
                          dateFormatting(row, data.property)
                        ) // formatter(data.format, rowData(data, row, rIdx))
                      ) > data.width.replace('px', '').replace('%', ''),
                  },
                  {
                    'cursor-pointer':
                      data.wrap === 'ellipsis' &&
                      !isEllipsis[rIdx] &&
                      getTextWidth(
                        formatter(
                          data.format,
                          dateFormatting(row, data.property)
                        ) // formatter(data.format, rowData(data, row, rIdx))
                      ) > data.width.replace('px', '').replace('%', ''),
                  },
                  { 'text-no-wrap': data.wrap === 'nowrap' },
                  { '': data.wrap === 'wrap' },
                ]"
                :style="
                  (data.wrap === 'ellipsis' && isEllipsis[rIdx]) ||
                  data.wrap === 'wrap'
                    ? `max-width: ${data.width}`
                    : 'max-width: 99%'
                "
                :data-content="
                  formatter(data.format, dateFormatting(row, data.property))
                "
                v-html="
                  formatter(data.format, dateFormatting(row, data.property))
                "
                @click.prevent="
                  data.wrap === 'ellipsis'
                    ? (isEllipsis[rIdx] = !isEllipsis[rIdx])
                    : ''
                "
              ></div>

              <span v-else>
                <template v-for="(dataItem, dIdx) in data" :key="dIdx">
                  <div
                    v-if="dataItem === 'image' && row[data.property]"
                    :class="[data.contentClass]"
                  >
                    <div @click.stop="photoOverlayHandler(row[data.property])">
                      <v-img
                        :src="
                          FirestoreImageHandler.getSource(row[data.property])
                        "
                        :lazy-src="
                          FirestoreImageHandler.getFallback(row[data.property])
                        "
                        cover
                        style="cursor: pointer"
                        :min-height="props.config.imageHeight"
                      />
                    </div>
                  </div>
                </template>
              </span>
            </td>
          </tr>

          <tr v-if="rowHasChildren(row)" class="widget-grid-table__child-table">
            <td style="height: 0"></td>
            <td
              :colspan="props.head.length - 1"
              class="px-0 overflow-x-auto"
              style="height: 0"
            >
              <Transition>
                <div v-if="showChild.includes(rIdx)">
                  <v-tabs
                    v-model="activeTab"
                    bg-color="grey-lighten-2"
                    align-tabs="left"
                    :class="[{ 'd-none': tabs.length < 2 }]"
                  >
                    <v-tab
                      v-for="(tab, tIdx) of tabs"
                      :value="tIdx"
                      :key="tIdx"
                      :class="{
                        'bg-primary': tIdx === activeTab,
                      }"
                      >{{ tab.childItems.label }}
                    </v-tab>
                  </v-tabs>
                  <v-window
                    disabled
                    v-model="activeTab"
                    class="overflow-y-auto"
                  >
                    <v-window-item
                      v-for="(tab, tIdx) of tabs"
                      :value="tIdx"
                      :key="tIdx"
                    >
                      <widget-grid-table
                        v-if="showChild.includes(rIdx)"
                        :config="props.config"
                        :head="tab.childItems.head"
                        :data="tab.childItems.data"
                        :height="tab.childItems.height"
                        :data-set="rowChildren(row, tIdx)"
                        @emit-map="mapHandler"
                        @emit-edit="editHandler"
                        @emit-add="addHandler"
                        @emit-toggle-child="childHandler"
                        :is-child="true"
                        :root-parent-id="
                          props.isChild ? props.rootParentId : row[recordId]
                        "
                        :root-parent-idx="rIdx"
                      />
                    </v-window-item>
                  </v-window>
                </div>
              </Transition>
            </td>
          </tr>
        </template>
      </tbody>
      <template v-slot:bottom>
        <slot name="bottom" />
      </template>
    </v-table>

    <v-overlay
      v-model="photoOverlay"
      close-on-content-click
      class="align-center justify-center"
      ><v-img
        :src="FirestoreImageHandler.getSource(selectedPhoto)"
        :lazy-src="FirestoreImageHandler.getFallback(selectedPhoto)"
        min-width="300px"
        aspect-ratio="1/1"
        style="width: 50vw; max-height: 80vh"
      />
    </v-overlay>
  </div>
</template>

<style lang="scss" scoped>
table,
th,
td {
  border-collapse: collapse;
}

.th-height {
  height: auto !important;
}

.v-enter-active,
.v-leave-active {
  transition: max-height 1s ease;
  max-height: v-bind(tableHeight);
}

.v-enter-from,
.v-leave-to {
  max-height: 0;
}
</style>

<style lang="scss">
.widget-grid-table {
  table {
    border-collapse: collapse;
    line-height: 1;
  }

  tr {
    max-height: 25px;
    overflow-y: hidden;
  }

  .v-table--fixed-header > .v-table__wrapper > table > thead > tr > th {
    padding: 0;
    // @media only screen and (max-width: 992px) {
    // hack. There is a bug in vuetify fixed headers that allows a gap to show on floating header
    top: -1px;
    // }
  }

  .v-table > .v-table__wrapper > table > tbody > tr > td {
    // Sets a new default. Hopefully this is never actually used. But padding is to be set via firestore.
    padding: 0;
  }
  .v-table--density-compact
    > .v-table__wrapper
    > table
    > tbody
    > tr.widget-grid-table__row
    > td {
    height: v-bind(styleHeight) !important;
    max-height: v-bind(styleHeight) !important;
  }

  mark {
    color: inherit;
  }

  &__row {
    &--active {
      background-color: #98c376;
    }
  }
}
</style>
