<script setup>
// common config
import useCommonConfig from '@/composables/useCommonConfig'
// components
import AddToHomeScreen from '@/components/AddToHomeScreen.vue'
import NavTopBar from '@/components/NavTopBar.vue'
import BreadcrumbBar from '@/components/BreadcrumbBar.vue'
import NavLeftDrawer from '@/components/NavLeftDrawer.vue'
import PageFooter from '@/components/PageFooter.vue'
import NavBottom from '@/components/NavBottom.vue'
import Settings from '@/components/Settings.vue'

import { ref, onMounted, computed } from 'vue'
// vars
const showLeftNav = ref(false)
const {
  isMobile,
  isNotMobile,
  isPhone,
  isIPhoneSE,
  isPWA,
  route,
  userStore,
  isOffline,
} = useCommonConfig()

const showBreadCrumbBar = computed(() => {
  if (route.path.includes('sign-in')) return false
  return true
})

const isAuthenticated = computed(() => userStore.isAuthenticated)
// methods

const toggleHandler = () => {
  showLeftNav.value = !showLeftNav.value
}

const isIPhoneSEClass = ref()

onMounted(() => {
  isIPhoneSEClass.value = isIPhoneSE() ? 'se-padding' : null
})

// :style="`max-height:${windowInnerHeight}`"
</script>
<template>
  <v-app :class="[isIPhoneSEClass]">
    <nav-top-bar
      :isDrawerOpen="showLeftNav"
      @toggleNav="toggleHandler"
      :style="isIPhoneSE() ? 'margin-top: 20px' : ''"
    />
    <!--<nav-left-drawer
      v-if="isAuthenticated && $vuetify.display.mdAndDown"
      v-model="showLeftNav"
      :style="
        showLeftNav
          ? 'transform: translateY(0) !important;'
          : 'transform: translateY(-110%) !important;'
      "
    />-->

    <v-main class="bg-grey-lighten-4 default-layout__main">
      <breadcrumb-bar
        v-if="userStore.user.isValidated && showBreadCrumbBar && isNotMobile"
        id="breadcrumbs"
        style="position: sticky; top: 54px; z-index: 999"
      />
      <slot />
      <add-to-home-screen v-if="isPhone() && !isPWA()" />
      <div id="pop-over"></div>
    </v-main>
    <page-footer v-if="!isMobile" />
    <nav-bottom v-if="isAuthenticated && isMobile" />
    <settings v-if="isAuthenticated" />
  </v-app>
</template>

<style lang="scss" scoped>
.default-layout__main {
  @media (orientation: landscape) {
    padding-left: env(safe-area-inset-left);
    padding-right: env(safe-area-inset-right);
  }
}

.se-padding {
  max-height: 647px;
  margin-top: 20px;
}
</style>
