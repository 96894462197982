import { FormFieldEmittedValue } from './FormData.model'
import { Incrementor } from './Util.model'
import {
  FormFieldDiff,
  WidgetFormConfig,
  WidgetFormFieldConfig,
  WidgetFormRowConfig,
} from './WidgetForm.model'

/**
 * A class that bundles together logic and handling for the data within a form
 * field
 */
export class FormFieldHandler {
  rawConfig: WidgetFormFieldConfig
  liveConfig: WidgetFormFieldConfig
  validationErrors: string[]
  id: string // An ID string to identify this field uniquely within the form
  sortableId: number // A sortable number on which to order errors and messages
  lockState: string[]
  isHidden: boolean
  isRequired: boolean
  isDisabled: boolean
  _isReadOnly: boolean

  focusCounter: number
  centerCounter: number
  originalValue: any

  /**
   * @param config The raw WidgetFormFieldConfig object that describes this form
   * field's behavior
   * @param id The question ID that this form field has been assigned
   * @param isReadOnly True if this field is read-only, false otherwise
   */
  constructor(
    config: WidgetFormFieldConfig,
    id: string,
    sortableId: number,
    isReadOnly = false
  ) {
    this.rawConfig = config
    this.id = id
    this.sortableId = sortableId
    this.isRequired = config?.isRequired ?? false
    this.isHidden = config?.isHidden ?? false
    this.isDisabled = config?.isDisabled ?? false
    this.originalValue = config?.value
    this.lockState = []
    this.validationErrors = []
    this.liveConfig = JSON.parse(JSON.stringify(this.rawConfig))
    this._isReadOnly = isReadOnly

    this.focusCounter = 0
    this.centerCounter = 0
  }

  /**
   * @returns This form field's type
   */
  getType(): string {
    return this.rawConfig.type
  }

  /**
   * Generates an html label for this form field
   * @param readOnly True if asking for the read-only label, false otherwise
   * @param withID True if the ID should be included in the label, false
   * otherwise
   * @returns A computed label for this form field using the given parameters
   */
  getLabel(readOnly = false, withID = true): string {
    let label = this.rawConfig.label ?? 'Field'
    if (this.isRequired && !readOnly) label = `${label}*`
    if (this.id && !readOnly && withID)
      label = `${label}&nbsp;<sup>(${this.id})</sup>`
    return label
  }

  /**
   * @returns The user-visible ID used to identify this question within the form
   */
  getId() {
    return this.id
  }

  /**
   * @returns The key used to look up this field in the FormConfigHandler
   */
  getFieldKey() {
    return this.liveConfig.name
  }

  getProp(propName: string): any {
    return (this.liveConfig as any)[propName]
  }

  setValue(value: any) {
    this.liveConfig.value = value
  }

  setReadOnlyState(isReadOnly: boolean) {
    this._isReadOnly = isReadOnly
  }

  isReadOnly(): boolean {
    return this._isReadOnly
  }

  isInteractive(): boolean {
    return !this.getProp('isHidden') && !this.getProp('isDisabled')
  }

  focus() {
    this.focusCounter++
  }

  center() {
    this.centerCounter++
  }

  /**
   * Produces a diff for this field's original value compared to the given value
   * @param ffev The FormFieldEmittedValue object describing the form change.
   * Using the ffev rather than the raw value allows a more holistic state to be
   * accounted for, including submit delay data
   * @returns A diff object representing the change or absence of change between
   * the original value and active value of this field
   */
  getDiff(ffev: FormFieldEmittedValue): FormFieldDiff {
    return new FormFieldDiff(ffev.value, this, ffev?.delayUpdate)
  }
}
