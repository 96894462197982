import { Timestamp } from 'firebase/firestore'
import { lookupRecordsetKey } from './lookup'

/**
 * Produces a copy of a given object with keys transformed from `PascalCase` to
 * `camelCase`
 * @param {*} obj An object to transform
 * @returns An object whose `CamelCase` keys correspond to the `PascalCase` ones
 * of the input object.
 */
export function objectToCamelCase(obj: any) {
  const camelObj = Object.create({})
  for (const key in obj) {
    if (
      ['Properties', 'Menu', 'Permissions', 'Widgets', 'MapData'].includes(key)
    ) {
      camelObj[prepareAttributeName(key)] = JSON.parse(obj[key])
    } else if (key.toLowerCase().includes('datetime') && obj[key]) {
      camelObj[prepareAttributeName(key)] = Timestamp.fromDate(obj[key])
    } else {
      camelObj[prepareAttributeName(key)] = obj[key]
    }
  }
  return camelObj
}

/**
 * Takes a record and the name of the table it comes from and returns the
 * record's primary key value
 * @param tableName The name of the table to which the given record belongs
 * @param record The record whose Primary Key to find
 * @returns The value of this record's primary key
 */
function getPK(tableName: string, record: any) {
  let attributeName = 'defaultJunkValue'
  if (
    Object.prototype.hasOwnProperty.call(
      record,
      lookupRecordsetKey(tableName) ?? ''
    )
  ) {
    attributeName = lookupRecordsetKey(tableName) ?? ''
  } else {
    console.log(`Key ${lookupRecordsetKey(tableName)} not found`)
    if (Object.prototype.hasOwnProperty.call(record, `${tableName}Id`)) {
      attributeName = `${tableName}Id`
    } else {
      console.log(`Key ${tableName}Id not found`)
    }
  }
  return record[attributeName]
}

/**
 * Transforms an attribute name from SQL-friendly format to JS-friendly format
 * @param {*} str An attribute name from an SQL table
 * @returns The corresponding attribute name for a JS-friendly object
 */
function prepareAttributeName(str: string) {
  if (str === 'UUID') {
    return 'uuid'
  }
  return pascalToCamel(str).replaceAll('_ID', 'Id')
}

/**
 * Transforms a string from `PascalCase` to `CamelCase`
 * @param {string} str A `PascalCase` string to transform to `CamelCase`
 * @returns A version of the input string where the first letter is not
 * capitalized
 */
function pascalToCamel(str: string) {
  return str.charAt(0).toLowerCase() + str.substr(1)
}
