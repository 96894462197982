<script setup>
import { ref, defineProps } from 'vue'
import { useRouter } from 'vue-router'

const compProps = defineProps({
  menuItems: Object,
  client: String,
  module: String,
  variant: String,
  mode: {
    type: String,
    default: 'horizontal',
  },
  avatarText: String,
})
const router = useRouter()

const isActive = (params = {}) => {
  const linkObj = {
    name: 'dynamic',
    params: {
      client: params.client,
      module: params.module,
      slug: params.slug,
    },
  }
  const routeData = router.resolve(linkObj)

  return routeData.href === router.currentRoute.value.path
}

const dropdownMenu = ref(false)
</script>

<template>
  <template v-if="compProps.mode === 'horizontal'">
    <template v-for="(menuItem, idx) in compProps.menuItems" :key="idx">
      <!-- -->
      <v-btn
        v-if="!menuItem.items"
        variant="flat"
        :to="{
          name: 'dynamic',
          params: {
            client: compProps.client ? compProps.client : menuItem.client,
            module: compProps.module ? compProps.module : menuItem.module,
            slug: menuItem.route,
          },
        }"
        :active="
          isActive({
            client: compProps.client ? compProps.client : menuItem.client,
            module: compProps.module ? compProps.module : menuItem.module,
            slug: menuItem.route,
          })
        "
        class="mr-2 py-1 px-2 rounded-sm bg-primary-dark text-white fs-13 text-capitalize"
      >
        <v-icon
          v-if="menuItem.icon && menuItem.icon !== ''"
          :icon="menuItem.icon"
          :class="{ 'mr-1 fs-13': menuItem.icon.includes('fa:') }"
        ></v-icon>
        <span>{{ menuItem.title }}</span>
      </v-btn>
      <v-menu v-else>
        <template v-slot:activator="{ props }">
          <v-btn
            v-if="compProps.variant === 'button'"
            color="white"
            icon="mdi-dots-vertical"
            v-bind="props"
          ></v-btn>
          <v-btn
            v-else-if="compProps.variant === 'avatar'"
            color="white"
            v-bind="props"
            class="pa-1 text-capitalize fs-13"
          >
            <v-avatar color="white" density="compact">
              <v-icon icon="mdi-account" color="grey" size="x-large"></v-icon>
            </v-avatar>
            <span v-if="$vuetify.display.smAndUp" class="ml-2">
              {{ compProps.avatarText }}
            </span>
            <v-icon
              icon="mdi-chevron-down"
              end
              color="white"
              size="x-large"
            ></v-icon>
          </v-btn>
          <v-btn
            v-else
            variant="flat"
            class="mr-2 pa-1 rounded-sm bg-primary-dark"
            v-bind="props"
          >
            <v-icon
              v-if="menuItem.icon && menuItem.icon !== ''"
              :icon="menuItem.icon"
              color="white"
              size="x-large"
              :class="{ 'mr-1 fs-13': menuItem.icon.includes('fa:') }"
            ></v-icon>
            <span class="text-capitalize"> {{ menuItem.title }} </span>
            <v-icon icon="mdi-chevron-down" size="x-large"></v-icon>
          </v-btn>
        </template>
        <v-list>
          <v-list-item
            v-for="(item, index) in menuItem.items"
            :to="{
              name: 'dynamic',
              params: {
                client: compProps.client ? compProps.client : menuItem.client,
                module: compProps.module ? compProps.module : menuItem.module,
                slug: item.route,
              },
            }"
            :key="index"
          >
            <v-list-item-title class="text-capitalize">
              <v-icon
                v-if="item.icon && item.icon !== ''"
                :icon="item.icon"
                :class="{ 'mr-1 fs-13': menuItem.icon.includes('fa:') }"
              ></v-icon>
              {{ item.title }}</v-list-item-title
            >
          </v-list-item>
        </v-list>
      </v-menu>
    </template>
  </template>
  <template v-if="compProps.mode === 'dropdown'">
    <v-menu
      v-model="dropdownMenu"
      :close-on-content-click="false"
      ref="parentMenuRef"
    >
      <template v-slot:activator="{ props }">
        <v-btn color="white" v-bind="props" class="mx-0 px-5"
          ><v-icon icon="mdi-menu" size="30"></v-icon
        ></v-btn>
      </template>
      <v-list>
        <template v-for="(menuItem, idx) in compProps.menuItems" :key="idx">
          <v-list-item
            v-if="!menuItem.items"
            :to="{
              name: 'dynamic',
              params: {
                client: compProps.client ? compProps.client : menuItem.client,
                module: compProps.module ? compProps.module : menuItem.module,
                slug: menuItem.route,
              },
            }"
            @click="dropdownMenu = false"
          >
            <v-list-item-title class="d-flex text-capitalize">
              <v-icon
                v-if="menuItem.icon && menuItem.icon !== ''"
                :icon="menuItem.icon"
                :class="[
                  'text-center',
                  { 'mr-1 fs-16': menuItem.icon.includes('fa:') },
                ]"
              ></v-icon>
              <span>{{ menuItem.title }}</span>
            </v-list-item-title>
          </v-list-item>
          <v-list-group
            v-else
            no-action
            expand-icon="mdi-chevron-down"
            collapse-icon="mdi-chevron-up"
          >
            <template v-slot:activator="{ props: subProp }">
              <v-list-item v-bind="subProp" class="mb-1">
                <template v-slot:prepend>
                  <v-icon
                    v-if="menuItem.icon && menuItem.icon !== ''"
                    :icon="menuItem.icon"
                    :class="[
                      'mr-1',
                      { 'fs-16': menuItem.icon.includes('fa:') },
                    ]"
                  >
                  </v-icon>
                </template>
                <v-list-item-title>{{ menuItem.title }}</v-list-item-title>
              </v-list-item>
            </template>
            <v-list-item
              v-for="(item, subIdx) in menuItem.items"
              :to="{
                name: 'dynamic',
                params: {
                  client: compProps.client ? compProps.client : menuItem.client,
                  module: compProps.module ? compProps.module : menuItem.module,
                  slug: item.route,
                },
              }"
              link
              class="mb-1"
              @click.prevent="dropdownMenu = false"
              :key="subIdx"
            >
              <template v-slot:prepend>
                <v-icon
                  v-if="item.icon && item.icon !== ''"
                  :icon="item.icon"
                  :class="['mr-2', { 'fs-16': menuItem.icon.includes('fa:') }]"
                >
                </v-icon>
              </template>
              {{ item.title }}
            </v-list-item>
          </v-list-group>
        </template>
      </v-list>
    </v-menu>
  </template>
  <template v-if="compProps.mode === 'vertical'">
    <v-list>
      <template v-for="(menuItem, idx) in compProps.menuItems" :key="idx">
        <v-list-item
          v-if="!menuItem.items"
          :to="{
            name: 'dynamic',
            params: {
              client: compProps.client ? compProps.client : menuItem.client,
              module: compProps.module ? compProps.module : menuItem.module,
              slug: menuItem.route,
            },
          }"
          class="mb-1 bg-primary-dark"
        >
          <v-list-item-title>{{ menuItem.title }}</v-list-item-title>
        </v-list-item>
        <v-list-group v-else>
          <template v-slot:activator="{ props }">
            <v-list-item v-bind="props" class="mb-1 bg-primary-dark">
              <v-list-item-title>{{ menuItem.title }}</v-list-item-title>
            </v-list-item>
          </template>
          <v-list-item
            v-for="(item, subIdx) in menuItem.items"
            :to="{
              name: 'dynamic',
              params: {
                client: compProps.client ? compProps.client : menuItem.client,
                module: compProps.module ? compProps.module : menuItem.module,
                slug: item.route,
              },
            }"
            link
            class="mb-1 bg-primary-dark"
            :key="subIdx"
          >
            {{ item.title }}
          </v-list-item>
        </v-list-group>
      </template>
    </v-list>
  </template>
</template>
