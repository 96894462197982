<script setup>
import { ref, computed } from 'vue'
import {
  getAuth,
  reauthenticateWithCredential,
  EmailAuthProvider,
  updatePassword,
} from 'firebase/auth'
import { useRouter } from 'vue-router'
import { useUserStore } from '@/stores/user'
import { recordLog } from '@/services/logging'

const auth = getAuth()
const userStore = useUserStore()
const router = useRouter()

const isSubmittingData = ref(false)
const hasPasswordChanged = ref(false)

const passwordRules = ref([
  v => !!v || 'Password is required',
  v =>
    /^(?=.*[A-Z])(?=.*[!@#$&*])(?=.*[0-9])(?=.*[a-z]).{10,}$/.test(v) ||
    'This must be a valid password',
])

const formIsValid = ref(false)
const currentPassword = ref()
const newPassword = ref()
const confirmPassword = ref()
const passwordIsHidden = ref(true)
const newPasswordIsHidden = ref(true)
const confirmPasswordIsHidden = ref(true)

// computed

const confirmRules = computed(() => {
  return newPassword.value === confirmPassword.value || 'Password must match'
})

const fieldsFilled = computed(() => {
  return (
    currentPassword.value?.length > 0 &&
    newPassword.value?.length > 0 &&
    confirmPassword.value?.length > 0
  )
})

//methods
const submitHandler = async () => {
  if (!formIsValid.value) return
  const user = auth.currentUser
  isSubmittingData.value = true
  const cred = EmailAuthProvider.credential(user.email, currentPassword.value)
  try {
    await reauthenticateWithCredential(user, cred)

    // User entered correct credentials
    // Update password
    await updatePassword(auth.currentUser, newPassword.value)
    hasPasswordChanged.value = true
    isSubmittingData.value = false
    recordLog('Change password', 'Success')
    // router.push({
    //  name: 'dynamic',
    //  params: {
    //    client: userStore.getCurrentClientName,
    //    module: userStore.getCurrentModuleName,
    // },
    //})
  } catch (e) {
    console.log(e.code, e.message)
    recordLog('Change password', 'Fail', e?.message)
    // Could be incorrect credentials
  }
}
</script>

<template>
  <v-container fluid>
    <v-card
      :width="$vuetify.display.mdAndUp ? 400 : 'auto'"
      class="mx-auto pa-5"
    >
      <v-btn
        icon="mdi-lock"
        variant="outlined"
        color="primary"
        size="x-large"
        disabled="true"
        style="border-width: 3px"
      ></v-btn>
      <v-card-title class="pb-0 font-weight-bold">Change Password</v-card-title>
      <v-card-subtitle>Enter your credentials below</v-card-subtitle>
      <v-alert v-if="hasPasswordChanged" type="success" class="my-2"
        >Password Successfully Changed</v-alert
      >
      <v-form
        v-model="formIsValid"
        class="my-5"
        @submit.prevent="submitHandler()"
      >
        <v-text-field
          v-model="currentPassword"
          variant="outlined"
          prepend-inner-icon="mdi-lock"
          label="Enter your current password"
          :validate-on="currentPassword ? 'blur' : 'submit'"
          :type="passwordIsHidden ? 'password' : 'text'"
          :append-inner-icon="passwordIsHidden ? 'mdi-eye-off' : 'mdi-eye'"
          @click:append-inner="passwordIsHidden = !passwordIsHidden"
        />
        <v-text-field
          v-model="newPassword"
          variant="outlined"
          prepend-inner-icon="mdi-lock"
          label="New Password"
          :rules="passwordRules"
          :validate-on="newPassword ? 'blur' : 'submit'"
          :type="newPasswordIsHidden ? 'password' : 'text'"
          :append-inner-icon="newPasswordIsHidden ? 'mdi-eye-off' : 'mdi-eye'"
          @click:append-inner="newPasswordIsHidden = !newPasswordIsHidden"
        />
        <v-text-field
          v-model="confirmPassword"
          variant="outlined"
          prepend-inner-icon="mdi-lock"
          label="Confirm Password"
          :rules="[passwordRules, confirmRules]"
          :validate-on="confirmPassword ? 'blur' : 'submit'"
          :type="confirmPasswordIsHidden ? 'password' : 'text'"
          :append-inner-icon="
            confirmPasswordIsHidden ? 'mdi-eye-off' : 'mdi-eye'
          "
          @click:append-inner="
            confirmPasswordIsHidden = !confirmPasswordIsHidden
          "
        />

        <v-alert
          color="yellow-lighten-4"
          class="mb-5 pa-2 text-left"
          style="border: 1px solid olive; line-height: 1"
        >
          <v-alert-title class="text-subtitle-1"
            >Password requirements</v-alert-title
          >
          <em class="text-body-2">
            Minimum of 10 characters, at least 1 lower case letter, 1 uppercase
            letter, 1 number, and 1 special character.
          </em>
        </v-alert>

        <v-btn
          :disabled="!fieldsFilled || isSubmittingData"
          type="submit"
          color="primary"
          prepend-icon="mdi-chevron-right-circle-outline"
          >Update Password</v-btn
        >
      </v-form>
    </v-card>
  </v-container>
</template>
