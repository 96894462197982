import { typedArrayToURL } from './utils'
import { WidgetFormConfig } from '@/model/WidgetForm.model'
import { PDFDocument, StandardFonts, rgb } from 'pdf-lib'
import { PDFExportOptions, RecordExportHelper } from '@/model/Export.model'

export async function generateSamplePDF(): Promise<string> {
  const pdfDoc = await PDFDocument.create()
  const timesRomanFont = await pdfDoc.embedFont(StandardFonts.TimesRoman)

  const page = pdfDoc.addPage()
  const { width, height } = page.getSize()
  const fontSize = 30
  page.drawText('Creating PDFs in JavaScript is awesome!', {
    x: 50,
    y: height - 4 * fontSize,
    size: fontSize,
    font: timesRomanFont,
    color: rgb(0, 0.53, 0.71),
  })

  const pdfBytes = await pdfDoc.save()
  return typedArrayToURL(pdfBytes, 'sample.pdf', 'application/pdf')
}

/**
 * Creates a PDF report from the given layout and record, then returns its URL
 * @param layout The form layout that should be used to export the given
 * record to a PDF
 * @param record The data that should populate the given form layout
 * @param options Optional parameters that may help in creating the PDF, such
 * as the record type and record ID
 */
export async function generatePDFFromRecord(
  layout: WidgetFormConfig,
  record: any,
  options?: PDFExportOptions
): Promise<string> {
  const reh = new RecordExportHelper(layout, record, options)
  return reh.getData()
}
